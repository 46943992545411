import React, {useEffect, useRef, useState} from "react";
import {useInjection} from "inversify-react";
import {useNavigate, useParams} from "react-router-dom";
import {
    Container,
    ContentFormTicketsView,
    ContentFormViewTicket,
    ContentView,
    Watermark,
} from "../../pages/ticket/ui/styled";
import {observer} from "mobx-react";
import GenericScreenNotFound from "@dropDesk/presentation/components/generic_screen_not_found";
import {TicketController} from "@dropDesk/presentation/pages/ticket/controller/ticket.controller";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import HeaderViewInformation from "@dropDesk/presentation/components/chat_view/header_view_information";
import HeaderActionButtons from "@dropDesk/presentation/components/chat_view/header_action_buttons";
import ModalsViewTicket from "@dropDesk/presentation/components/chat_view/modals";
import ChatMessage, {ChildRefType} from "@dropDesk/presentation/components/chat_view/chat_message";
import ImageEditor from "@dropDesk/presentation/pages/image_editor/ui";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import toast_message from "@dropDesk/utils/toast_message/toast_message";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {useTranslation} from "react-i18next";
import {ImageEditorController} from "@dropDesk/presentation/pages/image_editor/controller/image_editor.controller";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {ActiveRouteEnum, ChatController} from "@dropDesk/presentation/pages/chat/controller/chat.controller";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {AudioRecordEntity} from "@dropDesk/domain/entities/common/audio_record.entity";
import {FileEditionEntity} from "@dropDesk/domain/entities/image_editor/image_editor.entity";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import PanelInformTicketWaiting from "@dropDesk/presentation/components/chat_view/panel_inform_ticket_waiting";
import {CancelTokenManager} from "@dropDesk/data/clients/http.client";
import {useBetween} from "use-between";
import {useModalViewTickets} from "@dropDesk/presentation/components/chat_view/modals/visible_modal_view_ticket";
import {
    TicketEvaluationController
} from "@dropDesk/presentation/pages/ticket/evaluation/controller/ticket_evaluation.controller";
import {CancelTokenSource} from "axios";
import ContentInfoDisconnectedSubscription from "src/presentation/components/content_info_disconnected_subscription";

const ChatView = observer((
        {
            chatId,
            widthContainerListChats
        }: {
            chatId?: string
            widthContainerListChats?: number
        }
    ) => {
        const {t} = useTranslation();
        const ticketController: TicketController = useInjection(TicketController);
        const chatController: ChatController = useInjection(ChatController);
        const appController = useInjection(AppController);
        const imageEditorController = useInjection(ImageEditorController);
        const evaluationController = useInjection(TicketEvaluationController);
        const userLogged = appController.user!;
        const colors = appController.theme.colorScheme;
        const {id} = useParams();
        const isNewRegister: string = 'new';
        const useProblemStatic: boolean = userLogged.company.configurations.ticket.useProblemStatic;
        const realId: string = chatId ?? id ?? isNewRegister;
        const isChildView: boolean = !!chatId;
        const currentTicket: TicketEntity | undefined | null = chatController.currentChat;
        const navigate = useNavigate();
        const childRef = useRef<ChildRefType | null>(null);
        const [loadingReconnect, setLoadingReconnect] = useState<boolean>(false);
        const [hasErrorConnectSocket, setHasErrorConnectSocket] = useState<boolean>(false);
        const [hasErrorConnectSocketTemporary, setHasErrorConnectSocketTemporary] = useState<boolean>(false);

        useEffect(() => {
            if (isChildView && !!chatController.currentChat && chatController.currentChat.id !== ticketController.ticket?.id) {
                imageEditorController.setIdTicket(chatController.currentChat.id);
                ticketController.eraseTicket();
                readInfoTicket(ActiveRouteEnum.chat).then();
            }
        }, [chatController.currentChat]);

        useEffect(() => {
            if (!isChildView && id !== ticketController.ticket?.id) {
                readInfoTicket(ActiveRouteEnum.ticket).then();
            }
            return () => {
                ticketController.dispose();
                if (!isChildView) {
                    chatController.leaveTicket();
                    chatController.removeAllValues();
                    chatController.dispose();
                    imageEditorController.deleteAllCache();
                }
            }
        }, [id]);

        const {
            setVisibleModalTicketRating,
            setVisibleModalTicketClosed,
            setVisibleModalConnect
        } = useBetween(useModalViewTickets);

        const readInfoTicket = async (route: ActiveRouteEnum) => {
            try {
                const cancelTokenSource = CancelTokenManager.createNewToken();
                chatController.startLoading();
                chatController.setActiveRoute(route);

                if (!chatController.currentChat) {
                    await fetchTicket(realId, route, cancelTokenSource);
                } else {
                    fetchTicket(realId, route, cancelTokenSource);
                }

                if (route === ActiveRouteEnum.ticket) {
                    await chatController.initialize(userLogged, ActiveRouteEnum.ticket, handleDisconnect, handleGetLastUpdates, realId, navigate, chatController.currentChat?.hasActiveStatus);
                }
                await chatController.loadStaticMessages(cancelTokenSource);
                await chatController.informReadMessagesIfNeeded(chatController.messages, cancelTokenSource);
            } catch (error) {
                console.error(error);
            } finally {
                CancelTokenManager.clearToken();
            }
        }

        const handleDisconnect = (temporary: boolean) => {
            if (temporary) {
                setHasErrorConnectSocketTemporary(true);
            } else {
                setHasErrorConnectSocket(true);
                setVisibleModalConnect(true);
                setHasErrorConnectSocketTemporary(false);
            }
        }

        const handleReconnectSocket = async (): Promise<void> => {
            try {
                setLoadingReconnect(true);
                await readInfoTicket(ActiveRouteEnum.ticket);
                setVisibleModalConnect(false);
                setHasErrorConnectSocket(false);
                setLoadingReconnect(false);
                setHasErrorConnectSocketTemporary(false);
            } catch (e) {
                setHasErrorConnectSocket(true);
                setVisibleModalConnect(true);
                setLoadingReconnect(false);
                toast_message.error('Não foi possível estabelecer a conexão. Por favor, tente novamente.' +
                    ' Se o problema persistir, entre em contato com a equipe da DropDesk');
            }
        }

        const handleGetLastUpdates = async (): Promise<void> => {
            await Promise.all([
                chatController.loadStaticMessages(),
                fetchTicket(chatController.currentChat!.id, ActiveRouteEnum.ticket)
            ]);
            setHasErrorConnectSocketTemporary(false);

        }

        const fetchTicket = async (realId: string, route: ActiveRouteEnum, cancelTokenSource?: CancelTokenSource,): Promise<void> => {
            await ticketController.getTicket(realId, !chatController.currentChat, cancelTokenSource)
            chatController.setChat(ticketController.ticket!, !chatController.currentChat);
            if (route === ActiveRouteEnum.ticket && ticketController.ticket) {
                chatController.updateCacheDataSource(ticketController.ticket);
            }
        }

        const onSaveFileEditor = async (currentTicket: TicketEntity, filesEdition: FileEditionEntity[]): Promise<void> => {
            const currentMessage = childRef.current?.getCurrentMessage();
            const filesSave = filesEdition.map((entry) => entry.fileMessage);
            const _filesMessage = [...filesSave];
            const messages: TicketMessageEntity[] = chatController.parseFileToMessage(currentTicket, _filesMessage, userLogged);
            messages[0].reply = currentMessage?.reply;
            chatController.handleSendMessages(messages, currentTicket, userLogged);
        }

        const sendAudio = async (message: TicketMessageEntity, audioRecorder: AudioRecordEntity) => {
            const _message = await chatController.parseAudioToMessage(
                currentTicket!,
                audioRecorder,
                userLogged,
                message
            );
            chatController.handleSendMessages([_message], currentTicket!, userLogged);
        }

        const handleTransferTicket = async (sector: SectorEntity, message: TicketMessageEntity, user?: UserEntity) => {
            if (!!message.getText) {
                message.isPrivate = true;
                await chatController.handleSendMessages([message], currentTicket!, userLogged);
            }
            await ticketController.handleTransferTicket(sector, onSuccess, user);
        }

        const onSuccess = (key?: string, ticket?: TicketEntity) => {
            if (key) {
                toastMessage.success(t(key));
            }
            if (ticket) {
                if (ticket.isMeStartingChat && chatController.activeRoute === ActiveRouteEnum.chat) {
                    const allChats = [...chatController.tableListChats.data, ...chatController.tableListWaiting.data, ticket];
                    chatController.handleChangesByChats(allChats);
                } else {
                    chatController.setChat(ticket, false);
                    chatController.handleUpdateTicket(ticket);
                }
            }
        }

        const onSuccessEvaluation = (evaluation: TicketEvaluationEntity, currentTicket: TicketEntity) => {
            const newTicket = currentTicket.copyWith({
                evaluations: [...currentTicket?.evaluations ?? [], evaluation]
            });
            chatController.setChat(newTicket, false);
            ticketController.setTicket(newTicket);
        }


        const handleEvaluate = async () => {
            if (currentTicket && currentTicket.hasActiveStatus) {
                setVisibleModalTicketClosed(true);
            } else {
                await setEvaluate();
            }
            setVisibleModalTicketRating(false);
        }

        const handleClosed = async (description?: string) => {
            await ticketController.handleTicketClosed(onSuccess, description);
            if (evaluationController.ticketEvaluation) {
                await setEvaluate();
            }
        }

        const setEvaluate = async () => {
            if (evaluationController.ticketEvaluation && chatController.currentChat) {
                const ticket = await evaluationController.set(onSuccess);
                onSuccessEvaluation(evaluationController.ticketEvaluation, ticket ?? chatController.currentChat);
            }
        }

        const canChange = currentTicket?.canChangeTicket(userLogged) ?? false;
        const canSendMessage = currentTicket?.canSendMessage(userLogged) ?? false;
        const fixHeight: number = isChildView && imageEditorController.filesEdition.length === 0 ? 35 + appController.heightShowingHeaderInfo : 0 + appController.heightShowingHeaderInfo;

        return (
            <Container background={colors.backgroundChatBox} fixHeight={fixHeight}>
                <ContentFormTicketsView>
                    <ContentFormViewTicket background={"transparent"} border={colors.border}>
                        <ContentView background={colors.backgroundChatBox}>
                            <Watermark isDarkTheme={colors.brightness === Brightness.dark}
                                       background={colors.backgroundChatBox}>
                                {(!ticketController.ticketsNotFound && currentTicket) &&
                                    <>
                                        <HeaderViewInformation
                                            ticket={currentTicket}
                                            canChange={(canChange && userLogged.isAttendant)}
                                            canChangeClient={(canChange && userLogged.isAttendant && !!currentTicket.client)}
                                            alertPulseAnimation={currentTicket.lastMessage?.isSystemMessage && chatController.activeRoute === ActiveRouteEnum.ticket}
                                            loading={ticketController.loading}
                                            widthContainerListChats={widthContainerListChats}
                                        />
                                        <HeaderActionButtons
                                            canChange={canChange}
                                            findByPK={() => ticketController.getTicket(currentTicket.id)}
                                            permissionCancelTicket={userLogged.permissionCancelTicket}
                                            useButtonStartChat={currentTicket.hasOpenStatus && !currentTicket.transferIfEmptySector(appController.user!)}
                                            useButtonTicketRating={!!ticketController.ticket && currentTicket.isEligibleRating(userLogged) && currentTicket.hasWaitingReview}
                                            isEligibleRating={!!ticketController.ticket && currentTicket.isEligibleRating(userLogged)}
                                            hasInactiveStatus={!currentTicket.hasActiveStatus}
                                            hasClosedStatus={currentTicket.hasClosedStatus}
                                            closedByInactiveTime={currentTicket.closedByInactiveTime}
                                            hasCanceledStatus={currentTicket.hasCanceledStatus}
                                            setCompactModeMessage={(value) => chatController.setCompactModeMessage(value)}
                                            compactModeMessages={chatController.compactModeMessages}
                                            transferIfEmptySector={currentTicket.transferIfEmptySector(appController.user!)}
                                            userLogged={userLogged}
                                        />
                                        {userLogged.isUserClient && currentTicket.hasOpenStatus &&
                                            < PanelInformTicketWaiting
                                                businessOperation={userLogged.company.configurations.businessOperationHours}
                                            />}

                                        {(hasErrorConnectSocket || hasErrorConnectSocketTemporary) &&
                                            <ContentInfoDisconnectedSubscription
                                                loading={loadingReconnect}
                                                onClickReconnect={() => handleReconnectSocket()}
                                                visibleTemporaryInformation={hasErrorConnectSocketTemporary}
                                            />}

                                        <ChatMessage
                                            handlePickFile={(files) => imageEditorController.handlePickFile(files)}
                                            handleOnScrollTop={() => chatController.loadMoreMessages('top')}
                                            handleOnScrollBottom={() => chatController.loadMoreMessages('bottom')}
                                            canChange={canChange}
                                            canSendMessage={canSendMessage}
                                            userLogged={userLogged}
                                            compactModeMessages={chatController.compactModeMessages}
                                            userClient={currentTicket!.userClient!}
                                            messages={chatController.messages}
                                            idMessageFocused={chatController.idMessageFocused}
                                            idCurrentTicket={currentTicket.id}
                                            handleClickOnMessageReply={(reply) => chatController.handleClickOnMessageReply(reply)}
                                            cancelUpload={(upload) => {
                                                upload?.cancel();
                                            }}
                                            targetMessagePaginationTop={chatController.targetMessagePaginationTop}
                                            targetMessagePaginationBottom={chatController.targetMessagePaginationBottom}
                                            targetMessageButtonNavigateBottom={chatController.targetMessageButtonNavigateBottom}
                                            targetVirtualizedMessage={chatController.targetVirtualizedMessage}
                                            handleScrollTopVirtualizedMessage={() => chatController.handleScrollTopVirtualizedMessage()}
                                            handleMoveCursorToFinal={() => chatController.handleMoveCursorToFinal()}
                                            onRetrySendMessageError={(message) => chatController.retrySendMessage(message, currentTicket, userLogged)}
                                            bottomMessageLoading={((chatController.currentChatMessages.data.length === 0 || chatController.scrollPosition === 'bottom') && chatController.loading)}
                                            topMessageLoading={chatController.currentChatMessages.data.length > 0 && chatController.scrollPosition !== 'bottom' && chatController.loading}
                                            sendMessage={(message) => chatController.handleSendMessages([message], currentTicket, userLogged)}
                                            handleRemoveReaction={(reaction, messageReacted) => chatController.removeReaction(messageReacted, reaction, currentTicket)}
                                            handleAddReaction={(reaction, messageToReacted) => chatController.handleAddReaction(messageToReacted, reaction, userLogged, currentTicket)}
                                            lastUsedReactions={chatController.lastUsedReactions}
                                            sendAudio={(message, audioRecorder) => sendAudio(message, audioRecorder)}
                                            handleEditMessage={(ediText, currentMessage) => chatController.editMessage(ediText, currentMessage, currentTicket)}
                                            handleDeleteMessage={(message) => chatController.delete(currentTicket, message)}
                                            handleResetMessageFocused={() => chatController.handleResetMessageFocused()}
                                            allReactions={chatController.reactions}
                                            attendant={currentTicket.attendant}
                                            stopLoading={() => chatController.stopLoading()}
                                            isMeAttendingTicket={currentTicket.hasAttendingStatus && currentTicket.attendant?.id === userLogged.id}
                                            ticketIsOpen={currentTicket.hasOpenStatus}
                                            childRef={childRef}
                                            canEditFile={canSendMessage && !!currentTicket.id}
                                        />
                                        <ModalsViewTicket
                                            handleTransferTicket={(sector, message, user) => handleTransferTicket(sector, message, user)}
                                            handleTicketClosed={(description) => handleClosed(description)}
                                            handleCancellationTicket={(description) => ticketController.handleCancellationTicket(onSuccess, description)}
                                            handleChangePriority={(priority) => ticketController.handleChangePriority(priority, onSuccess)}
                                            handleChangeTimeSpent={(timeSpent) => ticketController.handleChangeTimeSpent(timeSpent, onSuccess)}
                                            handleChangeClient={(client) => ticketController.handleChangeClient(client, onSuccess)}
                                            onSuccessTransferUser={(client) => ticketController.handleChangeClient(client)}
                                            handleChangeDescription={(description, descriptionStatic) => ticketController.handleChangeDescription(onSuccess, description, descriptionStatic)}
                                            handleChangeDateDue={(dateDue) => ticketController.handleChangeDateDue(dateDue, onSuccess)}
                                            useProblemStatic={useProblemStatic}
                                            informCancellationDescription={(appController.user?.company.configurations.ticket.informReasonCancellation ?? false)}
                                            informSolutionDescription={(appController.user?.company.configurations.ticket.informResolutionTicket && !appController.isUserClient ? true : false)}
                                            loading={ticketController.loading}
                                            ticket={ticketController.ticket}
                                            listBySectorsByUserLinkedInTransferTicket={undefined}
                                            userLogged={userLogged}
                                            attendChat={() => ticketController.handleLinkAttendant(userLogged, (ticket) => onSuccess('ticket.success.link', ticket))}
                                            exportPdf={() => ticketController.exportPdf(onSuccess)}
                                            currentTicket={currentTicket}
                                            changeResolution={(description) => ticketController.handleChangeResolution(onSuccess, description)}
                                            ticketEvaluation={evaluationController.ticketEvaluation}
                                            setTicketEvaluation={(ticketEvaluation) => evaluationController.setTicketEvaluation(ticketEvaluation)}
                                            loadingEvaluation={evaluationController.loading}
                                            loadingMessageEvaluation={evaluationController.loadingMessage}
                                            makeEvaluation={() => evaluationController.makeEvaluation(currentTicket)}
                                            handleEvaluate={handleEvaluate}
                                            reconnectSocket={() => handleReconnectSocket()}
                                        />

                                        <ImageEditor
                                            onSave={async (referenceImageEdited) => {
                                                if (!imageEditorController.loading) {
                                                    imageEditorController.startLoading('imageEditor.loadingSave');
                                                    imageEditorController.setLoadableDesignState(referenceImageEdited);
                                                    const filesEdition = await imageEditorController.save();
                                                    onSaveFileEditor(currentTicket, filesEdition);
                                                }
                                            }}
                                            ticketHasActiveStatus={currentTicket.hasActiveStatus}
                                            imageEditorController={imageEditorController}
                                            idTicket={currentTicket.id}
                                            widthContainerListChats={widthContainerListChats}
                                        />


                                    </>
                                }
                                {
                                    ticketController.ticketsNotFound &&
                                    <GenericScreenNotFound
                                        textNotFound={'Oops, Atendimento não encontrado ...'}
                                        textHeaderForm={"Voltar"}
                                    />
                                }
                            </Watermark>
                        </ContentView>

                    </ContentFormViewTicket>

                </ContentFormTicketsView>
                {
                    (ticketController.loading && ticketController.loadingMessage) && <DropDeskLoading
                        height={250}
                        description={ticketController.loadingMessage}
                    />
                }
            </Container>
        )
            ;
    })
;

export default ChatView;
