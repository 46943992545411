import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";

@injectable()
export class ListTicketsUseCase {
    private _repository: TicketRepository;

    constructor(@inject(TicketRepository) repository: TicketRepository) {
        this._repository = repository;
    }

    public call(
        searchParam: string,
        page: number,
        limit: number,
        listOnlyDeleted: boolean,
        status: string | null,
        priority: string | null,
        listOnlySectorEmpty: boolean,
    ): Promise<ListPaginationEntity<TicketEntity>> {
        return this._repository.list(
            searchParam,
            page,
            limit,
            listOnlyDeleted,
            status,
            priority,
            listOnlySectorEmpty,
        );
    }
}

