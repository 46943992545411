import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {inject, injectable} from "inversify";
import {PlanEntity} from "@dropDesk/domain/entities/plan/plan_entity";
import {SubscriptionRemoteDataSource} from "@dropDesk/data/data_source/subscription/subscription_remote.datasource";
import {SubscriptionRepository} from "@dropDesk/domain/repositories/subscription/subscription.repository";
import {
    PaymentSubscriptionEntity,
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {Observable} from "rxjs";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {PaymentStatus} from "@dropDesk/domain/entities/payment/payment.enum";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {
    PaymentSubscriptionSimulateEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_simulate.entity";

@injectable()
export class SubscriptionRepositoryImpl implements SubscriptionRepository {
    private _dataSource: SubscriptionRemoteDataSource;

    constructor(@inject(SubscriptionRemoteDataSource) dataSource: SubscriptionRemoteDataSource) {
        this._dataSource = dataSource;
    }

    public findByPK(id: string): Promise<PaymentSubscriptionEntity> {
        try {
            return this._dataSource.findByPK(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public listPlan(listPlansLegacy: boolean): Promise<PlanEntity[]> {
        try {
            return this._dataSource.listPlan(listPlansLegacy);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public pay(subscription: PaymentSubscriptionEntity, paymentMethod?: PaymentMethodEntity): Promise<PaymentSubscriptionEntity> {
        try {
            return this._dataSource.pay(subscription, paymentMethod);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public getPaymentSubscription(id: string): Observable<PaymentEntity> {
        try {
            return this._dataSource.getPaymentSubscription(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public list(page: number, limit: number, status?: PaymentStatus): Promise<ListPaginationEntity<PaymentEntity>> {
        try {
            return this._dataSource.list(page, limit, status);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public simulate(subscription: PaymentSubscriptionEntity): Promise<PaymentSubscriptionSimulateEntity> {
        try {
            return this._dataSource.simulate(subscription);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public getPdfByPaymentId(payment: PaymentEntity): Promise<void> {
        try {
            return this._dataSource.getPdfByPaymentId(payment);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public cancel(
        idSubscription: string,
        cancellationReason: string,
        isFutureSubscription: boolean
    ): Promise<PaymentSubscriptionEntity> {
        try {
            return this._dataSource.cancel(idSubscription, cancellationReason, isFutureSubscription);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public reactivate(idSubscription: string): Promise<PaymentSubscriptionEntity> {
        try {
            return this._dataSource.reactivate(idSubscription);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }
}
