import {observer} from "mobx-react";
import {
    Container,
    Content, ContentDescription, ContentIcon, ContentSubTitle, TextBold,
    Text,Image
} from "./styled";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import React from "react";
import {useBetween} from "use-between";
import {useModalsSubscription} from "@dropDesk/presentation/pages/subscription/ui/modals/visible_modal_subscription";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import PlanPeriodValue from "@dropDesk/presentation/pages/subscription/ui/modals/plan_period_value";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import rejectPaymentImage from "@dropDesk/storage/images/reject_payment.png";

const RejectedPayCreditCard = observer(
    ({
         newSubscription,
         labelNewPeriod
     }: {
        newSubscription: PaymentSubscriptionEntity
        labelNewPeriod: string
    }) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            setVisibleModalRejectedCreditCardPayment
        } = useBetween(useModalsSubscription);

        return (
            <Container>
                <HeaderModal
                    disabled={false}
                    letter={'Concluir assinatura'}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={() => setVisibleModalRejectedCreditCardPayment(false)}
                />
                <Content background={colors.onBackground}>

                    <ContentIcon>
                        <Image src={rejectPaymentImage} alt={"Pagamento rejeitado"}/>
                    </ContentIcon>

                    <ContentSubTitle>
                        <TextBold size={20} color={colors.text}>Pagamento em rejeitado...</TextBold>
                    </ContentSubTitle>

                    <ContentDescription>
                        <Text size={16} color={colors.text}>Pagamento rejeitado. Por favor, verifique os dados inseridos ou tente outro método de pagamento.</Text>
                    </ContentDescription>

                    <PlanPeriodValue
                        newSubscription={newSubscription}
                        labelNewPeriod={labelNewPeriod}
                    />

                </Content>
            </Container>
        )
    })
export default RejectedPayCreditCard;
