import "reflect-metadata";
import {inject, injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ExportRemoteDataSource} from "@dropDesk/data/data_source/export/export_remote.datasource";
import {Observable, Observer} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export abstract class SectorRemoteDataSource {
    public abstract set(sector: SectorEntity): Promise<SectorEntity>;

    public abstract list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        idUser?: string,
        listableForClients?: boolean
    ): Promise<ListPaginationEntity<SectorEntity>>;

    public abstract restore(sectors: SectorEntity[]): Promise<void>;

    public abstract delete(sectors: SectorEntity[]): Promise<void>;

    public abstract findByPK(id: string): Promise<SectorEntity>;

    public abstract export(id?: string): Promise<ExportDataEntity>;

    public abstract exportSubscription(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null>;

}

@injectable()
export class SectorRemoteDataSourceImpl implements SectorRemoteDataSource {
    private _exportDataSource: ExportRemoteDataSource;

    constructor(
        @inject(ExportRemoteDataSource) exportDataSource: ExportRemoteDataSource
    ) {
        this._exportDataSource = exportDataSource;
    }

    baseSectorUrl: string = 'sectors/';
    baseSetSectorUrl: string = `${this.baseSectorUrl}set`;
    baseRestoreSectorUrl: string = `${this.baseSectorUrl}restore/`;
    baseUrlExport: string = `${this.baseSectorUrl}export/start`;


    public async list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        idUser?: string,
        listableForClients?: boolean
    )
        : Promise<ListPaginationEntity<SectorEntity>> {
        return new Promise<ListPaginationEntity<SectorEntity>>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.baseSectorUrl}?searchParam=${searchParam ?? ''}&page=${page ?? ''}&limit=${limit ?? ''}&type=${listOnlyDeleted ? 'deleted' : ''}&idUser=${idUser ?? ''}&listableForClients=${listableForClients ?? ''}`);
                const result = new ListPaginationEntity<SectorEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((json: Record<string, any>) => SectorEntity.fromJson(json)),
                });
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public set(sector: SectorEntity): Promise<SectorEntity> {
        return new Promise<SectorEntity>(async (resolve, reject) => {
            try {

                const response = await api.post(this.baseSetSectorUrl, sector);
                return resolve(response.data);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public restore(sectors: SectorEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                for (let i = 0; i < sectors.length; i++) {
                    await api.patch(this.baseRestoreSectorUrl + sectors[i].id);
                }

                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public delete(sectors: SectorEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                for (let i = 0; i < sectors.length; i++) {
                    await api.delete(this.baseSectorUrl + sectors[i].id);
                }

                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public findByPK(id: string): Promise<SectorEntity> {
        return new Promise<SectorEntity>(async (resolve, reject) => {
            try {

                const response = await api.get(this.baseSectorUrl + id);
                const sector = new SectorEntity({
                    ...response.data
                });

                return resolve(sector);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public export(id?: string): Promise<ExportDataEntity> {
        return new Promise<ExportDataEntity>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.baseUrlExport}?id=${id ?? ''}`);
                const exportData = new ExportDataEntity({
                    ...response.data,
                    progress: parseFloat((response.data.progress * 100).toFixed(2))
                });
                return resolve(exportData);
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public exportSubscription(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null> {
        return new Observable<ExportDataEntity | null>((observer: Observer<ExportDataEntity | null>) => {
            return this._exportDataSource.exportSubscription(
                'sectors',
                id,
                handleDisconnect,
                getLastUpdates,
                useSocket
            ).subscribe({
                next: (response) => {
                    observer.next(response);
                },
                error(err: any) {
                    observer.error(err);
                }
            })
        });
    }


}
