import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Container, ContainerButtonHeader,
    ContainerInputSearch,
    Content,
    ContentInputSearch,
    ContentLoading, ContentTextNoResults, Icon,
    Text
} from "./styled";
import LoadingInfinityScroll from "@dropDesk/presentation/components/loadings/loading_infinity_scroll";
import InputSearchGeneric from "@dropDesk/presentation/components/inputs/input_search";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import ContactList from "@dropDesk/presentation/components/contact_list";
import {useBetween} from "use-between";
import {useModalChat} from "@dropDesk/presentation/pages/chat/ui/contact_list/modals/handle_change_visible";
import {Tooltip} from "antd";
import ModalsChat from "@dropDesk/presentation/pages/chat/ui/contact_list/modals";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

export const ContactsList = (observer((
    {
        handleInitChat,
        maxWidthContainer
    }: {

        handleInitChat: (ticket: TicketEntity) => void
        maxWidthContainer: number
    }
) => {

    const userController: UserController = useInjection(UserController);

    useEffect(() => {
        initializeContacts().then();
    }, []);

    const onSearchContacts = (searchParam?: string) => {
        userController.list(searchParam ?? userController.searchParam, undefined, undefined, true);
    }

    const initializeContacts = async () => {
        userController.tableUsers.limit = ConstantsKeys.defaultLimit;
        userController.setRole(UserRole.userClient);
        await userController.list(userController.searchParam, undefined, undefined, true);
    }

    const [idContactSelected, setIdContactSelected] = useState<string | null>(null);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const {
        setVisibleModalEditClient,
        setVisibleModalInfoNewTicket,
        setVisibleModalNewTicket
    } = useBetween(useModalChat);

    return (
        <Container bordercolor={colors.border}>
            <ContainerInputSearch>
                <ContentInputSearch>
                    <InputSearchGeneric
                        inputSearchPlaceholder="Pesquise por contatos"
                        loading={userController.loading}
                        onSearch={(value) => onSearchContacts(value)}
                        prefix={
                            <Tooltip title={'Adicionar novo contato'}>
                                <ContainerButtonHeader
                                    background={colors.buttonOnBackground}
                                    accent={colors.accent}
                                >
                                    <DropDeskIcon
                                        style={{cursor: 'pointer'}}
                                        icon={ICONS_DROPDESK.newUser}
                                        size={16}
                                        onClick={() => {
                                            setVisibleModalEditClient({visible: true, idClient: null});
                                        }}
                                    />
                                </ContainerButtonHeader>
                            </Tooltip>}
                    />
                </ContentInputSearch>
            </ContainerInputSearch>
            <Content>
                {userController.loading && userController.tableUsers.data.length === 0 &&
                    <ContentLoading>
                        <SpinLoading
                            size={"large"}
                            color={colors.text}
                            tip={"Carregando ..."}
                        />
                    </ContentLoading>
                }
                {!userController.loading && userController.tableUsers.data.length === 0 &&
                    <ContentTextNoResults>
                        <DropDeskIcon icon={ICONS_DROPDESK.emptyFolder} size={55} color={colors.hintInverse}/>
                        <Text color={colors.text}>Nenhum contato encontrado.</Text>
                    </ContentTextNoResults>
                }

                {userController.tableUsers.data.map((contact) => {
                    return (
                        <div key={contact.id}>
                            <ContactList
                                maxWidthContainer={maxWidthContainer}
                                onClick={() => {
                                    setIdContactSelected(contact.id);
                                    setVisibleModalInfoNewTicket({
                                        visible: false,
                                        client: contact.client!,
                                        contact: contact
                                    });
                                    setVisibleModalNewTicket(true);
                                }}
                                onClickNewTicketChat={() => {
                                    setIdContactSelected(contact.id);
                                    setVisibleModalInfoNewTicket({
                                        visible: false,
                                        client: contact.client!,
                                        contact: contact
                                    });
                                    setVisibleModalNewTicket(true);
                                }}
                                contact={contact}
                                searchParam={userController.searchParam}
                                idContactSelected={idContactSelected}
                                onClickEditClient={(idClient) => {
                                    setIdContactSelected(idClient);
                                    setVisibleModalEditClient({visible: true, idClient})
                                }}
                            />
                        </div>
                    )
                })}

                {userController.tableUsers.hasMore &&
                    <LoadingInfinityScroll
                        loading={userController.loading && !userController.loadingMessage}
                        loadMore={() => userController.getDataFromPageContacts()}
                        label={'Carregar mais contatos'}
                        labelLoading={'Carregando mais contatos...'}
                    />}

            </Content>
            <ModalsChat
                handleSubmitEditClient={() => onSearchContacts()}
                handleSubmitNewChat={(ticket) => handleInitChat(ticket)}
            />
        </Container>
    )
}));
