import "reflect-metadata";
import {inject, injectable} from "inversify";
import {CompanyConfigurationRepository} from "@dropDesk/domain/repositories/company_configurations/company_configurations.repository";
import {PanelAdmEntity} from "@dropDesk/domain/entities/company_configurations/panel_adm.entity";

@injectable()
export class SetPanelAdmConfigurationsUseCase {
  private _repository: CompanyConfigurationRepository;

  constructor(@inject(CompanyConfigurationRepository) repository: CompanyConfigurationRepository) {
    this._repository = repository;
  }

  public call(panelAdm: PanelAdmEntity): Promise<void> {
    return this._repository.setPanelAdmConfiguration(panelAdm);
  }
}
