import {
    CompanyConfigurationRepository
} from "@dropDesk/domain/repositories/company_configurations/company_configurations.repository";
import {inject, injectable} from "inversify";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {
    CompanyConfigurationsRemoteDataSource
} from "@dropDesk/data/data_source/company_configurations/company_configurations_remote.datasource";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {TicketConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/ticket_configuration.entity";
import {
    BusinessOperationHoursConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/business_operation_hours_configuration.entity";
import {
    PortalClientConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/portal_client_configuration.entity";
import {ChatConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/chat_configuration.entity";
import {
    NotificationsConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/notifications_configuration.entity";
import {PanelAdmEntity} from "@dropDesk/domain/entities/company_configurations/panel_adm.entity";

@injectable()
export class CompanyConfigurationRepositoryImpl implements CompanyConfigurationRepository {

    private _dataSource: CompanyConfigurationsRemoteDataSource;

    constructor(
        @inject(CompanyConfigurationsRemoteDataSource)
        dataSource: CompanyConfigurationsRemoteDataSource
    ) {
        this._dataSource = dataSource;
    }

    public findByPK(id: string): Promise<CompanyConfigurationEntity> {
        try {
            return this._dataSource.findByPK(id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public setTheme(name?: string, fileLogoBrand?: File, removeLogo?: boolean): Promise<Record<string, boolean>> {
        try {
            return this._dataSource.setTheme(name, fileLogoBrand, removeLogo);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public resetChatMessages(): Promise<CompanyConfigurationEntity> {
        try {
            return this._dataSource.resetChatMessages();
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public set(companyConfigurations: CompanyConfigurationEntity): Promise<CompanyConfigurationEntity> {
        try {
            return this._dataSource.set(companyConfigurations);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public setConfigurationTicket(configurationTicket: TicketConfigurationEntity): Promise<CompanyConfigurationEntity> {
        try {
            return this._dataSource.setConfigurationTicket(configurationTicket);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public setConfigurationChat(configurationChat: ChatConfigurationEntity): Promise<CompanyConfigurationEntity> {
        try {
            return this._dataSource.setConfigurationChat(configurationChat);
        } catch (e: any) {
            throw new ServerError(e.message);
        }

    }

    public setConfigurationNotification(notifications: NotificationsConfigurationEntity): Promise<CompanyConfigurationEntity> {
        try {
            return this._dataSource.setConfigurationNotification(notifications);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public setConfigurationBusinessOperator(configurationBusinessOperator: BusinessOperationHoursConfigurationEntity): Promise<CompanyConfigurationEntity> {
        try {
            return this._dataSource.setConfigurationBusinessOperator(configurationBusinessOperator);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public setConfigurationPortalClient(configurationPortalClient: PortalClientConfigurationEntity): Promise<CompanyConfigurationEntity> {
        try {
            return this._dataSource.setConfigurationPortalClient(configurationPortalClient);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public setPanelAdmConfiguration(panelAdm: PanelAdmEntity): Promise<void> {
        try {
            return this._dataSource.setPanelAdmConfiguration(panelAdm);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


}
