import "reflect-metadata";
import {inject, injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {TicketConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/ticket_configuration.entity";
import {
    BusinessOperationDayEntity,
    BusinessOperationHoursConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/business_operation_hours_configuration.entity";
import {
    PortalClientConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/portal_client_configuration.entity";
import {
    ChatConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/chat_configuration.entity";
import {RemoteStorageDatasource} from "@dropDesk/data/data_source/remote_storage/remote_storage_datasource";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {
    NotificationsConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/notifications_configuration.entity";
import {PanelAdmEntity} from "@dropDesk/domain/entities/company_configurations/panel_adm.entity";

@injectable()
export abstract class CompanyConfigurationsRemoteDataSource {
    public abstract findByPK(id: string): Promise<CompanyConfigurationEntity>;

    public abstract setTheme(name?: string, fileLogoBrand?: File, removeLogo?: boolean): Promise<Record<string, boolean>>;

    public abstract resetChatMessages(): Promise<CompanyConfigurationEntity>;

    public abstract set(companyConfigurations: CompanyConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationTicket(configurationTicket: TicketConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationChat(configurationChat: ChatConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationBusinessOperator(configurationBusinessOperator: BusinessOperationHoursConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationPortalClient(configurationPortalClient: PortalClientConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationNotification(notifications: NotificationsConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setPanelAdmConfiguration(panelAdm: PanelAdmEntity): Promise<void>;
}

@injectable()
export class CompanyConfigurationsRemoteDataSourceImpl implements CompanyConfigurationsRemoteDataSource {
    baseCompanyConfigurationsUrl: string = 'companyConfiguration/';
    baseCompanyConfigurationsSetUrl: string = `${this.baseCompanyConfigurationsUrl}set`;
    setThemeUrl: string = `company/setTheme`;
    resetChatMessagesUrl: string = `${this.baseCompanyConfigurationsUrl}resetChatMessages`;
    configurationTicketUrl: string = `${this.baseCompanyConfigurationsUrl}ticket`;
    configurationBusinessOperatorUrl: string = `${this.baseCompanyConfigurationsUrl}businessOperator`;
    configurationChatUrl: string = `${this.baseCompanyConfigurationsUrl}chat`;
    configurationNotificationUrl: string = `${this.baseCompanyConfigurationsUrl}notification`;
    configurationPortalClientUrl: string = `${this.baseCompanyConfigurationsUrl}portalClient`;
    configurationPanelAdmUrl: string = `company/setRealTimeConnection`;


    private _remoteDataSource: RemoteStorageDatasource;

    constructor(
        @inject(RemoteStorageDatasource) dataSource: RemoteStorageDatasource,
    ) {
        this._remoteDataSource = dataSource;
    }

    public async findByPK(id: string): Promise<CompanyConfigurationEntity> {
        return new Promise<CompanyConfigurationEntity>(async (resolve, reject) => {
            try {
                const response = await api.get(this.baseCompanyConfigurationsUrl + id);
                return resolve(CompanyConfigurationEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public set(companyConfigurations: CompanyConfigurationEntity): Promise<CompanyConfigurationEntity> {
        return new Promise<CompanyConfigurationEntity>(async (resolve, reject) => {

            try {

                const objectCompanyConfiguration = new CompanyConfigurationEntity({
                    ...companyConfigurations,
                });

                const response = await api.post(this.baseCompanyConfigurationsSetUrl, objectCompanyConfiguration.toJson());
                return resolve(CompanyConfigurationEntity.fromJson(response.data));

            } catch (error) {


                return reject(parseServerError(error));

            }
        })
    }


    public resetChatMessages(): Promise<CompanyConfigurationEntity> {
        return new Promise<CompanyConfigurationEntity>(async (resolve, reject) => {
            try {
                const response = await api.put(this.resetChatMessagesUrl);
                return resolve(CompanyConfigurationEntity.fromJson(response.data));
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        });
    }

    public setTheme(name?: string, fileLogoBrand?: File, removeLogo?: boolean): Promise<Record<string, boolean>> {
        return new Promise<Record<string, boolean>>(async (resolve, reject) => {

            const path: string = `companies/${getIdCompanyByLocalStorage()}/logo.png`;
            let reference: string | null = null;

            try {
                if (fileLogoBrand) {
                    const timestamp = new Date().getTime();
                    reference = await this._remoteDataSource.uploadFile(fileLogoBrand, path);
                    reference = `${reference}&decache=${timestamp}`;
                }
                if (removeLogo) {
                    await this._remoteDataSource.removeFile(path);
                }
                const response = await api.put(this.setThemeUrl, {name, logo: reference, removeLogo});
                return resolve(response.data);

            } catch (error: any) {
                if (fileLogoBrand && reference) {
                    await this._remoteDataSource.removeFile(path);
                }
                return reject(parseServerError(error));
            }
        });
    }

    public setConfigurationTicket(configurationTicket: TicketConfigurationEntity): Promise<CompanyConfigurationEntity> {
        return new Promise<CompanyConfigurationEntity>(async (resolve, reject) => {
            try {

                const response = await api.put(this.configurationTicketUrl, configurationTicket);
                return resolve(CompanyConfigurationEntity.fromJson(response.data));

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public setConfigurationChat(configurationChat: ChatConfigurationEntity): Promise<CompanyConfigurationEntity> {
        return new Promise<CompanyConfigurationEntity>(async (resolve, reject) => {
            try {

                const response = await api.put(this.configurationChatUrl, configurationChat.toJson());
                return resolve(CompanyConfigurationEntity.fromJson(response.data));

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public setConfigurationNotification(notifications: NotificationsConfigurationEntity): Promise<CompanyConfigurationEntity> {
        return new Promise<CompanyConfigurationEntity>(async (resolve, reject) => {
            try {

                const response = await api.put(this.configurationNotificationUrl, notifications);
                return resolve(CompanyConfigurationEntity.fromJson(response.data));

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public setPanelAdmConfiguration(panelAdm: PanelAdmEntity): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                return resolve(await api.put(this.configurationPanelAdmUrl, panelAdm));
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }


    public setConfigurationBusinessOperator(configurationBusinessOperator: BusinessOperationHoursConfigurationEntity): Promise<CompanyConfigurationEntity> {
        return new Promise<CompanyConfigurationEntity>(async (resolve, reject) => {
            try {

                const response = await api.put(this.configurationBusinessOperatorUrl, configurationBusinessOperator);
                return resolve(CompanyConfigurationEntity.fromJson(response.data));

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public setConfigurationPortalClient(configurationPortalClient: PortalClientConfigurationEntity): Promise<CompanyConfigurationEntity> {
        return new Promise<CompanyConfigurationEntity>(async (resolve, reject) => {
            try {

                const response = await api.put(this.configurationPortalClientUrl, configurationPortalClient);
                return resolve(CompanyConfigurationEntity.fromJson(response.data));

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

}
