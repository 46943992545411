import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export abstract class SectorRepository {

    public abstract list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        idUser?: string,
        listableForClients?: boolean
    ): Promise<ListPaginationEntity<SectorEntity>>;

    public abstract set(sector: SectorEntity): Promise<SectorEntity>;

    public abstract restore(sectors: SectorEntity[]): Promise<void>;

    public abstract delete(sectors: SectorEntity[]): Promise<void>;

    public abstract findByPK(id: string): Promise<SectorEntity>;

    public abstract exportSubscription(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null>;

    public abstract export(id?: string): Promise<ExportDataEntity>;
}
