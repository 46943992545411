import "reflect-metadata";
import {Container} from "inversify";
//app
import {AppController} from "@dropDesk/presentation/app/app.controller";

//auth
import {AuthRemoteDataSource, AuthRemoteDataSourceImpl} from "@dropDesk/data/data_source/auth/auth_remote.datasource";
import {AuthRepositoryImpl} from "@dropDesk/data/repositories/auth/auth_impl.repository";
import {AuthRepository} from "@dropDesk/domain/repositories/auth/auth.repository";
import {GetUserByTokenUseCase} from "@dropDesk/domain/use_case/auth/get_by_token.usecase";
import {GetUserByDecodedTokenUseCase} from "@dropDesk/domain/use_case/auth/get_user_by_decoded_token_firebase.usecase";
import {LogoutByAdminUseCase} from "@dropDesk/domain/use_case/auth/logout_by_admin.usecase";

//storage
import {
    RemoteStorageDatasource,
    RemoteStorageDatasourceImpl
} from "@dropDesk/data/data_source/remote_storage/remote_storage_datasource";

//io
import {IoRemoteDataSource, IoRemoteDataSourceImpl} from "@dropDesk/data/data_source/io/io_remote.datasource";
import {CreateObjectUrlBlobUseCase} from "@dropDesk/domain/use_case/io/create_object_url_blob.usecase";
import {UrlToBlobUseCase} from "@dropDesk/domain/use_case/io/url_to_blob.usecase";
import {DownloadFileUseCase} from "@dropDesk/domain/use_case/io/download_file.usecase";
import {GetAudioFromMediaRecorderUseCase} from "@dropDesk/domain/use_case/io/get_audio_from_media_recorder.usecase";
import {GetMediaStreamUseCase} from "@dropDesk/domain/use_case/io/get_media_stream.usecase";
import {StartRecordVoiceListenerUseCase} from "@dropDesk/domain/use_case/io/start_record_voice_listener.usecase";
import {StopRecordUseCase} from "@dropDesk/domain/use_case/io/stop_record.usecase";
import {IoRepository} from "@dropDesk/domain/repositories/io/io.repository";
import {IoRepositoryImpl} from "@dropDesk/data/repositories/io/io_impl.repository";

//login
import {LoginController} from "@dropDesk/presentation/pages/login/controller/login.controller";
import {LoginUseCase} from "@dropDesk/domain/use_case/auth/sign_in.usecase";
import {LogoutUseCase} from "@dropDesk/domain/use_case/auth/logout.usecase";
import {GetUserByFirebaseUseCase} from "@dropDesk/domain/use_case/auth/get_user_by_firebase.usecase";
import {LoginServerUseCase} from "@dropDesk/domain/use_case/auth/login_server.usecase";
import {RecoveryAccountUseCase} from "@dropDesk/domain/use_case/auth/send_password_reset_email";

//task
import {TaskRemoteDataSource, TaskRemoteDataSourceImpl} from "@dropDesk/data/data_source/task/task_remote.datasource";
import {TaskRepository} from "@dropDesk/domain/repositories/task/task.repository";
import {DeleteTaskUseCase} from "@dropDesk/domain/use_case/task/delete_task.usecase";
import {ListTaskUseCase} from "@dropDesk/domain/use_case/task/list_task_pagination.usecase";
import {SetTaskUseCase} from "@dropDesk/domain/use_case/task/set_task.usecase";
import {MarkDoneUndoneTaskUseCase} from "@dropDesk/domain/use_case/task/mark_done_undone_task.usecase";
import {TaskRepositoryImpl} from "@dropDesk/data/repositories/task/task_impl.repository";
import {TaskController} from "@dropDesk/presentation/pages/task/controller/task.controller";

//report_tickets
import {
    ReportTicketRemoteDataSource,
    ReportTicketRemoteDataSourceImpl
} from "@dropDesk/data/data_source/reports/report_ticket/report_ticket_remote.datasource"
import {ReportTicketRepository} from "@dropDesk/domain/repositories/reports/report_ticket/report_ticket.repository";
import {GetReportTicketUseCase} from "@dropDesk/domain/use_case/reports/report_ticket/get_report_ticket.usecase";
import {
    ExportPdfReportTicketUseCase
} from "@dropDesk/domain/use_case/reports/report_ticket/export_pdf_report_ticket.usecase";
import {
    ReportTicketRepositoryImpl
} from "@dropDesk/data/repositories/reports/report_ticket/report_ticket_impl.repository";
import {
    ReportTicketController
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/controller/report_ticket.controller";


//report_tickets_aggregate
import {
    ReportTicketAggregateRemoteDataSource,
    ReportTicketAggregateRemoteDataSourceImpl
} from "@dropDesk/data/data_source/reports/report_ticket_aggregate/report_ticket_aggregate_remote.datasource"
import {
    ReportTicketAggregateRepository
} from "@dropDesk/domain/repositories/reports/report_ticket_aggregate/report_ticket_aggregate.repository";
import {
    GetReportTicketAggregateUseCase
} from "@dropDesk/domain/use_case/reports/report_ticket_aggregate/get_report_ticket_aggregate.usecase";
import {
    ExportPdfReportTicketAggregateUseCase
} from "@dropDesk/domain/use_case/reports/report_ticket_aggregate/export_pdf_report_ticket_aggregate.usecase";
import {
    ReportTicketAggregateRepositoryImpl
} from "@dropDesk/data/repositories/reports/report_ticket_aggregate/report_ticket_aggregate_impl.repository";
import {
    ReportTicketAggregateController
} from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket_aggregate/controller/report_ticket_aggregate.controller";

//report_tickets_rate
import {
    ReportTicketRateRemoteDataSource,
    ReportTicketRateRemoteDataSourceImpl
} from "@dropDesk/data/data_source/reports/report_ticket_rate/report_ticket_rate_remote.datasource"
import {
    ReportTicketRateRepository
} from "@dropDesk/domain/repositories/reports/report_ticket_rate/report_ticket_rate.repository";
import {
    GetReportTicketRateUseCase
} from "@dropDesk/domain/use_case/reports/report_ticket_rate/get_report_ticket_rate.usecase";
import {
    ReportTicketRateRepositoryImpl
} from "@dropDesk/data/repositories/reports/report_ticket_rate/report_ticket_rate_impl.repository";
import {
    ReportTicketRateController
} from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/controller/report_ticket_rate.controller";


//ticket
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";
import {TicketController} from "@dropDesk/presentation/pages/ticket/controller/ticket.controller";
import {
    TicketRemoteDataSource,
    TicketRemoteDataSourceImpl
} from "@dropDesk/data/data_source/ticket/ticket_remote.datasource";
import {
    TicketRepositoryImpl
} from "@dropDesk/data/repositories/ticket/ticket_impl.repository";
import {DeleteTicketsUseCase} from "@dropDesk/domain/use_case/ticket/delete_tickets.usecase";
import {
    FindByPKTicketsUseCase
} from "@dropDesk/domain/use_case/ticket/findbypk_tickets.usecase";
import {SetTicketsUseCase} from "@dropDesk/domain/use_case/ticket/set_tickets.usecase";
import {
    RestoreTicketsUseCase
} from "@dropDesk/domain/use_case/ticket/restore_tickets.usecase";
import {ListTicketsSubscriptionUseCase} from "@dropDesk/domain/use_case/ticket/list_tickets_subscription.usecase";
import {TransferTicketUseCase} from "@dropDesk/domain/use_case/ticket/transfer_ticket.usecase";
import {ExportPDFTicketsUseCase} from "@dropDesk/domain/use_case/ticket/export_pdf_tickets.usecase";
import {ChangeTimeSpentTicketUseCase} from "@dropDesk/domain/use_case/ticket/change_timespent_ticket.usecase";
import {ChangePriorityTicketUseCase} from "@dropDesk/domain/use_case/ticket/change_priority_ticket.usecase";
import {ChangeDateDueTicketUseCase} from "@dropDesk/domain/use_case/ticket/change_date_due_ticket.usecase";
import {CancelTicketUseCase} from "@dropDesk/domain/use_case/ticket/cancel_ticket.usecase";
import {CloseTicketUseCase} from "@dropDesk/domain/use_case/ticket/close_ticket.usecase";
import {ChangeResolutionTicketUseCase} from "@dropDesk/domain/use_case/ticket/change_resolution_ticket.usecase";
import {
    ChangeProblemDescriptionTicketUseCase
} from "@dropDesk/domain/use_case/ticket/change_problem_description_ticket.usecase";
import {LinkAttendantTicketUseCase} from "@dropDesk/domain/use_case/ticket/link_attendant_ticket.usecase";
import {ExportDataTicketsUseCase} from "@dropDesk/domain/use_case/ticket/export_data.usecase";
import {ExportTicketsSubscriptionUseCase} from "@dropDesk/domain/use_case/ticket/export_data_subscription.usecase";
import {
    ListTicketMessagesAfterLastSubsUseCase
} from "@dropDesk/domain/use_case/ticket/list_ticket_messages_after_last_subs.usecase";

//chat
import {ChatRemoteDataSource, ChatRemoteDataSourceImpl} from "@dropDesk/data/data_source/chat/chat_remote_datasource";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {ListChatsAttendingUseCase} from "@dropDesk/domain/use_case/chat/list_chats.usecase";
import {ListChatsWaitingChatUseCase} from "@dropDesk/domain/use_case/chat/list_waiting_chat.usecase";
import {ListChatMessagesUseCase} from "@dropDesk/domain/use_case/chat/list_chat_messages.usecase";
import {ListAllChatsChatUseCase} from "@dropDesk/domain/use_case/chat/list_all_chat.usecase";
import {
    ListChatMessagesAfterLastSubsUseCase
} from "@dropDesk/domain/use_case/chat/list_chat_messages_after_last_subs.usecase";
import {SubscribeChatsUseCase} from "@dropDesk/domain/use_case/chat/subscribe_chats.usecase";
import {ClearTicketsCacheUseCase} from "@dropDesk/domain/use_case/chat/clear_tickets_cache.usecase";
import {JoinTicketChatUseCase} from "@dropDesk/domain/use_case/chat/join_ticket.usecase";
import {LeaveTicketChatUseCase} from "@dropDesk/domain/use_case/chat/leave_ticket.usecase";
import {StartConnectionChatsUseCase} from "@dropDesk/domain/use_case/chat/start_connection_chats.usecase";
import {SubscribeChatConnectionUseCase} from "@dropDesk/domain/use_case/chat/subscribe_chat_connection.usecase";
import {LogoutChatsUseCase} from "@dropDesk/domain/use_case/chat/logout_chats.usecase";
import {ConnectionStateChatsUseCase} from "@dropDesk/domain/use_case/chat/connection_state_chats.usecase";
import {NavigateToMessageUseCase} from "@dropDesk/domain/use_case/chat/navigate_to_message.usecase";
import {ReactionMessageUseCase} from "@dropDesk/domain/use_case/chat/reaction_message.usecase";
import {InformReadMessageUseCase} from "@dropDesk/domain/use_case/chat/inform_read_message.usecase";
import {ChatRepositoryImpl} from "@dropDesk/data/repositories/chat/chat_impl.repository";
import {ChatController} from "@dropDesk/presentation/pages/chat/controller/chat.controller";

//evaluation
import {
    FindByPKTicketEvaluationUseCase
} from "@dropDesk/domain/use_case/ticket/evaluation/findbypk_ticket_evaluation.usecase";
import {SetTicketEvaluationUseCase} from "@dropDesk/domain/use_case/ticket/evaluation/set_ticket_evaluation.usecase";
import {
    ListTicketEvaluationUseCase
} from "@dropDesk/domain/use_case/ticket/evaluation/list_ticket_evaluation_pagination.usecase";
import {
    TicketEvaluationRemoteDataSourceImpl,
    TicketEvaluationRemoteDataSource
} from "@dropDesk/data/data_source/ticket/evaluation/ticket_evaluation_remote.datasource";


//user
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {UserRemoteDataSource, UserRemoteDataSourceImpl} from "@dropDesk/data/data_source/user/user_remote.datasource";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";
import {UserRepositoryImpl} from "@dropDesk/data/repositories/user/user_impl.repository";
import {GetCurrentUserUseCase} from "@dropDesk/domain/use_case/user/get_current_user";
import {DeleteUserUseCase} from "@dropDesk/domain/use_case/user/delete_user.usecase";
import {SetThemeUserUseCase} from "@dropDesk/domain/use_case/user/set_theme_user.usecase";
import {FindByPKUserUseCase} from "@dropDesk/domain/use_case/user/findbypk_user.usecase";
import {SetUserUseCase} from "@dropDesk/domain/use_case/user/set_user.usecase";
import {RestoreUserUseCase} from "@dropDesk/domain/use_case/user/restore_user.usecase";
import {ListUserUseCase} from "@dropDesk/domain/use_case/user/list_users_pagination.usecase";
import {ExportDataUserUseCase} from "@dropDesk/domain/use_case/user/export_data.usecase";
import {ExportUsersSubscriptionUseCase} from "@dropDesk/domain/use_case/user/export_data_subscription.usecase";

//clients
import {ClientController} from "@dropDesk/presentation/pages/client/controller/client.controller";
import {
    ClientRemoteDataSource,
    ClientRemoteDataSourceImpl
} from "@dropDesk/data/data_source/client/client_remote.datasource";
import {ListClientUseCase} from "@dropDesk/domain/use_case/client/list_client_pagination.usecase";
import {DeleteClientUseCase} from "@dropDesk/domain/use_case/client/delete_client.usecase";
import {RestoreClientUseCase} from "@dropDesk/domain/use_case/client/restore_client.usecase";
import {SetClientUseCase} from "@dropDesk/domain/use_case/client/set_client.usecase";
import {FindByPKClientUseCase} from "@dropDesk/domain/use_case/client/findbypk_client.usecase";
import {ListImportSubscriptionUseCase} from "@dropDesk/domain/use_case/client/list_imports.usecase";
import {ImportClientUseCase} from "@dropDesk/domain/use_case/client/import_client.usecase";
import {ImportSubscriptionClientUseCase} from "@dropDesk/domain/use_case/client/import_subscription_client.usecase";
import {RevertImportClientUseCase} from "@dropDesk/domain/use_case/client/revert_import.usecase";
import {DownloadFileExampleClientUseCase} from "@dropDesk/domain/use_case/client/download_file_example.usecase";
import {ListOnlyUsersLinkedClientUseCase} from "@dropDesk/domain/use_case/client/list_only_users_linked.usecase";
import {ExportClientsSubscriptionUseCase} from "@dropDesk/domain/use_case/client/export_data_subscription.usecase";
import {ExportDataClientUseCase} from "@dropDesk/domain/use_case/client/export_data.usecase";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";
import {ClientRepositoryImpl} from "@dropDesk/data/repositories/client/client_impl.repository";

//exports base datasource
import {
    ExportRemoteDataSource,
    ExportRemoteDataSourceImpl
} from "@dropDesk/data/data_source/export/export_remote.datasource";

//subscription
import {
    SubscriptionController
} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";

import {
    SubscriptionRemoteDataSource,
    SubscriptionRemoteDataSourceImpl
} from "@dropDesk/data/data_source/subscription/subscription_remote.datasource";

import {SubscriptionRepository} from "@dropDesk/domain/repositories/subscription/subscription.repository";
import {
    SubscriptionRepositoryImpl
} from "@dropDesk/data/repositories/subscription/subscription_impl.repository";
import {
    ListPlansSubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/list_plans_subscription.usecase";

import {
    PaySubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/pay_subscription.usecase";

import {
    GetPaymentSubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/get_payment_subscription.usecase";

import {
    FindByPKSubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/findbypk_subscription.usecase";

import {
    ListInvoicesSubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/list_invoices_subscription.usecase";

import {
    ReactivateSubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/reactivate_subscription.usecase";
import {
    GetPdfByPaymentIdSubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/get_pdf_by_payment_id_subscription.usecase";

import {
    CancelSubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/cancel_subscription.usecase";

import {
    GetSimulateSubscriptionUseCase
} from "@dropDesk/domain/use_case/subscription/get_simulate_subscription.usecase";

//response standard
import {
    ResponseStandardController
} from "@dropDesk/presentation/pages/response_standard/controller/response_standard.controller";
import {
    ResponseStandardRemoteDataSource,
    ResponseStandardRemoteDataSourceImpl
} from "@dropDesk/data/data_source/response_standard/response_standard_remote.datasource";
import {ResponseStandardRepository} from "@dropDesk/domain/repositories/response_standard/response_standard.repository";
import {
    ResponseStandardRepositoryImpl
} from "@dropDesk/data/repositories/response_standard/response_standard_impl.repository";
import {
    DeleteResponseStandardUseCase
} from "@dropDesk/domain/use_case/response_standard/delete_response_standard.usecase";
import {
    FindByPKResponseStandardUseCase
} from "@dropDesk/domain/use_case/response_standard/findbypk_response_standard.usecase";
import {SetResponseStandardUseCase} from "@dropDesk/domain/use_case/response_standard/set_response_standard.usecase";
import {
    RestoreResponseStandardUseCase
} from "@dropDesk/domain/use_case/response_standard/restore_response_standard.usecase";
import {
    ListResponseStandardUseCase
} from "@dropDesk/domain/use_case/response_standard/list_response_standard_pagination.usecase";

//description static
import {
    TicketsDescriptionStaticController
} from "@dropDesk/presentation/pages/ticket_description_static/controller/ticket_description_static.controller";
import {
    TicketsDescriptionStaticRemoteDataSource,
    TicketsDescriptionStaticRemoteDataSourceImpl
} from "@dropDesk/data/data_source/ticket_description_static/ticket_description_static_remote.datasource";
import {
    TicketsDescriptionStaticRepository
} from "@dropDesk/domain/repositories/ticket_description_static/ticket_description_static.repository";
import {
    TicketsDescriptionStaticRepositoryImpl
} from "@dropDesk/data/repositories/ticket_description_static/ticket_description_static_impl.repository";
import {
    DeleteTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/delete_tickets_description_static.usecase";
import {
    FindByPKTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/findbypk_tickets_description_static.usecase";
import {
    SetTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/set_tickets_description_static.usecase";
import {
    RestoreTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/restore_tickets_description_static.usecase";
import {
    ListTicketsDescriptionStaticUseCase
} from "@dropDesk/domain/use_case/ticket_descriptions_static/list_tickets_description_static_pagination.usecase";


//notices
import {NoticesController} from "@dropDesk/presentation/pages/notices/controller/notices.controller";
import {
    NoticesRemoteDataSource,
    NoticesRemoteDataSourceImpl
} from "@dropDesk/data/data_source/notices/notices_remote.datasource";
import {NoticesRepository} from "@dropDesk/domain/repositories/notices/notices.repository";
import {NoticesRepositoryImpl} from "@dropDesk/data/repositories/notices/notices_impl.repository";
import {DeleteNoticesUseCase} from "@dropDesk/domain/use_case/notices/delete_notices.usecase";
import {FindByPKNoticesUseCase} from "@dropDesk/domain/use_case/notices/findbypk_notices.usecase";
import {SetNoticesUseCase} from "@dropDesk/domain/use_case/notices/set_notices.usecase";
import {RestoreNoticesUseCase} from "@dropDesk/domain/use_case/notices/restore_notices.usecase";
import {ListNoticesUseCase} from "@dropDesk/domain/use_case/notices/list_notices_pagination.usecase";
import {ListNoticesByUserUseCase} from "@dropDesk/domain/use_case/notices/list_notices_by_user_notices.usecase";


//company configurations
import {
    CompanyConfigurationController
} from "@dropDesk/presentation/pages/company_configurations/controller/company_configurations.controller";
import {
    CompanyConfigurationsRemoteDataSource,
    CompanyConfigurationsRemoteDataSourceImpl
} from "@dropDesk/data/data_source/company_configurations/company_configurations_remote.datasource";
import {
    CompanyConfigurationRepository
} from "@dropDesk/domain/repositories/company_configurations/company_configurations.repository";
import {
    CompanyConfigurationRepositoryImpl
} from "@dropDesk/data/repositories/company_configurations/company_configurations_impl.repository";
import {
    SetCompanyConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_company_configurations.usecase";
import {
    SetConfigurationNotificationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_notification_configuration.usecase";
import {
    GetCompanyConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/find_by_pk_company_configurations.usecase";
import {
    SetTicketConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_ticket_configurations.usecase";
import {
    SetPanelAdmConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_panel_adm_configurations.usecase";

import {
    SetChatConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_ticket_chat.usecase";
import {
    SetBusinessOperatorConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_ticket_business_operator.usecase";
import {
    SetPortalClientConfigurationUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_portal_client_configuration.usecase";
import {
    ResetChatMessagesCompanyUseCase
} from "@dropDesk/domain/use_case/company_configurations/reset_chat_messages_company.usecase";


//sector
import {SectorController} from "@dropDesk/presentation/pages/sector/controller/sector.controller";
import {
    SectorRemoteDataSource,
    SectorRemoteDataSourceImpl
} from "@dropDesk/data/data_source/sector/sector_remote.datasource";
import {SectorRepository} from "@dropDesk/domain/repositories/sector/sector.repository";
import {SectorRepositoryImpl} from "@dropDesk/data/repositories/sector/sector_impl.repository";
import {DeleteSectorUseCase} from "@dropDesk/domain/use_case/sector/delete_sector.usecase";
import {FindByPKSectorUseCase} from "@dropDesk/domain/use_case/sector/findbypk_sector.usecase";
import {SetSectorUseCase} from "@dropDesk/domain/use_case/sector/set_sector.usecase";
import {RestoreSectorUseCase} from "@dropDesk/domain/use_case/sector/restore_sector.usecase";
import {ListSectorUseCase} from "@dropDesk/domain/use_case/sector/list_sectors_pagination.usecase";
import {ExportDataSectorSubscriptionUseCase} from "@dropDesk/domain/use_case/sector/export_data_subscription.usecase";
import {ExportDataSectorUseCase} from "@dropDesk/domain/use_case/sector/export_data.usecase";

//company
import {CompanyController} from "@dropDesk/presentation/pages/company/controller/company.controller";
import {
    CompanyRemoteDataSource,
    CompanyRemoteDataSourceImpl
} from "@dropDesk/data/data_source/company/company_remote.datasource";
import {CompanyRepository} from "@dropDesk/domain/repositories/company/company.repository";
import {CompanyRepositoryImpl} from "@dropDesk/data/repositories/company/company_impl.repository";
import {FindByPKCompanyUseCase} from "@dropDesk/domain/use_case/company/findbypk_company.usecase";
import {SetCompanyUseCase} from "@dropDesk/domain/use_case/company/set_company.usecase";
import {SetUpdateLegacyCompanyUseCase} from "@dropDesk/domain/use_case/company/set_update_legacy_company.usecase";
import {SetThemeCompanyUseCase} from "@dropDesk/domain/use_case/company_configurations/set_theme_company.usecase";
import {CreateAccountCompanyUseCase} from "@dropDesk/domain/use_case/company/create_account_company.usecase";
import {
    ListCompanySubscriptionLogsUseCase
} from "@dropDesk/domain/use_case/company/list_company_subscription_logs_company.usecase";
import {
    GetThemeByUniqueCodeCompanyUseCase
} from "@dropDesk/domain/use_case/company/get_theme_by_unique_code_company.usecase";
import {SetCompanyExtraInfoUseCase} from "@dropDesk/domain/use_case/company/set_extra_info_company.usecase";
import {
    ListActiveConnectionsCompanyUseCase
} from "@dropDesk/domain/use_case/company/list_active_connections_company.usecase";
import {
    SetPaymentMethodCompanyUseCase
} from "@dropDesk/domain/use_case/company/set_payment_method_subscription.usecase";


//image editor
import {
    ImageEditorDataSource,
    ImageEditorDataSourceImpl
} from "@dropDesk/data/data_source/image_editor/image_editor_remote.datasource";
import {ImageEditorRepository} from "@dropDesk/domain/repositories/image_editor/image_editor.repository";
import {ImageEditorRepositoryImpl} from "@dropDesk/data/repositories/image_editor/image_editor_impl.repository";
import {ImageEditorController} from "@dropDesk/presentation/pages/image_editor/controller/image_editor.controller";

//common
import {
    AddressRemoteDataSource,
    AddressRemoteDataSourceImpl
} from "@dropDesk/data/data_source/common/localization/localization.datasource";
import {AddressRepository} from "@dropDesk/domain/repositories/common/localization/localization.repository";
import {AddressRepositoryImpl} from "@dropDesk/data/repositories/common/localization/localization_impl.repository";
import {SearchAddressUseCase} from "@dropDesk/domain/use_case/common/localization/search_address.usecase";

import {
    CryptoRemoteDataSource,
    CryptoRemoteDataSourceImpl
} from "@dropDesk/data/data_source/common/crypto/crypto.datasource";
import {CryptoRepository} from "@dropDesk/domain/repositories/common/crypto/crypto.repository";
import {CryptoRepositoryImpl} from "@dropDesk/data/repositories/common/crypto/crypto_impl.repository";
import {CommonController} from "@dropDesk/presentation/pages/common/common.controller";
import {DecryptUseCase} from "@dropDesk/domain/use_case/common/crypto/decrypt.usecase";

import {CNPJRemoteDataSource, CNPJRemoteDataSourceImpl} from "@dropDesk/data/data_source/common/cnpj/cnpj.datasource";
import {CNPJRepository} from "@dropDesk/domain/repositories/common/cnpj/cnpj.repository";
import {CNPJRepositoryImpl} from "@dropDesk/data/repositories/common/cnpj/cnpj_impl.repository";
import {SearchCNPJUseCase} from "@dropDesk/domain/use_case/common/cnpj/search_cnpj.usecase";
import {SegmentsUseCase} from "@dropDesk/domain/use_case/common/segments/segments.usecase";
import {GetSignUpContractUseCase} from "@dropDesk/domain/use_case/common/contracts/get_sign_up_contract.usecase";
import {SendMessageUseCase} from "@dropDesk/domain/use_case/chat/send_message_message.usecase";
import {SubscriptionUploadFileUseCase} from "@dropDesk/domain/use_case/chat/upload_file_message.usecase";
import {
    TicketEvaluationController
} from "@dropDesk/presentation/pages/ticket/evaluation/controller/ticket_evaluation.controller";
import {TicketEvaluationRepository} from "@dropDesk/domain/repositories/ticket/evaluation/ticket_evaluation.repository";
import {
    TicketEvaluationRepositoryImpl
} from "@dropDesk/data/repositories/ticket/evaluation/ticket_evaluation_impl.repository";
import {IoController} from "@dropDesk/presentation/pages/io/io.controller";
import {DeleteMessageUseCase} from "@dropDesk/domain/use_case/chat/delete_message.usecase";
import {UpdateCacheFromSingleTicketUseCase} from "@dropDesk/domain/use_case/chat/update_cache_from_single_ticket_message.usecase";
import {EditMessageUseCase} from "@dropDesk/domain/use_case/chat/edit_message.usecase";
import {ChangePasswordUserUseCase} from "@dropDesk/domain/use_case/user/change_password_user.usecase";
import {ForgotUserUseCase} from "@dropDesk/domain/use_case/user/forgot_user.usecase";
import {ActivateAccountUseCase} from "@dropDesk/domain/use_case/user/activate_account_user.usecase";
import {TransferUserUseCase} from "@dropDesk/domain/use_case/user/transfer_user.usecase";
import {ChangeEmailUserUseCase} from "@dropDesk/domain/use_case/user/change_email_user.usecase";
import {AuthHashClient} from "@dropDesk/data/clients/auth_hash_client";
import {SegmentsRepository} from "@dropDesk/domain/repositories/common/segments/segments.repository";
import {ContractsRepository} from "@dropDesk/domain/repositories/common/contracts/contracts.repository";
import {SegmentsRepositoryImpl} from "@dropDesk/data/repositories/common/segments/segments_impl.repository";
import {ContractsRepositoryImpl} from "@dropDesk/data/repositories/common/contracts/contracts_impl.repository";
import {
    SegmentsRemoteDataSource,
    SegmentsRemoteDataSourceImpl
} from "@dropDesk/data/data_source/common/segments/segments.datasource";
import {
    ContractsRemoteDataSource,
    ContractsRemoteDataSourceImpl
} from "@dropDesk/data/data_source/common/contracts/contracts.datasource";
import {GetTicketsGlobalConfigsUseCase} from "@dropDesk/domain/use_case/ticket/get_tickets_global_configs.usecase";
import {GetCurrentImportClientUseCase} from "@dropDesk/domain/use_case/client/get_current_import.usecase";
import {ListImportUseCase} from "@dropDesk/domain/use_case/client/list_import_pagination.usecase";
import {ListTicketsUseCase} from "@dropDesk/domain/use_case/ticket/list_tickets.usecase";

const TYPE_INJECT = {
    //app injection
    AppController: AppController,

    //user injection
    UserRemoteDataSource: UserRemoteDataSource,
    UserRepository: UserRepository,
    GetUserByTokenUseCase: GetUserByTokenUseCase,
    GetUserByDecodedTokenUseCase: GetUserByDecodedTokenUseCase,
    LogoutByAdminUseCase: LogoutByAdminUseCase,
    UserController: UserController,
    AuthRemoteDataSource: AuthRemoteDataSource,
    LoginUseCase: LoginUseCase,
    GetCurrentUserUseCase: GetCurrentUserUseCase,
    GetTicketsGlobalConfigsUseCase: GetTicketsGlobalConfigsUseCase,
    DeleteUserUseCase: DeleteUserUseCase,
    SetThemeUserUseCase: SetThemeUserUseCase,
    SetUserUseCase: SetUserUseCase,
    RestoreUserUseCase: RestoreUserUseCase,
    FindByPKUserUseCase: FindByPKUserUseCase,
    ListUserUseCase: ListUserUseCase,
    ExportDataUserUseCase: ExportDataUserUseCase,
    ExportUsersSubscriptionUseCase: ExportUsersSubscriptionUseCase,
    TransferUserUseCase: TransferUserUseCase,
    AuthRepository: AuthRepository,
    LoginController: LoginController,
    LogoutUseCase: LogoutUseCase,
    GetUserByFirebaseUseCase: GetUserByFirebaseUseCase,
    LoginServerUseCase: LoginServerUseCase,
    RecoveryAccountUseCase: RecoveryAccountUseCase,
    ChangePasswordUserUseCase: ChangePasswordUserUseCase,
    ForgotUserUseCase: ForgotUserUseCase,
    ActivateAccountUseCase: ActivateAccountUseCase,
    ChangeEmailUserUseCase: ChangeEmailUserUseCase,

    //storage injection
    RemoteStorageDatasource: RemoteStorageDatasource,

    //io injection
    IoRemoteDataSource: IoRemoteDataSource,
    CreateObjectUrlBlobUseCase: CreateObjectUrlBlobUseCase,
    IoController: IoController,
    UrlToBlobUseCase: UrlToBlobUseCase,
    DownloadFileUseCase: DownloadFileUseCase,
    GetAudioFromMediaRecorderUseCase: GetAudioFromMediaRecorderUseCase,
    GetMediaStreamUseCase: GetMediaStreamUseCase,
    StartRecordVoiceListenerUseCase: StartRecordVoiceListenerUseCase,
    StopRecordUseCase: StopRecordUseCase,
    IoRepository: IoRepository,

    //task
    TaskRemoteDataSource: TaskRemoteDataSource,
    TaskRepository: TaskRepository,
    DeleteTaskUseCase: DeleteTaskUseCase,
    ListTaskUseCase: ListTaskUseCase,
    SetTaskUseCase: SetTaskUseCase,
    MarkDoneUndoneTaskUseCase: MarkDoneUndoneTaskUseCase,
    TaskController: TaskController,

    //report_tickets
    ReportTicketRemoteDataSource: ReportTicketRemoteDataSource,
    ReportTicketRepository: ReportTicketRepository,
    GetReportTicketUseCase: GetReportTicketUseCase,
    ExportPdfReportTicketUseCase: ExportPdfReportTicketUseCase,
    ReportTicketController: ReportTicketController,

    //report_tickets_aggregates
    ReportTicketAggregateRemoteDataSource: ReportTicketAggregateRemoteDataSource,
    ReportTicketAggregateRepository: ReportTicketAggregateRepository,
    GetReportTicketAggregateUseCase: GetReportTicketAggregateUseCase,
    ExportPdfReportTicketAggregateUseCase: ExportPdfReportTicketAggregateUseCase,
    ReportTicketAggregateController: ReportTicketAggregateController,

    //report_tickets_rate
    ReportTicketRateRemoteDataSource: ReportTicketRateRemoteDataSource,
    ReportTicketRateRepository: ReportTicketRateRepository,
    GetReportTicketRateUseCase: GetReportTicketRateUseCase,
    ReportTicketRateController: ReportTicketRateController,

    //ticket injection
    TicketRemoteDataSource: TicketRemoteDataSource,
    TicketController: TicketController,
    DeleteTicketsUseCase: DeleteTicketsUseCase,
    SetTicketsUseCase: SetTicketsUseCase,
    RestoreTicketsUseCase: RestoreTicketsUseCase,
    FindByPKTicketsUseCase: FindByPKTicketsUseCase,
    ListTicketsSubscriptionUseCase: ListTicketsSubscriptionUseCase,
    ListTicketsUseCase: ListTicketsUseCase,
    TransferTicketUseCase: TransferTicketUseCase,
    ExportPDFTicketsUseCase: ExportPDFTicketsUseCase,
    ChangeTimeSpentTicketUseCase: ChangeTimeSpentTicketUseCase,
    ChangePriorityTicketUseCase: ChangePriorityTicketUseCase,
    ChangeDateDueTicketUseCase: ChangeDateDueTicketUseCase,
    CancelTicketUseCase: CancelTicketUseCase,
    CloseTicketUseCase: CloseTicketUseCase,
    ChangeResolutionTicketUseCase: ChangeResolutionTicketUseCase,
    ChangeProblemDescriptionTicketUseCase: ChangeProblemDescriptionTicketUseCase,
    LinkAttendantTicketUseCase: LinkAttendantTicketUseCase,
    ExportDataTicketsUseCase: ExportDataTicketsUseCase,
    ExportTicketsSubscriptionUseCase: ExportTicketsSubscriptionUseCase,
    ListTicketMessagesAfterLastSubsUseCase: ListTicketMessagesAfterLastSubsUseCase,
    TicketRepository: TicketRepository,

    //chat injection
    ChatRemoteDataSource: ChatRemoteDataSource,
    ChatRepository: ChatRepository,
    ListChatsUseCase: ListChatsAttendingUseCase,
    ListWaitingChatUseCase: ListChatsWaitingChatUseCase,
    ListChatMessagesUseCase: ListChatMessagesUseCase,
    ListAllChatsChatUseCase: ListAllChatsChatUseCase,
    ListChatMessagesAfterLastSubsUseCase: ListChatMessagesAfterLastSubsUseCase,
    SubscribeChatsUseCase: SubscribeChatsUseCase,
    ClearTicketsCacheUseCase: ClearTicketsCacheUseCase,
    JoinTicketChatUseCase: JoinTicketChatUseCase,
    LeaveTicketChatUseCase: LeaveTicketChatUseCase,
    StartConnectionChatsUseCase: StartConnectionChatsUseCase,
    SubscribeChatConnectionUseCase: SubscribeChatConnectionUseCase,
    ConnectionStateChatsUseCase: ConnectionStateChatsUseCase,
    NavigateToMessageUseCase: NavigateToMessageUseCase,
    ReactionMessageUseCase: ReactionMessageUseCase,
    InformReadMessageUseCase: InformReadMessageUseCase,
    LogoutChatsUseCase: LogoutChatsUseCase,
    ChatController: ChatController,


    //evaluation
    SetTicketEvaluationUseCase: SetTicketEvaluationUseCase,
    FindByPKTicketEvaluationUseCase: FindByPKTicketEvaluationUseCase,
    ListTicketEvaluationUseCase: ListTicketEvaluationUseCase,
    TicketEvaluationRemoteDataSource: TicketEvaluationRemoteDataSource,
    TicketEvaluationController: TicketEvaluationController,
    TicketEvaluationRepository: TicketEvaluationRepository,

    //message
    SendMessageUseCase: SendMessageUseCase,
    SubscriptionUploadFileUseCase: SubscriptionUploadFileUseCase,
    DeleteMessageUseCase: DeleteMessageUseCase,
    UpdateCacheFromSingleTicketUseCase: UpdateCacheFromSingleTicketUseCase,
    EditMessageUseCase: EditMessageUseCase,

    //clients
    ClientController: ClientController,
    ListClientUseCase: ListClientUseCase,
    ListImportUseCase: ListImportUseCase,
    DeleteClientUseCase: DeleteClientUseCase,
    RestoreClientUseCase: RestoreClientUseCase,
    SetClientUseCase: SetClientUseCase,
    FindByPKClientUseCase: FindByPKClientUseCase,
    ListImportSubscriptionUseCase: ListImportSubscriptionUseCase,
    ImportClientUseCase: ImportClientUseCase,
    ImportSubscriptionClientUseCase: ImportSubscriptionClientUseCase,
    RevertImportClientUseCase: RevertImportClientUseCase,
    GetCurrentImportClientUseCase: GetCurrentImportClientUseCase,
    DownloadFileExampleClientUseCase: DownloadFileExampleClientUseCase,
    ListOnlyUsersLinkedClientUseCase: ListOnlyUsersLinkedClientUseCase,
    ExportClientsSubscriptionUseCase: ExportClientsSubscriptionUseCase,
    ExportDataClientUseCase: ExportDataClientUseCase,
    ClientRepository: ClientRepository,
    ClientRemoteDataSource: ClientRemoteDataSource,

    //exports base datasource
    ExportRemoteDataSource: ExportRemoteDataSource,


    //description static injection
    TicketsDescriptionStaticRemoteDataSource: TicketsDescriptionStaticRemoteDataSource,
    TicketsDescriptionStaticRepository: TicketsDescriptionStaticRepository,
    TicketsDescriptionStaticController: TicketsDescriptionStaticController,
    DeleteTicketsDescriptionStaticUseCase: DeleteTicketsDescriptionStaticUseCase,
    SetTicketsDescriptionStaticUseCase: SetTicketsDescriptionStaticUseCase,
    RestoreTicketsDescriptionStaticUseCase: RestoreTicketsDescriptionStaticUseCase,
    FindByPKTicketsDescriptionStaticUseCase: FindByPKTicketsDescriptionStaticUseCase,
    ListTicketsDescriptionStaticUseCase: ListTicketsDescriptionStaticUseCase,


    //sector injection
    SectorRemoteDataSource: SectorRemoteDataSource,
    SectorRepository: SectorRepository,
    SectorController: SectorController,
    DeleteSectorUseCase: DeleteSectorUseCase,
    SetSectorUseCase: SetSectorUseCase,
    RestoreSectorUseCase: RestoreSectorUseCase,
    FindByPKSectorUseCase: FindByPKSectorUseCase,
    ListSectorUseCase: ListSectorUseCase,
    ExportDataSectorSubscriptionUseCase: ExportDataSectorSubscriptionUseCase,
    ExportDataSectorUseCase: ExportDataSectorUseCase,

    //company injection
    CompanyRemoteDataSource: CompanyRemoteDataSource,
    CompanyRepository: CompanyRepository,
    CompanyController: CompanyController,
    SetCompanyUseCase: SetCompanyUseCase,
    SetUpdateLegacyCompanyUseCase: SetUpdateLegacyCompanyUseCase,
    SetThemeCompanyUseCase: SetThemeCompanyUseCase,
    CreateAccountCompanyUseCase: CreateAccountCompanyUseCase,
    ListCompanySubscriptionLogsUseCase: ListCompanySubscriptionLogsUseCase,
    GetThemeByUniqueCodeCompanyUseCase: GetThemeByUniqueCodeCompanyUseCase,
    SetCompanyExtraInfoUseCase: SetCompanyExtraInfoUseCase,
    ListActiveConnectionsCompanyUseCase: ListActiveConnectionsCompanyUseCase,
    FindByPKCompanyUseCase: FindByPKCompanyUseCase,


    //response Standard injection
    ResponseStandardRemoteDataSource: ResponseStandardRemoteDataSource,
    ResponseStandardRepository: ResponseStandardRepository,
    ResponseStandardController: ResponseStandardController,
    DeleteResponseStandardUseCase: DeleteResponseStandardUseCase,
    SetResponseStandardUseCase: SetResponseStandardUseCase,
    RestoreResponseStandardUseCase: RestoreResponseStandardUseCase,
    FindByPKResponseStandardUseCase: FindByPKResponseStandardUseCase,
    ListResponseStandardUseCase: ListResponseStandardUseCase,
    //company subscription injection

    //notices injection
    NoticesRemoteDataSource: NoticesRemoteDataSource,
    NoticesRepository: NoticesRepository,
    NoticesController: NoticesController,
    DeleteNoticesUseCase: DeleteNoticesUseCase,
    SetNoticesUseCase: SetNoticesUseCase,
    RestoreNoticesUseCase: RestoreNoticesUseCase,
    FindByPKNoticesUseCase: FindByPKNoticesUseCase,
    ListNoticesUseCase: ListNoticesUseCase,
    ListNoticesByUserUseCase: ListNoticesByUserUseCase,

    //subscription injection
    SubscriptionController: SubscriptionController,
    SubscriptionRemoteDataSource: SubscriptionRemoteDataSource,
    SubscriptionRepository: SubscriptionRepository,
    ListPlansSubscriptionUseCase: ListPlansSubscriptionUseCase,
    PaySubscriptionUseCase: PaySubscriptionUseCase,
    getPaymentSubscriptionUseCase: GetPaymentSubscriptionUseCase,
    FindByPKSubscriptionUseCase: FindByPKSubscriptionUseCase,
    ListInvoicesSubscriptionUseCase: ListInvoicesSubscriptionUseCase,
    ReactivateSubscriptionUseCase: ReactivateSubscriptionUseCase,
    GetPdfByPaymentIdSubscriptionUseCase: GetPdfByPaymentIdSubscriptionUseCase,
    CancelSubscriptionUseCase: CancelSubscriptionUseCase,
    GetSimulateSubscriptionUseCase: GetSimulateSubscriptionUseCase,
    SetPaymentMethodCompanyUseCase: SetPaymentMethodCompanyUseCase,

    //company configurations injection
    GetCompanyConfigurationsUseCase: GetCompanyConfigurationsUseCase,
    SetTicketConfigurationsUseCase: SetTicketConfigurationsUseCase,
    SetPanelAdmConfigurationsUseCase: SetPanelAdmConfigurationsUseCase,
    SetChatConfigurationsUseCase: SetChatConfigurationsUseCase,
    SetBusinessOperatorConfigurationsUseCase: SetBusinessOperatorConfigurationsUseCase,
    SetPortalClientConfigurationUseCase: SetPortalClientConfigurationUseCase,
    ResetChatMessagesCompanyUseCase: ResetChatMessagesCompanyUseCase,
    SetCompanyConfigurationsUseCase: SetCompanyConfigurationsUseCase,
    SetConfigurationNotificationsUseCase: SetConfigurationNotificationsUseCase,
    CompanyConfigurationsRemoteDataSource: CompanyConfigurationsRemoteDataSource,
    CompanyConfigurationRepository: CompanyConfigurationRepository,
    CompanyConfigurationController: CompanyConfigurationController,

    //common
    AddressRemoteDataSource: AddressRemoteDataSource,
    AddressRepository: AddressRepository,
    SearchAddressUseCase: SearchAddressUseCase,
    CNPJRemoteDataSource: CNPJRemoteDataSource,
    CryptoRemoteDataSource: CryptoRemoteDataSource,
    SegmentsRemoteDataSource: SegmentsRemoteDataSource,
    ContractsRemoteDataSource: ContractsRemoteDataSource,
    CNPJRepository: CNPJRepository,
    CryptoRepository: CryptoRepository,
    SegmentsRepository: SegmentsRepository,
    ContractsRepository: ContractsRepository,
    SearchCNPJUseCase: SearchCNPJUseCase,
    DecryptUseCase: DecryptUseCase,
    CommonController: CommonController,
    SegmentsUseCase: SegmentsUseCase,
    GetSignUpContractUseCase: GetSignUpContractUseCase,

    //image editor
    ImageEditorDataSource: ImageEditorDataSource,
    ImageEditorRepository: ImageEditorRepository,
    ImageEditorController: ImageEditorController,

    //auth hash
    AuthHashClient: AuthHashClient,
};

export const getIt = (): Container => {
    const container = new Container();

    //app injection
    container.bind<AppController>(TYPE_INJECT.AppController).toSelf().inSingletonScope();

    //auth hash injection
    container.bind<AuthHashClient>(TYPE_INJECT.AuthHashClient).toSelf().inSingletonScope();

    //io
    container.bind<IoRemoteDataSource>(TYPE_INJECT.IoRemoteDataSource).to(IoRemoteDataSourceImpl);
    container.bind<CreateObjectUrlBlobUseCase>(TYPE_INJECT.CreateObjectUrlBlobUseCase).toSelf();
    container.bind<IoController>(TYPE_INJECT.IoController).toSelf();
    container.bind<UrlToBlobUseCase>(TYPE_INJECT.UrlToBlobUseCase).toSelf();
    container.bind<DownloadFileUseCase>(TYPE_INJECT.DownloadFileUseCase).toSelf();
    container.bind<GetAudioFromMediaRecorderUseCase>(TYPE_INJECT.GetAudioFromMediaRecorderUseCase).toSelf();
    container.bind<GetMediaStreamUseCase>(TYPE_INJECT.GetMediaStreamUseCase).toSelf();
    container.bind<StartRecordVoiceListenerUseCase>(TYPE_INJECT.StartRecordVoiceListenerUseCase).toSelf();
    container.bind<StopRecordUseCase>(TYPE_INJECT.StopRecordUseCase).toSelf();
    container.bind<IoRepository>(TYPE_INJECT.IoRepository).to(IoRepositoryImpl);

    //storage injection
    container.bind<RemoteStorageDatasource>(TYPE_INJECT.RemoteStorageDatasource).to(RemoteStorageDatasourceImpl);

    //task injection
    container.bind<TaskRemoteDataSource>(TYPE_INJECT.TaskRemoteDataSource).to(TaskRemoteDataSourceImpl);
    container.bind<TaskRepository>(TYPE_INJECT.TaskRepository).to(TaskRepositoryImpl);
    container.bind<DeleteTaskUseCase>(TYPE_INJECT.DeleteTaskUseCase).toSelf();
    container.bind<ListTaskUseCase>(TYPE_INJECT.ListTaskUseCase).toSelf();
    container.bind<SetTaskUseCase>(TYPE_INJECT.SetTaskUseCase).toSelf();
    container.bind<MarkDoneUndoneTaskUseCase>(TYPE_INJECT.MarkDoneUndoneTaskUseCase).toSelf();
    container.bind<TaskController>(TYPE_INJECT.TaskController).toSelf();

    //report tickets injection
    container.bind<ReportTicketRemoteDataSource>(TYPE_INJECT.ReportTicketRemoteDataSource).to(ReportTicketRemoteDataSourceImpl);
    container.bind<ReportTicketRepository>(TYPE_INJECT.ReportTicketRepository).to(ReportTicketRepositoryImpl);
    container.bind<GetReportTicketUseCase>(TYPE_INJECT.GetReportTicketUseCase).toSelf();
    container.bind<ExportPdfReportTicketUseCase>(TYPE_INJECT.ExportPdfReportTicketUseCase).toSelf();
    container.bind<ReportTicketController>(TYPE_INJECT.ReportTicketController).toSelf();

    //report tickets aggregate injection
    container.bind<ReportTicketAggregateRemoteDataSource>(TYPE_INJECT.ReportTicketAggregateRemoteDataSource).to(ReportTicketAggregateRemoteDataSourceImpl);
    container.bind<ReportTicketAggregateRepository>(TYPE_INJECT.ReportTicketAggregateRepository).to(ReportTicketAggregateRepositoryImpl);
    container.bind<GetReportTicketAggregateUseCase>(TYPE_INJECT.GetReportTicketAggregateUseCase).toSelf();
    container.bind<ExportPdfReportTicketAggregateUseCase>(TYPE_INJECT.ExportPdfReportTicketAggregateUseCase).toSelf();
    container.bind<ReportTicketAggregateController>(TYPE_INJECT.ReportTicketAggregateController).toSelf();

    //report tickets rate injection
    container.bind<ReportTicketRateRemoteDataSource>(TYPE_INJECT.ReportTicketRateRemoteDataSource).to(ReportTicketRateRemoteDataSourceImpl);
    container.bind<ReportTicketRateRepository>(TYPE_INJECT.ReportTicketRateRepository).to(ReportTicketRateRepositoryImpl);
    container.bind<GetReportTicketRateUseCase>(TYPE_INJECT.GetReportTicketRateUseCase).toSelf();
    container.bind<ReportTicketRateController>(TYPE_INJECT.ReportTicketRateController).toSelf();

    //user injection
    container.bind<UserRemoteDataSource>(TYPE_INJECT.UserRemoteDataSource).to(UserRemoteDataSourceImpl);
    container.bind<UserRepository>(TYPE_INJECT.UserRepository).to(UserRepositoryImpl);
    container.bind<GetUserByTokenUseCase>(TYPE_INJECT.GetUserByTokenUseCase).toSelf();
    container.bind<GetUserByDecodedTokenUseCase>(TYPE_INJECT.GetUserByDecodedTokenUseCase).toSelf();
    container.bind<LogoutByAdminUseCase>(TYPE_INJECT.LogoutByAdminUseCase).toSelf();
    container.bind<UserController>(TYPE_INJECT.UserController).toSelf();
    container.bind<AuthRemoteDataSource>(TYPE_INJECT.AuthRemoteDataSource).to(AuthRemoteDataSourceImpl);
    container.bind<AuthRepository>(TYPE_INJECT.AuthRepository).to(AuthRepositoryImpl);
    container.bind<LoginUseCase>(TYPE_INJECT.LoginUseCase).toSelf();
    container.bind<GetCurrentUserUseCase>(TYPE_INJECT.GetCurrentUserUseCase).toSelf();
    container.bind<GetTicketsGlobalConfigsUseCase>(TYPE_INJECT.GetTicketsGlobalConfigsUseCase).toSelf();
    container.bind<SetUserUseCase>(TYPE_INJECT.SetUserUseCase).toSelf();
    container.bind<RestoreUserUseCase>(TYPE_INJECT.RestoreUserUseCase).toSelf();
    container.bind<DeleteUserUseCase>(TYPE_INJECT.DeleteUserUseCase).toSelf();
    container.bind<SetThemeUserUseCase>(TYPE_INJECT.SetThemeUserUseCase).toSelf();
    container.bind<FindByPKUserUseCase>(TYPE_INJECT.FindByPKUserUseCase).toSelf();
    container.bind<ListUserUseCase>(TYPE_INJECT.ListUserUseCase).toSelf();
    container.bind<ExportDataUserUseCase>(TYPE_INJECT.ExportDataUserUseCase).toSelf();
    container.bind<ExportUsersSubscriptionUseCase>(TYPE_INJECT.ExportUsersSubscriptionUseCase).toSelf();
    container.bind<ChangeEmailUserUseCase>(TYPE_INJECT.ChangeEmailUserUseCase).toSelf();
    container.bind<TransferUserUseCase>(TYPE_INJECT.TransferUserUseCase).toSelf();
    container.bind<LoginController>(TYPE_INJECT.LoginController).toSelf();
    container.bind<LogoutUseCase>(TYPE_INJECT.LogoutUseCase).toSelf();
    container.bind<GetUserByFirebaseUseCase>(TYPE_INJECT.GetUserByFirebaseUseCase).toSelf();
    container.bind<LoginServerUseCase>(TYPE_INJECT.LoginServerUseCase).toSelf();
    container.bind<RecoveryAccountUseCase>(TYPE_INJECT.RecoveryAccountUseCase).toSelf();
    container.bind<ChangePasswordUserUseCase>(TYPE_INJECT.ChangePasswordUserUseCase).toSelf();
    container.bind<ForgotUserUseCase>(TYPE_INJECT.ForgotUserUseCase).toSelf();
    container.bind<ActivateAccountUseCase>(TYPE_INJECT.ActivateAccountUseCase).toSelf();

    //ticket injection
    container.bind<TicketRemoteDataSource>(TYPE_INJECT.TicketRemoteDataSource).to(TicketRemoteDataSourceImpl);
    container.bind<TicketRepository>(TYPE_INJECT.TicketRepository).to(TicketRepositoryImpl);
    container.bind<TicketController>(TYPE_INJECT.TicketController).toSelf();
    container.bind<SetTicketsUseCase>(TYPE_INJECT.SetTicketsUseCase).toSelf();
    container.bind<RestoreTicketsUseCase>(TYPE_INJECT.RestoreTicketsUseCase).toSelf();
    container.bind<DeleteTicketsUseCase>(TYPE_INJECT.DeleteTicketsUseCase).toSelf();
    container.bind<FindByPKTicketsUseCase>(TYPE_INJECT.FindByPKTicketsUseCase).toSelf();
    container.bind<ListTicketsSubscriptionUseCase>(TYPE_INJECT.ListTicketsSubscriptionUseCase).toSelf();
    container.bind<ListTicketsUseCase>(TYPE_INJECT.ListTicketsUseCase).toSelf();
    container.bind<TransferTicketUseCase>(TYPE_INJECT.TransferTicketUseCase).toSelf();
    container.bind<ExportPDFTicketsUseCase>(TYPE_INJECT.ExportPDFTicketsUseCase).toSelf();
    container.bind<ChangeTimeSpentTicketUseCase>(TYPE_INJECT.ChangeTimeSpentTicketUseCase).toSelf();
    container.bind<ChangePriorityTicketUseCase>(TYPE_INJECT.ChangePriorityTicketUseCase).toSelf();
    container.bind<ChangeDateDueTicketUseCase>(TYPE_INJECT.ChangeDateDueTicketUseCase).toSelf();
    container.bind<CancelTicketUseCase>(TYPE_INJECT.CancelTicketUseCase).toSelf();
    container.bind<CloseTicketUseCase>(TYPE_INJECT.CloseTicketUseCase).toSelf();
    container.bind<ChangeResolutionTicketUseCase>(TYPE_INJECT.ChangeResolutionTicketUseCase).toSelf();
    container.bind<ChangeProblemDescriptionTicketUseCase>(TYPE_INJECT.ChangeProblemDescriptionTicketUseCase).toSelf();
    container.bind<LinkAttendantTicketUseCase>(TYPE_INJECT.LinkAttendantTicketUseCase).toSelf();
    container.bind<ExportDataTicketsUseCase>(TYPE_INJECT.ExportDataTicketsUseCase).toSelf();
    container.bind<ExportTicketsSubscriptionUseCase>(TYPE_INJECT.ExportTicketsSubscriptionUseCase).toSelf();
    container.bind<ListTicketMessagesAfterLastSubsUseCase>(TYPE_INJECT.ListTicketMessagesAfterLastSubsUseCase).toSelf();

    //chat injection
    container.bind<ChatRemoteDataSource>(TYPE_INJECT.ChatRemoteDataSource).to(ChatRemoteDataSourceImpl).inSingletonScope();
    container.bind<ChatRepository>(TYPE_INJECT.ChatRepository).to(ChatRepositoryImpl);
    container.bind<ListChatsWaitingChatUseCase>(TYPE_INJECT.ListWaitingChatUseCase).toSelf();
    container.bind<ListChatMessagesUseCase>(TYPE_INJECT.ListChatMessagesUseCase).toSelf();
    container.bind<ListAllChatsChatUseCase>(TYPE_INJECT.ListAllChatsChatUseCase).toSelf();
    container.bind<ListChatMessagesAfterLastSubsUseCase>(TYPE_INJECT.ListChatMessagesAfterLastSubsUseCase).toSelf();
    container.bind<SubscribeChatsUseCase>(TYPE_INJECT.SubscribeChatsUseCase).toSelf();
    container.bind<ClearTicketsCacheUseCase>(TYPE_INJECT.ClearTicketsCacheUseCase).toSelf();
    container.bind<JoinTicketChatUseCase>(TYPE_INJECT.JoinTicketChatUseCase).toSelf();
    container.bind<LeaveTicketChatUseCase>(TYPE_INJECT.LeaveTicketChatUseCase).toSelf();
    container.bind<StartConnectionChatsUseCase>(TYPE_INJECT.StartConnectionChatsUseCase).toSelf();
    container.bind<SubscribeChatConnectionUseCase>(TYPE_INJECT.SubscribeChatConnectionUseCase).toSelf();
    container.bind<ConnectionStateChatsUseCase>(TYPE_INJECT.ConnectionStateChatsUseCase).toSelf();
    container.bind<NavigateToMessageUseCase>(TYPE_INJECT.NavigateToMessageUseCase).toSelf();
    container.bind<ReactionMessageUseCase>(TYPE_INJECT.ReactionMessageUseCase).toSelf();
    container.bind<InformReadMessageUseCase>(TYPE_INJECT.InformReadMessageUseCase).toSelf();
    container.bind<LogoutChatsUseCase>(TYPE_INJECT.LogoutChatsUseCase).toSelf();
    container.bind<ListChatsAttendingUseCase>(TYPE_INJECT.ListChatsUseCase).toSelf();
    container.bind<ChatController>(TYPE_INJECT.ChatController).toSelf().inSingletonScope();

    //message
    container.bind<SendMessageUseCase>(TYPE_INJECT.SendMessageUseCase).toSelf();
    container.bind<SubscriptionUploadFileUseCase>(TYPE_INJECT.SubscriptionUploadFileUseCase).toSelf();
    container.bind<DeleteMessageUseCase>(TYPE_INJECT.DeleteMessageUseCase).toSelf();
    container.bind<UpdateCacheFromSingleTicketUseCase>(TYPE_INJECT.UpdateCacheFromSingleTicketUseCase).toSelf();
    container.bind<EditMessageUseCase>(TYPE_INJECT.EditMessageUseCase).toSelf();

    //ticket evaluation
    container.bind<SetTicketEvaluationUseCase>(TYPE_INJECT.SetTicketEvaluationUseCase).toSelf();
    container.bind<FindByPKTicketEvaluationUseCase>(TYPE_INJECT.FindByPKTicketEvaluationUseCase).toSelf();
    container.bind<ListTicketEvaluationUseCase>(TYPE_INJECT.ListTicketEvaluationUseCase).toSelf();
    container.bind<TicketEvaluationRemoteDataSource>(TYPE_INJECT.TicketEvaluationRemoteDataSource).to(TicketEvaluationRemoteDataSourceImpl);
    container.bind<TicketEvaluationController>(TYPE_INJECT.TicketEvaluationController).toSelf();
    container.bind<TicketEvaluationRepository>(TYPE_INJECT.TicketEvaluationRepository).to(TicketEvaluationRepositoryImpl);


    //client injection
    container.bind<ClientController>(TYPE_INJECT.ClientController).toSelf();
    container.bind<ListClientUseCase>(TYPE_INJECT.ListClientUseCase).toSelf();
    container.bind<ListImportUseCase>(TYPE_INJECT.ListImportUseCase).toSelf();
    container.bind<DeleteClientUseCase>(TYPE_INJECT.DeleteClientUseCase).toSelf();
    container.bind<RestoreClientUseCase>(TYPE_INJECT.RestoreClientUseCase).toSelf();
    container.bind<SetClientUseCase>(TYPE_INJECT.SetClientUseCase).toSelf();
    container.bind<ListImportSubscriptionUseCase>(TYPE_INJECT.ListImportSubscriptionUseCase).toSelf();
    container.bind<ImportClientUseCase>(TYPE_INJECT.ImportClientUseCase).toSelf();
    container.bind<ImportSubscriptionClientUseCase>(TYPE_INJECT.ImportSubscriptionClientUseCase).toSelf();
    container.bind<RevertImportClientUseCase>(TYPE_INJECT.RevertImportClientUseCase).toSelf();
    container.bind<GetCurrentImportClientUseCase>(TYPE_INJECT.GetCurrentImportClientUseCase).toSelf();
    container.bind<DownloadFileExampleClientUseCase>(TYPE_INJECT.DownloadFileExampleClientUseCase).toSelf();
    container.bind<FindByPKClientUseCase>(TYPE_INJECT.FindByPKClientUseCase).toSelf();
    container.bind<ListOnlyUsersLinkedClientUseCase>(TYPE_INJECT.ListOnlyUsersLinkedClientUseCase).toSelf();
    container.bind<ExportClientsSubscriptionUseCase>(TYPE_INJECT.ExportClientsSubscriptionUseCase).toSelf();
    container.bind<ExportDataClientUseCase>(TYPE_INJECT.ExportDataClientUseCase).toSelf();
    container.bind<ClientRepository>(TYPE_INJECT.ClientRepository).to(ClientRepositoryImpl);
    container.bind<ClientRemoteDataSource>(TYPE_INJECT.ClientRemoteDataSource).to(ClientRemoteDataSourceImpl);

    //exports base datasource
    container.bind<ExportRemoteDataSource>(TYPE_INJECT.ExportRemoteDataSource).to(ExportRemoteDataSourceImpl);


    //sector injection
    container.bind<SectorRemoteDataSource>(TYPE_INJECT.SectorRemoteDataSource).to(SectorRemoteDataSourceImpl);
    container.bind<SectorRepository>(TYPE_INJECT.SectorRepository).to(SectorRepositoryImpl);
    container.bind<SectorController>(TYPE_INJECT.SectorController).toSelf();
    container.bind<SetSectorUseCase>(TYPE_INJECT.SetSectorUseCase).toSelf();
    container.bind<RestoreSectorUseCase>(TYPE_INJECT.RestoreSectorUseCase).toSelf();
    container.bind<DeleteSectorUseCase>(TYPE_INJECT.DeleteSectorUseCase).toSelf();
    container.bind<FindByPKSectorUseCase>(TYPE_INJECT.FindByPKSectorUseCase).toSelf();
    container.bind<ListSectorUseCase>(TYPE_INJECT.ListSectorUseCase).toSelf();
    container.bind<ExportDataSectorSubscriptionUseCase>(TYPE_INJECT.ExportDataSectorSubscriptionUseCase).toSelf();
    container.bind<ExportDataSectorUseCase>(TYPE_INJECT.ExportDataSectorUseCase).toSelf();

    //company injection
    container.bind<CompanyRemoteDataSource>(TYPE_INJECT.CompanyRemoteDataSource).to(CompanyRemoteDataSourceImpl);
    container.bind<CompanyRepository>(TYPE_INJECT.CompanyRepository).to(CompanyRepositoryImpl);
    container.bind<CompanyController>(TYPE_INJECT.CompanyController).toSelf();
    container.bind<SetCompanyUseCase>(TYPE_INJECT.SetCompanyUseCase).toSelf();
    container.bind<SetUpdateLegacyCompanyUseCase>(TYPE_INJECT.SetUpdateLegacyCompanyUseCase).toSelf();
    container.bind<SetThemeCompanyUseCase>(TYPE_INJECT.SetThemeCompanyUseCase).toSelf();
    container.bind<CreateAccountCompanyUseCase>(TYPE_INJECT.CreateAccountCompanyUseCase).toSelf();
    container.bind<ListCompanySubscriptionLogsUseCase>(TYPE_INJECT.ListCompanySubscriptionLogsUseCase).toSelf();
    container.bind<GetThemeByUniqueCodeCompanyUseCase>(TYPE_INJECT.GetThemeByUniqueCodeCompanyUseCase).toSelf();
    container.bind<SetCompanyExtraInfoUseCase>(TYPE_INJECT.SetCompanyExtraInfoUseCase).toSelf();
    container.bind<ListActiveConnectionsCompanyUseCase>(TYPE_INJECT.ListActiveConnectionsCompanyUseCase).toSelf();
    container.bind<FindByPKCompanyUseCase>(TYPE_INJECT.FindByPKCompanyUseCase).toSelf();
    container.bind<SetPaymentMethodCompanyUseCase>(TYPE_INJECT.SetPaymentMethodCompanyUseCase).toSelf();


    //description static

    container.bind<TicketsDescriptionStaticRemoteDataSource>(TYPE_INJECT.TicketsDescriptionStaticRemoteDataSource).to(TicketsDescriptionStaticRemoteDataSourceImpl);
    container.bind<TicketsDescriptionStaticRepository>(TYPE_INJECT.TicketsDescriptionStaticRepository).to(TicketsDescriptionStaticRepositoryImpl);
    container.bind<TicketsDescriptionStaticController>(TYPE_INJECT.TicketsDescriptionStaticController).toSelf();
    container.bind<SetTicketsDescriptionStaticUseCase>(TYPE_INJECT.SetTicketsDescriptionStaticUseCase).toSelf();
    container.bind<RestoreTicketsDescriptionStaticUseCase>(TYPE_INJECT.RestoreTicketsDescriptionStaticUseCase).toSelf();
    container.bind<DeleteTicketsDescriptionStaticUseCase>(TYPE_INJECT.DeleteTicketsDescriptionStaticUseCase).toSelf();
    container.bind<FindByPKTicketsDescriptionStaticUseCase>(TYPE_INJECT.FindByPKTicketsDescriptionStaticUseCase).toSelf();
    container.bind<ListTicketsDescriptionStaticUseCase>(TYPE_INJECT.ListTicketsDescriptionStaticUseCase).toSelf();

    //response standard injection
    container.bind<ResponseStandardRemoteDataSource>(TYPE_INJECT.ResponseStandardRemoteDataSource).to(ResponseStandardRemoteDataSourceImpl);
    container.bind<ResponseStandardRepository>(TYPE_INJECT.ResponseStandardRepository).to(ResponseStandardRepositoryImpl);
    container.bind<ResponseStandardController>(TYPE_INJECT.ResponseStandardController).toSelf();
    container.bind<SetResponseStandardUseCase>(TYPE_INJECT.SetResponseStandardUseCase).toSelf();
    container.bind<RestoreResponseStandardUseCase>(TYPE_INJECT.RestoreResponseStandardUseCase).toSelf();
    container.bind<DeleteResponseStandardUseCase>(TYPE_INJECT.DeleteResponseStandardUseCase).toSelf();
    container.bind<FindByPKResponseStandardUseCase>(TYPE_INJECT.FindByPKResponseStandardUseCase).toSelf();
    container.bind<ListResponseStandardUseCase>(TYPE_INJECT.ListResponseStandardUseCase).toSelf();
    //company subscription injection

    //notices injection
    container.bind<NoticesRemoteDataSource>(TYPE_INJECT.NoticesRemoteDataSource).to(NoticesRemoteDataSourceImpl);
    container.bind<NoticesRepository>(TYPE_INJECT.NoticesRepository).to(NoticesRepositoryImpl);
    container.bind<NoticesController>(TYPE_INJECT.NoticesController).toSelf();
    container.bind<SetNoticesUseCase>(TYPE_INJECT.SetNoticesUseCase).toSelf();
    container.bind<RestoreNoticesUseCase>(TYPE_INJECT.RestoreNoticesUseCase).toSelf();
    container.bind<DeleteNoticesUseCase>(TYPE_INJECT.DeleteNoticesUseCase).toSelf();
    container.bind<FindByPKNoticesUseCase>(TYPE_INJECT.FindByPKNoticesUseCase).toSelf();
    container.bind<ListNoticesUseCase>(TYPE_INJECT.ListNoticesUseCase).toSelf();
    container.bind<ListNoticesByUserUseCase>(TYPE_INJECT.ListNoticesByUserUseCase).toSelf();

    //subscription inection
    container.bind<SubscriptionController>(TYPE_INJECT.SubscriptionController).toSelf();
    container.bind<SubscriptionRemoteDataSource>(TYPE_INJECT.SubscriptionRemoteDataSource).to(SubscriptionRemoteDataSourceImpl);
    container.bind<SubscriptionRepository>(TYPE_INJECT.SubscriptionRepository).to(SubscriptionRepositoryImpl);
    container.bind<ListPlansSubscriptionUseCase>(TYPE_INJECT.ListPlansSubscriptionUseCase).toSelf();
    container.bind<PaySubscriptionUseCase>(TYPE_INJECT.PaySubscriptionUseCase).toSelf();
    container.bind<GetPaymentSubscriptionUseCase>(TYPE_INJECT.getPaymentSubscriptionUseCase).toSelf();
    container.bind<FindByPKSubscriptionUseCase>(TYPE_INJECT.FindByPKSubscriptionUseCase).toSelf();
    container.bind<ListInvoicesSubscriptionUseCase>(TYPE_INJECT.ListInvoicesSubscriptionUseCase).toSelf();
    container.bind<ReactivateSubscriptionUseCase>(TYPE_INJECT.ReactivateSubscriptionUseCase).toSelf();
    container.bind<GetPdfByPaymentIdSubscriptionUseCase>(TYPE_INJECT.GetPdfByPaymentIdSubscriptionUseCase).toSelf();
    container.bind<CancelSubscriptionUseCase>(TYPE_INJECT.CancelSubscriptionUseCase).toSelf();
    container.bind<GetSimulateSubscriptionUseCase>(TYPE_INJECT.GetSimulateSubscriptionUseCase).toSelf();

    //company configurations injection
    container.bind<GetCompanyConfigurationsUseCase>(TYPE_INJECT.GetCompanyConfigurationsUseCase).toSelf();
    container.bind<SetTicketConfigurationsUseCase>(TYPE_INJECT.SetTicketConfigurationsUseCase).toSelf();
    container.bind<SetPanelAdmConfigurationsUseCase>(TYPE_INJECT.SetPanelAdmConfigurationsUseCase).toSelf();
    container.bind<SetChatConfigurationsUseCase>(TYPE_INJECT.SetChatConfigurationsUseCase).toSelf();
    container.bind<SetBusinessOperatorConfigurationsUseCase>(TYPE_INJECT.SetBusinessOperatorConfigurationsUseCase).toSelf();
    container.bind<SetPortalClientConfigurationUseCase>(TYPE_INJECT.SetPortalClientConfigurationUseCase).toSelf();
    container.bind<ResetChatMessagesCompanyUseCase>(TYPE_INJECT.ResetChatMessagesCompanyUseCase).toSelf();
    container.bind<SetCompanyConfigurationsUseCase>(TYPE_INJECT.SetCompanyConfigurationsUseCase).toSelf();
    container.bind<SetConfigurationNotificationsUseCase>(TYPE_INJECT.SetConfigurationNotificationsUseCase).toSelf();
    container.bind<CompanyConfigurationController>(TYPE_INJECT.CompanyConfigurationController).toSelf();
    container.bind<CompanyConfigurationsRemoteDataSource>(TYPE_INJECT.CompanyConfigurationsRemoteDataSource).to(CompanyConfigurationsRemoteDataSourceImpl);
    container.bind<CompanyConfigurationRepository>(TYPE_INJECT.CompanyConfigurationRepository).to(CompanyConfigurationRepositoryImpl);

    //image editor
    container.bind<ImageEditorDataSource>(TYPE_INJECT.ImageEditorDataSource).to(ImageEditorDataSourceImpl);
    container.bind<ImageEditorRepository>(TYPE_INJECT.ImageEditorRepository).to(ImageEditorRepositoryImpl);
    container.bind<ImageEditorController>(TYPE_INJECT.ImageEditorController).toSelf().inSingletonScope();

    //common
    container.bind<AddressRemoteDataSource>(TYPE_INJECT.AddressRemoteDataSource).to(AddressRemoteDataSourceImpl);
    container.bind<AddressRepository>(TYPE_INJECT.AddressRepository).to(AddressRepositoryImpl);
    container.bind<SearchAddressUseCase>(TYPE_INJECT.SearchAddressUseCase).toSelf();
    container.bind<CNPJRemoteDataSource>(TYPE_INJECT.CNPJRemoteDataSource).to(CNPJRemoteDataSourceImpl);
    container.bind<CryptoRemoteDataSource>(TYPE_INJECT.CryptoRemoteDataSource).to(CryptoRemoteDataSourceImpl);
    container.bind<SegmentsRemoteDataSource>(TYPE_INJECT.SegmentsRemoteDataSource).to(SegmentsRemoteDataSourceImpl);
    container.bind<ContractsRemoteDataSource>(TYPE_INJECT.ContractsRemoteDataSource).to(ContractsRemoteDataSourceImpl);
    container.bind<CNPJRepository>(TYPE_INJECT.CNPJRepository).to(CNPJRepositoryImpl);
    container.bind<CryptoRepository>(TYPE_INJECT.CryptoRepository).to(CryptoRepositoryImpl);
    container.bind<SegmentsRepository>(TYPE_INJECT.SegmentsRepository).to(SegmentsRepositoryImpl);
    container.bind<ContractsRepository>(TYPE_INJECT.ContractsRepository).to(ContractsRepositoryImpl);
    container.bind<SearchCNPJUseCase>(TYPE_INJECT.SearchCNPJUseCase).toSelf();
    container.bind<DecryptUseCase>(TYPE_INJECT.DecryptUseCase).toSelf();
    container.bind<CommonController>(TYPE_INJECT.CommonController).toSelf();
    container.bind<SegmentsUseCase>(TYPE_INJECT.SegmentsUseCase).toSelf();
    container.bind<GetSignUpContractUseCase>(TYPE_INJECT.GetSignUpContractUseCase).toSelf();
    return container;
};

export {TYPE_INJECT};
