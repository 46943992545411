import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";

export class ColorScheme {
    background!: string;
    onBackground!: string;
    backgroundInput!: string;
    backgroundInputChat!: string;
    buttonOnBackground!: string;
    text!: string;
    textOnAccent!: string;
    border!: string;
    hint!: string;
    hintInverse!: string;
    textError!: string;
    errorOnPrimary!: string;
    brightness!: Brightness;
    success!: string;
    info!: string;
    warning!: string;
    disabled!: string;
    name!: string;
    delicateBackground!: string;
    accent!: string;
    tertiary!: string;
    hintTertiary!: string;
    textLink!: string;
    readMessage!: string;

    public get isDarkTheme(): boolean {
        return this.brightness === Brightness.dark;
    }

    public get getThemeName(): string {
        return this.name === 'green' ? 'default' : this.name;
    }

    public get backgroundChatBox(): string {
        return this.isDarkTheme ? this.onBackground : this.delicateBackground;
    }

    public get backgroundLeftChatBox(): string {
        return this.isDarkTheme ? this.delicateBackground : this.backgroundInput;
    }

    public get backgroundInputChatBox(): string {
        return this.backgroundInputChat;
    }

    constructor({
                    background,
                    onBackground,
                    backgroundInput,
                    backgroundInputChat,
                    buttonOnBackground,
                    text,
                    textOnAccent,
                    border,
                    hint,
                    hintInverse,
                    errorOnPrimary,
                    textError,
                    brightness,
                    success,
                    info,
                    warning,
                    disabled,
                    name,
                    delicateBackground,
                    accent,
                    tertiary,
                    hintTertiary,
                    textLink,
                    readMessage,
                }: {
        background: string;
        onBackground: string;
        backgroundInput: string;
        backgroundInputChat: string;
        buttonOnBackground: string;
        text: string;
        textOnAccent: string;
        border: string;
        hint: string;
        hintInverse: string;
        textError: string;
        errorOnPrimary: string;
        brightness: Brightness;
        success: string;
        info: string;
        warning: string;
        disabled: string;
        name: string;
        delicateBackground: string;
        accent: string;
        tertiary: string;
        hintTertiary: string;
        textLink: string;
        readMessage: string;
    }) {
        Object.assign(this, {
            background,
            onBackground,
            backgroundInput,
            backgroundInputChat,
            buttonOnBackground,
            text,
            textOnAccent,
            border,
            hint,
            hintInverse,
            textError,
            errorOnPrimary,
            brightness,
            success,
            info,
            warning,
            disabled,
            name,
            delicateBackground,
            accent,
            tertiary,
            hintTertiary,
            textLink,
            readMessage,
        });
    }
}
