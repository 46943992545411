import {
    Container, ContainerDescription, ContainerIcon, ContentIcon, ContentSubTitle, ContentTitle, TextLink,
} from "./styled";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import DropDeskIcon from "@dropDesk/storage/icons";
import React from "react";


const ContentInfoDisconnectedSubscription = observer((
    {
        onClickReconnect,
        loading,
        visibleTemporaryInformation
    }: {
        onClickReconnect: () => void
        loading: boolean
        visibleTemporaryInformation: boolean
    }
) => {

    return (
        <Container>
            <ContainerIcon>
                <ContentIcon>
                    <DropDeskIcon icon={ICONS_DROPDESK.error} color={SharedColors.warningChat}
                                  size={26}/>
                </ContentIcon>
            </ContainerIcon>
            <ContainerDescription>
                {!loading && <ContentTitle>
                    {visibleTemporaryInformation ? 'Reconectando...' : 'Conexão interrompida'}
                </ContentTitle>}
                {visibleTemporaryInformation &&
                    <ContentSubTitle>
                        Sua conexão foi interrompida, mas estamos tentando restaurá-la. Aguarde um momento!
                    </ContentSubTitle>
                }
                {!loading && !visibleTemporaryInformation && <ContentSubTitle>
                    Confira se o computador está conectado à internet.&nbsp;&nbsp;
                    <TextLink
                        onClick={() => loading ? () => {
                        } : onClickReconnect()}
                    >
                        Reconectar <DropDeskIcon icon={ICONS_DROPDESK.next} color={SharedColors.black}/>
                    </TextLink>
                </ContentSubTitle>}

                {loading && <ContentTitle style={{height: '100%', justifyContent: 'center'}}>
                    Conectando...
                </ContentTitle>
                }
            </ContainerDescription>
        </Container>
    )
});
export default ContentInfoDisconnectedSubscription;
