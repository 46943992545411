import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {ListClientUseCase} from "@dropDesk/domain/use_case/client/list_client_pagination.usecase";
import {DeleteClientUseCase} from "@dropDesk/domain/use_case/client/delete_client.usecase";
import {RestoreClientUseCase} from "@dropDesk/domain/use_case/client/restore_client.usecase";
import {SetClientUseCase} from "@dropDesk/domain/use_case/client/set_client.usecase";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {FindByPKClientUseCase} from "@dropDesk/domain/use_case/client/findbypk_client.usecase";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {CreateObjectUrlBlobUseCase} from "@dropDesk/domain/use_case/io/create_object_url_blob.usecase";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import {SearchAddressUseCase} from "@dropDesk/domain/use_case/common/localization/search_address.usecase";
import {SearchCNPJUseCase} from "@dropDesk/domain/use_case/common/cnpj/search_cnpj.usecase";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {removeMask} from "@dropDesk/utils/helpers/string_helper";
import {ListOnlyUsersLinkedClientUseCase} from "@dropDesk/domain/use_case/client/list_only_users_linked.usecase";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {ListImportSubscriptionUseCase} from "@dropDesk/domain/use_case/client/list_imports.usecase";
import {ImportData} from "@dropDesk/domain/entities/import_data/import_data";
import {
    dateToSqoIso,
    getEndHourCurrentDate,
    getInitialHourCurrentDate,
    getSubtractDateFromNow
} from "@dropDesk/utils/helpers/date_helper";
import {ImportClientUseCase} from "@dropDesk/domain/use_case/client/import_client.usecase";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {ImportSubscriptionClientUseCase} from "@dropDesk/domain/use_case/client/import_subscription_client.usecase";
import {Subscription} from "rxjs";
import {
    newAddress,
    newClient,
    newClientCreateAccount,
    newUserClient
} from "@dropDesk/presentation/pages/client/controller/new_client";
import {RevertImportClientUseCase} from "@dropDesk/domain/use_case/client/revert_import.usecase";
import {ExportClientsSubscriptionUseCase} from "@dropDesk/domain/use_case/client/export_data_subscription.usecase";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {ExportDataClientUseCase} from "@dropDesk/domain/use_case/client/export_data.usecase";
import {
    getIdCompanyByLocalStorage,
    getPeriodAndLabelDisplay,
    initializeConfigurationLocalStorage,
    PeriodEnum,
    preventMissingPage,
    setPeriodAndLabelDisplay,
    setSearchParamLocalStorage
} from "@dropDesk/utils/helpers/common";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ClientCreateAccountEntity} from "@dropDesk/domain/entities/client/client_create_account_entity";
import {navigate} from "@dropDesk/utils/helpers/navigation";
import {DownloadFileExampleClientUseCase} from "@dropDesk/domain/use_case/client/download_file_example.usecase";
import {GetCurrentImportClientUseCase} from "@dropDesk/domain/use_case/client/get_current_import.usecase";
import {ListImportUseCase} from "@dropDesk/domain/use_case/client/list_import_pagination.usecase";

configure({
    enforceActions: "always",
});

@injectable()
export class ClientController {
    private readonly _listClientUseCase: ListClientUseCase;
    private readonly _setClientUseCase: SetClientUseCase;
    private readonly _importClientUseCase: ImportClientUseCase;
    private readonly _findByPKClientUseCase: FindByPKClientUseCase;
    private readonly _listImportSubscriptionUseCase: ListImportSubscriptionUseCase;
    private readonly _importSubscriptionClientUseCase: ImportSubscriptionClientUseCase;
    private readonly _revertImportClientUseCase: RevertImportClientUseCase;
    private readonly _listOnlyUsersLinkedClientUseCase: ListOnlyUsersLinkedClientUseCase;
    private readonly _createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase;
    private readonly _restoreUseCase: RestoreClientUseCase;
    private readonly _deleteUseCase: DeleteClientUseCase;
    private readonly _searchAddressUseCase: SearchAddressUseCase;
    private readonly _searchCNPJUseCase: SearchCNPJUseCase;
    private readonly _exportClientsSubscriptionUseCase: ExportClientsSubscriptionUseCase;
    private readonly _exportDataClientUseCase: ExportDataClientUseCase;
    private readonly _downloadFileExampleUseCase: DownloadFileExampleClientUseCase;
    private readonly _getCurrentImportClientUseCase: GetCurrentImportClientUseCase;
    private readonly _listImportUseCase: ListImportUseCase;

    constructor(
        @inject(ListClientUseCase) listClientUseCase: ListClientUseCase,
        @inject(SetClientUseCase) setClientUseCase: SetClientUseCase,
        @inject(ImportClientUseCase) importClientUseCase: ImportClientUseCase,
        @inject(FindByPKClientUseCase) findByPKClientUseCase: FindByPKClientUseCase,
        @inject(ListImportSubscriptionUseCase) listImportSubscriptionUseCase: ListImportSubscriptionUseCase,
        @inject(ImportSubscriptionClientUseCase) importSubscriptionClientUseCase: ImportSubscriptionClientUseCase,
        @inject(RevertImportClientUseCase) revertImportClientUseCase: RevertImportClientUseCase,
        @inject(ListOnlyUsersLinkedClientUseCase) listOnlyUsersLinkedClientUseCase: ListOnlyUsersLinkedClientUseCase,
        @inject(CreateObjectUrlBlobUseCase) createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase,
        @inject(RestoreClientUseCase) restoreUseCase: RestoreClientUseCase,
        @inject(DeleteClientUseCase) deleteUseCase: DeleteClientUseCase,
        @inject(SearchAddressUseCase) searchAddressUseCase: SearchAddressUseCase,
        @inject(SearchCNPJUseCase) searchCNPJUseCase: SearchCNPJUseCase,
        @inject(ExportClientsSubscriptionUseCase) exportClientsSubscriptionUseCase: ExportClientsSubscriptionUseCase,
        @inject(ExportDataClientUseCase) exportDataClientUseCase: ExportDataClientUseCase,
        @inject(DownloadFileExampleClientUseCase) downloadFileExampleUseCase: DownloadFileExampleClientUseCase,
        @inject(GetCurrentImportClientUseCase) getCurrentImportClientUseCase: GetCurrentImportClientUseCase,
        @inject(ListImportUseCase) listImportUseCase: ListImportUseCase,
    ) {
        makeObservable(this);
        this._listClientUseCase = listClientUseCase;
        this._setClientUseCase = setClientUseCase;
        this._findByPKClientUseCase = findByPKClientUseCase;
        this._listOnlyUsersLinkedClientUseCase = listOnlyUsersLinkedClientUseCase;
        this._createObjectURLBlobUseCase = createObjectURLBlobUseCase;
        this._restoreUseCase = restoreUseCase;
        this._deleteUseCase = deleteUseCase;
        this._searchAddressUseCase = searchAddressUseCase;
        this._searchCNPJUseCase = searchCNPJUseCase;
        this._listImportSubscriptionUseCase = listImportSubscriptionUseCase;
        this._importClientUseCase = importClientUseCase;
        this._importSubscriptionClientUseCase = importSubscriptionClientUseCase;
        this._revertImportClientUseCase = revertImportClientUseCase;
        this._exportClientsSubscriptionUseCase = exportClientsSubscriptionUseCase;
        this._exportDataClientUseCase = exportDataClientUseCase;
        this._downloadFileExampleUseCase = downloadFileExampleUseCase;
        this._getCurrentImportClientUseCase = getCurrentImportClientUseCase;
        this._listImportUseCase = listImportUseCase;
    }

    newClient: ClientEntity = newClient();

    newAddress: AddressEntity = newAddress();

    newClientCreateAccount: ClientCreateAccountEntity = newClientCreateAccount();

    table = new ListPaginationEntity<ClientEntity>({
        pages: 0,
        page: 0,
        limit: Math.floor((window.innerHeight - 230) / 52),
        totalRows: 0,
        data: observable.array([])
    });

    tableImportsInitial = new ListPaginationEntity<ImportData>({
        pages: 0,
        page: 0,
        limit: Math.floor((window.innerHeight - 230) / 52),
        totalRows: 0,
        data: observable.array([])
    });

    newImportation = new ImportData({
        idCompany: getIdCompanyByLocalStorage(),
        importId: '',
        status: StatusImport.init,
        progress: 0,
        rows: 0,
    });

    newExportation = new ExportDataEntity({
        idCompany: getIdCompanyByLocalStorage(),
        id: '',
        status: StatusImport.init,
        progress: 0,
        rows: 0,
        table: 'clients',
        responseStatus: 200
    });

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    idRevert: string | null = null;

    @observable
    tableClients: ListPaginationEntity<ClientEntity> = this.table;

    @observable
    tableImports: ListPaginationEntity<ImportData> = this.tableImportsInitial;

    @observable
    pagesData: Record<number, ClientEntity[]> = {};

    @observable
    pagesDataImport: Record<number, ImportData[]> = {};

    @observable
    fileImportClients?: File | null;

    @observable
    client?: ClientEntity;

    @observable
    clientCreateAccount?: ClientCreateAccountEntity;

    @observable
    importation: ImportData = this.newImportation;

    @observable
    exportation: ExportDataEntity = this.newExportation;

    @observable
    address: AddressEntity | null = null;

    @observable
    searchParam = '';

    @observable
    clientNotFound: boolean = false;

    @observable
    listOnlyDeleted = false;

    @observable
    rowSelectionClients: ClientEntity[] = [];

    @observable
    period: Array<Date> | null = getPeriodAndLabelDisplay(true).range;

    @observable
    labelPeriod: PeriodEnum = getPeriodAndLabelDisplay().label;

    @observable
    subscriptionImport?: Subscription;

    @observable
    subscriptionListImports?: Subscription;

    @observable
    subscriptionExport?: Subscription;

    @observable
    idContactFocused: string | null = null;

    @action
    resetTable() {
        this.pagesData = {};
        this.pagesDataImport = {};
        this.setTableImports(this.tableImportsInitial);
        this.setTableClients(this.table);
    }

    @action
    setRowSelectionClients(clients: ClientEntity[]) {
        this.rowSelectionClients = clients;
    }

    @action
    setFileImportClients(file: File | null) {
        this.fileImportClients = file;
    }

    setIdRevert(id: string | null) {
        this.idRevert = id;
    }

    @action
    setAddress(address: AddressEntity | null) {
        this.address = address;
    }

    @action
    setImportation(importation: ImportData) {
        this.importation = importation;
    }

    @action
    setExportation(exportation: ExportDataEntity) {
        this.exportation = exportation;
    }

    @action
    setListOnlyDeleted(value: boolean) {
        this.listOnlyDeleted = value;
        this.setRowSelectionClients([]);
        this.resetTable();
        this.list(this.searchParam);
    }

    @action
    setIdContactFocused(id: string | null) {
        this.idContactFocused = id;
    }

    @action
    setSearchParam(value: string) {
        this.searchParam = value;
        setSearchParamLocalStorage(value);
    }


    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    clearUrlImage() {

        this.setClient(this.client!.copyWith({
            urlImageProfile: null,
            replaceUrlImageProfileIfNull: true,
        }))
    }

    @action
    setTableClients(value: ListPaginationEntity<ClientEntity>) {
        this.tableClients = value;
        const isNecessaryReload = preventMissingPage(this.tableClients);
        if (isNecessaryReload) {
            this.list('');
        } else {
            this.pagesData[this.tableClients.page] = this.tableClients.data;
        }
    }

    @action
    setTableImports(value: ListPaginationEntity<ImportData>) {
        this.tableImports = value;
        this.pagesDataImport[this.tableImports.page] = this.tableImports.data;
    }

    @action
    setClient(client: ClientEntity) {
        this.client = client;
    }

    @action
    setClientCreateAccount(clientCreateAccount: ClientCreateAccountEntity) {
        this.clientCreateAccount = clientCreateAccount;
    }

    @action
    removeLineSelectRow() {
        this.setRowSelectionClients([]);
    }

    @action
    setPeriod(period: Array<Date> | null, label: PeriodEnum) {
        this.period = period;
        this.labelPeriod = label;
        setPeriodAndLabelDisplay({
            range: period!,
            label: label
        });
    }

    @action
    initializeClients(heightGarbage = 0) {
        const routeName = RoutesEnum.clients;
        const {lastPage, initSearchParam} = initializeConfigurationLocalStorage(routeName);
        this.tableClients.page = lastPage;
        this.searchParam = initSearchParam;
        this.tableClients.limit = Math.floor((window.innerHeight - 265 - 25 + heightGarbage) / 52);
        this.list(initSearchParam);
    }

    @action
    initializeListImports = async (
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean,
    ) => {
        const routeName = RoutesEnum.importclients;
        const {filters} = initializeConfigurationLocalStorage(routeName, false);
        this.tableImports.limit = Math.floor((window.innerHeight - 250) / 52);
        if (useSocket) {
            await this.listImports();
            this.listImportsSubscriptionSocket(handleDisconnect, getLastUpdates);
        } else {
            this.listImportsSubscriptionGql();
        }
    }

    @action
    list = async (searchParam: string): Promise<void> => {
        try {
            this.startLoading();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listClientUseCase.call(this.tableClients.page, this.searchParam, this.tableClients.limit, this.listOnlyDeleted);
            this.setTableClients(response);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableClients(this.tableClients.copyWith({
                ...this.tableClients,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableClients(this.tableClients.copyWith({
                ...this.tableClients,
                page: page,
            }))
            return this.list(this.searchParam);
        }
    }

    @action
    handleListImports = async (
        useSocket: boolean
    ) => {
        if (useSocket) {
            await this.listImports();
        } else {
            this.listImportsSubscriptionGql();
        }
    }

    @action
    listImports = async (withLoader = true) => {
        try {
            if (this.period && this.period[0] && this.period[1]) {
                const startDate = getInitialHourCurrentDate(this.period[0]);
                const endDate = getEndHourCurrentDate(this.period[1]);
                if (withLoader) {
                    this.startLoading();
                }
                const imports = await this._listImportUseCase.call(
                    this.tableImports.page,
                    this.tableImports.limit,
                    {startDate, endDate}
                );
                this.updateDataFromImport(imports);
                this.stopLoading();
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }


    @action
    listImportsSubscriptionGql = (): void => {
        try {
            if (this.period && this.period[0] && this.period[1]) {
                const startDate = getInitialHourCurrentDate(this.period[0]);
                const endDate = getEndHourCurrentDate(this.period[1]);
                this.startLoading();
                this.subscriptionListImports?.unsubscribe();
                this.subscriptionListImports = this._listImportSubscriptionUseCase.call(this.tableImports.page, {
                    startDate,
                    endDate
                }, this.tableImports.limit).subscribe({

                    next: (imports) => {
                        this.updateDataFromImport(imports);
                        if (this.loading) {
                            this.stopLoading();
                        }
                    },
                    error: (err: any) => {
                        toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                        this.stopLoading();
                    }

                });
            }

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    listImportsSubscriptionSocket = (
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
    ): void => {
        const idCompany = getIdCompanyByLocalStorage();
        const startDate = dateToSqoIso(getSubtractDateFromNow(1));
        const endDate = dateToSqoIso(getSubtractDateFromNow(-1));
        const id = `${idCompany}_import_list`;
        this.subscriptionImport?.unsubscribe();
        this.subscriptionImport = this._importSubscriptionClientUseCase.call(
            idCompany,
            startDate,
            endDate,
            id,
            handleDisconnect,
            getLastUpdates,
            true
        ).subscribe({
            next: (importData) => {
                if (importData) {
                    this.listImports(false).then();
                }
            },
            error: (err) => {
                toastMessage.error(err);
            }
        });
    };

    @action
    updateDataFromImport(imports: ListPaginationEntity<ImportData>) {
        this.setTableImports(imports);
        const revertImport: ImportData | undefined = imports.data.find(entry => entry.revertStatus === StatusImport.inProgress);
        if (revertImport) {
            this.setIdRevert(revertImport.importId);
        } else if (!revertImport && !!this.idRevert) {
            this.setIdRevert(null);
        }
    }

    @action
    getDataFromPageImports = async (
        page: number,
        useSocket: boolean
    ): Promise<void> => {
        this.setTableImports(this.tableImports.copyWith({
            ...this.tableImports,
            page: page,
        }))
        return this.handleListImports(useSocket);
    }

    @action
    import = async (
        onSuccess: (key: string) => void,
        onError: (key: string) => void,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Promise<void> => {
        try {
            this.startLoading("client.loadingImport");
            const id = generateUUIDV4();
            if (useSocket) {
                this.observeImport(onSuccess, onError, id, handleDisconnect, getLastUpdates, useSocket);
            }
            const importData: ImportData = await this._importClientUseCase.call(this.fileImportClients!, id);
            this.setImportation(this.importation.copyWith({
                createdAt: this.importation.createdAt ?? importData.createdAt,
                ...importData,
            }));
            if (importData.importId !== id && useSocket) {
                this.dispose();
                if (this.importation.inProgress) {
                    this.observeImport(onSuccess, onError, this.importation.importId, handleDisconnect, getLastUpdates, useSocket);
                }
            } else {
                this.observeImport(onSuccess, onError, this.importation.importId, handleDisconnect, getLastUpdates, useSocket);
            }
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    export = async (
        handleNoContent: () => void,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Promise<void> => {
        try {
            this.startLoading("client.loadingExport");
            const id = generateUUIDV4();
            if (useSocket) {
                this.observeExport(id, handleDisconnect, getLastUpdates, useSocket);
            }
            const exportData = await this._exportDataClientUseCase.call(id);
            if (exportData.responseStatus === 204) {
                this.dispose();
                handleNoContent();
                return;
            }
            this.setExportation(exportData);
            if (exportData.id !== id && useSocket) {
                this.dispose();
                if (exportData.inProgress) {
                    this.observeExport(exportData.id, handleDisconnect, getLastUpdates, useSocket);
                }
            } else if(!useSocket) {
                this.observeExport(id, handleDisconnect, getLastUpdates, useSocket);
            }
            if (!exportData.inProgress) {
                this.dispose();
            }
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            if (err.message === 'need_admin_privileges') {
                navigate(RoutesEnum.clients);
            }
            this.stopLoading();
        }
    }

    @action
    revertImport = async (id: string): Promise<void> => {
        try {
            this.startLoading("client.loadingRevertImport");
            await this._revertImportClientUseCase.call(id);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    async initializeImport(
        onSuccess: (key: string) => void,
        onError: (key: string) => void,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ) {
        try {
            this.startLoading("client.default");
            const importData: ImportData | null = await this._getCurrentImportClientUseCase.call();
            if (importData) {
                this.setImportation(this.importation.copyWith({
                    createdAt: this.importation.createdAt ?? importData?.createdAt,
                    ...importData,
                }));
                if (importData?.inProgress) {
                    this.observeImport(onSuccess, onError, importData.importId, handleDisconnect, getLastUpdates, useSocket);
                }
            }
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    observeImport(
        onSuccess: (key: string) => void,
        onError: (key: string) => void,
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ) {
        const idCompany = getIdCompanyByLocalStorage();
        const startDate = dateToSqoIso(getSubtractDateFromNow(1));
        const endDate = dateToSqoIso(getSubtractDateFromNow(-1));
        this.subscriptionImport?.unsubscribe();
        this.subscriptionImport = this._importSubscriptionClientUseCase.call(
            idCompany,
            startDate,
            endDate,
            id,
            handleDisconnect,
            getLastUpdates,
            useSocket
        ).subscribe({
            next: (importData) => {
                if (importData) {
                    if (this.importation.status === StatusImport.inProgress) {
                        if (importData.progress === 100) {
                            this.resetImportation();
                            onSuccess("client.success.import");
                        }
                        if (importData.status === StatusImport.stoppedWithError) {
                            this.resetImportation();
                            onError("server_messages.stopped_with_error");
                        }
                    }
                    this.setImportation(this.importation.copyWith({
                        createdAt: this.importation.createdAt ?? importData.createdAt,
                        ...importData,
                    }));

                    if (this.importation.done) {
                        this.subscriptionImport?.unsubscribe();
                    }
                }
            },
            error: (err) => {
                toastMessage.error(err);
                this.stopLoading();
            }
        });
    }

    @action
    observeExport(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ) {
        this.subscriptionExport?.unsubscribe();
        this.subscriptionExport = this._exportClientsSubscriptionUseCase.call(id, handleDisconnect, getLastUpdates, useSocket).subscribe({
            next: (response) => {
                if (response) {
                    this.setExportation(this.exportation.copyWith({
                        createdAt: this.exportation.createdAt,
                        ...response
                    }));
                    if (this.exportation.done) {
                        this.subscriptionExport?.unsubscribe();
                    }
                }
            },
            error: (err) => {
                toastMessage.error(err);
                this.stopLoading();
            }
        });
    }


    @action
    resetImportation() {
        this.setFileImportClients(null);
        this.setImportation(this.newImportation);
    }


    @action
    set = async (onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading("client.loadingSave");

            if (this.client!.isUnsaved()) {
                this.setClient(this.client!.copyWith({
                    id: generateUUIDV4(),
                }))
            }

            await this._setClientUseCase.call(this.client!);
            this.stopLoading();
            onSuccess("client.success.save");
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    delete = async (onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading("client.loadingDelete");
            await this._deleteUseCase.call(this.rowSelectionClients);
            this.removeLineSelectRow();
            onSuccess("client.success.delete");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    @action
    restore = async (onSuccess: (key: string) => void, restoreClientWithUser: boolean): Promise<void> => {
        try {
            this.startLoading("client.loadingRestore");
            await this._restoreUseCase.call(this.rowSelectionClients, restoreClientWithUser);
            if (restoreClientWithUser) {
                toastMessage.info(`Os usuários estão sendo restaurados em segundo plano,
                 este procedimento pode ser um pouco demorado ...`);
            }
            this.removeLineSelectRow();
            onSuccess("client.success.restore");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    @action
    listOnlyUsersLinked = async (id: string): Promise<ClientEntity> => {
        return new Promise<ClientEntity>(async (resolve, reject) => {
            try {
                this.startLoading("client.loadingContacts");
                const response = await this._listOnlyUsersLinkedClientUseCase.call(id);
                this.stopLoading();
                return resolve(response);
            } catch (err: any) {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                this.stopLoading();
            }
        });
    }

    @action
    downloadFileExample = async (): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                this.startLoading("client.loadingDownloadFile");
                await this._downloadFileExampleUseCase.call();
                this.stopLoading();
                return resolve();
            } catch (err: any) {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                this.stopLoading();
            }
        });
    }


    @action
    setClientNotFound(value: boolean) {
        this.clientNotFound = value;
    }

    @action
    makeClient = (isNewChatContact?: boolean) => {
        this.setClient(this.newClient);
        this.onNewUser(isNewChatContact);
    }

    @action
    makeClientCreateAccount = (uniqueCode: string | null) => {
        this.setClientCreateAccount(this.newClientCreateAccount.copyWith({
            companySponsorReference: uniqueCode ? parseInt(uniqueCode) : undefined
        }));
    }

    @action
    getClient = async (id: string, idContactFocused?: string | null): Promise<void> => {
        try {
            this.startLoading("client.loadingInit")
            const response = await this._findByPKClientUseCase.call(id);
            this.setClient(response);
            if (idContactFocused) {
                this.setIdContactFocused(idContactFocused);
            }
            this.stopLoading()
        } catch (err: any) {
            this.setClientNotFound(true);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    dispose = (): void => {
        this.subscriptionImport?.unsubscribe();
        this.subscriptionListImports?.unsubscribe();
        this.subscriptionExport?.unsubscribe();
    }

    @action
    createObjectURLBlobUseCase(file: File): string {
        return this._createObjectURLBlobUseCase.call(file);
    }

    @action
    onNewUser = (isNewChatContact?: boolean): void => {
        const _user = isNewChatContact ? newUserClient().copyWith({
            isMyContact: true,
            replaceIsMyContactIfNull: true
        }) : newUserClient();

        if (this.client!.users == null) {

            this.setClient(this.client!.copyWith({
                users: [_user]
            }));

        } else {

            this.setClient(this.client!.copyWith({
                    users: [_user, ...this.client!.users!]
                }
            ));

        }
    }

    @action
    onChangeUser = (newUser: UserEntity): void => {

        const _index = this.client!.users!.findIndex((user) => user.id == newUser.id);

        if (_index > -1) {
            const _users = this.client!.users!;
            _users[_index] = newUser.copyWith({
                action: BackendAction.update, replaceActionIfNull: true
            });
            this.setClient(this.client!.copyWith({
                users: _users
            }));
        }

    }

    @action
    onDeleteUser = (id: string) => {
        const _index = this.client!.users!.findIndex((user) => user.id == id);

        if (_index > -1) {
            const _users = this.client!.users!;

            if (_users[_index].action == BackendAction.insert || !_users[_index].createdAt) {
                _users.splice(_index, 1);
                this.setClient(this.client!.copyWith({
                    users: _users
                }));

            } else {

                const _user = _users[_index].copyWith({
                    action: BackendAction.delete,
                    replaceActionIfNull: true,
                    deleted: true,
                });
                _users[_index] = _user;
                this.setClient(this.client!.copyWith({
                    users: _users
                }));

            }
        }
    }

    @action
    onNewAddress = (): void => {

        const address = newAddress();
        if (this.client!.addresses == null) {

            this.setClient(this.client!.copyWith({
                addresses: [address]
            }));

        } else {

            this.setClient(this.client!.copyWith({
                addresses: [address, ...this.client!.addresses!]
            }));

        }
    }

    @action
    onChangeAddress = (newAddress: AddressEntity): void => {

        const _index = this.client!.addresses!.findIndex((address) => address.id == newAddress.id);

        if (_index > -1) {
            const _addresses = this.client!.addresses!;
            _addresses[_index] = newAddress.copyWith({
                action: BackendAction.update, replaceActionIfNull: true
            });
            this.setClient(this.client!.copyWith({
                addresses: _addresses
            }));
        }

    }


    @action
    onDeleteAddress = (id: string) => {
        const _index = this.client!.addresses!.findIndex((address) => address.id == id);
        if (_index > -1) {
            const _addresses = this.client!.addresses!;

            if (_addresses[_index].action == BackendAction.insert || !_addresses[_index].createdAt) {
                _addresses.splice(_index, 1);
                this.setClient(this.client!.copyWith({
                    addresses: _addresses
                }));

            } else {

                const _address = _addresses[_index].copyWith({action: BackendAction.delete, replaceActionIfNull: true});
                _addresses[_index] = _address;
                this.setClient(this.client!.copyWith({
                    addresses: _addresses
                }));

            }
        }
        this.setAddress(null);

    }

    @action
    onSearchZipCode = async (address: AddressEntity): Promise<void> => {
        try {
            this.startLoading("client.loadingAddress");
            const _response = await this._searchAddressUseCase.call(address.zipCode);
            const _address = address.copyWith({
                ..._response,
                id: address.id,
            });
            this.stopLoading();
            this.onChangeAddress(_address);
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    onSearchCNPJ = async (cnpj: string): Promise<void> => {
        try {
            this.startLoading("client.loadingDocument");
            const _response = await this._searchCNPJUseCase.call(removeMask(cnpj));
            this.setClient(this.client!.copyWith({
                name: _response.name,
                socialName: _response.socialName,
                friendlyName: _response.friendlyName,
                email: _response.email,
                telephone: _response.phone,
                replaceTelephoneIfNull: !!_response.phone,
                replaceEmailIfNull: !!_response.email
            }))
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

}
