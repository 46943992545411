import {useEffect, useState} from "react";
import {isValidDescription} from "@dropDesk/utils/helpers/validators";
import {isValidDateFromNowTime} from "@dropDesk/utils/helpers/date_helper";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";

export const useValidatorInputTickets = (ticket?: TicketEntity) => {

    const [errorMessageDescription, setErrorMessageDescription] = useState('');
    const [errorMessageClient, setErrorMessageClient] = useState('');
    const [errorMessageContact, setErrorMessageContact] = useState('');
    const [errorMessageSector, setErrorMessageSector] = useState('');
    const [errorMessageDateDue, setErrorMessageDateDue] = useState('');
    const [errorMessageAttendant, setErrorMessageAttendant] = useState('');
    const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);
    const [autoFillData, setAutoFillData] = useState<boolean>(JSON.parse(localStorage.getItem(ConstantsKeys.autoFillDataLocalStorage) ?? 'false'));

    useEffect(() => {
        validate();
    }, [ticket]);

    const validate = () => {

        setErrorMessageClient('');
        setErrorMessageContact('');
        setErrorMessageDescription('');
        setErrorMessageSector('');
        setErrorMessageDateDue('');


        if (!ticket?.idClient) {
            setErrorMessageClient("Selecione uma empresa");
            setIsValidDataForm(false);
            return false;
        } else if (!ticket.userClient) {
            setErrorMessageContact("Selecione um contato");
            setIsValidDataForm(false);
            return false;
        } else if (!ticket.idSector) {
            setErrorMessageSector('Selecione um setor');
            setIsValidDataForm(false);
            return false;
        } else if (ticket.dateDue && !isValidDateFromNowTime(ticket.dateDue, true) && ticket.isUnsaved()) {
            setErrorMessageDateDue('Você deve informar uma data maior do que hoje.');
            setIsValidDataForm(false);
            return false;
        } else if (!isValidDescription(ticket.description) && !ticket.idDescriptionStatic) {
            setErrorMessageDescription('Você deve informar uma descrição do problema');
            setIsValidDataForm(false);
            return false;
        } else {
            setIsValidDataForm(true);
            return true;
        }
    }

    const setAutoFillDataLocalStorage = (value: boolean) => {
        setAutoFillData(value);
        localStorage.setItem(ConstantsKeys.autoFillDataLocalStorage, String(value));
    }

    return {
        errorMessageDescription, setErrorMessageDescription,
        errorMessageClient, errorMessageContact,
        errorMessageSector, errorMessageDateDue,
        errorMessageAttendant, setErrorMessageAttendant,
        validate, isValidDataForm,
        autoFillData, setAutoFillDataLocalStorage
    }
};
