import jwt from 'jwt-decode';
import {auth} from "@dropDesk/data/clients/firebase.client";
import {
    onAuthStateChanged
} from "firebase/auth";
import {AuthHashClient} from "@dropDesk/data/clients/auth_hash_client";

export const getToken = async (forceRefresh = false): Promise<string | null> => {

    const token = localStorage.getItem(process.env.STORAGE_TOKEN as any);
    if (token && token !== 'undefined' && token !== 'null') {
        const payload = getTokenDecoded();
        if (isExpired(payload!.exp as number) || forceRefresh) {
            const newToken = await renewToken();
            return Promise.resolve(newToken ?? '');
        } else {
            return Promise.resolve(token);
        }
    } else {
        const renewedToken = await renewToken();
        return Promise.resolve(renewedToken);
    }

}

export const getTokenDecoded = (): Record<string, any> | null => {
    const token = localStorage.getItem(process.env.STORAGE_TOKEN as any);
    return token ? jwt(token!) : null;
}

const isExpired = (exp: number) => {
    const now = new Date().getTime() / 1000;
    return exp - now < 15 * 60;
}

export const renewToken = async (): Promise<string | null> => {
    return new Promise<string | null>((resolve, reject) => {
        const observer = onAuthStateChanged(auth, async function (user) {
            if (user) {
                const newToken = await user.getIdToken(true);
                if (newToken) {
                    saveToken(newToken!);
                    const payload = getTokenDecoded();
                    AuthHashClient.replaceByToken(payload!);
                    return resolve(newToken!);
                }
                observer();
                return resolve(null);
            } else {
                observer();
                localStorage.removeItem(process.env.STORAGE_TOKEN as string);
                return resolve(null);
            }
        });

    });
}

export const saveToken = (token: string) => {
    localStorage.setItem(process.env.STORAGE_TOKEN as any, token!);
}
