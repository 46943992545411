import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";
import {inject, injectable} from "inversify";
import {TicketRemoteDataSource} from "@dropDesk/data/data_source/ticket/ticket_remote.datasource";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ServerError, SubscriptionException} from "@dropDesk/domain/entities/exceptions/exceptions";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {TicketConfig} from "@dropDesk/domain/entities/ticket/ticket_config";
import {CancelTokenSource} from "axios";

@injectable()
export class TicketRepositoryImpl implements TicketRepository {

    private _dataSource: TicketRemoteDataSource;

    constructor(@inject(TicketRemoteDataSource) dataSource: TicketRemoteDataSource) {
        this._dataSource = dataSource;
    }

    public linkAttendant(idTicket: string): Promise<TicketEntity> {
        try {
            return this._dataSource.linkAttendant(idTicket);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public close(idTicket: string, closeDescription?: string): Promise<TicketEntity> {
        try {
            return this._dataSource.close(idTicket, closeDescription);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public changeResolution(idTicket: string, closeDescription: string): Promise<TicketEntity> {
        try {
            return this._dataSource.changeResolution(idTicket, closeDescription);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    public cancel(idTicket: string, cancelDescription?: string): Promise<TicketEntity> {
        try {
            return this._dataSource.cancel(idTicket, cancelDescription);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public changeProblemDescription(idTicket: string, description: string): Promise<TicketEntity> {
        try {
            return this._dataSource.changeProblemDescription(idTicket, description);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public changeDateDue(idTicket: string, dateDue: string): Promise<TicketEntity> {
        try {
            return this._dataSource.changeDateDue(idTicket, dateDue);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public changePriority(idTicket: string, priority: string): Promise<TicketEntity> {
        try {
            return this._dataSource.changePriority(idTicket, priority);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public transfer(idTicket: string, newSectorId: string, newAttendantId?: string): Promise<TicketEntity> {
        try {
            return this._dataSource.transfer(idTicket, newSectorId, newAttendantId);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public changeTimeSpent(idTicket: string, timeSpent: number): Promise<TicketEntity> {
        try {
            return this._dataSource.changeTimeSpent(idTicket, timeSpent);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }


    public listTicketsSubscription(
        searchParam: string,
        page: number,
        limit: number,
        listOnlyDeleted: boolean,
        status: string | null,
        priority: string | null,
        listOnlySectorEmpty: boolean,
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ListPaginationEntity<TicketEntity> | null> {
        try {
            return this._dataSource.listTicketsSubscription(
                searchParam,
                page,
                limit,
                listOnlyDeleted,
                status,
                priority,
                listOnlySectorEmpty,
                id,
                handleDisconnect,
                getLastUpdates,
                useSocket
            );
        } catch (e: any) {
            throw new SubscriptionException(e);
        }
    }

    public set(ticket: TicketEntity): Promise<TicketEntity> {
        try {
            return this._dataSource.set(ticket);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public findByPK(id: string, listMessages: boolean, cancelTokenSource?: CancelTokenSource): Promise<TicketEntity> {
        try {
            return this._dataSource.findByPK(id, listMessages, cancelTokenSource);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public restore(tickets: TicketEntity[]): Promise<void> {
        try {
            return this._dataSource.restore(tickets);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public delete(tickets: TicketEntity[]): Promise<void> {
        try {
            return this._dataSource.delete(tickets);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public exportSubscription(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null> {
        try {
            return this._dataSource.exportSubscription(id, handleDisconnect, getLastUpdates, useSocket);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public export(period: { startDate: string, endDate: string }, id?: string): Promise<ExportDataEntity> {
        try {
            return this._dataSource.export(period, id);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public exportPdf(idTicket: string): Promise<void> {
        try {
            return this._dataSource.exportPdf(idTicket);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    getConfigs(): Promise<TicketConfig[]> {
        try {
            return this._dataSource.getConfigs();
        } catch (e: any) {
            throw new ServerError(e.message);
        }

    }

    public list(
        searchParam: string,
        page: number,
        limit: number,
        listOnlyDeleted: boolean,
        status: string | null,
        priority: string | null,
        listOnlySectorEmpty: boolean,
    ): Promise<ListPaginationEntity<TicketEntity>> {
        try {
            return this._dataSource.list(
                searchParam,
                page,
                limit,
                listOnlyDeleted,
                status,
                priority,
                listOnlySectorEmpty,
            );
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

}
