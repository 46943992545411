import "reflect-metadata";
import {injectable} from "inversify";
import {UserEntity, UserInfoEntity} from "@dropDesk/domain/entities/user/user.entity";
import {auth} from "@dropDesk/data/clients/firebase.client";
import firebaseAuth, {
    createUserWithEmailAndPassword, onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";
import {DropDeskException, SignInError} from "@dropDesk/domain/entities/exceptions/exceptions";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {getToken, getTokenDecoded, renewToken, saveToken} from "@dropDesk/data/data_source/token/token.datasource";
import {ThemeEntity} from "@dropDesk/domain/entities/theme/theme.entity";
import {SecureStorageKeys, StorageKeys} from "@dropDesk/domain/entities/constants/storage_keys";
import secureLocalStorage from "react-secure-storage";
import {AuthHashEntity} from "@dropDesk/domain/entities/auth/auth_hash.entity";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import jwt from "jwt-decode";
import OneSignal from "react-onesignal";
import {FeatureToggle} from "@dropDesk/data/clients/firebase_remote_configs_client";
import {apolloClientWebSocketClose} from "@dropDesk/data/clients/apollo.client";
import {AuthHashClient} from "@dropDesk/data/clients/auth_hash_client";

@injectable()
export abstract class AuthRemoteDataSource {
    public abstract createUser(email: string, password: string): Promise<firebaseAuth.UserCredential>;

    public abstract login(email: string, password: string): Promise<void>;

    public abstract logout(): Promise<void>;

    public abstract recoveryAccount(email: string): Promise<void>;

    public abstract getCurrentUser(): Promise<UserEntity | null>;

    public abstract getByToken(): Promise<UserEntity | null>;

    public abstract loginServer(): Promise<Record<string, boolean>>;

    public abstract logoutServer(): Promise<Record<string, boolean>>;

    public abstract logoutByAdmin(idUser: string): Promise<Record<string, boolean>>;

    public abstract getUserByFirebaseAuth(): Promise<firebaseAuth.User | null>;

    public abstract getUserByDecodedToken(): Record<string, unknown> | null;
}

@injectable()
export class AuthRemoteDataSourceImpl implements AuthRemoteDataSource {

    baseUsers = `users/`
    baseGetByTokenUrl: string = `${this.baseUsers}byToken`;
    baseLoginServerUrl: string = `${this.baseUsers}login`;
    baseLogoutServerUrl: string = `${this.baseUsers}logout`;
    logoutByAdminUrl: string = `${this.baseUsers}logoutByAdmin`;

    public createUser = async (email: string, password: string): Promise<firebaseAuth.UserCredential> => {
        return new Promise<firebaseAuth.UserCredential>(async (resolve, reject) => {
            try {

                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                return resolve(userCredential);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public recoveryAccount = async (email: string): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await sendPasswordResetEmail(auth, email);
                return resolve();
            } catch (error) {
                const errorCode: string = (error as { code: string }).code ?? 'reset_password_error';
                return reject(this.parsedSignInError(errorCode));
            }
        })
    }

    public logoutFirebase = async (withRemoveLocalStorage = true): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await signOut(auth);
                if (withRemoveLocalStorage) {
                    AuthRemoteDataSourceImpl.removeKeysLocalStorage();
                }
                return resolve();
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        });
    }

    public logout = async (): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                apolloClientWebSocketClose();
                await this.logoutOneSignal();
                await this.logoutServer();
                await this.logoutFirebase(false);
                AuthRemoteDataSourceImpl.removeKeysLocalStorage();
                return resolve();
            } catch (error: any) {
                AuthRemoteDataSourceImpl.removeKeysLocalStorage();
                return reject(parseServerError(error));
            }
        });
    }

    public getCurrentUser = async (): Promise<UserEntity | null> => {
        return new Promise<UserEntity | null>(async (resolve, reject) => {
            try {
                let token = localStorage.getItem(process.env.STORAGE_TOKEN as any);
                if (!token || token === 'undefined' || token === 'null') {
                    token = await getToken();
                }
                if (token) {
                    const user = await this.getByToken();
                    if (user) {
                        const idCompany = user.idCompany;
                        localStorage.setItem(process.env.STORAGE_COMPANY as string, idCompany);
                        localStorage.setItem(process.env.STORAGE_USER as string, JSON.stringify({
                            id: user.id,
                            name: user.name,
                            email: user.email,
                            idCompany: user.idCompany,
                            role: user.role,
                            sectors: user.sectors,
                            userMain: user.userMain,
                            permissionAdmin: user.permissionAdmin
                        }));
                        return resolve(user);
                    } else {
                        // o usuário tem conta no firebase porém não tem dado no banco
                        await this.logout();
                        return reject(this.parsedSignInError("user_not_found"));
                    }
                } else {
                    return resolve(null);
                }
            } catch (error: any) {
                return reject(parseServerError(error))
            }
        });
    }

    private async loginOneSignal(token: string): Promise<void> {
        const oneSignalToggle = FeatureToggle.getOneSignalToggleState();
        const mode = process.env.MODE;
        if (oneSignalToggle === mode) {
            const decoded = jwt(token) as Record<string, any>;
            OneSignal.User.addTag('companyId', decoded.idCompany);
            await OneSignal.login(decoded.idUser);
        }
    }

    private async logoutOneSignal(): Promise<void> {
        const oneSignalToggle = FeatureToggle.getOneSignalToggleState();
        const mode = process.env.MODE;
        if (oneSignalToggle === mode) {
            await OneSignal.logout();
        }
    }

    async login(email: string, password: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const response = await signInWithEmailAndPassword(
                    auth,
                    email.trim(),
                    password.trim()
                );
                const token = await response.user?.getIdToken(true);
                saveToken(token!);
                await this.loginServer();
                apolloClientWebSocketClose();
                await this.loginOneSignal(token);
                return resolve();
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        });
    }

    public async getByToken(): Promise<UserEntity | null> {
        return new Promise<UserEntity | null>(async (resolve, reject) => {
            try {
                const response = await api.get(this.baseGetByTokenUrl);
                const user = UserEntity.fromJson(response.data);
                AuthHashClient.persistAuthHash(UserInfoEntity.fromJson(user.toJson()));
                const theme = ThemeEntity.getThemeByString(user.theme);
                const userWithTheme = user.copyWith({
                    themeObject: theme
                });
                localStorage.setItem(process.env.THEME as string, user.theme);
                return resolve(userWithTheme);
            } catch (error: any) {
                return reject(error);
            }
        });
    }

    public async getUserByFirebaseAuth(): Promise<firebaseAuth.User | null> {
        return new Promise<firebaseAuth.User | null>(async (resolve) => {
            const observer = onAuthStateChanged(auth, async function (user) {
                observer();
                return resolve(user);
            });
        })
    }

    public getUserByDecodedToken(): Record<string, unknown> | null {
        return getTokenDecoded();
    }

    public async loginServer(): Promise<Record<string, boolean>> {
        return new Promise<Record<string, boolean>>(async (resolve, reject) => {
            try {
                const user = await this.getUserByFirebaseAuth();
                if (user && user.email) {
                    const hash = new AuthHashEntity({
                        firebaseId: user.uid,
                        email: user.email,
                    });
                    secureLocalStorage.setItem(SecureStorageKeys.hash, hash);
                    const response = await api.patch(this.baseLoginServerUrl);
                    await renewToken();
                    return resolve(response.data);
                }
                return resolve({success: false});
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        });
    }

    public async logoutServer(): Promise<Record<string, boolean>> {
        return new Promise<Record<string, boolean>>(async (resolve, reject) => {
            try {
                const response = await api.patch(this.baseLogoutServerUrl);
                return resolve(response.data);
            } catch (error: any) {
                return reject(error);
            }
        });
    }

    public async logoutByAdmin(idUser: string): Promise<Record<string, boolean>> {
        return new Promise<Record<string, boolean>>(async (resolve, reject) => {
            try {
                const response = await api.patch(this.logoutByAdminUrl + `/${idUser}`);
                return resolve(response.data);
            } catch (error: any) {
                return reject(error);
            }
        });
    }

    private parsedSignInError = (errorCode: string): DropDeskException => {
        return new SignInError(errorCode ?? 'unknown');
    }

    private static removeKeysLocalStorage(): void {
        const keys: string[] = [
            process.env.STORAGE_COMPANY as string,
            process.env.STORAGE_USER as string,
            process.env.SEARCH_PARAM as string,
            process.env.ACTIVE_ROUTE as string,
            process.env.STORAGE_TOKEN as string,
            process.env.THEME as string,
            process.env.PLAYBACKRATE as string,
            process.env.COLLAPSED_MENU as string,
            StorageKeys.lastTimeChatSubscription,
            ConstantsKeys.soundQueueWaitingChat,
            ConstantsKeys.soundNewMessageChat,
            ConstantsKeys.lastUsedReactions,
            ConstantsKeys.isReconnectSubscription,
            ConstantsKeys.localStorageFilters,
            ConstantsKeys.activePage,
            ConstantsKeys.dateParams,
            ConstantsKeys.permitReadChatRules,
            ConstantsKeys.compactModeMessages,
            ConstantsKeys.blockUpdateLocalSearchCache,
            ConstantsKeys.autoFillDataLocalStorage,
            ConstantsKeys.defaultDataFormTicket,
        ];

        const secureKeys = [
            SecureStorageKeys.hash
        ];

        for (const key of keys) {
            localStorage.removeItem(key);
        }

        for (const key of secureKeys) {
            secureLocalStorage.removeItem(key);
        }

    }


}
