import {TimezoneEnum} from "@dropDesk/domain/entities/company_configurations/configuration_enum";

export const HourInTime = 1000 * 60 * 60;
export const DayInTime = HourInTime * 24;
export const QuarterHourInTime = 1000 * 60 * 15;


export const getCurrentDateUTC = (): string => {
    return (new Date()).toISOString();
}

export const getCurrentDateISO = (): string => {
    return (new Date().toISOString());
}

export const getInitialHourCurrentDate = (date: Date): string => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + "00" +
        ":" + "00" + ":" + "01";
}

export const getEndHourCurrentDate = (date: Date): string => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + "23" +
        ":" + "59" + ":" + "59";
}

export const getInitialHourUTC = (date: Date): string => {
    return date.getUTCDate() + "/" + (date.getUTCMonth() + 1) + "/" + date.getUTCFullYear() + " " + "00" +
        ":" + "00" + ":" + "01";
}

export const getEndHourUTC = (date: Date): string => {
    return date.getUTCDate() + "/" + (date.getUTCMonth() + 1) + "/" + date.getUTCFullYear() + " " + "23" +
        ":" + "59" + ":" + "59";
}

export const convertDateToFormatBR = (date: Date): string => {
    return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear() + ' ' + ('0' + (date.getHours())).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2);
}

export const convertSQLISOToDate = (dateString?: string | null): Date | null => {
    if (!dateString) {
        return null;
    }
    return new Date(dateString ? dateString.replace(' ', 'T') : new Date);
}

export const displayDateToLocale = (dateString: string): string => {
    const date = new Date(dateString);
    if (!date) {
        return '';
    }
    const localDate = date.toLocaleDateString(navigator.language, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    });

    return localDate.replace(',', '');
}

export const displayDateToLocaleUTC = (dateString: string): string => {
    const date = new Date(dateString);
    if (!date) {
        return '';
    }

    const localDate = date.toLocaleDateString(navigator.language, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: 'UTC',
    });

    return localDate.replace(',', '');
}

export const firstDayOfTheMonth = (): Date => {
    const now = new Date();
    const first = new Date(now.getFullYear(), now.getMonth(), 1);
    return first;
}

export const minutesToHours = (minutes: number): string => {
    const hours = (minutes / 60);
    const hoursRound = Math.floor(hours);
    const minutesConvert = (hours - hoursRound) * 60;
    const minutesConvertRound = Math.round(minutesConvert);
    return hoursRound + "h " + minutesConvertRound + "m";
}

const isIntString = (value: string): boolean => {
    try {
        if (value.indexOf('-') > 0) {
            return false;
        }

        return parseInt(value) > 0;
    } catch (e) {
        return false;
    }
}

export const getDateWhitOutTimeZone = (date: Date): Date => {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}

export const isValidDateFromNowTime = (dateString: string, withTime: boolean = true): boolean => {
    if (!dateString || dateString.length < 10) return false;
    try {
        const date = isIntString(dateString) ? new Date(parseInt(dateString)) : new Date(dateString);
        const dateWhitOutTimeZone = getDateWhitOutTimeZone(date);
        const isValid = dateWhitOutTimeZone.toString() !== "Invalid Date" && dateWhitOutTimeZone.toString() !== "NaN";
        if (isValid) {
            const now = new Date();
            let toCompare = getDateWhitOutTimeZone(now);
            if (!withTime) {
                toCompare = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            }
            return (toCompare.getTime() <= dateWhitOutTimeZone.getTime())
        }
        return false;
    } catch (e) {
        return false;
    }
}

export const isValidDate = (dateString: string): boolean => {
    if (!dateString || dateString.length < 10) return false;
    try {
        const date = isIntString(dateString) ? new Date(parseInt(dateString)) : new Date(dateString);
        return date.toString() !== "Invalid Date" && date.toString() !== "NaN";
    } catch (e) {
        return false;
    }
}

export const differentInMinutesBetweenDates = (dateString: string): number => {
    const date = new Date(dateString);
    const currentDate = new Date();
    const diff = Math.abs(date.getTime() - currentDate.getTime());
    const diffInMinutes = Math.floor((diff / 1000) / 60);
    return diffInMinutes;
}

export const getDaysToExpireLicense = (dateString: string): number => {
    const daysToExpireAccount = parseInt(process.env.DAYS_TO_EXPIRE_ACTIVE_ACCOUNT as string);
    const date = newDateWithOutTimeZone(new Date(dateString).toISOString());
    const currentDate = newDateWithOutTimeZone(new Date().toISOString());
    const diffInDays = differenceBetweenDatesInDays(currentDate, date);
    return daysToExpireAccount - diffInDays;
}

export const differenceBetweenDatesInDays = (startDate: Date, endDate: Date): number => {
    const diff = startDate.getTime() - endDate.getTime();
    const diffInDays = Math.floor((((diff / 1000) / 60) / 60) / 24);
    return diffInDays;
}

export const getDifferenceInSeconds = (startDate: Date, endDate: Date): number => {
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();
    const differenceInMilliseconds = startTime - endTime;
    const differenceInSeconds = differenceInMilliseconds / 1000;
    return differenceInSeconds;
}

export const addSecondsToDate = (date: Date, seconds: number): Date => {
    const timestamp = date.getTime();
    const newTimestamp = timestamp + (seconds * 1000);
    return new Date(newTimestamp);
}

export const isDateOnInterval = (date: Date, durationInMiliseconds: number): boolean => {
    const now = new Date().getTime();
    const maxDate = date.getTime() + durationInMiliseconds;
    return now <= maxDate;
}

export const getCurrentDate = (): Date => {
    return new Date();
}

export const setHourZero = (date: Date): Date => {
    date.setHours(0, 0, 0, 0);
    return date;
}
export const setMaxHour = (date: Date): Date => {
    date.setHours(23, 59, 59, 0);
    return date;
}

export const getSubtractDateFromNow = (minDateInDays: number): Date => {
    const now = new Date();
    now.setDate(now.getDate() - minDateInDays);
    return now;
}

export const copyHourMinutesSeconds = (origin: Date, destiny: Date): Date => {
    const hours = origin.getHours();
    const minutes = origin.getMinutes();
    const seconds = origin.getSeconds();

    destiny.setHours(hours);
    destiny.setMinutes(minutes);
    destiny.setSeconds(seconds);

    return destiny;
}

export const addDays = (date: Date, days: number): Date => {
    date.setDate(date.getDate() + days);
    return date;
}

export const isDateInCurrentMonth = (date: Date): boolean => {
    const currentDate = new Date(); // Obtém a data atual
    return date.getMonth() === currentDate.getMonth() && date.getFullYear() === currentDate.getFullYear();
}

export const getMothName = (date: Date): string => {
    const months = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    return months[date.getMonth()];

}

export const getDayName = (date: Date) => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return days[date.getDay()];
}

export const getMonthAndYear = (date: Date): string => {
    const year = date.getFullYear();

    return `${getMothName(date)} de ${year}`;
}

export const dateToSqoIso = (date: Date): string => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}

export const getFormattedDate = () => {
    const date = new Date();

    // Captura componentes da data
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    // Captura milissegundos e adiciona microsegundos extras
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
    const microseconds = String(Math.floor(Math.random() * 1000)).padStart(3, '0'); // microsegundos aleatórios

    // Formata a data conforme necessário
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${microseconds}+00:00`;
}


export const insertZeroInSeconds = (seconds: number): string => {
    if (seconds <= 9) {
        return "0" + seconds.toString();
    }
    return seconds.toString();
}

export const formatSecondsToHour = (seconds: number): string => {
    const minutes = insertZeroInSeconds(Math.trunc((seconds % 3600) / 60));
    const _seconds = insertZeroInSeconds((seconds % 3600) % 60);
    return minutes + ":" + _seconds;
}

export const timeToFloat = (time: string): number => {
    const arr = time.split(':');
    const dec = parseInt(String((parseInt(arr[1]) / 6) * 10), 10);

    return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
}

export const getCurrentWeek = (): Array<Date> => {
    return [getFirstDayOfWeek(), new Date()];
}

export const getYesterdayDate = (): Date => {
    let today = new Date();
    let yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
}

export const getFirstDayOfWeek = (): Date => {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
}

export const getLastWeeksDate = (): Date => {
    const now = new Date();
    return new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
}

export const getLastWeekDates = (): Array<Date> => {
    const today = new Date();
    let daysSinceLastMonday = today.getDay() - 1;
    if (daysSinceLastMonday < 0) {
        daysSinceLastMonday = 6;
    }
    const lastMonday = new Date(today.getTime() - daysSinceLastMonday * 24 * 60 * 60 * 1000);
    const lastWeekStart = new Date(lastMonday.getTime() - 7 * 24 * 60 * 60 * 1000);
    const lastWeekEnd = new Date(lastMonday.getTime() - 24 * 60 * 60 * 1000);
    return [lastWeekStart, lastWeekEnd];
}

export const getPreviousMonthDates = (): Array<Date> => {
    const today = new Date();
    const firstDayPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    return [firstDayPrevMonth, lastDayPrevMonth];
}

export const getCurrentMonthDates = (): Array<Date> => {
    const today = new Date();
    const firstDayCurrMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return [firstDayCurrMonth, new Date()];
}

export const sameDates = (date1: Date, date2: Date): boolean => {
    return date1.getUTCFullYear() === date2.getUTCFullYear() &&
        date1.getUTCMonth() === date2.getUTCMonth() &&
        date1.getUTCDate() === date2.getUTCDate();
}

export const isYesterdayDate = (date: Date): boolean => {
    const previous = new Date();
    previous.setDate(previous.getDate() - 1);

    return sameDates(previous, date);
}

export const formatDateChat = (dateString: string): string => {
    const date = new Date(dateString);
    if (sameDates(date, new Date())) {
        return date.toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit'
        });
    } else if (isYesterdayDate(date)) {
        return "Ontem";
    } else {
        return formatDateToDDMMYYYY(date);
    }
}

export const getTimestampWithoutTimezone = (date: Date): number => {
    return Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    );
}


export const resetHours = (date?: Date): Date => {
    date = date ?? new Date();
    const dateStr = date.toISOString();
    return new Date(dateStr.split('T')[0]);
}

export function getDateDifferenceInDays(nextDayDue: string, reference?: string): number {
    try {
        const now = new Date();
        const nowRef = new Date(`${now.getUTCFullYear()}-${(now.getUTCMonth() + 1).toString().padStart(2, '0')}-${now.getUTCDate().toString().padStart(2, '0')}T00:00:00.000Z`);
        const ref = reference ? new Date(reference) : nowRef;
        const nextDate = new Date(nextDayDue);

        if (sameDates(nextDate, ref)) {
            return 0;
        }

        return Math.ceil(diffInDaysBetweenDates(nextDate, ref));
    } catch (e) {
        throw e;
    }
}

export const getDateWithDropdeskTz = (date: Date) => {
    const dropdeskTz = parseInt(process.env.DROPDESK_TZ as string) * 60000;
    return new Date(date.getTime() - dropdeskTz);
}

export const getDateIsoStringWithOutTz = (date: Date): string => {
    return newDateWithOutTimeZone(date.toISOString()).toString();
}

export const newDateWithOutTimeZone = (dateIso: string): Date => {
    return new Date(`${dateIso.split('T')[0]}T00:00:00.000Z`);
}

export const createUtcDateFromIsoString = (isoDateString: string): Date => {
    const dateParts = isoDateString.split(/[-T:+Z]/);
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is zero-based in JavaScript
    const day = parseInt(dateParts[2], 10);
    const hours = parseInt(dateParts[3], 10);
    const minutes = parseInt(dateParts[4], 10);
    const seconds = parseInt(dateParts[5], 10);

    const utcMilliseconds = Date.UTC(year, month, day, hours, minutes, seconds);
    const utcDate = new Date(utcMilliseconds);

    return utcDate;
}

export const isBeforeNow = (date: Date, withoutTime = true): boolean => {
    let now = new Date();
    if (withoutTime) {
        now = resetHours(now);
        date = resetHours(date);
    }
    return date.getTime() < now.getTime();
}

export const isBeforeOrEqualNow = (date: Date, withoutTime = true): boolean => {
    let now = new Date();
    if (withoutTime) {
        now = resetHours(now);
        date = resetHours(date);
    }
    return date.getTime() <= now.getTime();
}

export const diffInDaysBetweenDates = (futureDate: Date, nowDate: Date): number => {
    if (futureDate.getTime() > nowDate.getTime()) {
        return ((futureDate.getTime() - nowDate.getTime()) / DayInTime);
    } else {
        return -1 * ((nowDate.getTime() - futureDate.getTime()) / DayInTime);
    }
}

export const addMonths = (date: Date, quantityOfMonths: number): Date => {
    date.setMonth(date.getMonth() + quantityOfMonths);
    return new Date(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T08:00`);
}

export const removeMonth = (date: Date, quantityOfMonths: number): Date => {
    date.setMonth(date.getMonth() - quantityOfMonths);
    return new Date(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T08:00`);
}

export const getDaysInCurrentMonth = (date = getDateWithDropdeskTz(new Date())) => {
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;

    return new Date(currentYear, currentMonth, 0).getDate();
}

export const formatDateToDDMM = (isoDateString: string): string => {
    const dateParts = isoDateString.split(/[-T:+Z]/);
    const month = dateParts[1];
    const day = dateParts[2];
    return `${day}/${month}`;
}

export const formatDateToDDMMYYYY = (date: Date): string => {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Lembrando que os meses começam em 0 (janeiro) e vão até 11 (dezembro)

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${date.getUTCFullYear().toString()}`;
}

export const formatDateToDDMMYYYYHHMM = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const hour = date.getHours();
    const minutes = date.getMinutes();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const formattedHour = hour < 10 ? `0${hour}` : hour;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedDay}/${formattedMonth}/${date.getFullYear().toString()} às ${formattedHour}:${formattedMinutes}h`;
}

export const formatIsoDateToDDMMYYYY = (isoDateString: string): string => {
    const dateParts = isoDateString.split(/[-T:+Z]/);
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    return `${day}/${month}/${year}`;
}


export const parseISOToDate = (dateString: string): Date => {
    return new Date(dateString);
}

export const subtractSecondsFromDate = (date: Date, seconds: number): Date => {
    const currentTimestamp = date.getTime();
    const newTimestamp = currentTimestamp - seconds * 1000;
    return new Date(newTimestamp);
}

export const getUtcFromTZ = (tz: TimezoneEnum): number => {
    const resolver = {
        [TimezoneEnum.americaRioBranco]: -5,
        [TimezoneEnum.americaManaus]: -4,
        [TimezoneEnum.americaSaoPaulo]: -3,
        [TimezoneEnum.brazilDeNoronha]: -2,
    }
    return resolver[tz];
}


export const getDateNowWithoutTz = (): Date => {
    const now = new Date();
    return new Date(now.toISOString().slice(0, -1));
}


export const isNowInRange = (gte: number, lte: number, utc = 0): boolean => {
    const now = getDateNowWithoutTz();
    const time = now.getTime() + (utc * HourInTime);
    return time >= gte && time <= lte;
}

export const isAfterNow = (date: Date): boolean => {
    const now = new Date().getTime();
    return date.getTime() > now;
}

export const isAfterOrEqualNow = (date: Date, withoutTime = true): boolean => {
    let now = new Date();
    if (withoutTime) {
        now = resetHours(now);
        date = resetHours(date);
    }
    return date.getTime() >= now.getTime();
}

export const getNowSumIndex = (index: number): string => {
    const now = new Date();
    now.setUTCMilliseconds(now.getUTCMilliseconds() + index);
    return now.toISOString();
}
