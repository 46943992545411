import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";

export class SocketChatIntegrationResponse {
    type!: TypeChatIntegrationSocket;
    chatIntegration?: ChatIntegrationConnectionEntity;

    constructor({
                    chatIntegration,
                    type
                }: {
        chatIntegration?: ChatIntegrationConnectionEntity;
        type: TypeChatIntegrationSocket;

    }) {
        Object.assign(this, {
            chatIntegration,
            type
        });
    }

    public static fromJson(json: Record<string, any>): SocketChatIntegrationResponse {
        return new SocketChatIntegrationResponse({
            type: json.type,
            chatIntegration: json.data?.chatIntegration ? ChatIntegrationConnectionEntity.fromJson(json.data.chatIntegration) : undefined,
        });
    }

    public get hasUnknownError() {
        return this.type === TypeChatIntegrationSocket.unknown;
    }
}

export enum TypeChatIntegrationSocket {
    unknown = 'unknown',
    chatIntegration = 'chatIntegration'
}