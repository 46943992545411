import {RealTimeConnectionEnum} from "@dropDesk/domain/entities/company_configurations/configuration_enum";

export class PanelAdmEntity {
    realTimeConnection!:RealTimeConnectionEnum;
    uniqueCode?:number;

    constructor({
                    realTimeConnection,
    uniqueCode,
                }: {
        realTimeConnection:RealTimeConnectionEnum;
        uniqueCode?:number;
    }) {
        Object.assign(this, {
            realTimeConnection,
            uniqueCode,
        });
    }

    copyWith({
                 realTimeConnection,
                 uniqueCode,
             }: {
        realTimeConnection?:RealTimeConnectionEnum;
        uniqueCode?:number;
    }): PanelAdmEntity {
        return new PanelAdmEntity({
            realTimeConnection: realTimeConnection ?? this.realTimeConnection,
            uniqueCode: uniqueCode ?? this.uniqueCode,
        })
    }
}
