import DetailSubscription from "@dropDesk/presentation/components/detail_subscription";
import React from "react";
import {
    ContentDetail,
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/styled";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import {
    ContentButton,
    ContentInfos
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/payment_info/styled";
import PaymentInfos from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_info_detail/infos";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";

const PaymentInfo = observer((
    {
        paymentInfo,
        loading,
        disabled,
        handleEditPaymentInfo,
    }: {
        paymentInfo?: CompanyPaymentInfo
        loading: boolean
        disabled: boolean
        handleEditPaymentInfo: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <DetailSubscription
            title={'Dados de cobrança'}
            content={
                <ContentDetail>
                    {paymentInfo && <ContentInfos>
                        <PaymentInfos paymentInfo={paymentInfo}/>
                    </ContentInfos>}
                    <ContentButton>
                        <ButtonMain
                            style={{paddingLeft: 5, paddingRight: 5}}
                            widthPercentage={100}
                            letterFontSize={15}
                            disabled={disabled}
                            loading={loading}
                            height={35}
                            color={colors.accent}
                            type={"submit"}
                            letter={'Trocar dados'}
                            onClick={() => handleEditPaymentInfo()}
                        />
                    </ContentButton>
                </ContentDetail>
            }
        />
    );
});
export default PaymentInfo;
