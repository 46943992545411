export enum TimezoneEnum {
    americaSaoPaulo = "America/Sao_Paulo",
    brazilDeNoronha = "Brazil/DeNoronha",
    americaManaus = "America/Manaus",
    americaRioBranco = "America/Rio_Branco"
}

export enum ChatMessageEnum {
    welcomeMessage = 'Olá, {{nome_cliente}}, Seja Bem-Vindo(a) a {{nome_empresa}}.\\n✅protocolo de atendimento: *#{{protocolo}}*\\nAbertura: *{{data_abertura}}*',
    chooseDepartmentMessage = 'Eu sou a 🙋🏻‍♀️ *JÚLIA*, assistente virtual da {{nome_empresa}}.\\n\\nPara iniciar seu atendimento *digite o número* da opção desejada:"',
    queueTicketMessage = 'Eu sou a 🙋🏻‍♀️ *JÚLIA*, assistente virtual da {{nome_empresa}}.\\n\\nJá estou lhe encaminhando para um dos nossos atendentes.\\n\\nPara agilizar seu atendimento por favor, digite sua dúvida.',
    closedTicketMessage = 'Agradecemos o seu contato!\\n\\n*Pedimos que por gentileza não responda essa mensagem.*\\n\\nPois esse atendimento foi concluido e qualquer nova mensagem abrirá um novo atendimento.\\n\\nPorém qualquer dúvida estamos sempre á disposição. Conte com a gente.\\n\\nAtendimento Finalizado!\\nProtocolo de atendimento *#{{protocolo}}*',
    outTimeOperatingMessage = 'Infelizmente não estamos online no momento.\\nEntraremos em contato o mais rápido possível.',
    transferSectorMessage = 'Você foi transferido para o setor {{nome_setor}}, aguarde um momento que logo um atendente irá lhe atender.',
    transferUserMessage = 'Atendimento atribuido ao atendente {{nome_atendente}}',
    ratingMessage = 'Por favor, avalie o meu atendimento com uma nota entre 1 e 5 onde 1 é completamente insatisfeito e 5 completamente satisfeito.',
    successRatingTicketMessage = "Agradecemos demais sua avaliação, ela servirá como feedback para melhoria do nosso atendimento.",
    notificationBeforeCloseIdleTicketMessage = 'Olá, percebemos que seu atendimento #{{protocolo}} está inativo e será encerrado em alguns minutos. Precisa de mais ajuda?',
    closedIdleTicketMessage = 'Seu atendimento foi automaticamente encerrado devido à inatividade!\n\nSaiba que estamos disponíveis para ajudá-lo a qualquer momento.\n\nAtendimento Finalizado!\nProtocolo de atendimento *#{{protocolo}}*',
}

export enum RealTimeConnectionEnum {
    gql = 'gql',
    socket = 'socket',
}
