import {
    Container, Image, Content, ContentImage, Title, ContentTitle, ContentDescription, Description, ContentButton,
    ButtonClosed, ContainerButtonClosed, TextClosed
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import blockAccess from "@dropDesk/storage/images/access_blocked.png"
import React, {useEffect} from "react";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {useNavigate} from "react-router-dom";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {CompanyController} from "@dropDesk/presentation/pages/company/controller/company.controller";
import {LoginController} from "@dropDesk/presentation/pages/login/controller/login.controller";
import {getTokenDecoded} from "@dropDesk/data/data_source/token/token.datasource";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ModalsConnectionExceeded from "@dropDesk/presentation/pages/connections_exceeded/ui/modals";
import {useBetween} from "use-between";
import {
    useModalConnectionExceeded
} from "@dropDesk/presentation/pages/connections_exceeded/ui/modals/handle_change_visible";
import {CompanyConnectionsEntity} from "@dropDesk/domain/entities/company/company_connections.entity";

const ConnectionsExceeded = observer(() => {

    const appController = useInjection(AppController);
    const companyController = useInjection(CompanyController);
    const loginController = useInjection(LoginController);
    const colors = appController.theme.colorScheme;
    const navigate = useNavigate();
    const user = getTokenDecoded();

    const {
        setVisibleModalConnectionExceeded, setVisibleModalLogoutByAdmin
    } = useBetween(useModalConnectionExceeded);

    const handleRetry = async () => {
        try {
            await loginController.loginServer();
            const route = await appController.getCurrentUser();
            navigate(route);
        } catch (e) {
            console.error(e);
        }
    }

    const onSuccessLogout = () => {
        navigate(RoutesEnum.login, {replace: true});
    }

    const handleClose = async () => {
        try {
            await loginController.logout(onSuccessLogout);
        } catch (e) {
            console.error(e);
        }
    }

    const onSuccessForceLogout = async () => {
        await handleRetry();
    }

    const forceLogout = async () => {
        const idUser = companyController.userActiveConnection?.userId;
        setVisibleModalLogoutByAdmin(false);
        setVisibleModalConnectionExceeded(false);
        if (idUser) {
            await loginController.logoutByAdmin(onSuccessForceLogout, idUser);
        }
    }

    const handleSetUserActiveConnection = (connection: CompanyConnectionsEntity) => {
        companyController.setUserActiveConnection(connection);
        setVisibleModalLogoutByAdmin(true);
    }

    return (
        <Container background={colors.onBackground}>
            <ContainerButtonClosed background={colors.onBackground}>
                <ButtonClosed
                    color={colors.text}
                    onClick={() => handleClose()}
                    disabled={false}
                    border={colors.border}
                    accent={colors.accent}
                >
                    <TextClosed>
                        Fechar
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.close}
                            size={13}
                            style={{cursor: 'pointer', marginLeft: 10, marginTop: 2}}
                        />
                    </TextClosed>
                </ButtonClosed>
            </ContainerButtonClosed>
            <ModalsConnectionExceeded
                logoutByAdmin={() => forceLogout()}
                loading={companyController.loading}
                handleSetUserActiveConnection={(connection) => handleSetUserActiveConnection(connection)}
            />
            <Content>
                <ContentTitle>
                    <Title color={colors.text}>Limite de conexões simultâneas excedido</Title>
                </ContentTitle>

                <ContentImage>
                    <Image
                        src={blockAccess}
                        alt={'connection exceeded'}
                    />
                </ContentImage>

                <ContentDescription>
                    <Description color={colors.text} size={19}>
                        Ops! O número máximo de conexões simultâneas foi atingido. Por favor, encerre algumas
                        conexões ou aguarde antes de fazer uma nova tentativa.
                    </Description>
                </ContentDescription>
                <ContentButton>
                    {user?.admin &&
                        <ButtonNew
                            marginLeft={0}
                            isButtonMain={true}
                            label={'Desconectar usuários'}
                            onClick={() => setVisibleModalConnectionExceeded(true)}
                            nameLeftIcon={ICONS_DROPDESK.userCancel}
                            sizeLeftIcon={16}
                            disabled={false}
                        />
                    }
                    <ButtonNew
                        marginLeft={16}
                        isButtonMain={false}
                        label={'Tentar novamente'}
                        onClick={() => handleRetry()}
                        nameLeftIcon={ICONS_DROPDESK.refresh}
                        sizeLeftIcon={16}
                        disabled={false}
                    />
                </ContentButton>
            </Content>
            {(appController.loading || loginController.loading) && <DropDeskLoading
                height={250}
                description={appController.loadingMessage ?? loginController.loadingMessage}
            />}
        </Container>
    );
});
export default ConnectionsExceeded;
