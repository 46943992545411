import {Container, ContainerSearch} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import React, {useEffect, useState} from "react";
import {ContentList, SubContentList} from "./styled";
import SearchBarTickets from "@dropDesk/presentation/pages/ticket/ui/search/search_bar";
import {TicketController} from "@dropDesk/presentation/pages/ticket/controller/ticket.controller";
import TableSearchTickets from "@dropDesk/presentation/pages/ticket/ui/search/table";
import ModalsTickets from "@dropDesk/presentation/pages/ticket/ui/search/modals";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {useTranslation} from "react-i18next";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import FooterGarbage from "@dropDesk/presentation/components/footer/footer_garbage";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useNavigate} from "react-router-dom";
import {ChatController} from "@dropDesk/presentation/pages/chat/controller/chat.controller";
import toast_message from "@dropDesk/utils/toast_message/toast_message";
import ModalReconnectSocket from "@dropDesk/presentation/components/modals/modal_reconnect_socket";
import ContentInfoDisconnectedSubscription
    from "@dropDesk/presentation/components/content_info_disconnected_subscription";

const Tickets = (observer(() => {

        const [hasErrorConnectSocket, setHasErrorConnectSocket] = useState<boolean>(false);
        const [hasErrorConnectSocketTemporary, setHasErrorConnectSocketTemporary] = useState<boolean>(false);
        const [loadingReconnect, setLoadingReconnect] = useState<boolean>(false);
        const [visibleModalConnect, setVisibleModalConnect] = useState<boolean>(false);
        const {t} = useTranslation();
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const controller = useInjection(TicketController);
        const userController = useInjection(UserController);
        const chatController = useInjection(ChatController);
        const permissionDeleteData = !!appController.user?.permissionDeleteData && !appController.isUserClient;
        const heightGarbage = permissionDeleteData ? 0 : 50;
        const navigate = useNavigate();

        useEffect(() => {
            initialize();
            return () => {
                controller.dispose();
            }
        }, []);

        const initialize = async () => {
            await controller.initialize(heightGarbage - appController.heightShowingHeaderInfo, getParamsSocket());
        }

        const onSuccess = (key: string) => {
            toastMessage.success(t(key));
        }

        const handleDisconnect = (temporary: boolean) => {
            if (temporary) {
                setHasErrorConnectSocketTemporary(true);
            } else {
                setHasErrorConnectSocket(true);
                setVisibleModalConnect(true);
                setHasErrorConnectSocketTemporary(false);
            }
        }

        const handleReconnectSocket = async (): Promise<void> => {
            try {
                setLoadingReconnect(true);
                await initialize();
                setHasErrorConnectSocket(false);
                setVisibleModalConnect(false);
                setLoadingReconnect(false);
                setHasErrorConnectSocketTemporary(false);
            } catch (e) {
                setHasErrorConnectSocket(true);
                setLoadingReconnect(false);
                toast_message.error('Não foi possível estabelecer a conexão. Por favor, tente novamente.' +
                    ' Se o problema persistir, entre em contato com a equipe da DropDesk');
            }
        }

        const handleGetLastUpdates = async (): Promise<void> => {
            setHasErrorConnectSocketTemporary(false);
            await controller.list();
        }

        const getParamsSocket
            = (): {
            handleDisconnect: (temporary: boolean) => void,
            getLastUpdates: () => void,
            useSocket: boolean,
        } => {

            return {
                handleDisconnect,
                getLastUpdates: handleGetLastUpdates,
                useSocket: appController.user?.company.configurations.useSocket ?? false
            }

        }

        return (
            <ContainerSearch background={colors.onBackground}>

                <ContentList>
                    {(hasErrorConnectSocket || hasErrorConnectSocketTemporary) &&
                        <ContentInfoDisconnectedSubscription
                            loading={loadingReconnect}
                            onClickReconnect={() => handleReconnectSocket()}
                            visibleTemporaryInformation={hasErrorConnectSocketTemporary}
                        />
                    }
                    <SearchBarTickets
                        onSearch={(searchParam) => controller.handleListTickets(searchParam ?? controller.searchParam, getParamsSocket())}
                        filterStatus={controller.filterStatus}
                        filterPriority={controller.filterPriority}
                        setFilterStatus={(filter, withList) => controller.setFilterStatus(filter, withList, getParamsSocket())}
                        setFilterPriority={(filter, withList) => controller.setFilterPriority(filter, withList, getParamsSocket())}
                        visibleInputSearch={controller.rowSelectionTicket.length === 0}
                        loading={controller.loading}
                        visibleButtonsOfActions={!controller.listOnlyDeleted && controller.rowSelectionTicket.length === 0}
                        visibleButtonDeleted={!controller.listOnlyDeleted && controller.rowSelectionTicket.length > 0}
                        visibleButtonRestore={controller.listOnlyDeleted && controller.rowSelectionTicket.length > 0}
                        setListOnlySectorEmpty={(value, withList) => controller.setListOnlySectorEmpty(value, withList, getParamsSocket())}
                        listOnlySectorEmpty={controller.listOnlySectorEmpty}
                    />

                    <SubContentList>
                        <TableSearchTickets
                            getDataFromPage={(page) => controller.getDataFromPage(page, getParamsSocket())}
                            tableTicket={controller.tableTicket}
                            rowSelectionTicket={controller.rowSelectionTicket}
                            setRowSelectionTicket={(ticket: TicketEntity[]) => controller.setRowSelectionTicket(ticket)}
                            setUser={(user) => userController.setUser(user)}
                            searchParam={controller.searchParam}
                            loading={controller.loading}
                            permitSelectionRow={controller.listOnlyDeleted}
                            heightGarbage={heightGarbage}
                            permissionDeleteData={permissionDeleteData}
                            onSelectedRow={(ticket) => {
                                chatController.setChat(ticket);
                                navigate(`${RoutesEnum.ticketsView}/${ticket.id}`);
                            }}
                        />

                        {permissionDeleteData &&
                            <FooterGarbage
                                setListOnlyDeleted={(value) => controller.setListOnlyDeleted(value, getParamsSocket())}
                                visibleListOnlyDeleted={controller.listOnlyDeleted}
                                loading={controller.loading}
                                text={'Atendimentos ativos'}
                                textDelete={'Atendimentos excluídos'}
                            />
                        }

                        <ModalsTickets
                            restoreTicket={() => controller.restore(onSuccess)}
                            deleteTicket={() => controller.delete(onSuccess)}
                            removeLineSelectRow={() => controller.removeLineSelectRow()}
                            userUrlImageProfile={userController.user?.urlImageProfile ?? ''}
                            userName={userController.user?.name ?? ''}
                            ticketNumber={controller.rowSelectionTicket.length > 0 ? controller.rowSelectionTicket[0].number : 1}
                            loading={controller.loading}
                        />
                    </SubContentList>
                    <ModalReconnectSocket
                        loading={controller.loading}
                        onRequestClose={() => setVisibleModalConnect(false)}
                        onRequestSave={() => handleReconnectSocket()}
                        visible={visibleModalConnect}
                    />
                </ContentList>

                {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                    height={250}
                    description={controller.loadingMessage}
                />}
            </ContainerSearch>
        )

    }
))
export default Tickets;
