import styled from "styled-components";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type ContainerType = {
    background: string;
}

type ButtonType = {
    background: string;
    color: string;
    border: string;
    accent: string;
    width: number;
    isDarkTheme: boolean;
}

type ButtonDropDown = {
    color: string;
    accent: string;
}

type TextType = {
    color: string;
}

type IconType = {
    color: string;
    fontsize: number;
}

type IconMenuType = {
    isDarkTheme: boolean;
    background: string;
    border: string;
    accent: string;
}

type ButtonDisabledType = {
    disable: boolean;
}


export const Container = styled.div<ContainerType>`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  width: 100%;
  background: ${(props) => props.background};
  margin-bottom: 12px;
`;

export const Text = styled.strong<TextType>`
  font-size: 14px;
  color: ${(props) => props.color};
  letter-spacing: 1.5px;
`;
export const ContentMoreInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
`;

export const ButtonAction = styled.button<ButtonType>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 35px;
  min-height: 35px;
  width: ${props => `${props.width}px`};
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 8px 0 0;
  color: ${props => props.color};
  background-color: ${props => props.background};
  border: 1px solid ${props => props.border};

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:disabled {
    pointer-events: none;
  }
`;
export const ContainerActionButtons = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  padding: 5px 0;
  flex-wrap: wrap;
`;
export const ContentButton = styled.div<ButtonDisabledType>`
  display: flex;

  cursor: ${props => props.disable ? 'no-drop' : 'auto'};

  &:not(:last-of-type) {
    padding: 0 10px 0 0;
  }

`;
export const ContainerCompactMode = styled.div`
  display: flex;
  padding: 5px 0;
`;

export const ContainerPopover = styled.div`
  display: flex;
  flex-direction: column;
  width: 275px;
  max-width: 275px;
  padding: 8px 16px;
`;

export const ContentIconMenu = styled.div<IconMenuType>`
  cursor: pointer;
  width: 20px;
  height: 35px;
  display: flex;
  background: ${(props) => `${props.background}`};
  border: 1px solid ${(props) => props.border};
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }
`;

export const TextDropDown = styled.span`
  font-size: 14px;
  height: 20px;
  width: 70px;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

export const ButtonDropDown = styled.button<ButtonDropDown>`
  padding: 0 5px 0 0;
  display: flex;
  flex-direction: row;
  border: 0;
  height: 37px;
  min-height: 37px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  background: transparent;
  border: none;
  font-size: 14px;
  width: 100%;
  color: ${props => props.color};
  margin: 4px 0;

  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${props => hexToRGBA(props.accent, .58)};
    outline: none;
    transition: box-shadow .3s ease-out .1s, border .3s ease-out, border-radius .3s ease-out, background-color .2s ease-out, color .2s ease-out, transform .2s ease-out;
  }

  &:disabled {
    pointer-events: none;
  }
`;



