import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";

export class SocketPaymentResponse {
    type!: TypePaymentSocket;
    payment?: PaymentEntity;

    constructor({
                    payment,
                    type
                }: {
        payment?: PaymentEntity;
        type: TypePaymentSocket;

    }) {
        Object.assign(this, {
            payment,
            type
        });
    }

    public static fromJson(json: Record<string, any>): SocketPaymentResponse {
        return new SocketPaymentResponse({
            type: json.type,
            payment: json.data?.payment ? PaymentEntity.fromJson(json.data.payment) : undefined,
        });
    }

    public get hasUnknownError() {
        return this.type === TypePaymentSocket.unknown;
    }
}

export enum TypePaymentSocket {
    unknown = 'unknown',
    payment = 'payment'
}