export const downloadFile = async (urlDownload: string, fileName: string) => {
    const anchor = document.createElement('a');
    const response = await fetch(urlDownload);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    anchor.href = url;
    anchor.download = fileName;
    anchor.target = "_blank";
    anchor.click();
    anchor.remove();
    window.URL.revokeObjectURL(url);
}
export const openFileInNewTab = (file: Blob, loadingMessage: string) => {
    setTimeout(() => {
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open(fileURL, '_blank');
        if (pdfWindow) {
            pdfWindow.document.write(loadingMessage);
            pdfWindow.location.href = fileURL;
        }
    }, 0);
}

export const isFileImage = (file: File | null): boolean => {
    return !!file && file['type'].split('/')[0] === 'image';
}

export const transformBytesToMegaBytes = (bytes: number): number => {
    if (!bytes) return 0;
    return bytes / (1024 ** 2);
}

export const dataBase64UrlToBlob = (base64Url: string, mimeType: string) => {
    const byteString = decodeBase64(base64Url);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], {type: mimeType});
}

export const base64ToBuffer = (base64Url: string): Buffer => {
    const uint8Array = urlBase64ToUint8Array(base64Url);
    return Buffer.from(uint8Array.buffer);
}

const decodeBase64 = (base64Url: string): string => {
    const base64String = base64Url.replace(/^data:\w+\/\w+;base64,/, '');
    return window.atob(base64String);
}

const urlBase64ToUint8Array = (base64Url: string): Uint8Array => {
    const byteString = decodeBase64(base64Url);
    const buffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(buffer);
    for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
    }
    return uintArray;
}

const getMimeTypeFromBase64 = (base64Url: string): string => {
    return base64Url.split(",")[0].split(":")[1].split(";")[0]
}

export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise<string>(async (resolve, reject) => {
        try {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = window.btoa(reader.result as string);
                return resolve(base64String);
            };

            reader.readAsBinaryString(file);

        } catch (error: any) {
            return reject(error);
        }
    })
}

export const fileToBuffer = (file: File): Promise<Buffer> => {
    return new Promise<Buffer>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const buffer = Buffer.from(reader.result as unknown as ArrayBuffer);
            return resolve(buffer);
        };

        reader.readAsArrayBuffer(file);
    });
};

export const bufferToBlobURL = (buffer?: Buffer | null | string, mimeType?: string): string | null => {
    try {
        if (!buffer) return null;
        if (typeof buffer === 'string') {
            return buffer as string;
        }
        const blob = new Blob([buffer], {type: mimeType});
        return URL.createObjectURL(blob);
    } catch (error) {
        return null;
    }
};
