import {SimpleUserInfo, UserEntity} from "../../user/user.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {UploadOptionsEntity} from "@dropDesk/domain/entities/ticket/message/upload_message.entity";
import {
    AudioMessageEntity,
    ContactMessageEntity,
    DocumentMessageEntity,
    ImageMessageEntity,
    LocationMessageEntity,
    MessageChatType,
    MessageStatus,
    ReactionMessageEntity,
    ReplyEntity,
    StickerMessageEntity, SystemMessageEntity,
    TextMessageEntity,
    VideoMessageEntity
} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {MessageBoxStatus} from "@dropDesk/presentation/components/messages/message_box";
import {MessageMediaBase} from "@dropDesk/domain/entities/ticket/message/external/message_media_base";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";

export enum TicketMessagePosition {
    first = 'first',
    last = 'last',
    unique = 'unique',
}

export enum StatusMessageError {
    viewOnceError = 'view_once_error',
    largeMediaError = 'large_media_error',
    receiveMediaError = 'receive_media_error',
    sendMessageError = 'send_message_error',
}

export class TicketMessageEntity {
    id!: string;
    idCompany!: string;
    idTicket!: string;
    ticket?: TicketEntity;
    idExternal?: string;
    type!: MessageChatType;
    senderId!: string;
    sender?: UserEntity;
    deleted!: boolean;
    isPrivate!: boolean;
    users?: Record<string, unknown>;
    received?: Record<string, unknown>;
    readers?: Record<string, unknown>;
    createdAt?: string;
    updatedAt?: string;
    createdAtLocal!: string;
    status!: MessageStatus;
    uploadOptions?: UploadOptionsEntity;
    fromMe!: boolean;
    participantId?: string | null;
    pushName?: string | null;
    textMessage?: TextMessageEntity | null;
    systemMessage?: SystemMessageEntity | null;
    imageMessage?: ImageMessageEntity | null;
    videoMessage?: VideoMessageEntity | null;
    documentMessage?: DocumentMessageEntity | null;
    locationMessage?: LocationMessageEntity | null;
    stickerMessage?: StickerMessageEntity | null;
    audioMessage?: AudioMessageEntity | null;
    reactionMessage?: ReactionMessageEntity | null;
    contactMessage?: ContactMessageEntity[] | null;
    blobURL?: string | null;
    reply?: ReplyEntity | null;
    outOfBusinessHour?: boolean;
    edited?: boolean;
    fromSystem?: boolean;
    position?: TicketMessagePosition;
    automatic?: boolean;
    statusError?: StatusMessageError | null;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    public get socialMessage(): string {

        if (this.deleted) {
            return "Mensagem apagada";
        }

        if (this.isLargeMediaError) {
            return this.getMessageLargeMediaError;
        }

        if (this.isViewOnceError) {
            return this.getMessageViewOnceError;
        }

        if (this.isReceiveError) {
            return this.getMessageReceiveError;
        }

        switch (this.type) {
            case MessageChatType.imageMessage:
                return (!!this.imageMessage && !!this.imageMessage.caption) ? `📷 Foto (${this.imageMessage.caption})` : "📷 Foto";

            case MessageChatType.stickerMessage:
                return (!!this.stickerMessage && !!this.stickerMessage.caption) ? `📷 Foto (${this.stickerMessage.caption})` : "📷 Foto";

            case MessageChatType.audioMessage:
                return (!!this.audioMessage && !!this.audioMessage.caption) ? `🎤 Mensagem de voz (${this.audioMessage.caption})` : "🎤 Mensagem de voz";

            case MessageChatType.videoMessage:
                return (!!this.videoMessage && !!this.videoMessage.caption) ? `📹 Vídeo (${this.videoMessage.caption})` : "📹 Vídeo";

            case MessageChatType.locationMessage:
                return "📍Localização";

            case MessageChatType.contactMessage:
                return "👤 Contato";

            case MessageChatType.documentMessage:
                return (!!this.documentMessage && !!this.documentMessage.caption) ? `📁 Arquivo (${this.documentMessage.caption})` : "📁 Arquivo";

            case MessageChatType.reactionMessage:
                return `${this.sender?.name} Reagiu com ${this.reactionMessage?.text}`;

            case MessageChatType.systemMessage:
                return this.systemMessage?.conversation ?? '';
            default:
                return this.textMessage?.conversation ?? '';
        }

    }

    public get isFirstPosition(): boolean {
        return this.position === TicketMessagePosition.first;
    }

    public get isLastPosition(): boolean {
        return this.position === TicketMessagePosition.last;
    }

    public get isUniquePosition(): boolean {
        return this.position === TicketMessagePosition.unique;
    }

    public get getBlobURL(): string | null {
        if (!!this.blobURL) {
            return this.blobURL;
        }
        const types: Record<string, string | undefined> = {
            [MessageChatType.audioMessage]: this.audioMessage?.buffer,
            [MessageChatType.documentMessage]: this.documentMessage?.buffer,
            [MessageChatType.imageMessage]: this.imageMessage?.buffer,
            [MessageChatType.videoMessage]: this.videoMessage?.buffer,
            [MessageChatType.stickerMessage]: this.stickerMessage?.buffer,
        };
        return types[this.type] ?? null;
    }

    private getPathFromBase(mimeType: string, type: string): string {
        const base = `companies/${this.idCompany}/tickets/${this.idTicket}`;
        const ext = mimeType.split('/')[1];
        const thumb = `${base}/thumbs/${this.id}.${ext}`;
        const file = `${base}/${type}/${this.id}.${ext}`;
        return file;
    }

    public get getPathToUpload(): string {
        const types: Record<string, string> = {
            [MessageChatType.audioMessage]: this.getPathFromBase(this.audioMessage?.mimetype ?? '', 'audios'),
            [MessageChatType.documentMessage]: this.getPathFromBase(this.documentMessage?.mimetype ?? '', 'documents'),
            [MessageChatType.imageMessage]: this.getPathFromBase(this.imageMessage?.mimetype ?? '', 'images'),
            [MessageChatType.videoMessage]: this.getPathFromBase(this.videoMessage?.mimetype ?? '', 'videos'),
            [MessageChatType.stickerMessage]: this.getPathFromBase(this.stickerMessage?.mimetype ?? '', 'stickers'),
        };

        return types[this.type] ?? '';
    }

    public get toReplyEntity(): ReplyEntity | null {
        return new ReplyEntity({
            messageId: this.idExternal ?? this.id,
            quotedConversationType: this.type,
            quotedMessage: this.getQuotedMessage,
            participantId: undefined,
            participant: this.sender ? new SimpleUserInfo({
                id: this.senderId,
                idCompany: this.idCompany,
                name: this.sender.name,
                urlImageProfile: this.sender.urlImageProfile as string | undefined,
                role: this.sender.role,
            }) : undefined,
        });
    }


    public get getQuotedMessage(): MessageMediaBase | null {
        const types: Record<string, any> = {
            [MessageChatType.audioMessage]: this.audioMessage?.toReply ?? null,
            [MessageChatType.documentMessage]: this.documentMessage?.toReply ?? null,
            [MessageChatType.imageMessage]: this.imageMessage?.toReply ?? null,
            [MessageChatType.videoMessage]: this.videoMessage?.toReply ?? null,
            [MessageChatType.stickerMessage]: this.stickerMessage?.toReply ?? null,
            [MessageChatType.locationMessage]: this.locationMessage?.toReply ?? null,
            [MessageChatType.textMessage]: this.textMessage?.toReply ?? null,
        };

        return types[this.type] ?? null;
    }

    public get getTextEditable(): string {
        const types: Record<string, any> = {
            [MessageChatType.audioMessage]: this.audioMessage?.caption,
            [MessageChatType.documentMessage]: this.documentMessage?.caption,
            [MessageChatType.imageMessage]: this.imageMessage?.caption,
            [MessageChatType.videoMessage]: this.videoMessage?.caption,
            [MessageChatType.stickerMessage]: this.stickerMessage?.caption,
        };

        return types[this.type] ?? this.getText;
    }

    public get hasSendError() {
        return this.status === MessageStatus.ERROR || this.statusError === StatusMessageError.sendMessageError;
    }

    public getStatus(
        userClient: UserEntity,
        currentUserRole: UserRole,
        attendant?: UserEntity
    ): MessageBoxStatus | undefined {

        if (this.automatic) {
            return;
        }
        const realId = currentUserRole === UserRole.attendant ? userClient?.id : attendant?.id;

        if (this.readers && realId && this.readers[realId] === true && !this.hasSendError) {
            return MessageBoxStatus.read;
        }

        if (this.received && realId && this.received[realId] === true && !this.hasSendError) {
            return MessageBoxStatus.received;
        }

        if (this.createdAt && ![MessageStatus.ERROR, MessageStatus.PENDING].includes(this.status) && !this.hasSendError) {
            return MessageBoxStatus.sent;
        }

        return MessageBoxStatus.waiting;
    }

    public get getMessageLargeMediaError(): string {
        return `#[ MENSAGEM DE AVISO ]#: Esta é uma mensagem de aviso do Sistema DropDesk, #${this.pushName}# enviou um arquivo com tamanho acima do limite permitido, verifique o arquivo através do seu WhatsApp, limite permitido de 16MB.`;
    }

    public get getMessageViewOnceError(): string {
        return `#[ MENSAGEM DE AVISO ]#: Você recebeu uma mensagem de visualização única, por segurança não aceitamos esse tipo de mensagem, peça ao remetente que envie a mensagem com visualização pública.`;
    }

    public get getMessageReceiveError(): string {
        return `#[ MENSAGEM DE AVISO ]#: Informamos que ocorreu um erro durante o recebimento do arquivo enviado por ${this.pushName}. Solicite que o mesmo envie novamente ou você pode verificar direto no WhatsApp. Agradecemos pela compreensão e pedimos desculpas pelo transtorno.`;
    }

    public get realCreatedAt(): string {
        return this.createdAtLocal ?? this.createdAt;
    }

    public get isLargeMediaError(): boolean {
        return this.statusError === StatusMessageError.largeMediaError;
    }

    public get isViewOnceError(): boolean {
        return this.statusError === StatusMessageError.viewOnceError;
    }

    public get isReceiveError(): boolean {
        return this.statusError === StatusMessageError.receiveMediaError;
    }

    public get getMessageError(): string {
        return this.isLargeMediaError ? this.getMessageLargeMediaError :
            this.isViewOnceError ? this.getMessageViewOnceError : this.getMessageReceiveError;
    }

    public get hasError(): boolean {
        return this.isLargeMediaError || this.isViewOnceError || this.isReceiveError;
    }

    public prepareToSend(): TicketMessageEntity {
        const newMessage: TicketMessageEntity = this.copyWith(this);
        delete newMessage.ticket;
        delete newMessage.sender;
        delete newMessage.uploadOptions;
        delete newMessage.updatedAt;
        return newMessage;
    }

    public removeUploadOptions(): TicketMessageEntity {
        const newMessage: TicketMessageEntity = this.copyWith(this);
        delete newMessage.uploadOptions;
        return newMessage;
    }

    get isFileMessage(): boolean {
        return this.type === MessageChatType.stickerMessage ||
            this.type === MessageChatType.imageMessage ||
            this.type === MessageChatType.videoMessage ||
            this.type === MessageChatType.audioMessage ||
            this.type === MessageChatType.documentMessage;
    }

    public get isValidPreviewMessage(): boolean {
        return this.type === MessageChatType.stickerMessage ||
            this.type === MessageChatType.imageMessage;
    }

    public setUrlDownloadInFileMessage(urlDownload: string): void {
        if (this.audioMessage) this.audioMessage.buffer = urlDownload;
        if (this.documentMessage) this.documentMessage.buffer = urlDownload;
        if (this.imageMessage) this.imageMessage.buffer = urlDownload;
        if (this.videoMessage) this.videoMessage.buffer = urlDownload;
        if (this.stickerMessage) this.stickerMessage.buffer = urlDownload;
        if (this.locationMessage) this.locationMessage.buffer = urlDownload;
    }

    public setEditableText(editText: string): TicketMessageEntity {
        if (this.audioMessage) this.audioMessage.caption = editText;
        if (this.documentMessage) this.documentMessage.caption = editText;
        if (this.imageMessage) this.imageMessage.caption = editText;
        if (this.videoMessage) this.videoMessage.caption = editText;
        if (this.stickerMessage) this.stickerMessage.caption = editText;
        if (this.textMessage) this.textMessage.conversation = editText;
        this.edited = true;

        return this;
    }

    public get isSystemMessage(): boolean {
        return this.type === MessageChatType.systemMessage;
    }

    public static fromJson(json: Record<string, any>): TicketMessageEntity {
        return new TicketMessageEntity({
            id: json['id'],
            idCompany: json['idCompany'],
            idTicket: json['idTicket'],
            ticket: json['ticket'] ? TicketEntity.fromJson(json['ticket']) : null,
            idExternal: json['idExternal'],
            type: json['type'],
            senderId: json['senderId'],
            sender: json['sender'] ? UserEntity.fromJson(json['sender']) : null,
            deleted: json['deleted'],
            isPrivate: json['isPrivate'],
            users: json['users'],
            received: json['received'],
            readers: json['readers'],
            createdAt: json['createdAt'],
            updatedAt: json['updatedAt'],
            createdAtLocal: json['createdAtLocal'],
            position: json['position'],
            automatic: json['automatic'],
            statusError: json['statusError'],
            fromMe: json['fromMe'] ?? false,
            participantId: json['participantId'] ?? null,
            pushName: json['pushName'] ?? null,
            status: json['status'],
            blobURL: json['blobURL'],
            textMessage: json['textMessage'] ? new TextMessageEntity({
                ...json['textMessage']
            }) : null,
            systemMessage: json['systemMessage'] ? new SystemMessageEntity({
                ...json['systemMessage']
            }) : null,
            imageMessage: json['imageMessage'] ? new ImageMessageEntity({
                ...json['imageMessage']
            }) : null,
            videoMessage: json['videoMessage'] ? new VideoMessageEntity({
                ...json['videoMessage']
            }) : null,
            documentMessage: json['documentMessage'] ? new DocumentMessageEntity({
                ...json['documentMessage']
            }) : null,
            locationMessage: json['locationMessage'] ? new LocationMessageEntity({
                ...json['locationMessage']
            }) : null,
            stickerMessage: json['stickerMessage'] ? new StickerMessageEntity({
                ...json['stickerMessage']
            }) : null,
            audioMessage: json['audioMessage'] ? new AudioMessageEntity({
                ...json['audioMessage']
            }) : null,
            reactionMessage: json['reactionMessage'] ? new ReactionMessageEntity({
                ...json['reactionMessage']
            }) : null,
            contactMessage: json['contactMessage'] ? (json['contactMessage'] as any[]).map((entry) => new ContactMessageEntity({
                ...entry
            })) : null,
            reply: json['reply'] ? new ReplyEntity({
                ...json['reply']
            }) : undefined,
            outOfBusinessHour: json['outOfBusinessHour'] ?? false,
            edited: json['edited'] ?? false,
            fromSystem: json['fromSystem'] ?? false,
            uploadOptions: json['uploadOptions'],
        });
    }

    public getMessagePreventDefaultCache(newMessage: TicketMessageEntity): TicketMessageEntity {
        const ticketPrevent = this.copyWith({
            blobURL: this.blobURL,
            uploadOptions: this.uploadOptions,
            ...newMessage
        });
        return ticketPrevent;
    }

    constructor({
                    id,
                    idCompany,
                    idTicket,
                    ticket,
                    idExternal,
                    type,
                    senderId,
                    sender,
                    deleted,
                    isPrivate,
                    users,
                    received,
                    readers,
                    createdAt,
                    updatedAt,
                    createdAtLocal,
                    status,
                    uploadOptions,
                    fromMe,
                    participantId,
                    pushName,
                    textMessage,
                    systemMessage,
                    imageMessage,
                    videoMessage,
                    documentMessage,
                    locationMessage,
                    stickerMessage,
                    audioMessage,
                    reactionMessage,
                    contactMessage,
                    blobURL,
                    reply,
                    outOfBusinessHour,
                    edited,
                    fromSystem,
                    position,
                    automatic,
                    statusError
                }: {
        id: string;
        idCompany: string;
        idTicket: string;
        ticket?: TicketEntity | null;
        idExternal?: string;
        type: MessageChatType;
        senderId: string;
        sender?: UserEntity | null;
        deleted: boolean;
        isPrivate: boolean;
        users?: Record<string, unknown>;
        received?: Record<string, unknown>;
        readers?: Record<string, unknown>;
        createdAt?: string;
        updatedAt?: string;
        createdAtLocal: string;
        status: MessageStatus;
        uploadOptions?: UploadOptionsEntity;
        fromMe: boolean;
        participantId?: string | null;
        pushName?: string | null;
        textMessage?: TextMessageEntity | null;
        systemMessage?: SystemMessageEntity | null;
        imageMessage?: ImageMessageEntity | null;
        videoMessage?: VideoMessageEntity | null;
        documentMessage?: DocumentMessageEntity | null;
        locationMessage?: LocationMessageEntity | null;
        stickerMessage?: StickerMessageEntity | null;
        audioMessage?: AudioMessageEntity | null;
        reactionMessage?: ReactionMessageEntity | null;
        contactMessage?: ContactMessageEntity[] | null;
        blobURL?: string | null;
        reply?: ReplyEntity | null;
        outOfBusinessHour?: boolean;
        edited?: boolean;
        fromSystem?: boolean;
        automatic?: boolean;
        statusError?: StatusMessageError | null;
        position?: TicketMessagePosition;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            idTicket,
            ticket,
            idExternal,
            type,
            senderId,
            sender,
            deleted,
            isPrivate,
            users,
            received,
            readers,
            createdAt,
            updatedAt,
            createdAtLocal,
            status,
            uploadOptions,
            fromMe,
            participantId,
            pushName,
            textMessage,
            systemMessage,
            imageMessage,
            videoMessage,
            documentMessage,
            locationMessage,
            stickerMessage,
            audioMessage,
            reactionMessage,
            contactMessage,
            blobURL,
            reply,
            outOfBusinessHour,
            edited,
            fromSystem,
            automatic,
            statusError,
            position
        });
    }

    copyWith({
                 id,
                 idCompany,
                 idTicket,
                 ticket,
                 idExternal,
                 type,
                 senderId,
                 sender,
                 deleted,
                 isPrivate,
                 users,
                 received,
                 readers,
                 createdAt,
                 updatedAt,
                 createdAtLocal,
                 status,
                 uploadOptions,
                 fromMe,
                 participantId,
                 pushName,
                 textMessage,
                 systemMessage,
                 imageMessage,
                 videoMessage,
                 documentMessage,
                 locationMessage,
                 stickerMessage,
                 audioMessage,
                 reactionMessage,
                 contactMessage,
                 blobURL,
                 reply,
                 outOfBusinessHour,
                 edited,
                 fromSystem,
                 automatic,
                 statusError,
                 replaceReplyIfNull,
                 position
             }: {
        id?: string;
        idCompany?: string;
        idTicket?: string;
        ticket?: TicketEntity;
        idExternal?: string;
        description?: string;
        type?: MessageChatType;
        senderId?: string;
        sender?: UserEntity | null;
        deleted?: boolean;
        isPrivate?: boolean;
        users?: Record<string, unknown>;
        received?: Record<string, unknown>;
        readers?: Record<string, unknown>;
        createdAt?: string;
        updatedAt?: string;
        createdAtLocal?: string;
        status?: MessageStatus;
        uploadOptions?: UploadOptionsEntity;
        fromMe?: boolean;
        participantId?: string | null;
        pushName?: string | null;
        textMessage?: TextMessageEntity | null;
        systemMessage?: SystemMessageEntity | null;
        imageMessage?: ImageMessageEntity | null;
        videoMessage?: VideoMessageEntity | null;
        documentMessage?: DocumentMessageEntity | null;
        locationMessage?: LocationMessageEntity | null;
        stickerMessage?: StickerMessageEntity | null;
        audioMessage?: AudioMessageEntity | null;
        reactionMessage?: ReactionMessageEntity | null;
        contactMessage?: ContactMessageEntity[] | null;
        blobURL?: string | null;
        reply?: ReplyEntity | null;
        replaceReplyIfNull?: boolean | null
        outOfBusinessHour?: boolean;
        edited?: boolean;
        fromSystem?: boolean;
        automatic?: boolean;
        statusError?: StatusMessageError | null;
        position?: TicketMessagePosition;
    }): TicketMessageEntity {
        return new TicketMessageEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            idTicket: idTicket ?? this.idTicket,
            ticket: ticket ?? this.ticket,
            idExternal: idExternal ?? this.idExternal,
            type: type ?? this.type,
            senderId: senderId ?? this.senderId,
            sender: sender ?? this.sender,
            deleted: deleted ?? this.deleted,
            isPrivate: isPrivate ?? this.isPrivate,
            users: users ?? this.users,
            received: received ?? this.received,
            readers: readers ?? this.readers,
            createdAt: createdAt ?? this.createdAt,
            updatedAt: updatedAt ?? this.updatedAt,
            createdAtLocal: createdAtLocal ?? this.createdAtLocal,
            status: status ?? this.status,
            uploadOptions: uploadOptions ?? this.uploadOptions,
            fromMe: fromMe ?? this.fromMe,
            participantId: participantId ?? this.participantId,
            pushName: pushName ?? this.pushName,
            textMessage: textMessage ?? this.textMessage,
            systemMessage: systemMessage ?? this.systemMessage,
            imageMessage: imageMessage ?? this.imageMessage,
            videoMessage: videoMessage ?? this.videoMessage,
            documentMessage: documentMessage ?? this.documentMessage,
            locationMessage: locationMessage ?? this.locationMessage,
            stickerMessage: stickerMessage ?? this.stickerMessage,
            audioMessage: audioMessage ?? this.audioMessage,
            reactionMessage: reactionMessage ?? this.reactionMessage,
            contactMessage: contactMessage ?? this.contactMessage,
            reply: replaceReplyIfNull ? reply : this.reply,
            outOfBusinessHour: outOfBusinessHour ?? this.outOfBusinessHour,
            edited: edited ?? this.edited,
            fromSystem: fromSystem ?? this.fromSystem,
            automatic: automatic ?? this.automatic,
            statusError: statusError ?? this.statusError,
            blobURL: blobURL ?? this.blobURL,
            position: position ?? this.position,
        })
    }

    public get getText(): string {
        if (this.textMessage) {
            return this.textMessage.conversation;
        }

        if (this.systemMessage) {
            return this.systemMessage.conversation;
        }

        return '';
    }

    public get getFileName(): string {
        if (this.documentMessage && this.documentMessage.fileName) {
            return this.documentMessage.fileName;
        }
        return this.id;
    }

}


export class BodyMessage {
    ticket!: TicketEntity;
    sender!: UserEntity;
    message!: TicketMessageEntity;

    constructor({
                    ticket,
                    sender,
                    message,
                }: {
        ticket: TicketEntity;
        sender: UserEntity;
        message: TicketMessageEntity;
    }) {
        Object.assign(this, {
            ticket,
            sender,
            message
        });
    }

}
