import DetailSubscription from "@dropDesk/presentation/components/detail_subscription";
import React from "react";
import TableInvoicesHistory
    from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/invoices_history/table";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {ContentTable} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/invoices_history/styled";
import {observer} from "mobx-react";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";

const InvoicesHistory = observer((
    {
        tableInvoices,
        loading,
        getDataFromPageInvoices,
        handleClickPayPending,
        onClickDownloadInvoice,
        currentSubscription
    }: {
        tableInvoices: ListPaginationEntity<PaymentEntity>,
        loading: boolean
        getDataFromPageInvoices: (page: number) => void,
        handleClickPayPending: (payment: PaymentEntity) => void
        onClickDownloadInvoice: (payment: PaymentEntity) => void
        currentSubscription: PaymentSubscriptionEntity

    }
) => {

    return (
        <DetailSubscription
            title={'Histórico de faturas'}
            content={
                <ContentTable>
                    <TableInvoicesHistory
                        tableInvoices={tableInvoices}
                        loading={loading}
                        getDataFromPageInvoices={getDataFromPageInvoices}
                        handleClickPayPending={handleClickPayPending}
                        onClickDownloadInvoice={onClickDownloadInvoice}
                        currentSubscription={currentSubscription}
                    />
                </ContentTable>
            }
        />
    );
});
export default InvoicesHistory;
