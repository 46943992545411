import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {ListSectorUseCase} from "@dropDesk/domain/use_case/sector/list_sectors_pagination.usecase";
import {SetSectorUseCase} from "@dropDesk/domain/use_case/sector/set_sector.usecase";
import {FindByPKSectorUseCase} from "@dropDesk/domain/use_case/sector/findbypk_sector.usecase";
import {RestoreSectorUseCase} from "@dropDesk/domain/use_case/sector/restore_sector.usecase";
import {DeleteSectorUseCase} from "@dropDesk/domain/use_case/sector/delete_sector.usecase";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {newSector} from "@dropDesk/presentation/pages/sector/controller/new_sector";
import {ExportDataSectorSubscriptionUseCase} from "@dropDesk/domain/use_case/sector/export_data_subscription.usecase";
import {ExportDataSectorUseCase} from "@dropDesk/domain/use_case/sector/export_data.usecase";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {Subscription} from "rxjs";
import {
    getIdCompanyByLocalStorage,
    initializeConfigurationLocalStorage,
    preventMissingPage,
    setGenericFiltersLocalStorage,
    setSearchParamLocalStorage
} from "@dropDesk/utils/helpers/common";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {navigate} from "@dropDesk/utils/helpers/navigation";

configure({
    enforceActions: "always",
});

@injectable()
export class SectorController {

    private readonly _listSectorUseCase: ListSectorUseCase;
    private readonly _setSectorUseCase: SetSectorUseCase;
    private readonly _findByPKSectorUseCase: FindByPKSectorUseCase;
    private readonly _restoreUseCase: RestoreSectorUseCase;
    private readonly _deleteUseCase: DeleteSectorUseCase;
    private readonly _exportDataSectorSubscriptionUseCase: ExportDataSectorSubscriptionUseCase;
    private readonly _exportDataSectorUseCase: ExportDataSectorUseCase;

    constructor(
        @inject(ListSectorUseCase) listSectorUseCase: ListSectorUseCase,
        @inject(SetSectorUseCase) setSectorUseCase: SetSectorUseCase,
        @inject(FindByPKSectorUseCase) findByPKSectorUseCase: FindByPKSectorUseCase,
        @inject(RestoreSectorUseCase) restoreUseCase: RestoreSectorUseCase,
        @inject(DeleteSectorUseCase) deleteUseCase: DeleteSectorUseCase,
        @inject(ExportDataSectorSubscriptionUseCase) exportDataSectorSubscriptionUseCase: ExportDataSectorSubscriptionUseCase,
        @inject(ExportDataSectorUseCase) exportDataSectorUseCase: ExportDataSectorUseCase,
    ) {
        makeObservable(this);
        this._listSectorUseCase = listSectorUseCase;
        this._setSectorUseCase = setSectorUseCase;
        this._findByPKSectorUseCase = findByPKSectorUseCase;
        this._restoreUseCase = restoreUseCase;
        this._deleteUseCase = deleteUseCase;
        this._exportDataSectorSubscriptionUseCase = exportDataSectorSubscriptionUseCase;
        this._exportDataSectorUseCase = exportDataSectorUseCase;
    }

    newSector: SectorEntity = newSector();

    table = new ListPaginationEntity<SectorEntity>({
        pages: 0,
        page: 0,
        limit: Math.floor((window.innerHeight - 230) / 50),
        totalRows: 0,
        data: observable.array([])
    });

    newExportation = new ExportDataEntity({
        idCompany: getIdCompanyByLocalStorage(),
        id: '',
        status: StatusImport.init,
        progress: 0,
        rows: 0,
        table: 'sectors',
        responseStatus: 200
    });

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    tableSectors: ListPaginationEntity<SectorEntity> = this.table;

    @observable
    pagesData: Record<number, SectorEntity[]> = {};

    @observable
    sector?: SectorEntity;

    @observable
    searchParam = '';

    @observable
    sectorNotFound: boolean = false;

    @observable
    exportation: ExportDataEntity = this.newExportation;

    @observable
    listOnlyDeleted = false;

    @observable
    rowSelectionSectors: SectorEntity[] = [];

    @observable
    subscriptionExport?: Subscription;

    @observable
    filterListableForClients: 'true' | 'false' | null = null;

    @action
    setRowSelectionSectors(sectors: SectorEntity[]) {
        this.rowSelectionSectors = sectors;
    }

    @action
    setFilterListableForClients(filter: 'true' | 'false' | null) {
        this.filterListableForClients = filter;
        setGenericFiltersLocalStorage({sectorFilterListableForClients: filter});
    }

    @action
    setExportation(exportation: ExportDataEntity) {
        this.exportation = exportation;
    }

    @action
    setListOnlyDeleted(value: boolean) {
        this.listOnlyDeleted = value;
        this.removeLineSelectRow();
        this.resetTable();
        this.list(this.searchParam);
    }

    @action
    resetTable() {
        this.pagesData = {};
        this.setTableSectors(this.table);
    }

    @action
    setSearchParam(value: string) {
        this.searchParam = value;
        setSearchParamLocalStorage(value);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setSector(sector: SectorEntity) {
        this.sector = sector;
    }

    @action
    removeLineSelectRow() {
        this.setRowSelectionSectors([]);
    }


    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableSectors(this.tableSectors.copyWith({
                ...this.tableSectors,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableSectors(this.tableSectors.copyWith({
                ...this.tableSectors,
                page: page,
            }))
            return this.list(this.searchParam);
        }
    }

    @action
    setSectorNotFound(value: boolean) {
        this.sectorNotFound = value;
    }

    @action
    initialize(heightGarbage: number, idUser?: string) {
        const routeName = RoutesEnum.sectors;
        const {lastPage, initSearchParam, filters} = initializeConfigurationLocalStorage(routeName);
        this.tableSectors.limit = Math.floor((window.innerHeight - 265 + heightGarbage) / 50);
        this.tableSectors.page = lastPage;
        this.filterListableForClients = filters.sectorFilterListableForClients!;
        this.searchParam = initSearchParam;
        this.list(initSearchParam, idUser);
    }

    @action
    set = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("sector.loadingSave");
            if (this.sector?.isUnsaved()) {
                this.setSector(this.sector?.copyWith({
                    id: generateUUIDV4()
                }))
            }

            await this._setSectorUseCase.call(this.sector!);
            onSuccess("sector.success.save");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();

        }
    }

    @action
    export = async (
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Promise<void> => {
        try {
            this.startLoading("client.loadingExport");
            const id = generateUUIDV4();
            if (useSocket) {
                this.observeExport(id, handleDisconnect, getLastUpdates, useSocket);
            }
            const exportData = await this._exportDataSectorUseCase.call(id);
            this.setExportation(exportData);

            if (exportData.id !== id && useSocket) {
                this.dispose();
                if (exportData.inProgress) {
                    this.observeExport(exportData.id, handleDisconnect, getLastUpdates, useSocket);
                }
            } else if(!useSocket) {
                this.observeExport(exportData.id, handleDisconnect, getLastUpdates, useSocket);
            }
            if (!exportData.inProgress) {
                this.dispose();
            }

            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            if (err.message === 'need_admin_privileges') {
                navigate(RoutesEnum.sectors);
            }
            this.stopLoading();
        }
    }

    @action
    observeExport(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ) {
        this.subscriptionExport?.unsubscribe();
        this.subscriptionExport = this._exportDataSectorSubscriptionUseCase.call(id, handleDisconnect, getLastUpdates, useSocket).subscribe({
            next: (response) => {
                if (response) {
                    this.setExportation(this.exportation.copyWith({
                        createdAt: this.exportation.createdAt,
                        ...response
                    }));
                    if (this.exportation.done) {
                        this.subscriptionExport?.unsubscribe();
                    }
                }
            },
            error: (err) => {
                toastMessage.error(err);
                this.stopLoading();
            }
        });
    }

    @action
    delete = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("sector.loadingDelete");
            await this._deleteUseCase.call(this.rowSelectionSectors);
            onSuccess("sector.success.delete");
            this.removeLineSelectRow();
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    @action
    restore = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("sector.loadingRestore");
            await this._restoreUseCase.call(this.rowSelectionSectors);
            this.removeLineSelectRow();
            onSuccess("sector.success.restore");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();

        }
    }

    @action
    dispose = (): void => {
        this.subscriptionExport?.unsubscribe();
    }

    @action
    makeSector = (): void => {
        this.setSector(this.newSector);
    }

    @action
    getSector = async (id: string): Promise<void> => {
        try {
            this.startLoading("sector.loadingInit")
            const response = await this._findByPKSectorUseCase.call(id);
            this.setSector(response);
            this.stopLoading();
        } catch (err: any) {
            this.setSectorNotFound(true);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    setTableSectors(value: ListPaginationEntity<SectorEntity>) {
        this.tableSectors = value;
        const isNecessaryReload = preventMissingPage(this.tableSectors);
        if (isNecessaryReload) {
            this.list('');
        } else {
            this.pagesData[this.tableSectors.page] = this.tableSectors.data;
        }
    }


    @action
    list = async (searchParam: string, idUser?: string): Promise<void> => {
        try {
            this.startLoading();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listSectorUseCase.call(
                this.tableSectors.page,
                this.searchParam,
                this.tableSectors.limit,
                this.listOnlyDeleted,
                idUser,
                (this.filterListableForClients !== null && this.filterListableForClients !== undefined)
                    ?
                    this.filterListableForClients === 'true'
                    :
                    undefined
            );
            this.setTableSectors(response)
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

}
