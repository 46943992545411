import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export type ContainerType = {
    background: string;
}

export type TextType = {
    color: string;
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 600px;
  width: 768px;
  border-radius: 7px;
`;
export const Content = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  background: ${props => props.background};
  flex: 1;
  border-radius: 1px 1px 5px 5px;
  justify-content: space-between;
  padding: 16px;
`;

export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerQRCode = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 250px;
  max-height: 250px;
  height: 250px;
  align-items: center;
  justify-content: center;
`;

export const ContentQRCode = styled.div`
  padding: 16px;
  background: ${SharedColors.white};
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
`;

export const ContainerTimeOut = styled.div`
  padding: 16px;
  background: ${SharedColors.white};
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
`;

export const ContentTimeOut = styled.div<ContainerType>`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
  width: 170px;
  border-radius: 50%;
  background: ${props => props.background};
  cursor: pointer;
  position: absolute;
  center: 0;
  flex-direction: column;
`;

export const TextList = styled.span<TextType>`
  color: ${props => props.color};
  font-size: 15px;
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 25px;
`;
export const ContentConnectionSuccess = styled.div<TextType>`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.color};

`;
