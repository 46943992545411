import {observer} from "mobx-react";
import React, {useState} from "react";
import {
    ContainerButtonNewUser, ContentButtonNewUser,
    ContentData,
    SubContentInputs
} from "./styled";
import ButtonNew from "@dropDesk/presentation/components/buttons/button_new";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import ModalDelete from "@dropDesk/presentation/components/modals/modal_information";
import ModalTransferContact from "@dropDesk/presentation/components/modals/modal_information";
import ModalConfirmTransferContact from "@dropDesk/presentation/components/modals/modal_information";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import ListInputsUsers from "@dropDesk/presentation/pages/client/ui/my_clients/form/inputs_contacts/list_inputs_users";
import ModalSearchClient from "@dropDesk/presentation/components/modals/modal_search_client";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {ModalChangeEmail} from "@dropDesk/presentation/pages/change_email/ui";
import {useBetween} from "use-between";
import {useModalFormClients} from "@dropDesk/presentation/pages/client/ui/my_clients/form/modals/handle_change_visible";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

const InputsFormContacts = observer(
    ({
         onNewUser,
         loading,
         contacts,
         onChangeUser,
         onDeleteUser,
         createObjectURLBlobUseCase,
         canTransfer,
         transferUserContact,
         userIsAdmin,
         forgot,
         activateAccount,
         idContactFocused,
         setIdContactFocused,
         isNewChatContact,
         modalContactTransferEntity,
         setModalContactTransferEntity
     }: {
        onNewUser: () => void,
        loading: boolean,
        canTransfer: boolean,
        userIsAdmin: boolean,
        forgot: (email: string) => Promise<void>,
        activateAccount: (email: string) => Promise<void>,
        contacts: UserEntity[],
        onChangeUser: (user: UserEntity) => void
        onDeleteUser: (id: string) => void,
        createObjectURLBlobUseCase: (file: File) => string,
        transferUserContact: (idNewClient: string, idUser: string) => Promise<void>,
        idContactFocused: string | null;
        setIdContactFocused: (id: string | null) => void;
        isNewChatContact?: boolean;
        modalContactTransferEntity: {
            visible: boolean, contact: UserEntity | null, client: ClientEntity | null
        }
        setModalContactTransferEntity: React.Dispatch<React.SetStateAction<{
            visible: boolean,
            contact: UserEntity | null,
            client: ClientEntity | null
        }>>
    }) => {

        const [modalContactDeletedEntity, setModalContactDeletedEntity] = useState<{
            visible: boolean,
            contact: UserEntity | null
        }>({
            visible: false,
            contact: null
        });


        const [visibleModalConfirmTransferContact, setVisibleModalConfirmTransferContact] = useState<boolean>(false);
        const [visibleModalSearchClient, setVisibleModalSearchClient] = useState<boolean>(false);
        const {
            visibleModalChangeEmail, setVisibleModalChangeEmail,
            visibleModalRememberPassword, setVisibleModalRememberPassword,
            visibleModalActivateAccount, setVisibleModalActivateAccount
        } = useBetween(useModalFormClients);

        return (

            <SubContentInputs>
                <ContentData>
                    <ContainerButtonNewUser>
                        <ContentButtonNewUser>
                            <ButtonNew
                                label={'Novo Contato'}
                                onClick={onNewUser}
                                sizeLeftIcon={16}
                                disabled={loading}
                                isButtonMain={true}
                            />
                        </ContentButtonNewUser>
                    </ContainerButtonNewUser>
                    {contacts.filter((user) => user.action != BackendAction.delete).map((contact) => {
                        return (
                            <div key={contact.id}>
                                <ListInputsUsers
                                    user={contact}
                                    isNewChatContact={isNewChatContact}
                                    canDelete={contacts.filter((user) => user.action != BackendAction.delete).length > 1}
                                    onChange={(user) => onChangeUser(user)}
                                    onDeleteUser={(user) => setModalContactDeletedEntity({
                                        visible: true,
                                        contact: user
                                    })}
                                    canTransfer={canTransfer}
                                    onTransferContact={(user) => setModalContactTransferEntity({
                                        visible: true,
                                        contact: user,
                                        client: null
                                    })}
                                    canChangeEmail={userIsAdmin && !!contact.hasAccountCreated && !!contact.createdAt}
                                    loading={loading}
                                    createObjectURLBlobUseCase={createObjectURLBlobUseCase}
                                    focus={idContactFocused === contact.id}
                                    onContactFocused={() => setTimeout(() => setIdContactFocused(null), 500)}
                                />
                            </div>
                        )
                    })}

                    <ModalDelete
                        open={modalContactDeletedEntity.visible}
                        onRequestClose={() => {
                            setModalContactDeletedEntity({visible: false, contact: null});
                        }}
                        textHeaderModal={'Excluir Contato'}
                        textButton={'Excluir'}
                        onRequestSave={async () => {
                            onDeleteUser(modalContactDeletedEntity.contact?.id ?? '');
                            setModalContactDeletedEntity({visible: false, contact: null});
                        }}
                        nameIcon={ICONS_DROPDESK.delete}
                        sizeIcon={130}
                        textInformation={`Deseja realmente fazer a exclusão deste contato?`}
                        loading={loading}
                        isActionDelete={true}
                    />

                    <ModalTransferContact
                        open={modalContactTransferEntity.visible}
                        onRequestClose={() => {
                            setModalContactTransferEntity({visible: false, contact: null, client: null});

                        }}
                        textHeaderModal={'Transferir Contato'}
                        textButton={'Transferir'}
                        onRequestSave={async () => {

                            setModalContactTransferEntity({
                                visible: false,
                                contact: modalContactTransferEntity.contact,
                                client: modalContactTransferEntity.client
                            });
                            setVisibleModalSearchClient(true);
                        }}
                        nameIcon={ICONS_DROPDESK.transfer}
                        sizeIcon={115}
                        textInformation={`Deseja realmente fazer a transferência deste contato para outra empresa? Após a transferência, os próximos atendimentos deste contato serão vinculado a nova empresa.`}
                        loading={loading}
                    />

                    {visibleModalSearchClient && <ModalSearchClient
                        visible={visibleModalSearchClient}
                        handleSelectClient={async (client) => {
                            setModalContactTransferEntity({
                                visible: false,
                                contact: modalContactTransferEntity.contact,
                                client: client
                            });
                            setVisibleModalSearchClient(false);
                            setVisibleModalConfirmTransferContact(true);
                        }}
                        onClose={() => {
                            setModalContactTransferEntity({visible: false, contact: null, client: null});
                            setVisibleModalSearchClient(false);
                        }}
                        textHeaderModal={"Selecione a empresa"}
                        labelNoResults={"Nenhuma empresa encontrada na pesquisa"}
                        inputSearchPlaceholder={"Pesquise por uma empresa"}
                    />}

                    <ModalConfirmTransferContact
                        open={visibleModalConfirmTransferContact}
                        onRequestClose={() => {
                            setModalContactTransferEntity({visible: false, contact: null, client: null});
                            setVisibleModalConfirmTransferContact(false);
                        }}
                        textHeaderModal={'Confirmar Transferência'}
                        textButton={'Confirmar'}
                        onRequestSave={async () => {
                            //transferir contato desta empresa para outra

                            await transferUserContact(modalContactTransferEntity.client?.id ?? '', modalContactTransferEntity.contact?.id ?? '');

                        }}
                        nameIcon={ICONS_DROPDESK.warning}
                        sizeIcon={130}
                        textInformation={`Ao confirmar esta transferência, este contato será transferido para empresa selecionada.`}
                        loading={loading}
                    />
                    {visibleModalChangeEmail.user && <ModalChangeEmail
                        visibleModalChangeEmail={visibleModalChangeEmail.visible}
                        setVisibleModalChangeEmail={() => setVisibleModalChangeEmail({visible: false})}
                        oldEmail={visibleModalChangeEmail.user.email!}
                        isCurrentUserChanged={false}
                        onSuccessChangeEmail={(newEmail) => {
                            const user = visibleModalChangeEmail.user!.copyWith({
                                email: newEmail,
                                verified: false,
                                replaceEmailIfNull: true,
                            });
                            onChangeUser(user);
                        }}
                    />}

                    {visibleModalRememberPassword.user && <ModalInformation
                        open={visibleModalRememberPassword.visible}
                        onRequestClose={() => {
                            setVisibleModalRememberPassword({visible: false});
                        }}
                        textHeaderModal={'Recuperar senha'}
                        textButton={'Enviar'}
                        onRequestSave={async () => {
                            await forgot(visibleModalRememberPassword.user?.email ?? '');
                            setVisibleModalRememberPassword({visible: false});
                        }}
                        nameIcon={ICONS_DROPDESK.mail}
                        sizeIcon={130}
                        textInformation={`Deseja realmente enviar um email para ${visibleModalRememberPassword.user.email} com a instrução de recuperação de senha?`}
                        loading={loading}
                    />}

                    {visibleModalActivateAccount.user && <ModalInformation
                        open={visibleModalActivateAccount.visible}
                        onRequestClose={() => {
                            setVisibleModalActivateAccount({visible: visibleModalActivateAccount.visible});
                        }}
                        textHeaderModal={'Ativar conta'}
                        textButton={'Enviar'}
                        onRequestSave={async () => {
                            await activateAccount(visibleModalActivateAccount.user?.email ?? '');
                            setVisibleModalActivateAccount({visible: visibleModalActivateAccount.visible});
                        }}
                        nameIcon={ICONS_DROPDESK.mail}
                        sizeIcon={130}
                        textInformation={`Deseja realmente enviar um email para ${visibleModalActivateAccount.user.email} com a instrução de ativação de conta?`}
                        loading={loading}
                    />}
                </ContentData>
            </SubContentInputs>
        )
    })
export default InputsFormContacts;
