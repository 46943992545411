import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {TicketConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/ticket_configuration.entity";
import {
    PortalClientConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/portal_client_configuration.entity";
import {
    ChatMessageEnum,
    RealTimeConnectionEnum,
    TimezoneEnum
} from "@dropDesk/domain/entities/company_configurations/configuration_enum";
import {
    BusinessOperationDayEntity,
    BusinessOperationHoursConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/business_operation_hours_configuration.entity";
import {ThemeConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/theme_configuration.entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {ChatConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/chat_configuration.entity";
import {
    NotificationsConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/notifications_configuration.entity";
import {PanelAdmEntity} from "@dropDesk/domain/entities/company_configurations/panel_adm.entity";


const getObjectHour = (isOpen: boolean, initialFirstHour: string, endFirstHour: string, initialSecondHour: string, endSecondHour: string): BusinessOperationDayEntity => {

    return new BusinessOperationDayEntity({
        open: isOpen,
        initialFirstHour,
        endFirstHour,
        initialSecondHour,
        endSecondHour,
    })
}

export const newCompanyConfiguration = (): CompanyConfigurationEntity => new CompanyConfigurationEntity({
    id: '',
    idCompany: getIdCompanyByLocalStorage(),
    ticket: new TicketConfigurationEntity({
        useRatingTicket: true,
        informReasonCancellation: true,
        informResolutionTicket: false,
        useProblemStatic: false,
    }),
    portalClient: new PortalClientConfigurationEntity({
        useChatOnline: false,
    }),
    businessOperationHours: new BusinessOperationHoursConfigurationEntity({
        monday: getObjectHour(true, '09:00', '18:00', '09:00', '18:00'),
        tuesday: getObjectHour(true, '09:00', '18:00', '09:00', '18:00'),
        wednesday: getObjectHour(true, '09:00', '18:00', '09:00', '18:00'),
        thursday: getObjectHour(true, '09:00', '18:00', '09:00', '18:00'),
        friday: getObjectHour(true, '09:00', '18:00', '09:00', '18:00'),
        saturday: getObjectHour(true, '09:00', '18:00', '09:00', '18:00'),
        sunday: getObjectHour(false, '09:00', '18:00', '09:00', '18:00'),
        usesTwoOperationHours: false,
        timezone: TimezoneEnum.americaSaoPaulo
    }),
    theme: new ThemeConfigurationEntity({
        name: 'light-green',
        logoBrandUrl: null
    }),
    chat: new ChatConfigurationEntity({
        displayNameUserInMessage: true,
        welcomeMessage: ChatMessageEnum.welcomeMessage,
        chooseDepartmentMessage: ChatMessageEnum.chooseDepartmentMessage,
        queueTicketMessage: ChatMessageEnum.queueTicketMessage,
        closedTicketMessage: ChatMessageEnum.closedTicketMessage,
        closedIdleTicketMessage: ChatMessageEnum.closedIdleTicketMessage,
        outTimeOperatingMessage: ChatMessageEnum.outTimeOperatingMessage,
        transferSectorMessage: ChatMessageEnum.transferUserMessage,
        transferUserMessage: ChatMessageEnum.transferUserMessage,
        successRatingTicketMessage: ChatMessageEnum.successRatingTicketMessage,
        useWelcomeMessage: true,
        useQueueTicketMessage: true,
        useClosedTicketMessage: true,
        useOutTimeOperatingMessage: true,
        useTransferSectorMessage: true,
        useTransferUserMessage: true,
        useClosedIdleTicketMessage: true,
        useNotifyBeforeCloseIdleTicketMessage: true,
        notificationBeforeCloseIdleTicketMessage: ChatMessageEnum.notificationBeforeCloseIdleTicketMessage,
        inactivityTime: 30,
        autoCloseInactiveAttending: false,
        autoCloseInactiveWaiting: false,
        inactivityTransferSectorTime: 15
    }),
    notifications: new NotificationsConfigurationEntity({
        allowMessagesTicketNotification: true,
        allowNewTicketNotification: true,
        allowTransferTicketNotification: true

    })
});

export const newPanelAdm = ():PanelAdmEntity =>  new PanelAdmEntity({
    realTimeConnection: RealTimeConnectionEnum.socket,
});