import {inject, injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {ChatRemoteDataSource} from "@dropDesk/data/data_source/chat/chat_remote_datasource";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {Observable} from "rxjs";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {VoidSuccess} from "@dropDesk/domain/entities/response/response";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";
import {CancelTokenSource} from "axios";
import {AllChatsEntity} from "@dropDesk/domain/entities/chat/all_chats.entity";

@injectable()
export class ChatRepositoryImpl implements ChatRepository {
    private _dataSource: ChatRemoteDataSource;

    constructor(@inject(ChatRemoteDataSource) dataSource: ChatRemoteDataSource) {
        this._dataSource = dataSource;
    }

    public listChatsAttending(searchParam: string, page: number): Promise<ListPaginationEntity<TicketEntity>> {
        try {
            return this._dataSource.listChatsAttending(searchParam, page);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public listChatsWaiting(searchParam: string, page: number): Promise<ListPaginationEntity<TicketEntity>> {
        try {
            return this._dataSource.listChatsWaiting(searchParam, page);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public subscribe(handleDisconnect: (temporary: boolean) => void, getLastUpdates: () => void, id?: string, retry?: boolean, useSocket?: boolean, ticket?: TicketEntity | null): Observable<TicketEntity[]> {
        try {
            return this._dataSource.subscribe(handleDisconnect, getLastUpdates, id, retry, useSocket, ticket);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public loadMessages(chatId: string, page: number, cancelTokenSource?: CancelTokenSource): Promise<ListPaginationEntity<TicketMessageEntity>> {
        try {
            return this._dataSource.loadMessages(chatId, page, cancelTokenSource);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public listChatMessagesAfterSubscription(chatId: string): Promise<TicketMessageEntity[]> {
        try {
            return this._dataSource.listChatMessagesAfterSubscription(chatId);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public startConnection(): Promise<ChatIntegrationConnectionEntity> {
        try {
            return this._dataSource.startConnection();
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public logOut(): Promise<VoidSuccess> {
        try {
            return this._dataSource.logOut();
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public connectionState(): Promise<ChatIntegrationConnectionEntity> {
        try {
            return this._dataSource.connectionState();
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public subscribeConnection(
        id: string,
        useSocket: boolean,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void
    ): Observable<ChatIntegrationConnectionEntity | null> {
        try {
            return this._dataSource.subscribeConnection(id, useSocket, handleDisconnect, getLastUpdates);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public sendMessage(message: TicketMessageEntity, associateSenderToTicket: boolean): Promise<TicketMessageEntity> {
        try {
            return this._dataSource.sendMessage(message, associateSenderToTicket);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public subscriptionUploadFile(message: TicketMessageEntity): Observable<TicketMessageEntity> {
        try {
            return this._dataSource.subscriptionUploadFile(message);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public delete(id: string): Promise<void> {
        try {
            return this._dataSource.delete(id);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public editMessage(idMessage: string, text: string): Promise<void> {
        try {
            return this._dataSource.editMessage(idMessage, text);
        } catch (e: any) {
            throw new ServerError(e);
        }
    }

    public navigateToMessage(id: string, ticketNumber: number): Promise<ListPaginationEntity<TicketMessageEntity>> {
        try {
            return this._dataSource.navigateToMessage(id, ticketNumber);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public reactMessage(
        messageId: string,
        reactionId: string,
        reaction: string,
    ): Promise<VoidSuccess> {
        try {
            return this._dataSource.reactMessage(messageId, reactionId, reaction);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public informReadMessages(ticketId: string, cancelTokenSource?: CancelTokenSource): Promise<void> {
        try {
            return this._dataSource.informReadMessages(ticketId, cancelTokenSource);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public clearTicketsCache(): void {
        try {
            return this._dataSource.clearTicketsCache();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public joinTicket(ticketId: string): void {
        try {
            return this._dataSource.joinTicket(ticketId);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public leaveTicket(ticketId: string): void {
        try {
            return this._dataSource.leaveTicket(ticketId);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public listAllChats(searchParam: string, page: number): Promise<AllChatsEntity> {
        try {
            return this._dataSource.listAllChats(searchParam, page);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public updateCacheFromSingleTicket(ticket: TicketEntity): void {
        try {
            return this._dataSource.updateCacheFromSingleTicket(ticket);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }
}
