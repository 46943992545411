import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {
    Container,
    TextWeight,
    Title,
    TextLink,
    Content
} from "@dropDesk/presentation/routes/header_confirmation_account/styled";
import React, {useState} from "react";
import {useInjection} from "inversify-react";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {getDaysToExpireLicense} from "@dropDesk/utils/helpers/date_helper";
import {observer} from "mobx-react";
import {ModalChangeEmail} from "@dropDesk/presentation/pages/change_email/ui";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {Button} from "@dropDesk/presentation/routes/header_subscription_info/styled";
import {MailProvider} from "@dropDesk/domain/entities/common/mail.entity";

const HeaderConfirmationAccount = (observer((
    {
        currentUser,
        onClickClose
    }: {
        currentUser: UserEntity
        onClickClose: () => void
    }) => {

    const [hasSendEmail, setHasSendEmail] = useState(false);
    const [emailProvider, setEmailProvider] = useState<MailProvider>(MailProvider.sendgrid);
    const [visibleModalChangeEmail, setVisibleModalChangeEmail] = useState(false);
    const userController = useInjection(UserController);
    const userEmail = currentUser.email ?? '';

    const {t} = useTranslation();
    const navigate = useNavigate();

    const onSuccessSendEmail = async (key: string, email: string) => {
        if (emailProvider === MailProvider.nodemailer) {
            setHasSendEmail(true);
        }
        setEmailProvider(MailProvider.nodemailer);
        toastMessage.success(t(key));
    }

    const renderSendEmail = () => {
        return (
            <>
                {!hasSendEmail && <TextLink
                    disabled={userController.loading === true}
                    onClick={() => userController.activateAccount(userEmail, onSuccessSendEmail, emailProvider === MailProvider.nodemailer)}
                    color={SharedColors.black}
                >{emailProvider === MailProvider.sendgrid ? 'Reenviar código de ativação' : '(não recebi código, enviar novamente)'}</TextLink>}
                {hasSendEmail && <Title>
                    e-mail já foi enviado por favor verifique.
                </Title>
                }
            </>)
    }

    const setVisibleModal = () => {
        setVisibleModalChangeEmail(!visibleModalChangeEmail)
    }

    const renderInsertToken = () => {
        return (
            <TextLink
                disabled={userController.loading === true}
                onClick={async () => {
                    navigate(`${RoutesEnum.firstAccess}?token=&confirmation=true`, {state: {from: RoutesEnum.dashboard}});
                }}
                color={SharedColors.black}
            >Inserir código</TextLink>
        )
    }
    const renderChangeEmail = () => {
        return (
            <TextLink
                disabled={userController.loading === true}
                onClick={() => setVisibleModal()}
                color={SharedColors.black}
            >Alterar e-mail</TextLink>
        )
    }
    const expireIn = () => {
        return (
            <Title><TextWeight>Expira em {getDaysToExpireLicense(currentUser.createdAt ?? '')} dias</TextWeight></Title>
        )
    }
    return (
        <Container>
            <Content>
                <Title>E-mail de ativação enviado para <TextWeight>{currentUser.email ?? ''}
                </TextWeight>&nbsp;&nbsp;-&nbsp;&nbsp;{renderSendEmail()}&nbsp;&nbsp;|&nbsp;&nbsp;{renderInsertToken()}
                    &nbsp;&nbsp;|&nbsp;&nbsp;{renderChangeEmail()}&nbsp;&nbsp;|&nbsp;&nbsp;{expireIn()}</Title>

                <Button onClick={() => {
                    onClickClose();
                }}>
                    <DropDeskIcon
                        icon={ICONS_DROPDESK.close} size={15}
                        color={SharedColors.black}
                    />
                </Button>
            </Content>
            <ModalChangeEmail
                visibleModalChangeEmail={visibleModalChangeEmail}
                setVisibleModalChangeEmail={setVisibleModal}
                oldEmail={currentUser.email!}
                isCurrentUserChanged={true}
            />
        </Container>
    )
}));
export default HeaderConfirmationAccount;
