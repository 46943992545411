import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {PlanEntity} from "@dropDesk/domain/entities/plan/plan_entity";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {
    PaymentSubscriptionEntity,
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {from, Observable, Observer} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {AxiosResponse} from "axios";
import {
    getPaymentSubscriptionResponse,
} from "@dropDesk/domain/entities/common/get_list_subscription_response";
import {gql} from "@apollo/client";
import {apolloClient} from "@dropDesk/data/clients/apollo.client";
import {PaymentStatus} from "@dropDesk/domain/entities/payment/payment.enum";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {
    PaymentSubscriptionSimulateEntity
} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_simulate.entity";
import {downloadFile, openFileInNewTab} from "@dropDesk/utils/helpers/files";

@injectable()
export abstract class SubscriptionRemoteDataSource {

    public abstract findByPK(id: string): Promise<PaymentSubscriptionEntity>;

    public abstract listPlan(listPlansLegacy: boolean): Promise<PlanEntity[]>;

    public abstract pay(subscription: PaymentSubscriptionEntity, paymentMethod?: PaymentMethodEntity): Promise<PaymentSubscriptionEntity>;

    public abstract getPaymentSubscription(id: string): Observable<PaymentEntity>;

    public abstract list(page: number, limit: number, status?: PaymentStatus): Promise<ListPaginationEntity<PaymentEntity>>;

    public abstract cancel(
        idSubscription: string,
        cancellationReason: string,
        isFutureSubscription: boolean
    ): Promise<PaymentSubscriptionEntity>;

    public abstract reactivate(idSubscription: string): Promise<PaymentSubscriptionEntity>;

    public abstract simulate(subscription: PaymentSubscriptionEntity): Promise<PaymentSubscriptionSimulateEntity>;

    public abstract getPdfByPaymentId(payment: PaymentEntity): Promise<void>;
}

@injectable()
export class SubscriptionRemoteDataSourceImpl implements SubscriptionRemoteDataSource {

    constructor() {
    }

    baseUrl: string = 'paymentSubscriptions/';
    baseUrlCancel: string = `${this.baseUrl}cancel/active/`;
    baseUrlCancelFuture: string = `${this.baseUrl}cancel/future/`;
    baseUrlSimulate: string = `${this.baseUrl}simulate`;

    public findByPK(id: string): Promise<PaymentSubscriptionEntity> {
        return new Promise<PaymentSubscriptionEntity>(async (resolve, reject) => {
            try {
                const response = await api.get(this.baseUrl + id);
                return resolve(PaymentSubscriptionEntity.fromJson(response.data));
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public cancel(
        idSubscription: string,
        cancellationReason: string,
        isFutureSubscription: boolean
    ): Promise<PaymentSubscriptionEntity> {
        return new Promise<PaymentSubscriptionEntity>(async (resolve, reject) => {
            try {
                const url = isFutureSubscription ? `${this.baseUrlCancelFuture}${idSubscription}` : `${this.baseUrlCancel}${cancellationReason}`;
                const response = await api.patch(url);
                const result = PaymentSubscriptionEntity.fromJson(response.data);
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public reactivate(idSubscription: string): Promise<PaymentSubscriptionEntity> {
        return new Promise<PaymentSubscriptionEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(`paymentSubscriptions/reactivate`);
                const result = PaymentSubscriptionEntity.fromJson(response.data);
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public listPlan(listPlansLegacy: boolean): Promise<PlanEntity[]> {
        return new Promise<PlanEntity[]>(async (resolve, reject) => {
            try {
                const limit = ConstantsKeys.defaultLimitListPlans;
                const response = await api.get(
                    `plans?searchParam=&page=0&limit=${limit}&listOnlyLegacy=${listPlansLegacy}`
                );
                const result: ListPaginationEntity<PlanEntity> = response.data;
                const plans: PlanEntity[] = result.data.map((plan) => PlanEntity.fromJson(plan));
                return resolve(plans);

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public simulate(subscription: PaymentSubscriptionEntity): Promise<PaymentSubscriptionSimulateEntity> {
        return new Promise<PaymentSubscriptionSimulateEntity>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.baseUrlSimulate}?idPlan=${subscription.idPlan}&dayDue=${subscription.dayDue}&planUserMultiplier=${subscription.planUserMultiplier}`);
                const result = new PaymentSubscriptionSimulateEntity({
                    ...response.data
                });
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public pay(subscription: PaymentSubscriptionEntity, paymentMethod?: PaymentMethodEntity): Promise<PaymentSubscriptionEntity> {
        return new Promise<PaymentSubscriptionEntity>(async (resolve, reject) => {
            try {
                const data = {
                    subscription: subscription.toJson(),
                    paymentMethod
                };
                const response = await api.post(`paymentSubscriptions`, data);
                const result = PaymentSubscriptionEntity.fromJson(response.data);
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public getPaymentSubscription(id: string): Observable<PaymentEntity> {
        const table = 'payments';

        return from(api.get(`${table}/queryById/${id}`))
            .pipe<PaymentEntity>(mergeMap<AxiosResponse, Observable<PaymentEntity>>((value: AxiosResponse<getPaymentSubscriptionResponse, any>, index: number) => {
                    const {query} = value.data;
                    const querySubscription = gql`${query}`;

                    return new Observable<PaymentEntity>((observer: Observer<PaymentEntity>) => {
                        return apolloClient.subscribe({
                            query: querySubscription,

                        }).subscribe({
                            next: async (response) => {
                                const result: Record<string, any> = response.data![table][0];
                                const lastPayment = PaymentEntity.fromJson(result);
                                observer.next(lastPayment);
                            },
                            error(err: any) {
                                observer.error(err);
                            },
                        });
                    });
                }
            ));
    }

    public async list(page: number, limit: number, status?: PaymentStatus): Promise<ListPaginationEntity<PaymentEntity>> {
        return new Promise<ListPaginationEntity<PaymentEntity>>(async (resolve, reject) => {
            try {
                const response = await api.get(`payments?page=${page ?? ''}&limit=${limit ?? ''}&status=${status ?? ''}`);
                const result = new ListPaginationEntity<PaymentEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: PaymentEntity) => PaymentEntity.fromJson(entry))
                });
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    async getPdfByPaymentId(payment: PaymentEntity): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const url = `payments/pdf?id=${payment.id}`;
                const response = await api.get(url, {responseType: 'blob'});
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                openFileInNewTab(file, 'Carregando pdf...');
                downloadFile(fileURL, `fatura_${payment.secureCode}.pdf`);
                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

}
