import "reflect-metadata";
import {inject, injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {apolloClient} from "@dropDesk/data/clients/apollo.client";
import {gql} from "@apollo/client";
import {Observable, from, Observer} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {AxiosResponse, CancelTokenSource} from "axios";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {ExportRemoteDataSource} from "@dropDesk/data/data_source/export/export_remote.datasource";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {ChatRemoteDataSource} from "@dropDesk/data/data_source/chat/chat_remote_datasource";
import {getListSubscriptionResponse} from "@dropDesk/domain/entities/common/get_list_subscription_response";
import {downloadFile, openFileInNewTab} from "@dropDesk/utils/helpers/files";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {TicketConfig} from "@dropDesk/domain/entities/ticket/ticket_config";
import {DropDeskErrorEnum} from "@dropDesk/domain/entities/exceptions/dropdesk_error_enum";

@injectable()
export abstract class TicketRemoteDataSource {

    public abstract set(ticket: TicketEntity): Promise<TicketEntity>;

    public abstract findByPK(id: string, listMessages: boolean, cancelTokenSource?: CancelTokenSource): Promise<TicketEntity>;

    public abstract restore(tickets: TicketEntity[]): Promise<void>;

    public abstract delete(tickets: TicketEntity[]): Promise<void>;

    public abstract transfer(idTicket: string, newSectorId: string, newAttendantId?: string): Promise<TicketEntity>;

    public abstract listTicketsSubscription(
        searchParam: string,
        page: number,
        limit: number,
        listOnlyDeleted: boolean,
        status: string | null,
        priority: string | null,
        listOnlySectorEmpty: boolean
    ): Observable<ListPaginationEntity<TicketEntity>>;

    public abstract changeTimeSpent(idTicket: string, timeSpent: number): Promise<TicketEntity>;

    public abstract changePriority(idTicket: string, priority: string): Promise<TicketEntity>;

    public abstract changeDateDue(idTicket: string, dateDue: string): Promise<TicketEntity>;

    public abstract changeProblemDescription(idTicket: string, description: string): Promise<TicketEntity>;

    public abstract cancel(idTicket: string, cancelDescription?: string): Promise<TicketEntity>;

    public abstract close(idTicket: string, closeDescription?: string): Promise<TicketEntity>;

    public abstract changeResolution(idTicket: string, closeDescription: string): Promise<TicketEntity>;

    public abstract linkAttendant(idTicket: string): Promise<TicketEntity>;

    public abstract exportSubscription(): Observable<ExportDataEntity>;

    public abstract export(period: { startDate: string, endDate: string }): Promise<ExportDataEntity>;

    public abstract exportPdf(idTicket: string): Promise<void>;

    public abstract getConfigs(): Promise<TicketConfig[]>;
}

@injectable()
export class TicketRemoteDataSourceImpl implements TicketRemoteDataSource {

    private _exportDataSource: ExportRemoteDataSource;
    private _chatRemoteDataSource: ChatRemoteDataSource;

    constructor(
        @inject(ExportRemoteDataSource) exportDataSource: ExportRemoteDataSource,
        @inject(ChatRemoteDataSource) chatRemoteDataSource: ChatRemoteDataSource
    ) {
        this._exportDataSource = exportDataSource;
        this._chatRemoteDataSource = chatRemoteDataSource;
    }

    baseTicketUrl: string = 'tickets/';
    baseRestoreTicketUrl: string = `${this.baseTicketUrl}restore/`;
    baseTransferUrl: string = `${this.baseTicketUrl}transfer/`;
    baseChangeTimeSpentUrl: string = `${this.baseTicketUrl}changeTimeSpent/`;
    baseChangePriorityUrl: string = `${this.baseTicketUrl}changePriority/`;
    baseChangeDateDutUrl: string = `${this.baseTicketUrl}changeDateDue/`;
    baseChangeProblemDescriptionUrl: string = `${this.baseTicketUrl}changeProblemDescription/`;
    baseCancelTicketUrl: string = `${this.baseTicketUrl}cancel/`;
    baseCloseTicketUrl: string = `${this.baseTicketUrl}close/`;
    baseChangeResolutionTicketUrl: string = `${this.baseTicketUrl}changeDescriptionClosed/`;
    baseLinkAttendantTicketUrl: string = `${this.baseTicketUrl}linkAttendant/`;
    baseUrlExport: string = `${this.baseTicketUrl}export/start`;
    baseUrlExportPDF: string = `${this.baseTicketUrl}export/pdf`;

    public linkAttendant(idTicket: string): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(`${this.baseLinkAttendantTicketUrl}${idTicket}`);
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public close(idTicket: string, closeDescription?: string): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(`${this.baseCloseTicketUrl}?idTicket=${idTicket}${closeDescription ? `&closeDescription=${closeDescription}` : ''}`);
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public changeResolution(idTicket: string, closeDescription: string): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(`${this.baseChangeResolutionTicketUrl}${idTicket}/${closeDescription}`);
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }


    public cancel(idTicket: string, cancelDescription?: string): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(`${this.baseCancelTicketUrl}${idTicket}/${cancelDescription}`);
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public changeProblemDescription(idTicket: string, description: string): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(this.baseChangeProblemDescriptionUrl + idTicket + `/${description}`);
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public changeDateDue(idTicket: string, dateDue: string): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(this.baseChangeDateDutUrl + idTicket + `/${dateDue}`);
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public changePriority(idTicket: string, priority: string): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(this.baseChangePriorityUrl + idTicket + `/${priority}`);
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public changeTimeSpent(idTicket: string, timeSpent: number): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(this.baseChangeTimeSpentUrl + idTicket + `/${timeSpent}`);
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public transfer(idTicket: string, newSectorId: string, newAttendantId?: string): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.patch(this.baseTransferUrl + idTicket + `/${newSectorId}` + `${newAttendantId ? `/${newAttendantId}` : ''}`);
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    private getTicketsByIds(records: Record<string, any>[]): Promise<TicketEntity[]> {
        return new Promise<TicketEntity[]>(async (resolve, reject) => {
            try {
                const ids: string[] = [];
                const cache: TicketEntity[] = [];
                for (const record of records) {
                    if (this.hasValidCache(record)) {
                        const ticket = this.getFromCache(record['id']);
                        if (ticket) {
                            cache.push(ticket);
                        }
                    } else {
                        ids.push(record['id']);
                    }
                }
                let result: TicketEntity[] = [];
                if (ids.length > 0) {
                    const response = await api.get(`${this.baseTicketUrl}byIds/${ids.map((entry) => entry).join(',')}`);
                    result = (response.data as Record<string, any>[]).map(ticket => TicketEntity.fromJson(ticket));
                }
                const resolvedList = [...result, ...cache];
                let sortFn = (ticket1: TicketEntity, ticket2: TicketEntity) => ticket1.number > ticket2.number ? -1 : ticket1.number < ticket2.number ? 1 : 0
                const sortedList = resolvedList.sort(sortFn);
                this.persistCache(sortedList)
                return resolve(sortedList);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    private clearCache(): void {
        localStorage.removeItem('tickets');
    }

    private persistCache(tickets: TicketEntity[]): void {
        localStorage.setItem('tickets', JSON.stringify(tickets));
    }

    private getCache(): TicketEntity[] {
        const cache = localStorage.getItem('tickets');
        if (cache) {
            return (JSON.parse(cache) as Record<string, any>[]).map(ticket => TicketEntity.fromJson(ticket));
        }

        return [];
    }

    private hasValidCache(record: Record<string, any>): boolean {
        let result = false;
        const tickets = this.getCache();
        for (const ticket of tickets) {
            if (ticket.id === record['id'] && ticket.updatedAt === record['updatedAt']) {
                result = true;
            }
        }

        return result;
    }

    private getFromCache(id: string): TicketEntity | null {
        let result: TicketEntity | null = null;
        const tickets = this.getCache();
        for (const ticket of tickets) {
            if (ticket.id === id) {
                result = ticket;
            }
        }

        return result;
    }

    public listTicketsSubscription(
        searchParam: string,
        page: number,
        limit: number,
        listOnlyDeleted: boolean,
        status: string | null,
        priority: string | null,
        listOnlySectorEmpty: boolean
    ): Observable<ListPaginationEntity<TicketEntity>> {
        const url = `${this.baseTicketUrl}getListSubscription?searchParam=${searchParam}&page=${page}&limit=${limit}&type=${listOnlyDeleted ? 'deleted' : ''}&status=${status ?? ''}&priority=${priority ?? ''}&listOnlySectorEmpty=${listOnlySectorEmpty}`;
        this.clearCache();
        return from(api.get(url)).pipe<ListPaginationEntity<TicketEntity>>(mergeMap<AxiosResponse, Observable<ListPaginationEntity<TicketEntity>>>((value: AxiosResponse<getListSubscriptionResponse, any>, _: number) => {
                const {querySubscription, queryAggregate} = value.data;
                const query = gql`${querySubscription}`;
                const queryAgg = gql`${queryAggregate}`;
                return new Observable<ListPaginationEntity<TicketEntity>>((observer: Observer<ListPaginationEntity<TicketEntity>>) => {
                    return apolloClient.subscribe({
                        query
                    }).subscribe({
                        next: async (response) => {
                            const valueAgg = await apolloClient.query({query: queryAgg});
                            const totalRows = valueAgg.data!['tickets_aggregate']['aggregate']['count'];
                            const ids = response.data!['tickets'] as Record<string, any>[];
                            const tickets = await this.getTicketsByIds(ids);
                            await this._chatRemoteDataSource.updateUsersInfoData(tickets);
                            const ticketsPaginated = new ListPaginationEntity<TicketEntity>({
                                data: tickets.map((entry) => new TicketEntity({
                                    ...entry,
                                    usersInfo: this._chatRemoteDataSource.getUsersFromCache(entry.users!),
                                })),
                                page,
                                limit,
                                totalRows,
                                pages: Math.ceil(totalRows / limit),
                            })
                            observer.next(ticketsPaginated);
                        },
                        error(err: any) {
                            observer.error(err);
                        },
                    });
                });
            }
        ));
    }


    public set(ticket: TicketEntity): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const response = await api.post(this.baseTicketUrl, ticket.toJson());
                return resolve(TicketEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public restore(tickets: TicketEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                for (let i = 0; i < tickets.length; i++) {
                    await api.patch(this.baseRestoreTicketUrl + tickets[i].id);
                }

                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public delete(tickets: TicketEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                for (let i = 0; i < tickets.length; i++) {
                    await api.delete(this.baseTicketUrl + tickets[i].id);
                }

                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public findByPK(id: string, listMessages: boolean = true, cancelTokenSource?: CancelTokenSource): Promise<TicketEntity> {
        return new Promise<TicketEntity>(async (resolve, reject) => {
            try {
                const url: string = `${this.baseTicketUrl}${id}?listMessages=${listMessages}&limit=${ConstantsKeys.defaultLimit}`;
                const response = await api.get(url, {cancelToken: cancelTokenSource?.token});
                const ticket = TicketEntity.fromJson(response.data);
                return resolve(ticket);
            } catch (error) {
                const errorParsed = parseServerError(error);
                if (errorParsed.message === DropDeskErrorEnum.operationCanceledDueToNewRequest) {
                    console.log('cancel token')
                    return;
                }
                return reject(errorParsed);
            }
        })
    }

    public export(period: { startDate: string, endDate: string }): Promise<ExportDataEntity> {
        return new Promise<ExportDataEntity>(async (resolve, reject) => {
            try {

                const response = await api.get(`${this.baseUrlExport}?gte=${period.startDate}&lte=${period.endDate}`);
                const exportData = new ExportDataEntity({
                    ...response.data,
                    progress: parseFloat((response.data.progress * 100).toFixed(2))
                });
                return resolve(exportData);

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    async exportPdf(idTicket: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const url = `${this.baseUrlExportPDF}?id=${idTicket}`;
                const response = await api.get(url, {responseType: 'blob'});
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                openFileInNewTab(file, 'Carregando pdf...');
                downloadFile(fileURL, `export_atendimento_${displayDateToLocale(new Date().toISOString())}.pdf`);
                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public exportSubscription(): Observable<ExportDataEntity> {
        return new Observable<ExportDataEntity>((observer: Observer<ExportDataEntity>) => {
            return this._exportDataSource.exportSubscription('tickets').subscribe({
                next: (response) => {
                    observer.next(response);
                },
                error(err: any) {
                    observer.error(err);
                }
            })
        });
    }

    getConfigs(): Promise<TicketConfig[]> {
        return new Promise<TicketConfig[]>(async (resolve, reject) => {
            try {
                const url: string = `${this.baseTicketUrl}getGlobalConfigs`;
                const response = await api.get(url);
                const configs = (response.data as Record<string, any>[]).map((json) => TicketConfig.fromJson(json));
                return resolve(configs);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

}
