import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import ExportDataToExcel from "@dropDesk/presentation/components/export_data_to_excel";
import {SectorController} from "@dropDesk/presentation/pages/sector/controller/sector.controller";
import toast_message from "@dropDesk/utils/toast_message/toast_message";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const ExportDataSector = (observer(() => {

    const [hasErrorConnectSocket, setHasErrorConnectSocket] = useState<boolean>(false);
    const [hasErrorConnectSocketTemporary, setHasErrorConnectSocketTemporary] = useState<boolean>(false);
    const [loadingReconnect, setLoadingReconnect] = useState<boolean>(false);
    const controller = useInjection(SectorController);
    const appController = useInjection(AppController);

    const initialize = async () => {
        await startExport();
    }

    useEffect(() => {
        initialize();
        return () => {
            controller.dispose();
        }
    }, []);

    const handleDisconnect = (temporary: boolean) => {
        if (temporary) {
            setHasErrorConnectSocketTemporary(true);
        } else {
            setHasErrorConnectSocket(true);
            setHasErrorConnectSocketTemporary(false);
        }
    }

    const handleReconnectSocket = async (): Promise<void> => {
        try {
            setLoadingReconnect(true);
            await initialize();
            setHasErrorConnectSocket(false);
            setLoadingReconnect(false);
            setHasErrorConnectSocketTemporary(false);
        } catch (e) {
            setHasErrorConnectSocket(true);
            setLoadingReconnect(false);
            toast_message.error('Não foi possível estabelecer a conexão. Por favor, tente novamente.' +
                ' Se o problema persistir, entre em contato com a equipe da DropDesk');
        }
    }

    const handleGetLastUpdates = async (): Promise<void> => {
        await handleReconnectSocket();
    }

    const startExport = async () => {
        await controller.export(handleDisconnect, handleGetLastUpdates, appController.user?.company.configurations.useSocket ?? false);
    }

    return (
        <ExportDataToExcel
            tableName={'setores'}
            loading={controller.loading}
            loadingMessage={controller.loadingMessage}
            initExport={() => startExport()}
            exportation={controller.exportation}
            handleReconnectSocket={handleReconnectSocket}
            hasErrorConnectSocket={hasErrorConnectSocket}
            hasErrorConnectSocketTemporary={hasErrorConnectSocketTemporary}
            loadingReconnect={loadingReconnect}
        />
    )

}));
export default ExportDataSector;
