import {useEffect, useState} from "react";
import {PanelAdmEntity} from "@dropDesk/domain/entities/company_configurations/panel_adm.entity";

export const useValidatorInputPanelAdm = (panelAdm?: PanelAdmEntity) => {

    const [errorMessageUniqueCode, setErrorMessageUniqueCode] = useState('');
    const [isValidDataForm, setIsValidDataForm] = useState<boolean>(false);


    useEffect(() => {
        validate();
    }, [panelAdm]);

    const validate = (): boolean => {
        setErrorMessageUniqueCode('');
        if (!panelAdm) {
            setIsValidDataForm(false);
            return false;
        }
        if (!panelAdm.uniqueCode) {
            setErrorMessageUniqueCode('Insira um código de referência');
            setIsValidDataForm(false);
            return false;

        } else {
            setIsValidDataForm(true);
            return true;
        }
    }

    return {
        errorMessageUniqueCode,
        validate,
        isValidDataForm
    }
};
