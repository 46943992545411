import {inject} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {injectable} from "inversify";
import {LoginUseCase} from "@dropDesk/domain/use_case/auth/sign_in.usecase";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {RecoveryAccountUseCase} from "@dropDesk/domain/use_case/auth/send_password_reset_email";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {LoginServerUseCase} from "@dropDesk/domain/use_case/auth/login_server.usecase";
import {LogoutByAdminUseCase} from "@dropDesk/domain/use_case/auth/logout_by_admin.usecase";
import {LogoutUseCase} from "@dropDesk/domain/use_case/auth/logout.usecase";
import firebaseAuth from "firebase/auth";
import {GetUserByFirebaseUseCase} from "@dropDesk/domain/use_case/auth/get_user_by_firebase.usecase";
import {GetUserByDecodedTokenUseCase} from "@dropDesk/domain/use_case/auth/get_user_by_decoded_token_firebase.usecase";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";

configure({
    enforceActions: "always",
});

@injectable()
export class LoginController {
    private readonly _signInuseCase: LoginUseCase;
    private readonly _loginServerUseCase: LoginServerUseCase;
    private readonly _recoveryAccount: RecoveryAccountUseCase;
    private readonly _logoutByAdminUseCase: LogoutByAdminUseCase;
    private readonly _logoutUseCase: LogoutUseCase;
    private readonly _getUserByFirebaseUseCase: GetUserByFirebaseUseCase;
    private readonly _getUserByDecodedTokenUseCase: GetUserByDecodedTokenUseCase;

    constructor(
        @inject(LoginUseCase) signInuseCase: LoginUseCase,
        @inject(RecoveryAccountUseCase) recoveryAccount: RecoveryAccountUseCase,
        @inject(LoginServerUseCase) loginServerUseCase: LoginServerUseCase,
        @inject(LogoutByAdminUseCase) logoutByAdminUseCase: LogoutByAdminUseCase,
        @inject(LogoutUseCase) logoutUseCase: LogoutUseCase,
        @inject(GetUserByFirebaseUseCase) getUserByFirebaseUseCase: GetUserByFirebaseUseCase,
        @inject(GetUserByDecodedTokenUseCase) getUserByDecodedTokenUseCase: GetUserByDecodedTokenUseCase,
    ) {
        makeObservable(this);
        this._signInuseCase = signInuseCase;
        this._recoveryAccount = recoveryAccount;
        this._loginServerUseCase = loginServerUseCase;
        this._logoutUseCase = logoutUseCase;
        this._logoutByAdminUseCase = logoutByAdminUseCase;
        this._getUserByFirebaseUseCase = getUserByFirebaseUseCase;
        this._getUserByDecodedTokenUseCase = getUserByDecodedTokenUseCase;
    }

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    userFirebase: firebaseAuth.User | null = null;

    @action
    setUserFirebase(user: firebaseAuth.User | null) {
        this.userFirebase = user;
    }

    @action
    recoveryAccount = async (email: string, onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading();
            await this._recoveryAccount.call(email);
            onSuccess("auth.success.recovery_account");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    login = async (loginEntity: { email: string; password: string; }, onSuccess: Function): Promise<void> => {
        try {
            this.startLoading("signIn.loading");
            await this._signInuseCase.call(loginEntity.email, loginEntity.password);
            this.stopLoading();
            onSuccess();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    loginServer = async (): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                this.startLoading("signIn.loading");
                await this._loginServerUseCase.call();
                this.stopLoading();
                return resolve();
            } catch (err: any) {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                this.stopLoading();
                return reject(err);
            }
        })
    };

    @action
    logout = async (onSuccess: () => void): Promise<void> => {
        try {
            this.startLoading("signIn.logout");
            await this._logoutUseCase.call();
            onSuccess();
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    logoutByAdmin = async (onSuccess: (key: string) => void, idUser: string): Promise<void> => {
        try {
            this.startLoading("signIn.loadingLogoutByAdmin");
            await this._logoutByAdminUseCase.call(idUser);
            this.stopLoading();
            onSuccess("signIn.success.logoutByAdmin");
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    getUserByFirebase = async (): Promise<firebaseAuth.User | null | undefined> => {
        try {
            const user = await this._getUserByFirebaseUseCase.call();
            this.setUserFirebase(user);
            return user;
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    getUserByDecodedToken = (): Record<string, unknown> | null => {
        return this._getUserByDecodedTokenUseCase.call();
    }

    @action
    async initializeConfirmEmail(onSuccessGetUser: (user: firebaseAuth.User | null) => void): Promise<void> {
        await this.getUserByFirebase();
        onSuccessGetUser(this.userFirebase);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }
}
