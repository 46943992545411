import React, {useEffect, useState} from "react";
import {useInjection} from "inversify-react";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {useNavigate, useParams} from "react-router-dom";
import {
    Container,
    ContentFooter,
    ContentFormUser,
    ContentHeaderAndInput,
    ContentPermission,
    ContentSectorsLinked,
    FormUser,
} from "./styled";
import {observer} from "mobx-react";
import HeaderFormUsers from "@dropDesk/presentation/pages/user/ui/form/header_form";
import InputsForm from "@dropDesk/presentation/pages/user/ui/form/inputs";
import Permissions from "@dropDesk/presentation/pages/user/ui/form/permissions";
import FooterFormUsers from "@dropDesk/presentation/pages/user/ui/form/footer_form";
import ModalsFormUsers from "@dropDesk/presentation/pages/user/ui/form/modals";
import FormSectors from "@dropDesk/presentation/pages/user/ui/form/sectors_linked";
import GenericScreenNotFound from "@dropDesk/presentation/components/generic_screen_not_found";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {UserEntity, UserExtraInfoEntity} from "@dropDesk/domain/entities/user/user.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {useValidatorInputUsers} from "@dropDesk/presentation/pages/user/ui/form/inputs/validator";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {MailProvider} from "@dropDesk/domain/entities/common/mail.entity";

const FormUsers = (observer(() => {

    const controller = useInjection(UserController);
    const appController = useInjection(AppController);
    const userLogged = appController.user!;
    const colors = appController.theme.colorScheme;
    const {id} = useParams();
    const isNewRegister = 'new';
    const navigate = useNavigate();
    const {t} = useTranslation();
    const isNewUser: boolean = id === isNewRegister && !appController.isUserClient;
    const realId = appController.isUserClient ? userLogged.id : id ?? isNewRegister;
    const [emailProvider, setEmailProvider] = useState<MailProvider>(MailProvider.sendgrid);

    const initialize = async () => {
        if (isNewUser) {
            controller.makeUser();
        } else {
            await controller.getUser(realId, appController.user?.role);
        }
    }

    useEffect(() => {
        initialize();
    }, []);

    const {isValidDataForm} = useValidatorInputUsers(controller.user);

    const handleSubmit = async () => {
        if (isValidDataForm) {
            await controller.set(userLogged, onUpdateCurrentUser, onSuccess);
        }
    };

    const onSuccess = (key: string) => {
        if (!appController.isUserClient) {
            navigate(-1);

        }
        toastMessage.success(t(key));
    }

    const onSuccessSendEmail = (key: string) => {
        setEmailProvider(MailProvider.nodemailer);
        toastMessage.success(t(key));
    }

    const onUpdateCurrentUser = (user: UserEntity) => {
        const currentUser = userLogged;
        const newUser = currentUser.copyWith({
            ...user,
            company: currentUser.company,
            client: currentUser.client,
            replaceUrlImageProfileIfNull: true
        });
        appController.setUser(newUser);

    }

    const handleAllChanges = (event: {
        target: { name: string; value: any; checked: any; type: string };
    }) => {
        const isExtraInfo = event.target.name.includes('extraInfo');
        const value = (event.target.type === "checkbox" || event.target.type === "switch") ? event.target.checked : event.target.value;
        const valueExtraInfo = isExtraInfo ? {[event.target.name.split(`.`)[1]]: value} : {};
        const _user = isExtraInfo
            ?
            controller.user!.copyWith({
                extraInfo: controller.user!.extraInfo ? controller.user!.extraInfo?.copyWith(valueExtraInfo) :
                    new UserExtraInfoEntity(valueExtraInfo)
            })
            :
            controller.user!.copyWith({
                [event.target.name]: value,
            });

        controller.setUser(_user);

    };


    return (
        <Container background={colors.onBackground}>

            <ContentFormUser>

                {controller.userNotFound === false &&
                    <FormUser isUserClient={appController.user?.isUserClient} background={colors.onBackground}
                              border={colors.border}
                              heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>

                        <ContentHeaderAndInput background={colors.onBackground}>

                            <HeaderFormUsers
                                loading={controller.loading}
                                title={appController.isUserClient
                                    ? 'Editando perfil'
                                    :
                                    id === isNewRegister ? "Cadastro de atendente" : "Editando atendente"
                                }
                                isUserClient={appController.isUserClient}
                            />
                            <InputsForm
                                onUpdate={(user) => controller.setUser(user)}
                                user={controller.user}
                                isCurrentUser={controller.isCurrentUser(userLogged, controller.user)}
                                userLogged={userLogged}
                                createObjectURLBlobUseCase={(file) => controller.createObjectURLBlobUseCase(file)}
                                clearUrlImage={() => controller.clearUrlImage()}
                                loading={controller.loading}
                                isNewUser={isNewUser}
                                permitChangeEmail={(!isNewUser && controller.currentUserIsAdmin(userLogged) && !controller.user?.userMain) || (controller.isCurrentUser(userLogged, controller.user))}
                                isUserClient={appController.isUserClient}
                            />

                        </ContentHeaderAndInput>

                        {!appController.isUserClient &&
                            <ContentPermission background={colors.onBackground}>
                                <Permissions
                                    handleAllChanges={handleAllChanges}
                                    changePermissionAdmin={(value) => controller.changePermissionAdmin(value)}
                                    user={controller.user}
                                    disableOnChangeCheckBox={!!controller.user?.userMain || !!controller.user?.permissionAdmin}
                                />
                            </ContentPermission>
                        }

                        {!appController.isUserClient && <ContentSectorsLinked background={colors.onBackground}>
                            <FormSectors
                                onChangeSectorsLinked={(sector) => controller.onChangeSectorsLinked(sector)}
                                onDeleteSectorsLinked={(id) => controller.onDeleteSectorsLinked(id)}
                                user={controller.user}
                                loading={controller.loading}
                                permissionScreenSectors={appController.user?.permissionScreenSectors ?? false}
                                usersSectorsEligible={controller.user?.sectors.filter((sector) => sector.action !== BackendAction.delete) ?? []}
                                usersSectorsDefault={controller.user?.sectors ?? []}
                            />
                        </ContentSectorsLinked>}

                        <ContentFooter background={colors.onBackground}>
                            <FooterFormUsers
                                handleSubmit={handleSubmit}
                                loading={controller.loading}
                                user={controller.user}
                                createdAt={controller.user?.createdAt && !appController.isUserClient ? `Criado em ${displayDateToLocale(controller.user.createdAt)}` : undefined}
                                isUserClient={appController.isUserClient}
                            />
                        </ContentFooter>

                        <ModalsFormUsers
                            handleAllChanges={handleAllChanges}
                            onDeleteUser={async () => {
                                controller.setRowSelectionUsers([controller.user!]);
                                await controller.delete(onSuccess);
                            }}
                            emailProvider={emailProvider}
                            changePermissionAdmin={(value) => controller.changePermissionAdmin(value)}
                            loading={controller.loading}
                            user={controller.user}
                            forgot={() => controller.forgot(controller.user?.email ?? '', onSuccessSendEmail, emailProvider === MailProvider.nodemailer)}
                            activateAccount={() => controller.activateAccount(controller.user?.email ?? '', onSuccessSendEmail, emailProvider === MailProvider.nodemailer)}
                            onCloseModalSendEmail={() => {
                                setEmailProvider(MailProvider.sendgrid);
                            }}
                        />

                    </FormUser>}

                {controller.userNotFound && <GenericScreenNotFound
                    textNotFound={'Oops, Usuário não encontrado ...'}
                    textHeaderForm={"Voltar"}
                />}

            </ContentFormUser>


            {controller.loading && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}
        </Container>
    );
}));

export default FormUsers;
