import {TypeSocket} from "@dropDesk/domain/entities/chat/chat_enum";

export class ChatSocketResponse {
    type!: TypeSocket;
    rooms!: string[];

    constructor({type, rooms}: {
        type: TypeSocket,
        rooms: string[];
    }) {
        Object.assign(this, {
            type,
            rooms
        });
    }

    public static fromJson(json: Record<string, any>): ChatSocketResponse {
        console.log('json', json);
        return new ChatSocketResponse({
            type: json['type'],
            rooms: json['data'] && json['data']['rooms'] ? json['data'] && json['data']['rooms'] : []
        });
    }
}
