import {observer} from "mobx-react";
import React from "react";
import ModalLogout from "@dropDesk/presentation/components/modals/modal_information";
import ModalRestoreMessages from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {
    useModalWhatsApp
} from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/handle_change_visible";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import ReadQrCode
    from "@dropDesk/presentation/pages/company_configurations/ui/whatsapp_configuration/modals/read_qr_code";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import ModalSearchSector from "@dropDesk/presentation/components/modals/modal_search_sector";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";

const ModalsWhatsApp = observer(
    ({
         logout,
         loading,
         chatConnection,
         dispose,
         startConnection,
         resetMessages,
         handleSelectSector,
         hasErrorConnectSocket,
         hasErrorConnectSocketTemporary,
         handleReconnectSocket,
         loadingReconnect
     }: {
        logout: () => void,
        loading: boolean
        chatConnection: ChatIntegrationConnectionEntity
        dispose: () => void
        resetMessages: () => void
        startConnection: () => Promise<void>
        handleSelectSector: (sector: SectorEntity) => void,
        hasErrorConnectSocket: boolean
        hasErrorConnectSocketTemporary: boolean
        loadingReconnect: boolean
        handleReconnectSocket: () => void
    }) => {

        const {
            visibleModalLogout, setVisibleModalLogout,
            visibleModalConnect, setVisibleModalConnect,
            visibleModalRestoreMessage, setVisibleModalRestoreMessage,
            visibleModalSearchSector, setVisibleModalSearchSector,
            visibleModalDuplicateConnection, setVisibleModalDuplicateConnection
        } = useBetween(useModalWhatsApp);

        return (
            <>

                <ModalRestoreMessages
                    open={visibleModalRestoreMessage}
                    onRequestClose={() => {
                        setVisibleModalRestoreMessage(false);
                    }}
                    textHeaderModal={'Redefinir mensagens'}
                    textButton={'Redefinir'}
                    onRequestSave={() => {
                        resetMessages();
                        setVisibleModalRestoreMessage(false);
                    }}
                    nameIcon={ICONS_DROPDESK.warning}
                    sizeIcon={130}
                    textInformation={
                        `Ao redefinir as mensagens, todas as mensagens serão redefinidas para o padrão DropDesk, deseja realmente redefinir as mensagens?`
                    }
                    loading={loading}
                    isActionDelete={true}
                />

                <ModalLogout
                    open={visibleModalLogout}
                    onRequestClose={() => {
                        setVisibleModalLogout(false);
                    }}
                    textHeaderModal={'Desconectar whatsapp'}
                    textButton={'Desconectar'}
                    onRequestSave={() => {
                        logout();
                        setVisibleModalLogout(false);
                    }}
                    nameIcon={ICONS_DROPDESK.warning}
                    sizeIcon={130}
                    textInformation={
                        `Ao remover a conexão do whatsapp, o DropDesk perde completamente o controle sobre suas mensagens, deseja realmente desconectar o whatsapp?`
                    }
                    loading={loading}
                    isActionDelete={true}
                />
                <ModalMain
                    open={visibleModalConnect}
                    onRequestClose={() => setVisibleModalConnect(false)}
                    width={768}
                    ignoreCloseOnClickOutside={true}
                    renderComponent={
                        <ReadQrCode
                            setVisibleModalConnect={setVisibleModalConnect}
                            chatConnection={chatConnection}
                            dispose={dispose}
                            startConnection={startConnection}
                            hasErrorConnectSocket={hasErrorConnectSocket}
                            hasErrorConnectSocketTemporary={hasErrorConnectSocketTemporary}
                            handleReconnectSocket={handleReconnectSocket}
                            loadingReconnect={loadingReconnect}
                        />
                    }
                />

                {visibleModalSearchSector && <ModalSearchSector
                    visible={visibleModalSearchSector}
                    onClose={() => setVisibleModalSearchSector(false)}
                    handleSelectSector={(sector) => {
                        handleSelectSector(sector);
                        setVisibleModalSearchSector(false);
                    }}
                    textHeaderModal={"Pesquisa de Setores"}
                    labelNoResults={"Nenhum setor encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por setores"}
                />}

                <ModalInformation
                    open={visibleModalDuplicateConnection}
                    onRequestClose={() => {
                        setVisibleModalDuplicateConnection(false)
                    }}
                    textHeaderModal={'Atenção: WhatsApp vinculado a outra conta'}
                    textButton={'OK'}
                    onRequestSave={() => {
                        setVisibleModalDuplicateConnection(false)
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={`Identificamos que o número de WhatsApp informado já está vinculado a outra conta
                     em nossa plataforma DropDesk. Deslogamos a conexão na outra conta e liberamos essa nova conexão.
                     Para garantir a segurança e a integridade dos seus atendimentos,
                     cada número de WhatsApp pode estar associado a apenas uma conta.
                     Se precisar de mais informações, entre em 
                     contato com nosso suporte. Estamos à disposição para resolver qualquer dúvida.`}
                    loading={loading}
                />

            </>
        )
    })
export default ModalsWhatsApp;
