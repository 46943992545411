import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";

export class ImportData {
    idCompany!: string;
    importId!: string;
    status!: StatusImport;
    progress!: number;
    rows!: number;
    createdAt?: string;
    revertedRows?: number;
    revertStatus?: StatusImport;
    revertedAt?: string;
    revertProgress?: number;

    static fromJson(json: Record<string, any>): ImportData {
        return new ImportData({
            idCompany: json['idCompany'],
            importId: json['importId'],
            status: json['status'],
            progress: json['progress'] ? parseFloat((json['progress'] * 100).toFixed(2)) : 0,
            rows: json['rows'] ?? 0,
            createdAt: json['createdAt'],
            revertedRows: json['revertedRows'],
            revertStatus: json['revertStatus'],
            revertedAt: json['revertedAt'],
            revertProgress: json['revertProgress'] ? parseFloat((json['revertProgress'] * 100).toFixed(2)) : undefined,
        });
    }

    public get inProgress(): boolean {
        return this.status === StatusImport.inProgress;
    }

    public get revertInProgress(): boolean {
        return this.revertStatus === StatusImport.inProgress;
    }

    public get done(): boolean {
        return this.status === StatusImport.done;
    }

    constructor({
                    idCompany,
                    importId,
                    status,
                    progress,
                    rows,
                    createdAt,
                    revertedRows,
                    revertStatus,
                    revertedAt,
                    revertProgress,
                }: {
        idCompany: string;
        importId: string;
        status: StatusImport;
        progress: number;
        rows: number;
        createdAt?: string;
        revertedRows?: number;
        revertStatus?: StatusImport;
        revertedAt?: string;
        revertProgress?: number;
    }) {
        Object.assign(this, {
            idCompany,
            importId,
            status,
            progress,
            rows,
            createdAt,
            revertedRows,
            revertStatus,
            revertedAt,
            revertProgress,
        });
    }

    copyWith({
                 idCompany,
                 importId,
                 status,
                 progress,
                 rows,
                 createdAt,
                 revertedRows,
                 revertStatus,
                 revertedAt,
                 revertProgress,
             }: {
        idCompany?: string;
        importId?: string;
        status?: StatusImport;
        progress?: number;
        rows?: number;
        createdAt?: string;
        revertedRows?: number;
        revertStatus?: StatusImport;
        revertedAt?: string;
        revertProgress?: number;
    }): ImportData {
        return new ImportData({
            idCompany: idCompany ?? this.idCompany,
            importId: importId ?? this.importId,
            status: status ?? this.status,
            progress: progress ?? this.progress,
            rows: rows ?? this.rows,
            createdAt: createdAt ?? this.createdAt,
            revertedRows: revertedRows ?? this.revertedRows,
            revertStatus: revertStatus ?? this.revertStatus,
            revertedAt: revertedAt ?? this.revertedAt,
            revertProgress: revertProgress ?? this.revertProgress,
        })
    }
}
