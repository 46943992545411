import {
    Container,
    Content,
    ContentButtonSend,
    ContentConfirmEmail,
    ContentPin,
    ContentReSend,
    ContentSubTitle,
    ContentWelcomeAndClosedButton,
    DivButtonSend,
    Section,
    SubTitleConfirmEmail,
    TextLink,
    TextSubTitle,
    TextWeight,
    TextWelcome,
    TitleConfirmEmail
} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PinField} from "@dropDesk/presentation/components/pin_field";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {ModalChangeEmail} from "@dropDesk/presentation/pages/change_email/ui";
import activateImage from "@dropDesk/storage/images/activate.png";
import AnimatedImage from "@dropDesk/presentation/pages/change_password/ui/animate_image";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {LoginController} from "@dropDesk/presentation/pages/login/controller/login.controller";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import firebaseAuth from "firebase/auth";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {MailProvider} from "@dropDesk/domain/entities/common/mail.entity";

const ConfirmEmail = observer(() => {

    const [token, setToken] = useState("");
    const [hasSendEmail, setHasSendEmail] = useState(false);
    const [emailProvider, setEmailProvider] = useState<MailProvider>(MailProvider.sendgrid);
    const [visibleModalChangeEmail, setVisibleModalChangeEmail] = useState(false);
    const [visibleModalLogout, setVisibleModalLogout] = useState(false);
    const appController = useInjection(AppController);
    const loginController = useInjection(LoginController);
    const colors = appController.theme.colorScheme;
    const userController = useInjection(UserController);
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        preventUnauthorizedAccess();
        loginController.initializeConfirmEmail(onSuccessGetUser);
    }, []);

    const preventUnauthorizedAccess = (): void => {
        if (appController.user && appController.user.verified) {
            navigate(appController.user.role === UserRole.userClient ? RoutesEnum.tickets : RoutesEnum.dashboard, {replace: true});
        }
    }

    const onSuccessGetUser = async (user: firebaseAuth.User | null) => {
        if (user) {
            await userController.activateAccount(
                user.email!,
                (key) => onSuccessSendEmail(key, '', false)
            );
        } else {
            navigate(RoutesEnum.login, {replace: true});
        }

    }
    const setVisibleModal = () => {
        setVisibleModalChangeEmail(!visibleModalChangeEmail)
    }

    const onSuccessSendEmail = (key: string, email: string, withSetSendEmailRestriction = true) => {
        if (withSetSendEmailRestriction) {
            if (emailProvider === MailProvider.nodemailer) {
                setHasSendEmail(true);
            }
            setEmailProvider(MailProvider.nodemailer);
        }
        toastMessage.success(t(key));
    }

    const onSuccessLogout = () => {
        appController.setUser(null);
    }

    const onSuccessConfirmEmail = async (key: string) => {
        toastMessage.success(t(key));
        await loginController.loginServer();
        const route = await appController.getCurrentUser();
        setVerifyCurrentUser();
        navigate(route, {replace: true});
    }

    const setVerifyCurrentUser = () => {
        const userLogged = appController.user!.copyWith({
            verified: true
        });
        appController.setUser(userLogged);
    }

    const handleSendToken = async () => {
        if (token.length === 6) {
            await userController.firstAccess(token, onSuccessConfirmEmail);
        }
    }

    return (
        <Container>

            <AnimatedImage
                imageSource={activateImage}
                title={"Confirmação de conta"}
                subTitle={`Para que você tenha toda segurança dentro de nossa plataforma, precisamos que confirme seu email.`}
            />

            <Content background={colors.delicateBackground} border={colors.border}>
                <Section>
                    <ContentWelcomeAndClosedButton>
                        <TextWelcome color={colors.text}>Seu prazo para confirmar e-mail
                            expirou.</TextWelcome>
                    </ContentWelcomeAndClosedButton>

                    <ContentSubTitle>
                        <TextSubTitle color={colors.text}>Confirme seu email, para
                            sua total segurança, siga o passo
                            abaixo.</TextSubTitle>
                    </ContentSubTitle>

                    <ContentConfirmEmail background={colors.tertiary} border={colors.border}>
                        <TitleConfirmEmail color={colors.text}>
                            Confirmar email
                        </TitleConfirmEmail>
                        <SubTitleConfirmEmail color={colors.text}>
                            Enviamos um <TextWeight>código</TextWeight> para o
                            e-mail <TextWeight>{loginController.userFirebase?.email ?? ''}</TextWeight>, para
                            verificação. Consulte seu e-mail e
                            informe o código para
                            confirmar e ativar sua conta:
                        </SubTitleConfirmEmail>

                        <ContentPin>
                            <PinField
                                noOfBoxes={6}
                                autoFocus={true}
                                disabled={userController.loading}
                                onChange={(token) => {
                                    setToken(token);
                                }}
                                useButtonClear={false}
                                acceptOnlyNumbers={true}
                            />
                        </ContentPin>

                        <ContentButtonSend>

                            <DivButtonSend>

                                <ButtonMain
                                    letterFontSize={16}
                                    widthPercentage={100}
                                    disabled={token.length < 6 || userController.loading}
                                    loading={userController.loading}
                                    color={colors.accent}
                                    height={45}
                                    type={"submit"}
                                    letter={"Enviar"}
                                    onClick={() => handleSendToken()}
                                />

                            </DivButtonSend>

                        </ContentButtonSend>

                    </ContentConfirmEmail>

                    <ContentReSend>
                        <SubTitleConfirmEmail color={colors.text}>
                            Se você não tiver recebido o e-mail, procure na pasta de spam ou&nbsp;
                            {!hasSendEmail && <TextLink
                                disabled={userController.loading}
                                onClick={async () => {
                                    await userController.activateAccount(loginController.userFirebase!.email!, onSuccessSendEmail, emailProvider === MailProvider.nodemailer);
                                }}
                                color={colors.text}
                            >{emailProvider === MailProvider.sendgrid ? 'reenvie o e-mail.' : '( não recebeu o e-mail? Clique aqui para tentar novamente ).'}</TextLink>}
                            {hasSendEmail && <SubTitleConfirmEmail color={colors.text}>
                                e-mail já foi enviado por favor verifique.
                            </SubTitleConfirmEmail>
                            }
                        </SubTitleConfirmEmail>

                        <SubTitleConfirmEmail color={colors.text}>
                            Quer alterar o seu endereço de e-mail?<TextLink
                            onClick={setVisibleModal}
                            color={colors.text}>&nbsp;alterar</TextLink>
                        </SubTitleConfirmEmail>

                        <SubTitleConfirmEmail color={colors.text}>
                            Deseja sair do sistema?
                            <TextLink
                                onClick={() => setVisibleModalLogout(true)}
                                color={colors.text}>&nbsp;sair do sistema</TextLink>
                        </SubTitleConfirmEmail>
                    </ContentReSend>
                </Section>
            </Content>

            {loginController.userFirebase && loginController.userFirebase.email && <ModalChangeEmail
                visibleModalChangeEmail={visibleModalChangeEmail}
                setVisibleModalChangeEmail={setVisibleModal}
                oldEmail={loginController.userFirebase?.email}
                isCurrentUserChanged={true}
            />}

            <ModalInformation
                open={visibleModalLogout}
                onRequestClose={() => {
                    setVisibleModalLogout(false);
                }}
                textHeaderModal={'Sair do sistema'}
                textButton={'Sair'}
                onRequestSave={async () => {
                    await loginController.logout(onSuccessLogout);
                    navigate(RoutesEnum.login, {replace: true});
                    setVisibleModalLogout(false);
                }}
                nameIcon={ICONS_DROPDESK.logout}
                sizeIcon={130}
                textInformation={`Deseja realmente sair do sistema?`}
                loading={loginController.loading}
            />

            {(userController.loading || loginController.loading) && <DropDeskLoading
                height={250}
                description={userController.loadingMessage ?? loginController.loadingMessage}
            />}
        </Container>
    );
});
export default ConfirmEmail;
