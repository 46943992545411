import {useEffect} from "react";
import {AudioPlayerType} from "@dropDesk/presentation/components/audio_player/audio_player_";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {AudioStyled} from "@dropDesk/presentation/components/audio_player/styled";

const AudioPlayer = observer((props: AudioPlayerType) => {

    const appController = useInjection(AppController);

    useEffect(() => {
        const audioPlayer: HTMLAudioElement | null = document.getElementById(`audio${props.id}`) as HTMLAudioElement;
        if (audioPlayer) {
            const playback = appController.audioPlaybackRate;
            audioPlayer.playbackRate = playback;
        }
    }, [appController.audioPlaybackRate]);


    return (
        <AudioStyled
            id={`audio${props.id}`}
            onRateChange={(event) => {
                appController.setPlayBlackRate(event.currentTarget.playbackRate ?? appController.audioPlaybackRate);
            }}
            src={props.src ?? undefined}
            controls={true}
            preload={"metadata"}
        />
    )
});
export default AudioPlayer;
