import {Container, Content, ContentDescription, Description, Image} from "./styled";
import React from "react";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import FooterModal from "@dropDesk/presentation/components/footer/footer_modal";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import paymentExpired from "@dropDesk/storage/images/credit-card-expired.png";

const ModalSubscriptionOverdue = (observer((
    {
        visibleModalSubscriptionOverdue,
        onRequestClose,
        onSubmit
    }: {
        visibleModalSubscriptionOverdue: boolean
        onRequestClose: () => void
        onSubmit: () => void
    }) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <ModalMain
            open={visibleModalSubscriptionOverdue}
            onRequestClose={onRequestClose}
            width={500}
            ignoreCloseOnClickOutside={true}
            renderComponent={
                <Container background={colors.onBackground}>

                    <HeaderModal
                        disabled={false}
                        letter={'Pagamento atrasado'}
                        nameIcon={ICONS_DROPDESK.close}
                        onClick={onRequestClose}
                    />

                    <Content>
                        <Image src={paymentExpired} alt={"Imagem Pagamento expirado"}/>
                    </Content>
                    <ContentDescription>
                        <Description color={colors.text}>
                            Sua conta está atualmente vencida. Por favor, conclua o pagamento para garantir
                            que não haja interrupção em sua conta clicando em Verificar.
                        </Description>
                    </ContentDescription>

                    <FooterModal
                        disabled={false}
                        loading={false}
                        type={"submit"}
                        letter={"Verificar"}
                        onSubmit={onSubmit}
                        onRequestClose={onRequestClose}
                    />
                </Container>}
        />
    )
}));
export default ModalSubscriptionOverdue;
