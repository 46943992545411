import {useState} from "react";

export const useModalConnectionExceeded = () => {

    const [visibleModalLogoutByAdmin, setVisibleModalLogoutByAdmin] = useState<boolean>(false);
    const [visibleModalConnectionExceeded, setVisibleModalConnectionExceeded] = useState<boolean>(false);

    return {
        visibleModalLogoutByAdmin,
        setVisibleModalLogoutByAdmin,
        visibleModalConnectionExceeded, setVisibleModalConnectionExceeded
    }
}
