import {ImportData} from "@dropDesk/domain/entities/import_data/import_data";

export class SocketImportResponse {
    type!: TypeImportSocket;
    importData?: ImportData;

    constructor({
                    importData,
                    type
                }: {
        importData?: ImportData;
        type: TypeImportSocket;

    }) {
        Object.assign(this, {
            importData,
            type
        });
    }

    public static fromJson(json: Record<string, any>): SocketImportResponse {
        return new SocketImportResponse({
            type: json.type,
            importData: json.data?.import ? ImportData.fromJson(json.data?.import) : undefined,
        });
    }

    public get hasUnknownError() {
        return this.type === TypeImportSocket.unknown;
    }
}

export enum TypeImportSocket {
    unknown = 'unknown',
    import = 'import'
}