import {observer} from "mobx-react";
import {
    Container, ContainerInfo, ContainerPayment, ContainerPaymentInfo,
    Content,
} from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/styled";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import React, {useEffect} from "react";
import {useBetween} from "use-between";
import {useModalsSubscription} from "@dropDesk/presentation/pages/subscription/ui/modals/visible_modal_subscription";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import DividerDropDesk from "@dropDesk/presentation/components/divider";
import InputPaymentInfo from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/input_payment_info";
import CopyAndPastePix from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/copy_and_paste";
import InfoMessage from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/info_message";
import ResumeQrCode from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/qr_code";
import ButtonsInvoice from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/buttons_invoice";

const PayInfo = observer(
    ({
         lastPayment,
         newSubscription,
         labelNewPeriod,
         disposePaymentSubscription,
         onClickDownloadInvoice,
         isPix
     }: {
        lastPayment: PaymentEntity;
        newSubscription: PaymentSubscriptionEntity
        labelNewPeriod: string
        disposePaymentSubscription: () => void
        onClickDownloadInvoice: (payment: PaymentEntity) => void
        isPix: boolean
    }) => {

        useEffect(() => {
            return () => {
                disposePaymentSubscription();
            }
        }, []);

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const {
            setVisibleModalDisplayDataPix, setVisibleModalDisplayBoleto
        } = useBetween(useModalsSubscription);


        return (
            <Container>
                <HeaderModal
                    disabled={false}
                    letter={'Concluir pagamento'}
                    nameIcon={ICONS_DROPDESK.close}
                    onClick={() => {
                        setVisibleModalDisplayDataPix(false);
                        setVisibleModalDisplayBoleto(false);
                    }}
                />
                <Content background={colors.onBackground}>
                    <DividerDropDesk/>
                    <ContainerPaymentInfo>
                        <ContainerInfo>
                            <InputPaymentInfo
                                lastPayment={lastPayment}
                                labelNewPeriod={labelNewPeriod}
                                newSubscription={newSubscription}
                            />
                            {isPix && lastPayment.pix?.qrcodeText &&
                                <CopyAndPastePix qrcodeText={lastPayment.pix.qrcodeText} isPix={isPix}/>
                            }

                            {!isPix &&
                                lastPayment.boleto?.digitableLine &&
                                lastPayment.boleto?.document &&
                                lastPayment.pix?.qrcodeText &&
                                <ButtonsInvoice
                                    digitableLine={lastPayment.boleto.digitableLine}
                                    onClickDownloadInvoice={() => {
                                        onClickDownloadInvoice(lastPayment);
                                    }}
                                    inverse={true}
                                />
                            }
                            <InfoMessage
                                text={!isPix ? lastPayment.messageTimeProcessPaymentBoleto :
                                    lastPayment.messageTimeProcessPaymentPix}/>
                        </ContainerInfo>
                        <ContainerPayment>
                            <ResumeQrCode
                                qrCode={lastPayment.pix?.qrcode}
                                qrCodeText={!isPix ? lastPayment.pix?.qrcodeText : undefined}
                            />
                        </ContainerPayment>
                    </ContainerPaymentInfo>
                </Content>
            </Container>
        )
    })
export default PayInfo;
