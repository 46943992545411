import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export class ExportDataTicketsUseCase {
  private _repository: TicketRepository;

  constructor(@inject(TicketRepository) repository: TicketRepository) {
    this._repository = repository;
  }

  public call(period: { startDate: string, endDate: string }, id?: string): Promise<ExportDataEntity> {
    return this._repository.export(period, id);
  }
}

