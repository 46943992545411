import {BillingInfoHolder, CompanyEntity, CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import {CompanyOrigin} from "@dropDesk/domain/entities/company/company_enum";
import {
    newCompanyConfiguration
} from "@dropDesk/presentation/pages/company_configurations/controller/new_configuration";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {PaymentProvider, PixType} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {PaymentSubscriptionOrigin} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription.enum";
import {newAddress} from "@dropDesk/presentation/pages/client/controller/new_client";
import {ClientJuridicalNature} from "@dropDesk/domain/entities/client/client_enum";
import {
    BoletoEntity,
    PixEntity,
    TokenizeCardEntity
} from "@dropDesk/domain/entities/payment_method/payment_method.entity";

export const newCompany = () => new CompanyEntity({
    configurations: newCompanyConfiguration(),
    id: '',
    name: '',
    document: '',
    origin: CompanyOrigin.organic,
    uniqueCode: Math.floor(Date.now() * Math.random()),
    contract: false,
    paymentSubscriptions: [
        new PaymentSubscriptionEntity({
            daysToExpiration: 0,
            id: '',
            dayDue: 0,
            idPlan: "",
            nextDueDate: "",
            origin: PaymentSubscriptionOrigin.owner,
            planUserMultiplier: 1,
            provider: PaymentProvider.iugu
        })
    ]
});

export const newTokenizeCardEntity = () => new TokenizeCardEntity({
    number: '',
    expirationDate: '',
    cvv: '',
    holderName: ''
});

export const newPixEntity = () => new PixEntity({
    type: PixType.static
});

export const newBoletoEntity = () => new BoletoEntity({
    use: true
});

export const newCompanyPaymentInfo = () => new CompanyPaymentInfo({
    billingAddress: newAddress(),
    billingInfoHolder: new BillingInfoHolder({
        name: '',
        document: '',
        email: '',
        phone: '',
        receiveInvoices: false,
        juridicalNature: ClientJuridicalNature.juridical
    }),
});

