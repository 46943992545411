import {SectorEntity} from "../sector/sector.entity";
import {UserEntity} from "../user/user.entity";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {TicketEvaluationEntity} from "@dropDesk/domain/entities/ticket/evaluation/ticket_evaluation.entity";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import {TicketConfig} from "@dropDesk/domain/entities/ticket/ticket_config";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {TicketLogsEntity} from "@dropDesk/domain/entities/ticket/ticket_logs.entity";
import {
    InfoOrigin,
    InfoOriginDetail,
    InfoOriginDetailToTranslate,
    InfoOriginToTranslate
} from "@dropDesk/domain/entities/generic/info_origin.enum";
import {displayDateToLocale, isValidDateFromNowTime} from "@dropDesk/utils/helpers/date_helper";
import {TicketStatusSubType, TicketStatusType} from "@dropDesk/domain/entities/ticket/ticket_maps";
import {TicketNotificationEntity} from "@dropDesk/domain/entities/ticket/ticket_notifications_entity";
import {ChatOperationsEntity} from "@dropDesk/domain/entities/ticket/chat_operations_entity";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";

export class TicketEntity {
    id!: string;
    idCompany!: string;
    idSector!: string;
    sector?: SectorEntity;
    dateDue?: string | null;
    description!: string;
    idDescriptionStatic?: string;
    descriptionStatic?: TicketsDescriptionStaticEntity;
    number!: number;
    timeSpent?: number;
    timeFirstResponse?: number;
    origin!: InfoOrigin;
    originDetail?: InfoOriginDetail;
    users!: Record<string, boolean>;
    idOpenedByUser!: string;
    openedByUser?: UserEntity;
    updatedAt?: string;
    updateOp?: string;
    messages!: TicketMessageEntity[];
    lastMessage?: TicketMessageEntity;
    evaluations?: TicketEvaluationEntity[];
    notifications?: TicketNotificationEntity[];
    status!: string;
    priority!: string;
    resolutionTime?: string;
    createdAt?: string;
    dateClosed?: string;
    deleted?: boolean;
    idClient!: string;
    client?: ClientEntity;
    usersInfo?: UserEntity[];
    descriptionClosed?: string;
    statusObject?: TicketConfig;
    priorityObject?: TicketConfig;
    lastMessageId?: string;
    logs?: TicketLogsEntity[];
    soundPlayed?: boolean;
    isMeStartingChat?: boolean;
    countUnreadNotification?: number;
    chatOperations?: ChatOperationsEntity;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    public realDescription(useDescriptionStatic: boolean, loading?: boolean): string {
        return (loading && (useDescriptionStatic && !this.descriptionStatic) || (!useDescriptionStatic && !this.description && !this.descriptionStatic))
            ?
            'Carregando...'
            :
            ((useDescriptionStatic && this.descriptionStatic) || (!this.description && this.descriptionStatic)) ?
                this.descriptionStatic.description
                :
                this.description;
    }

    public transferIfEmptySector(user: UserEntity): boolean {
        return this.hasOpenStatus && user.permissionAdmin && !this.sector && user.isAttendant;
    }

    public get realDescriptionClosed() {
        return this.descriptionClosed === 'idle_ticket_closed_by_assistant' ? 'Atendimento encerrado devido à inatividade pela assistente virtual.' : this.descriptionClosed;
    }

    public get attendant(): UserEntity | undefined {
        return this.usersInfo?.find((entry) => entry.role === UserRole.attendant && entry.id !== process.env.VIRTUAL_ASSISTANT_ID);
    }

    public get userClient(): UserEntity | undefined {
        return this.usersInfo?.find((entry) => entry.role === UserRole.userClient);
    }

    public get hasActiveStatus(): boolean {
        return this.hasOpenStatus || this.hasAttendingStatus;
    }

    public get overdue(): boolean {
        return !!this.dateDue && !isValidDateFromNowTime(this.dateDue, true);
    }

    public get hasOpenStatus(): boolean {
        return this.statusObject?.extra?.type === TicketStatusType.opened;
    }

    public isEligibleRating(user: UserEntity): boolean {
        if (user.isUserClient) {
            return user.company.configurations.ticket.useRatingTicket;
        }
        return !!this.chatOperations?.idTicketReference &&
            !this.chatOperations.evaluate?.evaluated && this.attendant?.id === user.id &&
            !!this.chatOperations.evaluate?.canEvaluate;
    }

    public isEligibleListenerTicket(userLogged: UserEntity): boolean {
        return this.hasActiveStatus && (!this.attendant || this.attendant.id !== userLogged.id);
    }

    public isEligibleToRemoveListener(userLogged: UserEntity): boolean {
        return (!this.attendant || this.attendant.id !== userLogged.id);
    }

    public hasEligibleChat(user: UserEntity): boolean {
        return (this.users[user.id] && this.hasAttendingStatus) || (this.hasOpenStatus && (user.sectors.some(item => item.idSector === this.idSector) || !!user.userMain));
    }

    public get hasAttendingStatus(): boolean {
        return this.statusObject?.extra?.type === TicketStatusType.inProgress;
    }

    public get hasClosedStatus(): boolean {
        return this.statusObject?.extra?.type === TicketStatusType.closed;
    }

    public get closedByInactiveTime(): boolean {
        return this.statusObject?.extra?.type === TicketStatusType.closed && this.statusObject?.extra?.subType === TicketStatusSubType.byInactive;
    }

    public get hasCanceledStatus(): boolean {
        return this.statusObject?.extra?.type === TicketStatusType.canceled;
    }

    public get hasWaitingReview(): boolean {
        return this.statusObject?.extra?.subType === TicketStatusSubType.byWaitingReview;
    }


    public get getOrigin(): string {
        return `criado via ${InfoOriginToTranslate[this.origin as keyof typeof InfoOriginToTranslate]}${this.originDetail ? ` através do ${InfoOriginDetailToTranslate[this.originDetail as keyof typeof InfoOriginDetailToTranslate]}` : ""}`;
    }


    public get getStatus(): string {
        if (this.hasOpenStatus) {
            return "Aberto";
        }

        if (this.hasAttendingStatus) {
            return "Atendendo";
        }

        if (this.hasCanceledStatus) {
            return "Cancelado";
        }

        return "Fechado";
    }

    public canChangeTicket = (userLogged: UserEntity): boolean => {
        const ImAttending = this.attendant?.id === userLogged.id || this.userClient?.id === userLogged.id;
        const isTicketFromMySector = userLogged.sectors.filter(
            (entry) => entry.idSector === this.idSector,
        ).length > 0;
        const hasAttendant = !!this.attendant;
        return (
            (this.transferIfEmptySector(userLogged)) ||
            (this.hasActiveStatus && ImAttending) ||
            (this.hasActiveStatus && userLogged.permissionAdmin) ||
            (this.hasOpenStatus && isTicketFromMySector && !hasAttendant && !userLogged.isUserClient)
        );
    };

    public canSendMessage = (userLogged: UserEntity): boolean => {
        const hasActiveStatus = this.hasActiveStatus;
        const ImAttending = this.attendant?.id === userLogged.id || this.userClient?.id === userLogged.id;
        return (((hasActiveStatus && ImAttending) || (this.hasOpenStatus)) && !!this.idSector)
    };

    public preventDeleteRelations(oldTicket: TicketEntity): TicketEntity {
        this.descriptionStatic = this.descriptionStatic ?? oldTicket.descriptionStatic;
        this.idClient = this.idClient ?? oldTicket.idClient;
        this.client = this.client ?? oldTicket.client;
        return this;
    }

    public canAccess = (user: UserEntity): boolean => {
        const eligibleUserClient = user.isUserClient && this.userClient?.id === user.id;
        const isTicketFromMySector = user.sectors.filter(
            (entry) => entry.idSector === this.idSector,
        ).length > 0;
        const justListUserTicket = user.extraInfo?.permissionJustListUserTicket;
        const ImAttending = this.attendant?.id === user.id;

        return !this.deleted && (
            eligibleUserClient || (
                (justListUserTicket && this.hasOpenStatus && isTicketFromMySector) ||
                (justListUserTicket && ImAttending) ||
                (user.permissionAdmin && (isTicketFromMySector || !this.idSector)) ||
                (user.userMain) ||
                (!justListUserTicket && isTicketFromMySector)
            )
        );
    };

    public get getPriority(): string {
        const typeToHuman: Record<string, string> = {
            'urgent': 'Urgente',
            'high': 'Alta',
            'normal': 'Normal',
            'low': 'Baixa',
        }

        const key: string = this.priorityObject?.extra?.type ?? '';
        return typeToHuman[key] ?? "unknown";
    }

    public get closingServiceTicketHeaderText(): string {
        return `Fim do atendimento #[ ${this.number} ]# fechado em: ${displayDateToLocale(this.dateClosed!)}`;
    }

    public get startingServiceTicketHeaderText(): string {
        return `Início do atendimento #[ ${this.number} ]# aberto em: ${displayDateToLocale(this.createdAt!)}`;
    }

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = [
            'sector', 'client', 'usersInfo',
            'statusObject', 'priorityObject', 'descriptionStatic',
            'evaluations', 'messages', 'soundPlayed', 'isMeStartingChat', 'countUnreadNotification', 'openedByUser',
            'notifications'];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    public toFillDataLocalStorage(): Record<string, any> {
        return {
            idSector: this.idSector,
            sector: this.sector,
            attendant: this.attendant,
            idAttendant: this.attendant?.id,
            priority: this.priority,
            priorityObject: this.priorityObject,
            dateDue: this.dateDue,
            description: this.description,
            idDescriptionStatic: this.idDescriptionStatic,
            descriptionStatic: this.descriptionStatic
        }
    }

    static fromJson(json: Record<string, any>): TicketEntity {
        const ticket = new TicketEntity({
            id: json['id'] as string,
            idCompany: json['idCompany'] as string,
            idSector: json['idSector'] as string,
            idClient: json['idClient'] as string,
            sector: json['sector'] ? new SectorEntity({
                ...json['sector']
            }) : undefined,
            dateDue: json['dateDue'] ?? null,
            description: json['description'],
            idDescriptionStatic: json['idDescriptionStatic'] as string | undefined,
            descriptionStatic: json['descriptionStatic']
                ? new TicketsDescriptionStaticEntity({
                    ...json['descriptionStatic']
                })
                : undefined,
            number: json['number'] as number,
            timeSpent: json['timeSpent'] ?? null,
            timeFirstResponse: json['timeFirstResponse'] ?? null,
            origin: json['origin'] as InfoOrigin,
            originDetail: json['originDetail'] as InfoOriginDetail,
            users: json['users'] as Record<string, boolean>,
            idOpenedByUser: json['idOpenedByUser'] as string,
            openedByUser: json['openedByUser']
                ? new UserEntity({
                    ...json['openedByUser']
                })
                : undefined,
            updatedAt: json['updatedAt'] ?? null,
            updateOp: json['updateOp'] ?? null,
            logs: json['logs']
                ? (json['logs'] as any[]).map((log) => TicketLogsEntity.fromJson(log))
                : [],
            messages: json['messages']
                ? (json['messages'] as Record<string, any>[]).map((entry) =>
                    TicketMessageEntity.fromJson(entry),
                )
                : [],
            lastMessage: json['lastMessage']
                ? TicketMessageEntity.fromJson(json['lastMessage'])
                : undefined,
            evaluations: json['evaluations'] ? (json['evaluations'] as any[]).map((evaluation) => TicketEvaluationEntity.fromJson(evaluation)) : undefined,
            notifications: json['notifications'] ? (json['notifications'] as any[]).map((notifications) => TicketNotificationEntity.fromJson(notifications)) : undefined,
            status: json['status'] as string,
            priority: json['priority'] as string,
            resolutionTime: json['resolutionTime'] as string | undefined,
            createdAt: json['createdAt'] as string | undefined,
            dateClosed: json['dateClosed'] && json['dateClosed'] !== '' ? json['dateClosed'] : null,
            client: json['client'] ? new ClientEntity({
                ...json['client']
            }) : undefined,
            usersInfo: json['usersInfo']
                ? (json['usersInfo'] as any[]).map((entry) =>
                    new UserEntity({
                        ...entry
                    }),
                )
                : undefined,
            descriptionClosed: json['descriptionClosed'] as string | undefined,
            statusObject: json['statusObject']
                ? new TicketConfig({
                    ...json['statusObject']
                })
                : undefined,
            priorityObject: json['priorityObject']
                ? new TicketConfig({
                    ...json['priorityObject']
                })
                : undefined,
            deleted: (json['deleted'] as boolean | null) ?? false,
            lastMessageId: json['lastMessageId'] as string | undefined,
            soundPlayed: json['soundPlayed'] as boolean | undefined,
            isMeStartingChat: json['isMeStartingChat'] as boolean | undefined,
            countUnreadNotification: (json['countUnreadNotification'] as number | undefined) ?? 0,
            chatOperations: json['chatOperations'] as ChatOperationsEntity | undefined
        });

        ticket.description ??= '';
        return ticket;
    }

    constructor({
                    id,
                    idCompany,
                    idSector,
                    sector,
                    dateDue,
                    description,
                    idDescriptionStatic,
                    descriptionStatic,
                    number,
                    timeSpent,
                    timeFirstResponse,
                    origin,
                    originDetail,
                    dateClosed,
                    users,
                    idOpenedByUser,
                    openedByUser,
                    updatedAt,
                    updateOp,
                    messages,
                    lastMessage,
                    evaluations,
                    notifications,
                    status,
                    priority,
                    resolutionTime,
                    createdAt,
                    deleted,
                    client,
                    usersInfo,
                    descriptionClosed,
                    idClient,
                    statusObject,
                    priorityObject,
                    lastMessageId,
                    logs,
                    soundPlayed,
                    isMeStartingChat,
                    countUnreadNotification,
                    chatOperations
                }: {
        id: string;
        idCompany: string;
        idSector: string;
        sector?: SectorEntity;
        dateDue?: string | null;
        description: string;
        idDescriptionStatic?: string;
        descriptionStatic?: TicketsDescriptionStaticEntity;
        number: number;
        timeSpent?: number;
        timeFirstResponse?: number;
        origin: InfoOrigin;
        originDetail?: InfoOriginDetail;
        users: Record<string, boolean>;
        idOpenedByUser: string;
        openedByUser?: UserEntity;
        updatedAt?: string;
        updateOp?: string;
        messages: TicketMessageEntity[];
        lastMessage?: TicketMessageEntity;
        evaluations?: TicketEvaluationEntity[];
        notifications?: TicketNotificationEntity[];
        status: string;
        priority: string;
        resolutionTime?: string;
        createdAt?: string;
        dateClosed?: string;
        deleted?: boolean;
        client?: ClientEntity;
        idClient: string;
        usersInfo?: UserEntity[];
        descriptionClosed?: string;
        statusObject?: TicketConfig;
        priorityObject?: TicketConfig;
        lastMessageId?: string;
        logs?: TicketLogsEntity[];
        soundPlayed?: boolean;
        isMeStartingChat?: boolean;
        countUnreadNotification?: number;
        chatOperations?: ChatOperationsEntity;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            idSector,
            sector,
            dateDue,
            description,
            idClient,
            idDescriptionStatic,
            descriptionStatic,
            number,
            timeSpent,
            timeFirstResponse,
            origin,
            originDetail,
            users,
            idOpenedByUser,
            openedByUser,
            updatedAt,
            updateOp,
            messages,
            lastMessage,
            evaluations,
            notifications,
            status,
            priority,
            resolutionTime,
            createdAt,
            dateClosed,
            deleted,
            client,
            usersInfo,
            descriptionClosed,
            statusObject,
            priorityObject,
            lastMessageId,
            logs,
            soundPlayed,
            isMeStartingChat,
            countUnreadNotification,
            chatOperations
        });
    }

    private getUpdatedUserData(role: UserRole, user?: UserEntity, clearUserClient?: boolean): {
        usersUpdated: Record<string, boolean>;
        usersInfoUpdated: UserEntity[];
    } {
        if (!user && !clearUserClient) return {
            usersUpdated: (this.users ?? {}),
            usersInfoUpdated: (this.usersInfo ?? [])
        };

        const usersUpdated = this.users ?? {};
        const usersInfoUpdated = this.usersInfo ?? [];
        const userOldId: string = role === UserRole.userClient ? (this.userClient?.id ?? '') : (this.attendant?.id ?? '');

        if (userOldId) {
            delete usersUpdated[userOldId];
        }

        const index = usersInfoUpdated.findIndex((entry) => entry.id === userOldId);
        if (index > -1) {
            usersInfoUpdated.splice(index, 1);
        }
        if (user) {
            usersUpdated[user.id] = true;
            usersInfoUpdated.push(user);
        }

        return {usersUpdated, usersInfoUpdated};
    }


    copyWith({
                 id,
                 idCompany,
                 idSector,
                 idClient,
                 sector,
                 dateDue,
                 description,
                 idDescriptionStatic,
                 descriptionStatic,
                 number,
                 timeSpent,
                 timeFirstResponse,
                 origin,
                 originDetail,
                 users,
                 idOpenedByUser,
                 openedByUser,
                 updatedAt,
                 updateOp,
                 messages,
                 lastMessage,
                 evaluations,
                 notifications,
                 status,
                 priority,
                 resolutionTime,
                 createdAt,
                 dateClosed,
                 deleted,
                 client,
                 usersInfo,
                 descriptionClosed,
                 statusObject,
                 priorityObject,
                 newUserClient,
                 newAttendant,
                 replaceClientIfNull,
                 replaceUserClientIfNull,
                 replaceAttendantIfNull,
                 replaceDateDueIfNull,
                 lastMessageId,
                 logs,
                 soundPlayed,
                 isMeStartingChat,
                 countUnreadNotification,
                 chatOperations
             }: {
        id?: string;
        idCompany?: string;
        idSector?: string;
        sector?: SectorEntity;
        dateDue?: string | null;
        replaceDateDueIfNull?: boolean | null;
        description?: string;
        idDescriptionStatic?: string;
        descriptionStatic?: TicketsDescriptionStaticEntity;
        number?: number;
        timeSpent?: number;
        timeFirstResponse?: number;
        origin?: InfoOrigin;
        originDetail?: InfoOriginDetail;
        users?: Record<string, boolean>;
        idOpenedByUser?: string;
        openedByUser?: UserEntity;
        updatedAt?: string;
        updateOp?: string;
        messages?: TicketMessageEntity[];
        lastMessage?: TicketMessageEntity;
        evaluations?: TicketEvaluationEntity[];
        notifications?: TicketNotificationEntity[];
        status?: string;
        priority?: string;
        resolutionTime?: string;
        createdAt?: string;
        dateClosed?: string;
        deleted?: boolean;
        idClient?: string;
        client?: ClientEntity;
        usersInfo?: UserEntity[];
        descriptionClosed?: string;
        statusObject?: TicketConfig;
        priorityObject?: TicketConfig;
        newUserClient?: UserEntity;
        newAttendant?: UserEntity;
        replaceClientIfNull?: boolean | null;
        replaceUserClientIfNull?: boolean | null;
        replaceAttendantIfNull?: boolean | null;
        lastMessageId?: string;
        logs?: TicketLogsEntity[];
        soundPlayed?: boolean;
        isMeStartingChat?: boolean;
        countUnreadNotification?: number;
        chatOperations?: ChatOperationsEntity;
    }): TicketEntity {

        let {
            usersUpdated,
            usersInfoUpdated
        } = this.getUpdatedUserData(newUserClient || replaceUserClientIfNull ? UserRole.userClient : UserRole.attendant,
            newUserClient || replaceUserClientIfNull ? newUserClient : newAttendant, replaceUserClientIfNull === true || replaceAttendantIfNull === true);

        return new TicketEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            idSector: idSector ?? this.idSector,
            sector: sector ?? this.sector,
            dateDue: replaceDateDueIfNull ? dateDue : this.dateDue,
            description: description ?? this.description,
            idDescriptionStatic: idDescriptionStatic ?? this.idDescriptionStatic,
            descriptionStatic: descriptionStatic ?? this.descriptionStatic,
            number: number ?? this.number,
            timeSpent: timeSpent ?? this.timeSpent,
            timeFirstResponse: timeFirstResponse ?? this.timeFirstResponse,
            origin: origin ?? this.origin,
            originDetail: originDetail ?? this.originDetail,
            idOpenedByUser: idOpenedByUser ?? this.idOpenedByUser,
            openedByUser: openedByUser ?? this.openedByUser,
            updatedAt: updatedAt ?? this.updatedAt,
            updateOp: updateOp ?? this.updateOp,
            messages: messages ?? this.messages,
            lastMessage: lastMessage ?? this.lastMessage,
            evaluations: evaluations ?? this.evaluations,
            notifications: notifications ?? this.notifications,
            status: status ?? this.status,
            priority: priority ?? this.priority,
            resolutionTime: resolutionTime ?? this.resolutionTime,
            createdAt: createdAt ?? this.createdAt,
            dateClosed: dateClosed ?? this.dateClosed,
            deleted: deleted ?? this.deleted,
            client: replaceClientIfNull ? client : client ?? this.client,
            idClient: idClient ?? this.idClient,
            users: usersUpdated ?? this.users,
            usersInfo: usersInfoUpdated ?? this.usersInfo,
            descriptionClosed: descriptionClosed ?? this.descriptionClosed,
            statusObject: statusObject ?? this.statusObject,
            priorityObject: priorityObject ?? this.priorityObject,
            lastMessageId: lastMessageId ?? this.lastMessageId,
            logs: logs ?? this.logs,
            soundPlayed: soundPlayed ?? this.soundPlayed,
            isMeStartingChat: isMeStartingChat ?? this.isMeStartingChat,
            countUnreadNotification: countUnreadNotification ?? this.countUnreadNotification,
            chatOperations: chatOperations ?? this.chatOperations,

        })
    }


}
