import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {CreateObjectUrlBlobUseCase} from "@dropDesk/domain/use_case/io/create_object_url_blob.usecase";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {ListTicketsSubscriptionUseCase} from "@dropDesk/domain/use_case/ticket/list_tickets_subscription.usecase";
import {SetTicketsUseCase} from "@dropDesk/domain/use_case/ticket/set_tickets.usecase";
import {FindByPKTicketsUseCase} from "@dropDesk/domain/use_case/ticket/findbypk_tickets.usecase";
import {RestoreTicketsUseCase} from "@dropDesk/domain/use_case/ticket/restore_tickets.usecase";
import {DeleteTicketsUseCase} from "@dropDesk/domain/use_case/ticket/delete_tickets.usecase";
import {Subscription} from "rxjs";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {
    TicketsDescriptionStaticEntity
} from "@dropDesk/domain/entities/ticket_description_static/ticket_description_static.entity";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {newTicket} from "@dropDesk/presentation/pages/ticket/controller/new_ticket";
import {TransferTicketUseCase} from "@dropDesk/domain/use_case/ticket/transfer_ticket.usecase";
import {ChangeTimeSpentTicketUseCase} from "@dropDesk/domain/use_case/ticket/change_timespent_ticket.usecase";
import {ChangePriorityTicketUseCase} from "@dropDesk/domain/use_case/ticket/change_priority_ticket.usecase";
import {ChangeDateDueTicketUseCase} from "@dropDesk/domain/use_case/ticket/change_date_due_ticket.usecase";
import {
    ChangeProblemDescriptionTicketUseCase
} from "@dropDesk/domain/use_case/ticket/change_problem_description_ticket.usecase";
import {CancelTicketUseCase} from "@dropDesk/domain/use_case/ticket/cancel_ticket.usecase";
import {CloseTicketUseCase} from "@dropDesk/domain/use_case/ticket/close_ticket.usecase";
import {LinkAttendantTicketUseCase} from "@dropDesk/domain/use_case/ticket/link_attendant_ticket.usecase";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {
    getIdCompanyByLocalStorage,
    initializeConfigurationLocalStorage,
    preventMissingPage,
    setGenericFiltersLocalStorage,
    setSearchParamLocalStorage
} from "@dropDesk/utils/helpers/common";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {ExportDataTicketsUseCase} from "@dropDesk/domain/use_case/ticket/export_data.usecase";
import {ExportTicketsSubscriptionUseCase} from "@dropDesk/domain/use_case/ticket/export_data_subscription.usecase";
import {InfoOrigin, InfoOriginDetail} from "@dropDesk/domain/entities/generic/info_origin.enum";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ExportPDFTicketsUseCase} from "@dropDesk/domain/use_case/ticket/export_pdf_tickets.usecase";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {TicketConfig} from "@dropDesk/domain/entities/ticket/ticket_config";
import {TicketStatusType} from "@dropDesk/domain/entities/ticket/ticket_maps";
import {navigate} from "@dropDesk/utils/helpers/navigation";
import {ChangeResolutionTicketUseCase} from "@dropDesk/domain/use_case/ticket/change_resolution_ticket.usecase";
import {CancelTokenSource} from "axios";
import {addSecondsToDate, getDifferenceInSeconds, isValidDateFromNowTime} from "@dropDesk/utils/helpers/date_helper";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {LeaveTicketChatUseCase} from "@dropDesk/domain/use_case/chat/leave_ticket.usecase";
import {ListTicketsUseCase} from "@dropDesk/domain/use_case/ticket/list_tickets.usecase";

configure({
    enforceActions: "always",
});

@injectable()
export class TicketController {
    private readonly _listTicketsSubscriptionUseCase: ListTicketsSubscriptionUseCase;
    private readonly _setTicketUseCase: SetTicketsUseCase;
    private readonly _findByPKTicketUseCase: FindByPKTicketsUseCase;
    private readonly _createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase;
    private readonly _restoreUseCase: RestoreTicketsUseCase;
    private readonly _deleteUseCase: DeleteTicketsUseCase;
    private readonly _transferTicketUseCase: TransferTicketUseCase;
    private readonly _changeTimeSpentTicketUseCase: ChangeTimeSpentTicketUseCase;
    private readonly _changePriorityTicketUseCase: ChangePriorityTicketUseCase;
    private readonly _changeDateDueTicketUseCase: ChangeDateDueTicketUseCase;
    private readonly _changeProblemDescriptionTicketUseCase: ChangeProblemDescriptionTicketUseCase;
    private readonly _cancelTicketUseCase: CancelTicketUseCase;
    private readonly _closeTicketUseCase: CloseTicketUseCase;
    private readonly _linkAttendantTicketUseCase: LinkAttendantTicketUseCase;
    private readonly _exportTicketsSubscriptionUseCase: ExportTicketsSubscriptionUseCase;
    private readonly _exportDataTicketsUseCase: ExportDataTicketsUseCase;
    private readonly _exportPDFTicketsUseCase: ExportPDFTicketsUseCase;
    private readonly _changeResolutionTicketUseCase: ChangeResolutionTicketUseCase;
    private readonly _listTicketsUseCase: ListTicketsUseCase;


    constructor(
        @inject(ListTicketsSubscriptionUseCase) listTicketsSubscriptionUseCase: ListTicketsSubscriptionUseCase,
        @inject(SetTicketsUseCase) setTicketUseCase: SetTicketsUseCase,
        @inject(FindByPKTicketsUseCase) findByPKTicketUseCase: FindByPKTicketsUseCase,
        @inject(CreateObjectUrlBlobUseCase) createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase,
        @inject(RestoreTicketsUseCase) restoreUseCase: RestoreTicketsUseCase,
        @inject(DeleteTicketsUseCase) deleteUseCase: DeleteTicketsUseCase,
        @inject(TransferTicketUseCase) transferTicketUseCase: TransferTicketUseCase,
        @inject(ChangeTimeSpentTicketUseCase) changeTimeSpentTicketUseCase: ChangeTimeSpentTicketUseCase,
        @inject(ChangePriorityTicketUseCase) changePriorityTicketUseCase: ChangePriorityTicketUseCase,
        @inject(ChangeDateDueTicketUseCase) changeDateDueTicketUseCase: ChangeDateDueTicketUseCase,
        @inject(ChangeProblemDescriptionTicketUseCase) changeProblemDescriptionTicketUseCase: ChangeProblemDescriptionTicketUseCase,
        @inject(CancelTicketUseCase) cancelTicketUseCase: CancelTicketUseCase,
        @inject(CloseTicketUseCase) closeTicketUseCase: CloseTicketUseCase,
        @inject(LinkAttendantTicketUseCase) linkAttendantTicketUseCase: LinkAttendantTicketUseCase,
        @inject(ExportTicketsSubscriptionUseCase) exportTicketsSubscriptionUseCase: ExportTicketsSubscriptionUseCase,
        @inject(ExportDataTicketsUseCase) exportDataTicketsUseCase: ExportDataTicketsUseCase,
        @inject(ExportPDFTicketsUseCase) exportPDFTicketsUseCase: ExportPDFTicketsUseCase,
        @inject(ChangeResolutionTicketUseCase) changeResolutionTicketUseCase: ChangeResolutionTicketUseCase,
        @inject(ListTicketsUseCase) listTicketsUseCase: ListTicketsUseCase,
    ) {
        makeObservable(this);
        this._listTicketsSubscriptionUseCase = listTicketsSubscriptionUseCase;
        this._setTicketUseCase = setTicketUseCase;
        this._findByPKTicketUseCase = findByPKTicketUseCase;
        this._createObjectURLBlobUseCase = createObjectURLBlobUseCase;
        this._restoreUseCase = restoreUseCase;
        this._deleteUseCase = deleteUseCase;
        this._transferTicketUseCase = transferTicketUseCase;
        this._changeTimeSpentTicketUseCase = changeTimeSpentTicketUseCase;
        this._changePriorityTicketUseCase = changePriorityTicketUseCase;
        this._changeDateDueTicketUseCase = changeDateDueTicketUseCase;
        this._changeProblemDescriptionTicketUseCase = changeProblemDescriptionTicketUseCase;
        this._cancelTicketUseCase = cancelTicketUseCase;
        this._closeTicketUseCase = closeTicketUseCase;
        this._linkAttendantTicketUseCase = linkAttendantTicketUseCase;
        this._exportTicketsSubscriptionUseCase = exportTicketsSubscriptionUseCase;
        this._exportDataTicketsUseCase = exportDataTicketsUseCase;
        this._exportPDFTicketsUseCase = exportPDFTicketsUseCase;
        this._changeResolutionTicketUseCase = changeResolutionTicketUseCase;
        this._listTicketsUseCase = listTicketsUseCase;
    }

    table = new ListPaginationEntity<TicketEntity>({
        pages: 0,
        page: 0,
        limit: Math.floor((window.innerHeight - 230) / 52),
        totalRows: 0,
        data: observable.array([])
    });

    newExportation = new ExportDataEntity({
        idCompany: getIdCompanyByLocalStorage(),
        id: '',
        status: StatusImport.init,
        progress: 0,
        rows: 0,
        table: 'tickets',
        responseStatus: 200
    });

    user?: UserEntity;

    @observable
    exportation: ExportDataEntity = this.newExportation;

    @observable
    subscriptionListTicket?: Subscription;

    @observable
    subscriptionTicketMessage?: Subscription;

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    tableTicket: ListPaginationEntity<TicketEntity> = this.table;

    @observable
    ticket?: TicketEntity;

    @observable
    searchParam = '';

    @observable
    ticketsNotFound: boolean = false;

    @observable
    listOnlyDeleted = false;

    @observable
    rowSelectionTicket: TicketEntity[] = [];

    @observable
    filterStatus: string | null = null;

    @observable
    filterPriority: string | null = null;

    @observable
    subscriptionExport?: Subscription;

    @observable
    listOnlySectorEmpty = false;

    @action
    createObjectURLBlobUseCase(file: File): string {
        return this._createObjectURLBlobUseCase.call(file);
    }

    @action
    setFilterStatus(
        status: string | null,
        withList = true,
        {handleDisconnect, getLastUpdates, useSocket}: {
            handleDisconnect: (temporary: boolean) => void,
            getLastUpdates: () => void,
            useSocket: boolean,
        }) {
        this.filterStatus = status as any;
        setGenericFiltersLocalStorage({ticketFilterStatus: status});
        this.resetTable();
        if (withList) {
            this.handleListTickets(this.searchParam, {handleDisconnect, getLastUpdates, useSocket});
        }

    }

    @action
    setFilterPriority(
        priority: string | null,
        withList = true,
        {handleDisconnect, getLastUpdates, useSocket}: {
            handleDisconnect: (temporary: boolean) => void,
            getLastUpdates: () => void,
            useSocket: boolean,
        }
    ) {
        this.filterPriority = priority as any;
        setGenericFiltersLocalStorage({ticketFilterPriority: priority});
        this.resetTable();
        if (withList) {
            this.handleListTickets(this.searchParam, {handleDisconnect, getLastUpdates, useSocket});
        }
    }

    @action
    setListOnlySectorEmpty(
        value: boolean,
        withList = true,
        {handleDisconnect, getLastUpdates, useSocket}: {
            handleDisconnect: (temporary: boolean) => void,
            getLastUpdates: () => void,
            useSocket: boolean,
        }
    ) {
        this.listOnlySectorEmpty = value;
        setGenericFiltersLocalStorage({ticketListEmptySector: value});
        this.resetTable();
        if (withList) {
            this.handleListTickets(this.searchParam, {handleDisconnect, getLastUpdates, useSocket});
        }
    }

    @action
    resetTable() {
        this.setTableTicket(this.table);
    }

    @action
    removeLineSelectRow() {
        this.setRowSelectionTicket([]);
    }

    @action
    setRowSelectionTicket(tickets: TicketEntity[]) {
        this.rowSelectionTicket = tickets;
    }

    @action
    setListOnlyDeleted(
        value: boolean,
        {handleDisconnect, getLastUpdates, useSocket}: {
            handleDisconnect: (temporary: boolean) => void,
            getLastUpdates: () => void,
            useSocket: boolean,
        }
    ) {
        this.listOnlyDeleted = value;
        this.removeLineSelectRow();
        this.resetTable();
        this.handleListTickets(this.searchParam, {handleDisconnect, getLastUpdates, useSocket});
    }

    @action
    setSearchParam(value: string) {
        this.searchParam = value;
        setSearchParamLocalStorage(value);
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    setTicket(ticket: TicketEntity) {
        this.ticket = ticket;
    }

    @action
    setExportation(exportation: ExportDataEntity) {
        this.exportation = exportation;
    }

    @action
    getDataFromPage = async (
        page: number,
        {handleDisconnect, getLastUpdates, useSocket}: {
            handleDisconnect: (temporary: boolean) => void,
            getLastUpdates: () => void,
            useSocket: boolean,
        }
    ): Promise<void> => {
        this.setTableTicket(this.tableTicket.copyWith({
            ...this.tableTicket,
            page: page,
        }));
        return this.handleListTickets(this.searchParam, {handleDisconnect, getLastUpdates, useSocket});
    }

    @action
    setTableTicket(value: ListPaginationEntity<TicketEntity>) {
        this.tableTicket = value;
        // const isNecessaryReload = preventMissingPage(this.tableTicket);
        // if (isNecessaryReload) {
        //     this.handleListTickets('');
        // }
    }

    @action
    setTicketNotFound(value: boolean) {
        this.ticketsNotFound = value;
    }

    @action
    async initialize(
        heightGarbage: number,
        {handleDisconnect, getLastUpdates, useSocket}: {
            handleDisconnect: (temporary: boolean) => void,
            getLastUpdates: () => void,
            useSocket: boolean,
        }
    ) {
        const routeName = RoutesEnum.tickets;
        const {lastPage, initSearchParam, filters} = initializeConfigurationLocalStorage(routeName);
        this.tableTicket.page = lastPage;
        this.searchParam = initSearchParam;
        this.tableTicket.limit = Math.floor((window.innerHeight - 270 + heightGarbage) / 52);
        this.filterStatus = filters.ticketFilterStatus!;
        this.filterPriority = filters.ticketFilterPriority!;
        this.listOnlySectorEmpty = filters.ticketListEmptySector ?? false;
        await this.subscriptionListTickets(initSearchParam, {handleDisconnect, getLastUpdates, useSocket});
    }

    @action
    set = async (onSuccess: (key: string, ticket: TicketEntity) => void): Promise<void> => {
        try {
            this.startLoading("ticket.loadingSave");
            if (this.ticket?.isUnsaved()) {
                this.setTicket(this.ticket.copyWith({
                    id: generateUUIDV4(),
                    dateDue: this.ticket?.dateDue ? this.validateDateDue(this.ticket?.dateDue) : this.ticket?.dateDue
                }));
            }
            await this._setTicketUseCase.call(this.ticket!);
            localStorage.setItem(ConstantsKeys.defaultDataFormTicket, JSON.stringify(this.ticket!.toFillDataLocalStorage()));
            onSuccess("ticket.success.save", this.ticket!);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    export = async (
        period: { startDate: string, endDate: string },
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Promise<void> => {
        try {
            this.startLoading("ticket.loadingExport");
            const id = generateUUIDV4();
            if (useSocket) {
                this.observeExport(id, handleDisconnect, getLastUpdates, useSocket);
            }
            const exportData = await this._exportDataTicketsUseCase.call(period, id);
            this.setExportation(exportData);
            if (exportData.id !== id && useSocket) {
                this.dispose();
                if (exportData.inProgress) {
                    this.observeExport(exportData.id, handleDisconnect, getLastUpdates, useSocket);
                }
            } else if (!useSocket) {
                this.observeExport(id, handleDisconnect, getLastUpdates, useSocket);
            }
            if (!exportData.inProgress) {
                this.dispose();
            }
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            if (err.message === 'need_admin_privileges') {
                navigate(RoutesEnum.reporttickets);
            }
            this.stopLoading();
        }
    }

    @action
    observeExport(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ) {
        this.subscriptionExport?.unsubscribe();
        this.subscriptionExport = this._exportTicketsSubscriptionUseCase.call(id, handleDisconnect, getLastUpdates, useSocket).subscribe({
            next: (response) => {
                if (response) {
                    this.setExportation(this.exportation.copyWith({
                        createdAt: this.exportation.createdAt,
                        ...response
                    }));
                    if (this.exportation.done) {
                        this.subscriptionExport?.unsubscribe();
                    }
                }
            },
            error: (err) => {
                toastMessage.error(err);
                this.stopLoading();
            }
        });
    }

    @action
    updateWithUnchangedRelationships(ticket: TicketEntity) {
        const ticketUpdate = ticket.copyWith({
            evaluations: this.ticket?.evaluations,
        });
        this.setTicket(ticketUpdate);
    }

    @action
    handleTransferTicket = async (
        sector: SectorEntity,
        onSuccess: (key: string, ticket?: TicketEntity) => void,
        user?: UserEntity
    ): Promise<void> => {
        try {
            this.startLoading("ticket.changeTransferTicket");
            const ticketToTransfer = this.ticket ?? this.rowSelectionTicket[0];
            const ticket = await this._transferTicketUseCase.call(ticketToTransfer.id, sector.id, user?.id);
            this.updateWithUnchangedRelationships(ticket.copyWith({
                newAttendant: user,
                replaceAttendantIfNull: true,
                sector: sector,
                idSector: sector.id,
            }));
            onSuccess("ticket.success.transfer");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    handleTicketClosed = async (
        onSuccess: (key: string, ticket?: TicketEntity) => void,
        descriptionClosed?: string
    ): Promise<void> => {
        try {
            this.startLoading("ticket.changeTicketClosed");
            const ticket = await this._closeTicketUseCase.call(this.ticket!.id, descriptionClosed);
            this.updateWithUnchangedRelationships(ticket);
            onSuccess("ticket.success.closed", this.ticket);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }

    }

    @action
    handleChangeResolution = async (
        onSuccess: (key: string, ticket?: TicketEntity) => void,
        descriptionClosed: string
    ): Promise<void> => {
        try {
            this.startLoading("ticket.changeResolution");
            const ticket = await this._changeResolutionTicketUseCase.call(this.ticket!.id, descriptionClosed);
            this.updateWithUnchangedRelationships(ticket);
            onSuccess("ticket.success.changeResolution", this.ticket);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }

    }

    @action
    handleLinkAttendant = async (
        userLogged: UserEntity,
        onSuccess: (ticket: TicketEntity) => void
    ): Promise<void> => {
        try {
            this.startLoading("ticket.linkAttendant");
            const ticket = await this._linkAttendantTicketUseCase.call(this.ticket!.id);
            this.updateWithUnchangedRelationships(ticket.copyWith({
                newAttendant: userLogged,
                isMeStartingChat: true
            }));
            onSuccess(this.ticket!);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }

    }

    @action
    exportPdf = async (
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("ticket.exportPdf");
            await this._exportPDFTicketsUseCase.call(this.ticket!.id);
            onSuccess("ticket.success.exportPdf");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }

    }

    @action
    handleCancellationTicket = async (
        onSuccess: (key: string, ticket?: TicketEntity) => void,
        descriptionClosed?: string,
    ): Promise<void> => {
        try {
            this.startLoading("ticket.changeCancellationTicket");
            const ticket = await this._cancelTicketUseCase.call(this.ticket!.id, descriptionClosed);
            this.updateWithUnchangedRelationships(ticket);
            onSuccess("ticket.success.canceled", ticket);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    handleChangeTimeSpent = async (
        timeSpent: number,
        onSuccess: (key: string, ticket?: TicketEntity) => void,
    ): Promise<void> => {
        try {
            this.startLoading("ticket.changeTimeSpent");
            const ticket = await this._changeTimeSpentTicketUseCase.call(this.ticket!.id, timeSpent);
            this.updateWithUnchangedRelationships(ticket);
            onSuccess("ticket.success.time_spent", this.ticket);
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }

    }


    @action
    handleChangePriority = async (
        priority: string,
        onSuccess: (key: string, ticket?: TicketEntity) => void
    ): Promise<void> => {

        try {
            this.startLoading("ticket.changePriority");
            const ticket = await this._changePriorityTicketUseCase.call(this.ticket!.id, priority);
            this.updateWithUnchangedRelationships(ticket);
            onSuccess("ticket.success.priority", this.ticket);
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }

    }

    @action
    handleSelectClientAndDependOpenModal = (client: ClientEntity): boolean => {
        try {
            if (this.clientDifferentFromSelected(client)) {

                this.setTicket(this.ticket!.copyWith({
                    idClient: client.id,
                    client: client,
                    newUserClient: undefined,
                    replaceUserClientIfNull: true
                }));

                if (this.getTotalUsers(client) === 1) {

                    const newUserClient = client.users!.find(entry => entry.deleted === false);
                    this.setTicket(this.ticket!.copyWith({
                        newUserClient,
                    }));

                    return false;

                } else {

                    if (this.getTotalUsers(client) === 0) {
                        toastMessage.warning('Não podemos prosseguir com a abertura do atendimento, pois essa empresa não possui contato.');
                        return false;
                    }

                    return true;
                }

            } else {
                return false;
            }
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
            return false;
        }
    }

    @action
    handleRemoveClient = (): void => {
        try {
            this.setTicket(this.ticket!.copyWith({
                newUserClient: undefined,
                client: undefined,
                idClient: '',
                replaceClientIfNull: true,
                replaceUserClientIfNull: true
            }));
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    handleChangeClient = (client: ClientEntity, onSuccess?: (key?: string, ticket?: TicketEntity) => void): void => {
        try {
            const contactSelected = client.users?.find(entry => entry.id === this.ticket!.userClient?.id) ?? this.ticket!.userClient;
            this.setTicket(this.ticket!.copyWith({
                newUserClient: contactSelected,
                client: client,
                idClient: client.id,
            }));
            if (onSuccess) {
                onSuccess(undefined, this.ticket);
            }

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    handleChangeDescription = async (
        onSuccess: (key: string, ticket?: TicketEntity) => void,
        description?: string,
        descriptionStatic?: TicketsDescriptionStaticEntity
    ): Promise<void> => {
        try {
            if (!description && !descriptionStatic) {
                return;
            }
            this.startLoading("ticket.changeDescriptionProblem");
            const newDescription = description ? description : descriptionStatic ? descriptionStatic.id : '';
            const ticket = await this._changeProblemDescriptionTicketUseCase.call(this.ticket!.id, newDescription);
            this.updateWithUnchangedRelationships(ticket);
            onSuccess("ticket.success.descriptionProblem", this.ticket);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    private validateDateDue = (dateDue: string) => {
        if (!isValidDateFromNowTime(dateDue, true)) {
            const now = new Date();
            const differenceInSeconds = getDifferenceInSeconds(now, new Date(dateDue));
            if (differenceInSeconds <= ConstantsKeys.minuteInSeconds) {
                return addSecondsToDate(now, 10).toISOString();
            }
        }

        return dateDue;
    }

    @action
    handleChangeDateDue = async (
        dateDue: string,
        onSuccess: (key: string, ticket?: TicketEntity) => void,
    ): Promise<void> => {
        try {
            this.startLoading("ticket.changeDateDue");
            dateDue = this.validateDateDue(dateDue);
            const ticket = await this._changeDateDueTicketUseCase.call(this.ticket!.id, dateDue);
            this.updateWithUnchangedRelationships(ticket.copyWith({
                dateDue: ticket.dateDue,
                replaceDateDueIfNull: true
            }));
            onSuccess("ticket.success.dateDue", this.ticket);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    handleSelectContact = (contact: UserEntity): void => {
        this.setTicket(this.ticket!.copyWith({
            newUserClient: contact,
            client: contact.client!,
            idClient: contact.idClient!,
        }));
    }

    @action
    handleSelectUserAttendant = (newAttendant: UserEntity, ticketGlobalConfigs: TicketConfig[],): void => {
        this.setTicket(this.ticket!.copyWith({
            newAttendant,
            status: ticketGlobalConfigs.find((item) => item.isAttendingStatus)?.id,
            statusObject: ticketGlobalConfigs.find((item) => item.isAttendingStatus),
        }));
    }

    private sectorDifferentFromSelected = (idSector: string): boolean => {
        return this.ticket?.idSector !== idSector;
    }

    @action
    handleSelectSector = (sector: SectorEntity, ticketGlobalConfigs: TicketConfig[]): void => {
        if (this.sectorDifferentFromSelected(sector.id)) {
            this.setTicket(this.ticket!.copyWith({
                sector: sector,
                idSector: sector.id,
                newAttendant: undefined,
                replaceAttendantIfNull: true,
                status: ticketGlobalConfigs.find((item) => item.isDefaultStatus)?.id,
                statusObject: ticketGlobalConfigs.find((item) => item.isDefaultStatus),
            }));
        }
    }

    @action
    handleSelectDescriptionStatic = (descriptionStatic: TicketsDescriptionStaticEntity): void => {
        this.setTicket(this.ticket!.copyWith({
            idDescriptionStatic: descriptionStatic.id,
            descriptionStatic: descriptionStatic,
        }));
    }


    @action
    delete = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("ticket.loadingDelete");
            await this._deleteUseCase.call(this.rowSelectionTicket);
            this.removeLineSelectRow();
            onSuccess("ticket.success.delete");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }


    restore = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("ticket.loadingRestore");
            await this._restoreUseCase.call(this.rowSelectionTicket);
            this.removeLineSelectRow();
            this.stopLoading();
            onSuccess("ticket.success.restore");

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }


    @action
    updateTicket(tickets: TicketEntity[]): void {
        for (const ticket of tickets) {
            this.setTicket(this.ticket!.copyWith({
                status: ticket.status,
                statusObject: ticket.statusObject,
                idSector: ticket.idSector,
            }));
        }
    }

    @action
    eraseTicket() {
        this.ticket = undefined;
    }

    @action
    getTicket = async (id: string, withLoadOverlapPage = false, cancelTokenSource?: CancelTokenSource): Promise<TicketEntity | undefined> => {
        try {
            this.startLoading();
            this.startLoading(withLoadOverlapPage ? "ticket.loadingInit" : null);
            const ticket = await this._findByPKTicketUseCase.call(id, false, cancelTokenSource);
            this.setTicket(ticket);
            this.stopLoading();
            return ticket;
        } catch (err: any) {
            this.setTicketNotFound(true);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    makeTicket = (
        userLogged: UserEntity,
        ticketGlobalConfigs: TicketConfig[],
        isNewChatBody?: { client: ClientEntity, contact: UserEntity | null },
    ) => {
        const isNewTicketByPortalClient = userLogged.role === UserRole.userClient;
        const newStatus = ticketGlobalConfigs.find((item) => item.isDefaultStatus);
        const newPriority = ticketGlobalConfigs.find((item) => item.isDefaultPriority);
        let _newTicket = newTicket(newStatus!, newPriority!);
        const fillData = JSON.parse(localStorage.getItem(ConstantsKeys.defaultDataFormTicket) ?? '{}');
        const autoFillData = JSON.parse(localStorage.getItem(ConstantsKeys.autoFillDataLocalStorage) ?? 'false');

        const sectors = userLogged.sectors.filter((entry) => entry.sector?.deleted === false);
        if (sectors.length === 1) {
            _newTicket = _newTicket.copyWith({
                idSector: sectors[0].idSector,
                sector: sectors[0].sector,
            })
        }

        _newTicket = _newTicket.copyWith({
            idOpenedByUser: userLogged.id,
            openedByUser: userLogged,
            idSector: autoFillData && fillData?.idSector ? fillData.idSector : _newTicket.idSector,
            sector: autoFillData && fillData?.sector ? SectorEntity.fromJson(fillData.sector) : _newTicket.sector,
            priority: autoFillData && fillData?.priority ? fillData.priority : _newTicket.priority,
            priorityObject: autoFillData && fillData?.priorityObject ? new TicketConfig({
                ...fillData.priorityObject
            }) : _newTicket.priorityObject,
            dateDue: autoFillData && fillData?.dateDue ? fillData.dateDue : _newTicket.dateDue,
            replaceDateDueIfNull: autoFillData && !!fillData?.dateDue,
            newAttendant: autoFillData && fillData?.attendant ? UserEntity.fromJson(fillData.attendant) : _newTicket.attendant,
            replaceAttendantIfNull: autoFillData && !!fillData?.attendant,
            description: autoFillData && fillData?.description ? fillData.description : _newTicket.description,
            idDescriptionStatic: autoFillData && fillData?.idDescriptionStatic ? fillData.idDescriptionStatic : _newTicket.idDescriptionStatic,
            descriptionStatic: autoFillData && fillData?.descriptionStatic ? new TicketsDescriptionStaticEntity({
                ...fillData.descriptionStatic
            }) : _newTicket.descriptionStatic,
        });


        if (isNewChatBody || isNewTicketByPortalClient) {
            const paramStatus: string = isNewTicketByPortalClient ? TicketStatusType.opened : TicketStatusType.inProgress;
            const status = ticketGlobalConfigs.find((item) => item.extra?.type === paramStatus)?.id;

            _newTicket = _newTicket.copyWith({
                client: isNewTicketByPortalClient ? userLogged.client! : isNewChatBody!.client,
                idClient: isNewTicketByPortalClient ? userLogged.idClient! : isNewChatBody!.client.id,
                newUserClient: isNewTicketByPortalClient ? userLogged : isNewChatBody!.contact ?? undefined,
                replaceUserClientIfNull: true,
                origin: isNewTicketByPortalClient ? InfoOrigin.portal : InfoOrigin.chat,
                originDetail: isNewTicketByPortalClient ? undefined : InfoOriginDetail.system,
                status,
            });
        }
        this.setTicket(_newTicket);
    }

    @action
    dispose = (): void => {
        this.subscriptionListTicket?.unsubscribe();
        this.subscriptionTicketMessage?.unsubscribe();
        this.subscriptionExport?.unsubscribe();
    }

    @action
    async list(withLoader = true, searchParam?: string): Promise<void> {
        try {
            if (withLoader) {
                this.startLoading();
            }
            if ((searchParam !== undefined && searchParam !== null) && searchParam !== this.searchParam) {
                this.resetTable();
                this.setSearchParam(searchParam ?? '');
            }

            const response = await this._listTicketsUseCase.call(
                this.searchParam, this.tableTicket.page, this.tableTicket.limit, this.listOnlyDeleted, this.filterStatus,
                this.filterPriority, this.listOnlySectorEmpty,
            );
            this.setTableTicket(response);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    handleListTickets = async (
        searchParam: string,
        {handleDisconnect, getLastUpdates, useSocket}: {
            handleDisconnect: (temporary: boolean) => void,
            getLastUpdates: () => void,
            useSocket: boolean,
        },
    ) => {
        if (useSocket) {
            await this.list(true, searchParam);
        } else {
            this.subscriptionListTickets(searchParam ?? this.searchParam, {
                handleDisconnect,
                getLastUpdates,
                useSocket
            });
        }
    }


    @action
    subscriptionListTickets = async (
        searchParam: string,
        {handleDisconnect, getLastUpdates, useSocket}: {
            handleDisconnect: (temporary: boolean) => void,
            getLastUpdates: () => void,
            useSocket: boolean,
        }
    ): Promise<void> => {
        try {
            this.startLoading();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            this.subscriptionListTicket?.unsubscribe();
            if (useSocket) {
                await this.list();
            }
            const idCompany = getIdCompanyByLocalStorage();
            const id = `${idCompany}_ticket_list`;
            this.subscriptionListTicket = this._listTicketsSubscriptionUseCase.call(
                this.searchParam, this.tableTicket.page, this.tableTicket.limit, this.listOnlyDeleted, this.filterStatus,
                this.filterPriority, this.listOnlySectorEmpty, id, handleDisconnect, getLastUpdates, useSocket
            ).subscribe({
                next: (response) => {
                    if (response) {
                        if (useSocket) {
                            this.list(false).then();
                        } else {
                            this.setTableTicket(response);
                        }
                    }
                    if (this.loading) {
                        this.stopLoading();
                    }
                },
                error: (err: any) => {
                    toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                    this.stopLoading();
                }
            });

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    clientDifferentFromSelected = (client: ClientEntity): boolean => {
        return this.ticket?.idClient !== client.id;
    }

    getTotalUsers = (client: ClientEntity): number => {
        return client.users?.filter((entry) => entry.deleted === false).length ?? 0;
    }


}
