import React, {useEffect} from 'react';
import {
    Container,
    SubContentList,
    ContentList,
} from "./styled";
import {observer} from "mobx-react";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {useInjection} from "inversify-react";
import {CompanyController} from "@dropDesk/presentation/pages/company/controller/company.controller";
import SearchBar from "@dropDesk/presentation/pages/company_active_connections/search/search_bar";
import TableActiveConnections from "@dropDesk/presentation/pages/company_active_connections/search/table";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalsActiveConnections from "@dropDesk/presentation/pages/company_active_connections/search/modals";
import {LoginController} from "@dropDesk/presentation/pages/login/controller/login.controller";

const CompanyActiveConnections = (observer(() => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const loginController = useInjection(LoginController);
    const controller = useInjection(CompanyController);
    const {t} = useTranslation();

    useEffect(() => {
        controller.initializeConnections();
    }, []);

    const onSuccess = (key: string) => {
        controller.listActiveConnections(controller.searchParam);
        toastMessage.success(t(key));
    }

    const onSuccessLogout = () => {
        appController.setUser(null);
    }

    const logout = async () => {
        const idUser = controller.userActiveConnection?.userId;
        if (idUser) {
            if (idUser === appController.user?.id) {
                await loginController.logout(onSuccessLogout);
            } else {
                await loginController.logoutByAdmin(onSuccess, idUser);
            }
        }
    }

    return (
        <Container background={colors.onBackground}>

            <ContentList>
                <SearchBar
                    onSearch={(searchParam) => controller.listActiveConnections(searchParam)}
                    visibleInputSearch={true}
                    loading={controller.loading}
                />

                <SubContentList>

                    <TableActiveConnections
                        setUserActiveConnection={(connection) => controller.setUserActiveConnection(connection)}
                        getDataFromPage={(page) => controller.getDataFromPage(page)}
                        tableConnections={controller.tableConnections}
                        searchParam={controller.searchParam}
                        loading={controller.loading}
                    />

                    <ModalsActiveConnections
                        logoutByAdmin={logout}
                        loading={controller.loading}/>

                </SubContentList>
            </ContentList>

            {(
                (controller.loading && controller.loadingMessage) ||
                (appController.loading || appController.loadingMessage) ||
                (loginController.loading || loginController.loadingMessage)
            ) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage ?? appController.loadingMessage ?? loginController.loadingMessage}
            />}

        </Container>
    )

}))
export default CompanyActiveConnections;
