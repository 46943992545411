import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";

export class AllChatsEntity {
    attending!: ListPaginationEntity<TicketEntity>;
    waiting!: ListPaginationEntity<TicketEntity>;

    constructor({
                    attending,
                    waiting,
                }: {
        waiting: ListPaginationEntity<TicketEntity>;
        attending: ListPaginationEntity<TicketEntity>;
    }) {
        Object.assign(this, {
            attending,
            waiting,
        });
    }
}