import "reflect-metadata";
import {inject, injectable} from "inversify";
import {TicketRepository} from "@dropDesk/domain/repositories/ticket/ticket.repository";
import {Observable} from "rxjs";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";

@injectable()
export class ListTicketsSubscriptionUseCase {
    private _repository: TicketRepository;

    constructor(@inject(TicketRepository) repository: TicketRepository) {
        this._repository = repository;
    }

    public call(
        searchParam: string, page: number, limit: number, listOnlyDeleted: boolean, status: string | null,
        priority: string | null, listOnlySectorEmpty: boolean,
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ListPaginationEntity<TicketEntity> | null> {
        return this._repository.listTicketsSubscription(
            searchParam,
            page,
            limit,
            listOnlyDeleted,
            status,
            priority,
            listOnlySectorEmpty,
            id,
            handleDisconnect,
            getLastUpdates,
            useSocket
        );
    }
}

