import "reflect-metadata";
import {injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {ReportTicketEntity} from "@dropDesk/domain/entities/reports/report_ticket/report_ticket_entity";
import {downloadFile, openFileInNewTab} from "@dropDesk/utils/helpers/files";

@injectable()
export abstract class ReportTicketRemoteDataSource {

    public abstract get(
        period: { startDate: string, endDate: string },
        idUser: string | null,
        idSector: string | null,
        idClient: string | null
    ): Promise<ReportTicketEntity>;

    public abstract exportPdf(
        period: { startDate: string, endDate: string },
        idUser: string | null,
        idSector: string | null,
        idClient: string | null
    ): Promise<void>;

}

@injectable()
export class ReportTicketRemoteDataSourceImpl implements ReportTicketRemoteDataSource {
    baseUrl: string = 'reportTickets/data';
    baseUrlExportPdf: string = 'reportTickets/pdf';

    async get(
        period: { startDate: string, endDate: string },
        idUser: string | null,
        idSector: string | null,
        idClient: string | null
    ): Promise<ReportTicketEntity> {
        return new Promise<ReportTicketEntity>(async (resolve, reject) => {
            try {
                const url = `${this.baseUrl}?startDate=${period.startDate}&endDate=${period.endDate}&idUser=${idUser ?? ''}&idSector=${idSector ?? ''}&idClient=${idClient ?? ''}`;
                const response = await api.get(url);
                const result = ReportTicketEntity.fromJson(response.data).byOrder();
                return resolve(result);

            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    async exportPdf(
        period: { startDate: string, endDate: string },
        idUser: string | null,
        idSector: string | null,
        idClient: string | null
    ): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const url = `${this.baseUrlExportPdf}?startDate=${period.startDate}&endDate=${period.endDate}&idUser=${idUser ?? ''}&idSector=${idSector ?? ''}&idClient=${idClient ?? ''}`;
                const response = await api.get(url, {responseType: 'blob'});
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                openFileInNewTab(file, 'Carregando pdf...');
                downloadFile(fileURL, `relatório_${period.startDate}-${period.endDate}.pdf`);
                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

}
