import "reflect-metadata";
import {inject, injectable} from "inversify";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";

@injectable()
export class ForgotUserUseCase {
    private _repository: UserRepository;

    constructor(@inject(UserRepository) repository: UserRepository) {
        this._repository = repository;
    }

    public call(email: string, retry?: boolean): Promise<void> {
        return this._repository.forgot(email, retry);
    }
}

