import React, {useContext, useEffect, useState} from 'react';
import {Container, Content} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {t} from "i18next";
import {
    RoutesSubscription,
    SubscriptionController
} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import DetailSubscription from "@dropDesk/presentation/pages/subscription/ui/detail_subscription";
import {CompanyEntity} from "@dropDesk/domain/entities/company/company.entity";
import SelectPlan from "@dropDesk/presentation/pages/subscription/ui/select_plan";
import Checkout from "@dropDesk/presentation/pages/subscription/ui/checkout";
import {CompanyController} from "@dropDesk/presentation/pages/company/controller/company.controller";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";
import ModalsSubscription from "@dropDesk/presentation/pages/subscription/ui/modals";
import {useModalsSubscription} from "@dropDesk/presentation/pages/subscription/ui/modals/visible_modal_subscription";
import {useBetween} from "use-between";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {useNavigate} from "react-router-dom";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {LoginController} from "@dropDesk/presentation/pages/login/controller/login.controller";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import Version from "@dropDesk/presentation/components/version";
import toast_message from "@dropDesk/utils/toast_message/toast_message";

const Subscription = (observer(() => {
        const controller = useInjection(SubscriptionController);
        const companyController = useInjection(CompanyController);
        const loginController = useInjection(LoginController);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const collapsed = useContext(MenuCollapsedContext);
        const navigate = useNavigate();
        const company = controller.companyLocal;
        const [hasErrorConnectSocket, setHasErrorConnectSocket] = useState<boolean>(false);
        const [hasErrorConnectSocketTemporary, setHasErrorConnectSocketTemporary] = useState<boolean>(false);
        const [loadingReconnect, setLoadingReconnect] = useState<boolean>(false);

        useEffect(() => {
            initialize().then();
            return () => {
                controller.dispose();
            }
        }, []);

        const initialize = async () => {
            try {
                controller.setCompanyLocal(appController.user?.company);
                const company = await getCompany();
                controller.initialize(company.newSubs);
            } catch (e) {
                console.error(e);
            }
        }

        const useSocket = ():boolean => {
            return !appController.user ? true : appController.user?.company.configurations.useSocket ?? false;
        }

        const getCompany = async (): Promise<CompanyEntity> => {
            const currentCompany = controller.companyLocal;
            const newCompany = await companyController.getCompany();
            const realCompany = newCompany ?? currentCompany;
            onUpdateCompanyLocal(realCompany);
            return realCompany;
        }

        const onSuccess = (key: string) => {
            toastMessage.success(t(key));
        }

        const getAllData = async () => {
            await Promise.all([
                getCompany(),
                controller.listInvoices(),
                controller.listSubscriptionLogs()
            ]);
        }

        const onSuccessNewPaymentMethod = async (key: string, paymentMethod: PaymentMethodEntity) => {
            if (company) {
                await getAllData();
            }
            onSuccess(key);
            setVisibleModalChangePaymentMethod(false);
        }

        const onUpdateCompanyLocal = (newCompany: CompanyEntity) => {
            controller.setCompanyLocal(newCompany);
            if (appController.user) {
                const _userLogged = appController.user.copyWith({
                    company: newCompany
                })
                appController.setUser(_userLogged);
            }
        }


        const onUpdateSubscriptionLocal = (
            subscription: PaymentSubscriptionEntity,
            paymentMethod?: PaymentMethodEntity,
        ) => {
            const currentCompany = controller.companyLocal;
            if (currentCompany) {
                if (
                    ((currentCompany.newSubs.lastPayment?.isPending || currentCompany.newSubs.lastPayment?.rejected) || currentCompany.newSubs.isFree)
                    && subscription.lastPayment?.isPaid
                    && currentCompany.newSubs.lastPayment?.id === subscription.lastPayment?.id
                ) {
                    onSuccessPaymentReceived().then();
                }
                const newCompany = currentCompany.copyWith({
                    paymentSubscriptions: [subscription],
                    paymentMethod: paymentMethod ?? currentCompany.paymentMethod,
                });
                onUpdateCompanyLocal(newCompany);
            }
        }

        const handleSavePaymentInfo = async (company: CompanyEntity) => {
            const newCompany = await companyController.savePaymentInfo(company, onSuccess);
            if (newCompany) {
                onUpdateCompanyLocal(newCompany);
            }
            setVisibleModalChangePaymentInfo(false);
        }

        const onSuccessPay = async (
            newSubs: PaymentSubscriptionEntity,
            paymentMethod: PaymentMethodEntity,
            key: string
        ) => {
            onUpdateSubscriptionLocal(newSubs, paymentMethod);
            await getAllData();
            if (!newSubs.lastPayment?.rejected) {
                handleOpenModalPayment(newSubs.lastPayment);
            } else {
                setVisibleModalRejectedCreditCardPayment(true);
            }
            onSuccess(key);
        }

        const handleOpenModalPayment = (payment?: PaymentEntity, forcePix = false) => {
            if (!!payment && payment.value > 0) {
                observerPaymentSubscriptionDependant(payment);
                setVisibleModalDisplayBoleto(forcePix ? false : !!payment?.isBoleto);
                setVisibleModalDisplayDataPix(forcePix ? true : !!payment?.isPix);
                setVisibleModalProcessingCreditCardPayment(!!payment?.isCreditCard && payment.isPending);
                if (!!payment?.isCreditCard && payment.isPaid) {
                    onSuccessPaymentReceived();
                }
            }
        }

        const observerPaymentSubscriptionDependant = (payment?: PaymentEntity) => {
            if (payment && !!payment.pix && !payment.isPaid) {
                controller.observerPaymentSubscription(
                    payment.id,
                    onUpdateSubscriptionLocal,
                    useSocket(),
                    handleDisconnect,
                    handleGetLastUpdates
                );
            }
        }

        const handleDisconnect = (temporary: boolean) => {
            if (temporary) {
                setHasErrorConnectSocketTemporary(true);
            } else {
                setHasErrorConnectSocket(true);
                setHasErrorConnectSocketTemporary(false);
            }
        }

        const handleReconnectSocket = async (): Promise<void> => {
            try {
                setLoadingReconnect(true);
                const company = await getCompany();
                observerPaymentSubscriptionDependant(company.newSubs.lastPayment);
                if (company.newSubs.lastPayment?.isPaid) {
                    onSuccessPaymentReceived();
                }
                setHasErrorConnectSocket(false);
                setLoadingReconnect(false);
                setHasErrorConnectSocketTemporary(false);
            } catch (e) {
                setHasErrorConnectSocket(true);
                setLoadingReconnect(false);
                toast_message.error('Não foi possível estabelecer a conexão. Por favor, tente novamente.' +
                    ' Se o problema persistir, entre em contato com a equipe da DropDesk');
            }
        }

        const handleGetLastUpdates = async (): Promise<void> => {
            await handleReconnectSocket();
        }

        const forcePayWithPix = (payment: PaymentEntity) => {
            handleOpenModalPayment(payment, true);
        }

        const onSuccessPaymentReceived = async () => {
            setVisibleModalPaymentSuccess(true);
            setVisibleModalProcessingCreditCardPayment(false);
            setVisibleModalDisplayBoleto(false);
            setVisibleModalDisplayDataPix(false);
            setVisibleModalPay(false);
            getAllData();
            if (!appController.user) {
                await loginController.loginServer();
                await appController.getCurrentUser();
            }
        }

        const logout = async () => {
            await loginController.logout(onSuccessLogout);
        }

        const onSuccessLogout = () => {
            appController.setUser(null);
            navigate(RoutesEnum.login, {replace: true});
            setVisibleModalLogout(false);
        }

        const onSuccessCancel = async (subscription: PaymentSubscriptionEntity, key: string) => {
            onUpdateSubscriptionLocal(subscription);
            await getAllData();
            onSuccess(key);
        }

        const onSuccessReactive = async (subscription: PaymentSubscriptionEntity, key: string) => {
            onUpdateSubscriptionLocal(subscription);
            await getAllData();
            onSuccess(key);
        }

        const handleClickConfirmPlan = async () => {
            if (company?.newSubs.hasDowngrade(controller.newSubscription)) {
                setVisibleModalDowngrade(true);
            } else {
                controller.onClickConfirmPlan();
                await getCompany();
            }
        }

        const {
            setVisibleModalPay, setVisibleModalDisplayDataPix,
            setVisibleModalDisplayBoleto, setVisibleModalProcessingCreditCardPayment,
            setVisibleModalPaymentSuccess, setVisibleModalChangePaymentInfo,
            setVisibleModalChangePaymentMethod, setVisibleModalReactiveSubscription,
            setVisibleModalCancelSubscription, setVisibleModalLogout,
            setVisibleModalCancelFutureSubscription, setVisibleModalDowngrade,
            setVisibleModalViewLog, setVisibleModalCancelLegacySubscription,
            setVisibleModalRejectedCreditCardPayment
        } = useBetween(useModalsSubscription);

        return (
            <Container collapsedMenu={collapsed} background={colors.onBackground}>
                {(company && company.newSubs) &&
                    <>
                        {controller.activeRoute === RoutesSubscription.detailSubscription &&
                            <Content>
                                <DetailSubscription
                                    currentSubscription={company.newSubs}
                                    loading={controller.loading}
                                    disabled={controller.loading}
                                    onClickSelectPlan={() => controller.setActiveRoute(RoutesSubscription.selectPlan)}
                                    onClickChangePlan={() => controller.setActiveRoute(RoutesSubscription.selectPlan)}
                                    onClickReactivePlan={() => setVisibleModalReactiveSubscription(true)}
                                    lastPayment={company.newSubs.lastPayment}
                                    visibleButtonChangePlan={!(company.newSubs.lastPayment?.isPending && company.newSubs.lastPayment.isCreditCard) && !company.newSubs.isPending}
                                    handleClickPayPending={(payment) => handleOpenModalPayment(payment, payment.isCreditCard)}
                                    handleClickPayWithPix={(payment) => forcePayWithPix(payment)}
                                    paymentMethod={company.paymentMethod}
                                    paymentInfo={company.extraInfo?.paymentInfo}
                                    handleEditPaymentInfo={() => {
                                        companyController.handleEditPaymentInfo(company!, true);
                                        setVisibleModalChangePaymentInfo(true);
                                    }}
                                    handleEditPaymentMethod={() => {
                                        setVisibleModalChangePaymentMethod(true);
                                    }}
                                    onSelectedRowLog={(log) => {
                                        controller.setLog(log);
                                        setVisibleModalViewLog(true);
                                    }}
                                    getDataFromPageInvoices={(page) => controller.getDataFromPage(page)}
                                    getDataFromPageLogs={(page) => controller.getDataFromPageLogs(page)}
                                    tableInvoices={controller.tableInvoices}
                                    tableSubscriptionLogs={controller.tableSubscriptionLogs}
                                    loadingTableInvoices={controller.loading && !controller.loadingMessage}
                                    onClickCancelSubscription={() => {
                                        if (company?.newSubs.plan.legacy) {
                                            setVisibleModalCancelLegacySubscription(true);
                                        } else {
                                            setVisibleModalCancelSubscription(true);
                                        }
                                    }}
                                    onCLickLogout={() => setVisibleModalLogout(true)}
                                    onClickCancelFutureSubscription={() => setVisibleModalCancelFutureSubscription(true)}
                                    onClickDownloadInvoice={(payment) => controller.onClickDownloadInvoice(payment)}
                                    visibleCancelButton={company.oldSubs && !company.oldSubs.isFree ? (company.oldSubs.hasDowngrade(company.newSubs) || (company.oldSubs.hasUpgrade(company.newSubs) && !company.newSubs.lastPayment?.isCreditCard)) : false}
                                    hasDowngrade={company.oldSubs?.hasDowngrade(company.newSubs) ?? false}
                                    hasUpgrade={company.oldSubs?.hasUpgrade(company.newSubs) ?? false}
                                    oldSubs={company.oldSubs}
                                    currentUser={appController.user}
                                />
                            </Content>
                        }

                        {controller.activeRoute === RoutesSubscription.selectPlan &&
                            <Content>
                                <SelectPlan
                                    newSubscription={controller.newSubscription}
                                    onUpdate={(subscription) => controller.setNewPaymentSubscription(subscription, company.newSubs)}
                                    setActiveRoute={(route) => controller.setActiveRoute(route)}
                                    valuePlanOnDemand={controller.getValuePlanOnDemand()}
                                    loading={controller.loading}
                                    onClickConfirmPlan={() => handleClickConfirmPlan()}
                                    disabled={!controller.isValidDataConfirmPlan(company.newSubs)}
                                    disabledText={controller.getDisabledText(company.newSubs)}
                                    currentSubscription={company.newSubs}
                                    makeNewSubscription={() => controller.makeNewSubscription(company.newSubs)}
                                    hasDowngrade={company.newSubs.hasDowngrade(controller.newSubscription)}
                                    labelFirstPayment={company.newSubs.labelFirstPayment}
                                    totalFirstPayment={controller.simulate?.total ?? 0}
                                    daysCharged={controller.simulate?.changes?.find(item => 'days' in item)?.days}
                                />
                            </Content>
                        }
                        {controller.activeRoute === RoutesSubscription.checkout && controller.newSubscription &&
                            <Content>
                                <Checkout
                                    setActiveRoute={(route) => controller.setActiveRoute(route)}
                                    paymentInfo={company.extraInfo?.paymentInfo}
                                    newPaymentInfo={companyController.paymentInfo}
                                    initializePaymentInfo={() => companyController.initializePaymentInfo(company!.extraInfo?.paymentInfo)}
                                    loading={companyController.loading}
                                    onUpdate={(paymentInfo) => companyController.setPaymentInfo(paymentInfo)}
                                    onSearchCNPJ={(cnpj) => companyController.onSearchCNPJ(cnpj)}
                                    onSearchZipCode={(zipCode) => companyController.onSearchZipCode(zipCode)}
                                    onClickSavePaymentInfo={() => handleSavePaymentInfo(company)}
                                    valuePlanOnDemand={controller.getValuePlanOnDemand()}
                                    isEditPaymentInfo={companyController.isEditPaymentInfo}
                                    handleEditPaymentInfo={(value) => companyController.handleEditPaymentInfo(company!, false, value)}
                                    newTokenizeCard={companyController.newTokenizeCardEntity}
                                    onUpdateTokenizeCard={(card) => companyController.setTokenizeCard(card)}
                                    pay={() => controller.pay(onSuccessPay, company?.paymentMethod, companyController.getPaymentMethod)}
                                    setActiveTabPayment={(key) => companyController.setActiveTabPayment(key)}
                                    cardSaved={company.paymentMethod?.card}
                                    onClickPayCreditCardSaved={() => setVisibleModalPay(true)}
                                    newSubscription={controller.newSubscription}
                                    totalFirstPayment={controller.simulate?.total ?? 0}
                                    totalOthersPayment={controller.newSubscription.totalValue}
                                    activeTabPayment={companyController.activeTabPayment}
                                    makePaymentMethod={() => companyController.makePaymentMethod()}
                                    resetPaymentMethod={() => companyController.resetPaymentMethod()}
                                    labelFirstPayment={company.newSubs.labelFirstPayment}
                                    simulate={controller.simulate}
                                    oldSubs={company.newSubs}
                                />
                            </Content>
                        }

                        <ModalsSubscription
                            labelNewPeriod={company.newSubs.lastPayment?.periodFormatted ?? ''}
                            lastPayment={company.newSubs.lastPayment}
                            changePaymentMethod={() => companyController.setPaymentMethod(onSuccessNewPaymentMethod)}
                            payCreditCardSaved={() => controller.pay(onSuccessPay)}
                            loading={controller.loading}
                            log={controller.log}
                            newSubscription={company.newSubs}
                            onClickDownloadInvoice={(payment) => controller.onClickDownloadInvoice(payment)}
                            newPaymentInfo={companyController.paymentInfo}
                            onUpdatePaymentInfo={(paymentInfo) => companyController.setPaymentInfo(paymentInfo)}
                            onSearchCNPJ={(cnpj) => companyController.onSearchCNPJ(cnpj)}
                            onSearchZipCode={(zipCode) => companyController.onSearchZipCode(zipCode)}
                            onClickSavePaymentInfo={async () => {
                                await handleSavePaymentInfo(company);
                            }}
                            logout={() => logout()}
                            setActiveRoute={(route) => controller.setActiveRoute(route)}
                            newTokenizeCard={companyController.newTokenizeCardEntity}
                            onUpdateTokenizeCard={(card) => companyController.setTokenizeCard(card)}
                            setActiveTabPayment={(key) => companyController.setActiveTabPayment(key)}
                            currentPayment={company.paymentMethod?.convertPaymentMethodToTabPayment}
                            cardSaved={company.paymentMethod?.card}
                            activeTabPayment={companyController.activeTabPayment}
                            makePaymentMethod={() => companyController.makePaymentMethod()}
                            setCancellationReason={(cancellationReason) => controller.setCancellationReason(cancellationReason)}
                            cancellationReason={controller.cancellationReason}
                            cancelSubscription={() => controller.cancel(company.newSubs, false, onSuccessCancel)}
                            reactiveSubscription={() => controller.reactivate(company.newSubs, onSuccessReactive)}
                            resetPaymentMethod={() => companyController.resetPaymentMethod()}
                            disposePaymentSubscription={() => controller.dispose()}
                            oldSubscription={company.oldSubs}
                            cancelFutureSubscription={() => controller.cancel(company.newSubs, true, onSuccessCancel)}
                            downgradePlan={() => controller.pay(onSuccessPay)}
                            textDowngrade={controller.newSubscription.textDowngrade(company.newSubs)}
                            hasErrorConnectSocket={hasErrorConnectSocket}
                            hasErrorConnectSocketTemporary={hasErrorConnectSocketTemporary}
                            handleReconnectSocket={(payment) => handleReconnectSocket()}
                            loadingReconnect={loadingReconnect}
                        />
                        {(controller.loading || companyController.loading) && (!!controller.loadingMessage || !!companyController.loadingMessage) &&
                            <DropDeskLoading
                                height={250}
                                description={controller.loadingMessage ?? companyController.loadingMessage}
                            />}
                    </>
                }
            </Container>
        )

    }
));
export default Subscription;
