import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketNotificationEntity} from "@dropDesk/domain/entities/ticket/ticket_notifications_entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export class ShortTicketEntity {
    id!: string;
    notifications?: TicketNotificationEntity[];
    updatedAt?: string;
    updateOp?: string;
    idSector?: string;

    constructor({
                    id,
                    notifications,
                    updatedAt,
                    updateOp,
                    idSector
                }: {
        id: string;
        notifications?: TicketNotificationEntity[];
        updatedAt?: string;
        updateOp?: string;
        idSector?: string;
    }) {
        Object.assign(this, {
            id,
            notifications,
            updatedAt,
            updateOp,
            idSector
        });
    }

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = [
            'notifications', 'updatedAt', 'updateOp', 'idSector'
        ];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    public isEligibleChat(user: UserEntity): boolean {
        return (user.sectors.some(item => item.idSector === this.idSector) || !!user.userMain || user.isUserClient);
    }

    public static fromTicket(ticket: TicketEntity): ShortTicketEntity {
        return new ShortTicketEntity({
            id: ticket.id,
        });
    }

    public static fromJson(json: Record<string, any>): ShortTicketEntity {
        return new ShortTicketEntity({
            id: json['id'],
            updatedAt: json['updatedAt'],
            updateOp: json['updateOp'],
            idSector: json['idSector'],
            notifications: json['notifications'] ? (json['notifications'] as any[]).map((notifications) => TicketNotificationEntity.fromJson(notifications)) : undefined,
        });
    }

    copyWith({
                 id,
                 notifications,
                 updatedAt,
                 updateOp,
                 idSector
             }: {
        id?: string;
        notifications?: TicketNotificationEntity[];
        updatedAt?: string;
        updateOp?: string;
        idSector?: string;

    }): ShortTicketEntity {
        return new ShortTicketEntity({
            id: id ?? this.id,
            notifications: notifications ?? this.notifications,
            updatedAt: updatedAt ?? this.updatedAt,
            updateOp: updateOp ?? this.updateOp,
            idSector: idSector ?? this.idSector,
        })
    }
}
