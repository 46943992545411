import "reflect-metadata";
import {inject, injectable} from "inversify";
import {SubscriptionRepository} from "@dropDesk/domain/repositories/subscription/subscription.repository";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";

@injectable()
export class GetPdfByPaymentIdSubscriptionUseCase {
    private _repository: SubscriptionRepository;

    constructor(@inject(SubscriptionRepository) repository: SubscriptionRepository) {
        this._repository = repository;
    }

    public call(payment: PaymentEntity): Promise<void> {
        return this._repository.getPdfByPaymentId(payment);
    }
}

