import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {ImportData} from "@dropDesk/domain/entities/import_data/import_data";

@injectable()
export class ListImportUseCase {
    private _repository: ClientRepository;

    constructor(@inject(ClientRepository) repository: ClientRepository) {
        this._repository = repository;
    }

    public call(page: number, limit: number, period: {
        startDate: string;
        endDate: string;
    }): Promise<ListPaginationEntity<ImportData>> {
        return this._repository.listImports(page, limit, period);
    }
}

