import styled from "styled-components";

export type ContainerType = {
    background: string;
}
export type TextType = {
    color: string;
}

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${props => props.background};
  border-radius: 7px;
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 250px;
  align-items: center;
  justify-content: center;
`;

export const ContentDescription = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
`;

export const Image = styled.img`
  display: flex;
  width: auto;
  height: 250px;
  pointer-events: none;
`;

export const Description = styled.span<TextType>`
  width: 100%;
  margin-top: 15px;
  line-height: 22px;
  font-size: 17px;
  color: ${(props) => props.color};
`;
