import "reflect-metadata";
import {injectable} from "inversify";
import {from, Observable, Observer} from "rxjs";
import {gql} from "@apollo/client";
import {apolloClient} from "@dropDesk/data/clients/apollo.client";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {getToken} from "@dropDesk/data/data_source/token/token.datasource";
import {mergeMap} from "rxjs/operators";
import {disconnect, socket, socketConnect} from "@dropDesk/data/clients/socket.client";
import {SocketPaymentResponse} from "@dropDesk/domain/entities/payment/payment_socket.entity";
import {SocketExportResponse} from "@dropDesk/domain/entities/export_data/export_socket.entity";

@injectable()
export abstract class ExportRemoteDataSource {
    public abstract exportSubscription(
        table: string,
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null>;
}

@injectable()
export class ExportRemoteDataSourceImpl implements ExportRemoteDataSource {

    constructor() {
    }

    public exportSubscription(
        table: string,
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null> {

        if (useSocket) {
            return this.subscribeExportSocket(id, handleDisconnect, getLastUpdates);
        }
        return this.subscribeExport(table);

    }

    private subscribeExportSocket(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void
    ): Observable<ExportDataEntity | null> {
        return new Observable<ExportDataEntity | null>((observer: Observer<ExportDataEntity | null>) => {
            return from(getToken()).pipe(mergeMap((token: string | null) => {
                    return new Observable(() => {
                        socketConnect(
                            {authorization: `Bearer ${token}`, id},
                            handleDisconnect,
                            getLastUpdates,
                            null,
                            id,
                            'export'
                        );

                        socket!.on('message', async (response: any) => {
                            try {
                                const result = SocketExportResponse.fromJson(response);
                                if (result.hasUnknownError) {
                                    disconnect(['message', id]);
                                    handleDisconnect(false);
                                    observer.next(null);
                                } else {
                                    observer.next(result.exportData ?? null);
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        });
                        return () => disconnect(['message', id]);
                    });
                })
            ).subscribe();
        });
    }

    private subscribeExport(table: string): Observable<ExportDataEntity | null> {
        const idCompany = getIdCompanyByLocalStorage();
        const tableName = 'export_data_status';

        const queryString = `subscription ExportSubscription {
                         ${tableName}(
                         limit: 1, where: {idCompany: {_eq: "${idCompany}"},table: {_eq: "${table}"}}, 
                         order_by: {createdAt: desc}
                         ) {
                          status
                          progress
                          createdAt
                          id
                          idCompany
                          url
                          table
                          extras
                      }
                    }`;

        const query = gql`${queryString}`;

        return new Observable<ExportDataEntity | null>((observer: Observer<ExportDataEntity | null>) => {
            return apolloClient.subscribe({query}).subscribe({
                next: (response) => {

                    const exports = (response.data![tableName] as ExportDataEntity[])[0] as ExportDataEntity | undefined;
                    observer.next(new ExportDataEntity({
                        id: exports ? exports.id : '',
                        idCompany: exports ? exports.idCompany : '',
                        table: exports ? exports.table : '',
                        url: exports ? exports.url : '',
                        status: exports ? exports.status : StatusImport.done,
                        progress: exports ? parseFloat((exports.progress * 100).toFixed(2)) : 0,
                        rows: exports ? exports.rows : 0,
                        createdAt: exports ? exports.createdAt : '',
                        extras: exports && exports.extras ? exports.extras : undefined,
                        responseStatus: 200
                    }));

                },
                error(err: any) {
                    observer.error(err);
                }
            })
        });
    }
}
