export class SharedColors {
    static backgroundLight = '#FAFAFA';
    static backgroundDark = '#242424';
    static onBackgroundLight = '#FFFFFF';
    static onBackgroundDark = '#202020';
    static backgroundInputLight = '#FFFFFF';
    static backgroundInputDark = '#181818';
    static backgroundInputChatDark = '#333333';
    static backgroundInputChatLight = '#EEEEEE';
    static buttonOnBackgroundLight = '#F5F5F5';
    static buttonOnBackgroundDark = '#373839';
    static textLight = '#000000';
    static textDark = '#FAFAFA';
    static textOnAccentBackgroundLight = '#FAFAFA';
    static textOnAccentBackgroundDark = '#FAFAFA';
    static borderLight = '#d7d7d7';
    static borderDark = '#3d3d3d';
    static hintLight = '#A1A1A1';
    static hintDark = '#A1A1A1';
    static hintInverseLight = '#616161';
    static hintInverseDark = '#A1A1A1';
    static textError = '#FF5C75';
    static errorOnPrimary: string = '#F35E5E';
    static success = '#1BD760';
    static info: string = '#fef1e1';
    static warning: string = '#F89406'
    static disabled: string = '#DEDEDE';
    static black: string = '#141414';
    static white: string = '#FFFFFF';
    static loading: string = '#01102A';
    static onCloseStatus: string = '#D9EADB';
    static textCloseStatus: string = '#6F9A80';
    static onCancelStatus: string = '#FFE0DE';
    static textCancelStatus: string = '#70363B';
    static onOpenStatus: string = '#D3E4EE';
    static textOpenStatus: string = '#314250';
    static onAttendingStatus: string = '#FFEAC9';
    static textAttendingStatus: string = '#D84820';
    static textLink: string = '#4AADFF';
    static rate: string = '#FBBD10';
    static warningChat: string = '#FFD279';
}

export class GreenAppColors {
    static delicateBackgroundLight: string = '#E7F6EF';
    static delicateBackgroundDark: string = '#333333';
    static accent: string = '#00a982';
    static tertiary: string = '#C2F1D9';
    static hintTertiary: string = '#808090';
    static readMessage: string = '#4AADFF';
    static tertiaryDark: string = '#005C4B';
    static readMessageDark: string = '#53BDEB';
    static hintTertiaryDark: string = '#B0B0B0';
}

export class OrangeAppColors {
    static delicateBackgroundLight: string = '#F6F2E7';
    static delicateBackgroundDark: string = '#333333';
    static accent: string = '#DE7C12';
    static tertiaryDark: string = '#804815';
    static readMessageDark: string = '#51BAE7';
    static hintTertiaryDark: string = '#CCCCCC';
    static tertiary: string = '#FFCFA4';
    static readMessage: string = '#00BFFF';
    static hintTertiary: string = '#6F6F6F';
}

export class BlueAppColors {
    static delicateBackgroundLight: string = '#E7F1F6';
    static delicateBackgroundDark: string = '#333333';
    static accent: string = '#0050DC';
    static tertiary: string = '#91b5ea';
    static tertiaryDark: string = '#152C55';
    static readMessageDark: string = '#4AADFF';
    static readMessage: string = '#00BFFF';
    static hintTertiary: string = '#808090';
    static hintTertiaryDark: string = '#B0B0B0';
}

export class RedAppColors {
    static delicateBackgroundLight: string = '#F6EBE7';
    static delicateBackgroundDark: string = '#333333';
    static accent: string = '#EA1D2C';
    static tertiary: string = '#FBC1C5';
    static hintTertiary: string = '#757070';
    static readMessage: string = '#4AADFF';
    static tertiaryDark: string = '#6E4043';
    static readMessageDark: string = '#4AADFF';
    static hintTertiaryDark: string = '#A1A1A1';
}

export class PurpleAppColors {
    static delicateBackgroundLight: string = '#F2E7F6';
    static delicateBackgroundDark: string = '#333333';
    static accent: string = '#A743E6';
    static tertiary: string = '#E5C2F1';
    static readMessage: string = '#4AADFF';
    static hintTertiary: string = '#808070';
    static tertiaryDark: string = '#562A72';
    static readMessageDark: string = '#4AADFF';
    static hintTertiaryDark: string = '#A1A1A1';
}

export class GreyAppColors {
    static delicateBackgroundLight: string = '#F3F3F3';
    static delicateBackgroundDark: string = '#333333';
    static accent: string = '#707070';
    static tertiary: string = '#D1D1D1';
    static readMessage: string = '#4AADFF';
    static hintTertiary: string = '#808090';
    static tertiaryDark: string = '#555555';
    static readMessageDark: string = '#4AADFF';
    static hintTertiaryDark: string = '#B0B0B0';
}

export class PinkAppColors {
    static delicateBackgroundLight: string = '#FFE7FD';
    static delicateBackgroundDark: string = '#333333';
    static accent: string = '#F745E8';
    static tertiary: string = '#F6CCF1';
    static hintTertiary: string = '#787890';
    static readMessage: string = '#00BFFF';
    static tertiaryDark: string = '#74276D';
    static readMessageDark: string = '#4AADFF';
    static hintTertiaryDark: string = '#A1A1A1';
}

export class BrownAppColors {
    static delicateBackgroundLight = '#E0D4CE';
    static delicateBackgroundDark: string = '#333333';
    static accent = '#9E5823';
    static tertiary = '#CFA985';
    static readMessage: string = '#00BFFF';
    static hintTertiary: string = '#5C5C6A';
    static tertiaryDark: string = '#61422B';
    static readMessageDark: string = '#4AADFF';
    static hintTertiaryDark: string = '#B0B0B0';
}
