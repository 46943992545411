import React, {useEffect, useState} from 'react';
import {
    Container,
    ContentList,
    SubContentList,
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {ClientController} from "@dropDesk/presentation/pages/client/controller/client.controller";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import SearchBar from "@dropDesk/presentation/pages/client/ui/import/search/search_bar";
import TableImports from "@dropDesk/presentation/pages/client/ui/import/search/table";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {useNavigate} from "react-router-dom";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalsRevertImportClient from "@dropDesk/presentation/pages/client/ui/import/search/modals";
import toast_message from "@dropDesk/utils/toast_message/toast_message";
import ContentInfoDisconnectedSubscription
    from "@dropDesk/presentation/components/content_info_disconnected_subscription";
import ModalReconnectSocket from "@dropDesk/presentation/components/modals/modal_reconnect_socket";

const ImportClients = (observer(() => {

    const [hasErrorConnectSocket, setHasErrorConnectSocket] = useState<boolean>(false);
    const [hasErrorConnectSocketTemporary, setHasErrorConnectSocketTemporary] = useState<boolean>(false);
    const [loadingReconnect, setLoadingReconnect] = useState<boolean>(false);
    const [visibleModalConnect, setVisibleModalConnect] = useState<boolean>(false);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const controller = useInjection(ClientController);
    const navigate = useNavigate();
    const userLogged = appController.user!;


    useEffect(() => {
        initialize();
        return () => {
            controller.dispose();
        }
    }, []);

    const initialize = async () => {
        await controller.initializeListImports(handleDisconnect, handleGetLastUpdates, userLogged.company.configurations.useSocket);
    }

    const handleDisconnect = (temporary: boolean) => {
        if (temporary) {
            setHasErrorConnectSocketTemporary(true);
        } else {
            setHasErrorConnectSocket(true);
            setVisibleModalConnect(true);
            setHasErrorConnectSocketTemporary(false);
        }
    }

    const handleReconnectSocket = async (): Promise<void> => {
        try {
            setLoadingReconnect(true);
            await initialize();
            setHasErrorConnectSocket(false);
            setVisibleModalConnect(false);
            setLoadingReconnect(false);
            setHasErrorConnectSocketTemporary(false);
        } catch (e) {
            setHasErrorConnectSocket(true);
            setLoadingReconnect(false);
            toast_message.error('Não foi possível estabelecer a conexão. Por favor, tente novamente.' +
                ' Se o problema persistir, entre em contato com a equipe da DropDesk');
        }
    }

    const handleGetLastUpdates = async (): Promise<void> => {
        setHasErrorConnectSocketTemporary(false);
        await controller.listImports();
    }

    return (
        <Container background={colors.onBackground}>

            <ContentList>
                {(hasErrorConnectSocket || hasErrorConnectSocketTemporary) &&
                    <ContentInfoDisconnectedSubscription
                        loading={loadingReconnect}
                        onClickReconnect={() => handleReconnectSocket()}
                        visibleTemporaryInformation={hasErrorConnectSocketTemporary}
                    />
                }
                <SearchBar
                    loading={controller.loading}
                    visibleButtonsOfActions={controller.rowSelectionClients.length === 0}
                    period={controller.period}
                    setPeriod={(dateArray, label) => controller.setPeriod(dateArray, label)}
                    listImports={() => controller.handleListImports(appController.user?.company.configurations.useSocket ?? false)}
                    navigateToImport={() => navigate(`${RoutesEnum.importclients}/new`)}
                    labelPeriodCustom={controller.labelPeriod}
                />


                <SubContentList>
                    <TableImports
                        loading={controller.loading}
                        tableImports={controller.tableImports}
                        getDataFromPage={(page) => controller.getDataFromPageImports(page, appController.user?.company.configurations.useSocket ?? false)}
                        idRevert={controller.idRevert}
                    />

                </SubContentList>

                <ModalsRevertImportClient
                    loading={controller.loading}
                    revertImport={(id) => controller.revertImport(id)}
                />

                <ModalReconnectSocket
                    loading={controller.loading}
                    onRequestClose={() => setVisibleModalConnect(false)}
                    onRequestSave={() => handleReconnectSocket()}
                    visible={visibleModalConnect}
                />
            </ContentList>
            {(controller.loading && controller.loadingMessage) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage}
            />}
        </Container>
    );
}))
export default ImportClients;
