import {observer} from "mobx-react";
import {Container, ContainerPermission, Content} from "./styled";
import React from "react";
import HeaderConfiguration from "@dropDesk/presentation/components/headers/header_configuration";
import FooterConfiguration from "@dropDesk/presentation/components/footer/footer_configuration";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import InputsPanelAdm from "@dropDesk/presentation/pages/company_configurations/ui/painel_adm/inputs";
import {PanelAdmEntity} from "@dropDesk/domain/entities/company_configurations/panel_adm.entity";
import {
    useValidatorInputPanelAdm
} from "@dropDesk/presentation/pages/company_configurations/ui/painel_adm/inputs/validator";

const PanelADM = observer(
    ({
         loading,
         handleSubmit,
         panelAdm,
         onUpdate
     }: {
        loading: boolean,
        handleSubmit: () => void,
        panelAdm: PanelAdmEntity
        onUpdate: (panelAdm: PanelAdmEntity) => void,
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const {isValidDataForm} = useValidatorInputPanelAdm(panelAdm);

        return (
            <Container heightShowingHeaderInfo={appController.heightShowingHeaderInfo}>
                <Content background={colors.onBackground} border={colors.border}>
                    <HeaderConfiguration
                        letter={"Painel ADM"}
                        disabled={loading}
                    />
                    <ContainerPermission>
                        <InputsPanelAdm
                            panelAdm={panelAdm}
                            onUpdate={onUpdate}
                            loading={loading}
                        />
                    </ContainerPermission>
                    <FooterConfiguration
                        disabled={loading || !isValidDataForm}
                        loading={loading}
                        type={"submit"}
                        letter={"Salvar"}
                        onSubmit={handleSubmit}
                    />
                </Content>
            </Container>
        )
    })
export default PanelADM;
