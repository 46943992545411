import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import ExportDataToExcel from "@dropDesk/presentation/components/export_data_to_excel";
import {
    getEndHourCurrentDate,
    getEndHourUTC, getInitialHourCurrentDate,
    getInitialHourUTC
} from "@dropDesk/utils/helpers/date_helper";
import {TicketController} from "@dropDesk/presentation/pages/ticket/controller/ticket.controller";
import {getPeriodAndLabelDisplay} from "@dropDesk/utils/helpers/common";
import {useLocation} from "react-router-dom";
import toast_message from "@dropDesk/utils/toast_message/toast_message";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const ExportDataTicket = (observer(() => {

    const [hasErrorConnectSocket, setHasErrorConnectSocket] = useState<boolean>(false);
    const [hasErrorConnectSocketTemporary, setHasErrorConnectSocketTemporary] = useState<boolean>(false);
    const [loadingReconnect, setLoadingReconnect] = useState<boolean>(false);
    const controller = useInjection(TicketController);
    const appController = useInjection(AppController);
    const location = useLocation();
    const startDate: Date = location?.state?.startDate ?? getPeriodAndLabelDisplay().range[0] ?? new Date() as Date;
    const endDate: Date = location?.state?.endDate ?? getPeriodAndLabelDisplay().range[1] ?? new Date() as Date;

    const initialize = async () => {
        await startExport();
    }

    useEffect(() => {
        initialize();
        return () => {
            controller.dispose();
        }
    }, []);

    const handleDisconnect = (temporary: boolean) => {
        if (temporary) {
            setHasErrorConnectSocketTemporary(true);
        } else {
            setHasErrorConnectSocket(true);
            setHasErrorConnectSocketTemporary(false);
        }
    }

    const handleReconnectSocket = async (): Promise<void> => {
        try {
            setLoadingReconnect(true);
            await initialize();
            setHasErrorConnectSocket(false);
            setLoadingReconnect(false);
            setHasErrorConnectSocketTemporary(false);
        } catch (e) {
            setHasErrorConnectSocket(true);
            setLoadingReconnect(false);
            toast_message.error('Não foi possível estabelecer a conexão. Por favor, tente novamente.' +
                ' Se o problema persistir, entre em contato com a equipe da DropDesk');
        }
    }

    const handleGetLastUpdates = async (): Promise<void> => {
        await handleReconnectSocket();
    }

    const startExport = async () => {
        await controller.export({
            startDate: getInitialHourCurrentDate(startDate),
            endDate: getEndHourCurrentDate(endDate)
        }, handleDisconnect, handleGetLastUpdates, appController.user?.company.configurations.useSocket ?? false)
    }

    return (
        <ExportDataToExcel
            tableName={'atendimentos'}
            loading={controller.loading}
            loadingMessage={controller.loadingMessage}
            initExport={() => startExport()}
            exportation={controller.exportation}
            period={{
                startDate: getInitialHourUTC(startDate),
                endDate: getEndHourUTC(endDate)
            }}
            handleReconnectSocket={handleReconnectSocket}
            hasErrorConnectSocket={hasErrorConnectSocket}
            hasErrorConnectSocketTemporary={hasErrorConnectSocketTemporary}
            loadingReconnect={loadingReconnect}
        />
    )

}));
export default ExportDataTicket;
