import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import ExportDataToExcel from "@dropDesk/presentation/components/export_data_to_excel";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useNavigate} from "react-router-dom";
import toast_message from "@dropDesk/utils/toast_message/toast_message";
import {AppController} from "@dropDesk/presentation/app/app.controller";

const ExportContacts = (observer(() => {

    const controller = useInjection(UserController);
    const navigate = useNavigate();
    const [hasErrorConnectSocket, setHasErrorConnectSocket] = useState<boolean>(false);
    const [hasErrorConnectSocketTemporary, setHasErrorConnectSocketTemporary] = useState<boolean>(false);
    const [loadingReconnect, setLoadingReconnect] = useState<boolean>(false);
    const appController = useInjection(AppController);


    const initialize = async () => {
        controller.setRole(UserRole.userClient);
        await startExport();
    }

    const handleNoContent = () => {
        navigate(RoutesEnum.contacts);
        toastMessage.info("Não há contatos para serem exportados");
    }

    const handleDisconnect = (temporary: boolean) => {
        if (temporary) {
            setHasErrorConnectSocketTemporary(true);
        } else {
            setHasErrorConnectSocket(true);
            setHasErrorConnectSocketTemporary(false);
        }
    }

    const handleReconnectSocket = async (): Promise<void> => {
        try {
            setLoadingReconnect(true);
            await initialize();
            setHasErrorConnectSocket(false);
            setLoadingReconnect(false);
            setHasErrorConnectSocketTemporary(false);
        } catch (e) {
            setHasErrorConnectSocket(true);
            setLoadingReconnect(false);
            toast_message.error('Não foi possível estabelecer a conexão. Por favor, tente novamente.' +
                ' Se o problema persistir, entre em contato com a equipe da DropDesk');
        }
    }

    const handleGetLastUpdates = async (): Promise<void> => {
        await handleReconnectSocket();
    }

    const startExport = async () => {
        await controller.export(handleNoContent, handleDisconnect, handleGetLastUpdates, appController.user?.company.configurations.useSocket ?? false)
    }

    useEffect(() => {
        initialize();
        return () => {
            controller.dispose();
        }
    }, []);

    return (
        <ExportDataToExcel
            tableName={'contatos'}
            loading={controller.loading}
            loadingMessage={controller.loadingMessage}
            initExport={() => startExport()}
            exportation={controller.exportation}
            handleReconnectSocket={handleReconnectSocket}
            hasErrorConnectSocket={hasErrorConnectSocket}
            hasErrorConnectSocketTemporary={hasErrorConnectSocketTemporary}
            loadingReconnect={loadingReconnect}
        />
    )

}));
export default ExportContacts;
