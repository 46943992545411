import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";

@injectable()
export class JoinTicketChatUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(ticketId: string): void {
        return this._repository.joinTicket(ticketId);
    }
}

