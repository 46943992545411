import {ThemeEntity} from "@dropDesk/domain/entities/theme/theme.entity";
import {Brightness, CustomColors} from "@dropDesk/domain/entities/theme/colors.entity";
import {AppCustomColors} from "@dropDesk/domain/entities/theme/app_cutom_colors";
import {ColorsType} from "@dropDesk/domain/entities/theme/colors_enum";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {DropDeskFiltersEntity} from "@dropDesk/domain/entities/common/dropdesk_filters.entity";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {
    getCurrentMonthDates,
    getCurrentWeek,
    getLastWeekDates, getPreviousMonthDates,
    getYesterdayDate, isDateLessThan3MonthsAgo
} from "@dropDesk/utils/helpers/date_helper";
import {parseServerError} from "@dropDesk/data/clients/http.client";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {AudioCacheEntity, AudioRecordEntity} from "@dropDesk/domain/entities/common/audio_record.entity";

export const getActiveRoute = (): string => {
    const route = `/${window.location.pathname.split('/')[1]}`;
    const activeRoute = route === RoutesEnum.exportsectors ? RoutesEnum.sectors :
        route === RoutesEnum.exportclients ? RoutesEnum.clients :
            route === RoutesEnum.exportusers ? RoutesEnum.users :
                route === RoutesEnum.exportcontacts ? RoutesEnum.contacts : route;
    return activeRoute;
}

export const isTicketChatRoute = (): boolean => {
    const ticketView = getActiveRoute() === RoutesEnum.tickets;
    const subPath = (`/${window.location.pathname.split('/')[2]}`).replace('/', '');
    const isTicketChatView = !!ticketView && !!subPath && subPath === 'view';
    return isTicketChatView;
}

export const copyToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text);
}
export const getThemeEntityByString = (themeName: ColorsType, currentBrightess: Brightness): ThemeEntity => {
    let themeColors: CustomColors = AppCustomColors.getColor(themeName);
    themeColors = themeColors.setBrightness(currentBrightess);
    return new ThemeEntity(themeColors);
}

export const getDifferentTheme = (currentTheme: ThemeEntity): ThemeEntity => {
    const brightness = currentTheme.colorScheme.brightness === Brightness.dark ? Brightness.light : Brightness.dark;
    let themeColors: CustomColors = AppCustomColors.getColor(currentTheme.colorScheme.name as ColorsType);
    themeColors = themeColors.setBrightness(brightness);
    return new ThemeEntity(themeColors);
}

export const getThemeName = (userTheme?: string): string => {
    const defaultThemeName = 'green';
    if (!userTheme) {
        return defaultThemeName;
    }
    const themeName = userTheme.split('-')[1];

    if (themeName) {
        return themeName;
    }
    return defaultThemeName;
}

export const getLocalTheme = (): ThemeEntity => {
    const localData: string | null = localStorage.getItem(process.env.THEME as string);
    const brightness = localData ? localData.split('-')[0] : Brightness.light;
    const themeName = localData ? localData.split('-')[1] : ColorsType.default;

    if (themeName) {
        let themeColors: CustomColors = AppCustomColors.getColor(themeName as ColorsType);
        themeColors = themeColors.setBrightness(brightness as Brightness);
        return new ThemeEntity(themeColors);
    }
    return new ThemeEntity(AppCustomColors.getColor(ColorsType.default));
}


export const getIdCompanyByLocalStorage = (): string => {
    return localStorage.getItem(process.env.STORAGE_COMPANY as string) ?? '';
}

export const scrollInBottom = (element: HTMLElement): boolean => {
    return Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) <= 1;
}


export const scrollInTopColumnReverse = (element: HTMLElement): boolean => {

    return Math.abs(element.scrollHeight - element.clientHeight + Math.round(element.scrollTop)) <= 1;
}

export const scrollInTopColumnReversePercentage = (element: HTMLElement, percent: number): boolean => {
    const scrollPosition = element.scrollTop;
    const containerHeight = element.clientHeight;
    const scrollThreshold = containerHeight * percent;
    return scrollPosition >= scrollThreshold;
}

export const scrollInBottomColumnReversePercentage = (element: HTMLElement, percent: number): boolean => {
    const elementHeight = element.clientHeight;
    const windowHeight = window.innerHeight;
    const scrollTop = element.scrollTop;
    return (scrollTop + windowHeight) >= (elementHeight * percent);
}
export const showButtonMoveToBottom = (element: HTMLElement, maxHeight: number): boolean => {
    return (Math.abs(Math.round(element.scrollTop))) >= maxHeight;
}

export const scrollInBottomColumnReverse = (element: HTMLElement): boolean => {
    return Math.abs(Math.round(element.scrollTop)) <= 1;
}

export const setBlockUpdateLocalSearchCache = (value: boolean) => {
    localStorage.setItem(ConstantsKeys.blockUpdateLocalSearchCache as string, JSON.stringify(value));
}

export const isBlockUpdateLocalSearchCache = (): boolean => {
    const value = JSON.parse(localStorage.getItem(ConstantsKeys.blockUpdateLocalSearchCache as string) ?? 'false');
    return value;
}

export const setActivePageLocalStorage = (page: number) => {
    if (!isBlockUpdateLocalSearchCache()) {
        localStorage.setItem(ConstantsKeys.activePage as string, JSON.stringify(page));
    }
}

export const getActivePageLocalStorage = (): number => {
    return parseInt(localStorage.getItem(ConstantsKeys.activePage as string) ?? '0');
}

export const setActiveRouteLocalStorage = (route: string) => {
    if (!isBlockUpdateLocalSearchCache()) {
        localStorage.setItem(process.env.ACTIVE_ROUTE as string, route);
    }
}

export const getActiveRouteLocalStorage = (): string => {
    return localStorage.getItem(process.env.ACTIVE_ROUTE as string) ?? '';
}

export const setSearchParamLocalStorage = (searchParam: string) => {
    if (!isBlockUpdateLocalSearchCache()) {
        localStorage.setItem(process.env.SEARCH_PARAM as string, searchParam);
    }
}

export const getSearchParamLocalStorage = (): string => {
    return localStorage.getItem(process.env.SEARCH_PARAM as string) ?? '';
}

export const getFiltersFromLocalStorage = (): DropDeskFiltersEntity => {
    return DropDeskFiltersEntity.fromLocalStorage(localStorage.getItem(ConstantsKeys.localStorageFilters));
}

export const setGenericFiltersLocalStorage = (object: Record<string, any>): void => {
    let filters = getFiltersFromLocalStorage();
    const key = Object.keys(object)[0];
    filters = filters.copyWith({
        ...object,
        replaceTicketFilterPriorityIfNull: key === 'ticketFilterPriority',
        replaceTicketFilterStatusIfNull: key === 'ticketFilterStatus',
        replaceTicketListEmptySectorIfNull: key === 'ticketListEmptySector',
        replaceSectorFilterListableForClientsIfNull: key === 'sectorFilterListableForClients',
        replaceNoticeFilterDestinyIfNull: key === 'noticeFilterDestiny',
        replaceReportTicketPersonIfNull: key === 'reportTicketFilterPerson',
        replaceReportTicketUserIfNull: key === 'reportTicketFilterUser',
        replaceReportTicketRateUserIfNull: key === 'reportTicketRateFilterUser',
        replaceReportTicketClientIfNull: key === 'reportTicketFilterClient',
        replaceReportTicketSectorIfNull: key === 'reportTicketFilterSector',
        replaceTicketEvaluationFilterValueIfNull: key === 'ticketEvaluationFilterValue',
        replaceDescriptionStaticFilterListableForClientsIfNull: key === 'descriptionStaticFilterListableForClients',
    })
    setFiltersLocalStorage(filters);
}

export const setFiltersLocalStorage = (filters: DropDeskFiltersEntity): void => {
    localStorage.setItem(ConstantsKeys.localStorageFilters, JSON.stringify(filters));
}

export const initializeConfigurationLocalStorage = (routeName: string, forceResetRouter = true): {
    lastPage: number,
    initSearchParam: string
    filters: DropDeskFiltersEntity,
} => {
    setBlockUpdateLocalSearchCache(false);
    let initSearchParam: string = getSearchParamLocalStorage();
    const lastRoute = getActiveRouteLocalStorage();
    let lastPage: number = getActivePageLocalStorage();
    let filters: DropDeskFiltersEntity = getFiltersFromLocalStorage();

    if ((lastRoute !== routeName) && forceResetRouter) {
        initSearchParam = '';
        lastPage = 0;
        filters = DropDeskFiltersEntity.eraseFilters();
        setSearchParamLocalStorage(initSearchParam);
        setActiveRouteLocalStorage(routeName);
        setActivePageLocalStorage(lastPage);
        setFiltersLocalStorage(filters);
    }

    return {initSearchParam, lastPage, filters};
}

export const setDraftMessageCache = (idTicket: string, message: TicketMessageEntity, audio: AudioCacheEntity): void => {
    const draftMessagesCache = eligibleMessagesDraftCache(idTicket);
    const newDraftMessage = {[idTicket]: {message, audio}, ...draftMessagesCache};
    localStorage.setItem(ConstantsKeys.draftMessageCache, JSON.stringify(newDraftMessage));
}

export const getMessagesDraftCache = (): Record<string, Record<string, any>> | null => {
    const draftCacheString: string | null = localStorage.getItem(ConstantsKeys.draftMessageCache);
    return draftCacheString ? JSON.parse(draftCacheString) : draftCacheString;
}

export const getMessageDraftCacheById = (idTicket: string): {
    message: TicketMessageEntity,
    audio: AudioCacheEntity
} | null => {
    const draftMessagesCache: Record<string, Record<string, any>> | null = getMessagesDraftCache();
    return draftMessagesCache?.[idTicket]
        ? {
            message: TicketMessageEntity.fromJson(draftMessagesCache[idTicket].message),
            audio: draftMessagesCache[idTicket].audio
        }
        : null;
}

const eligibleMessagesDraftCache = (keyToRemove: string): Record<string, Record<string, any>> | null => {
    const object = getMessagesDraftCache();
    if (object?.hasOwnProperty(keyToRemove)) {
        const {[keyToRemove]: _, ...newObj} = object;
        return newObj;
    } else {
        return object;
    }
};

export const preventMissingPage = (value: ListPaginationEntity<any>): boolean => {
    if (value.page > value.pages) {
        value.page = 0;
        setActivePageLocalStorage(value.page);
        setGenericFiltersLocalStorage(DropDeskFiltersEntity.eraseFilters());
        return true;
    }
    setActivePageLocalStorage(value.page);
    return false;
}

export const setPeriodAndLabelDisplay = (dateParams: DateParams): void => {
    localStorage.setItem(ConstantsKeys.dateParams, JSON.stringify(dateParams));
}

export const getPeriodAndLabelDisplay = (restrictMonthsAgo?: boolean): DateParams => {
    const initDateParams = localStorage.getItem(ConstantsKeys.dateParams);
    const dateParams: DateParams = initDateParams ? JSON.parse(initDateParams) as any as DateParams : {
        range: getCurrentWeek(),
        label: PeriodEnum.currentWeek
    };
    if (dateParams.label === PeriodEnum.custom) {
        const starDate = dateParams.range && dateParams.range[0] ? dateParams.range[0] : new Date();
        const endDate = dateParams.range && dateParams.range[1] ? dateParams.range[1] : new Date();
        const isDate3MonthsAgo = isDateLessThan3MonthsAgo(new Date(starDate).toISOString());
        if(restrictMonthsAgo && isDate3MonthsAgo){
            return {
                range: getCurrentWeek(),
                label: PeriodEnum.currentWeek
            };
        }
        return {
            range: [new Date(starDate), new Date(endDate)],
            label: dateParams.label
        };
    } else {
        return getStaticDateFromPeriod(dateParams.label);
    }
}

export const getStaticDateFromPeriod = (period: PeriodEnum): DateParams => {
    if (period === PeriodEnum.now) {
        return {
            range: [new Date(), new Date()], label: PeriodEnum.now
        };
    } else if (period === PeriodEnum.yesterday) {
        return {
            range: [getYesterdayDate(), getYesterdayDate()], label: PeriodEnum.yesterday
        };
    } else if (period === PeriodEnum.currentWeek) {
        return {
            range: getCurrentWeek() as Array<Date>, label: PeriodEnum.currentWeek
        };
    } else if (period === PeriodEnum.lastWeek) {
        return {
            range: getLastWeekDates() as Array<Date>, label: PeriodEnum.lastWeek
        };
    } else if (period === PeriodEnum.currentMonth) {
        return {
            range: getCurrentMonthDates() as Array<Date>, label: PeriodEnum.currentMonth
        };
    } else {
        return {
            range: getPreviousMonthDates() as Array<Date>, label: PeriodEnum.lastMonth
        };
    }
}

export const getOnlyLabelFromStorage = (): PeriodEnum => {
    const initDateParams = localStorage.getItem(ConstantsKeys.dateParams);
    const dateParams: DateParams = initDateParams ? JSON.parse(initDateParams) as any as DateParams : {
        range: getCurrentWeek(),
        label: PeriodEnum.currentWeek
    };
    return dateParams.label;
}

export type DateParams = {
    range: Array<Date>,
    label: PeriodEnum
}

export enum PeriodEnum {
    now = 'now',
    yesterday = 'yesterday',
    currentWeek = 'currentWeek',
    lastWeek = 'lastWeek',
    currentMonth = 'currentMonth',
    lastMonth = 'lastMonth',
    custom = 'custom',
}

export const formatCurrency = (value: number): string => {
    const formattedValue = value.toFixed(2);
    return `R$ ${formattedValue.replace('.', ',')}`;
}


export const getMediaStream = (): Promise<MediaStream> => {
    return new Promise<MediaStream>(async (resolve, reject) => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            return resolve(stream);
        } catch (error: any) {
            return reject(parseServerError(error));
        }
    })
}

export const sleep = async (seconds: number) => {
    return new Promise(function (resolve, reject) {
        return setTimeout(() => {
            return resolve(null)
        }, seconds * 1000);
    }).then((result) => {
        return result;
    })
}
export const isSmallestNumberInArray = (array: Array<number>, page: number) => {
    return array.every(number => number >= page);
}

export const isLargestNumberInArray = (array: Array<number>, page: number) => {
    return page === Math.max(...array);
}

export const findMaxNumber = (numbers: Array<number>) => {
    return Math.max(...numbers);
}

export const findMinNumber = (numbers: Array<number>) => {
    const minPage = Math.min(...numbers);
    return minPage >= 0 ? minPage : 0;
}

export const orderByCreatedAt = (array: Array<Record<string, any>>) => {
    array.sort((a, b) => new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime());
}

export const isIntegerNumber = (value: number) => {
    return value === Math.floor(value);
}
