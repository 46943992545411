import {io, Socket} from 'socket.io-client';
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {getToken} from "@dropDesk/data/data_source/token/token.datasource";

let handleDisconnect: null | Function = null;
let getLastUpdates: null | Function = null;
let origin: string | null = null;
let id: string | null = null;
export let socket: Socket | null = null;

export const socketConnect = (
    params: { [key: string]: string },
    _handleDisconnect: (temporary: boolean) => void,
    _getLastUpdates: () => void,
    _origin: string | null,
    _id: string | null,
    type: 'chat' | 'payment' | 'export' | 'import' | 'chatIntegration'
) => {
    socket = io(`${process.env.SOCKET_URL}${type}`, {
        autoConnect: false,
        reconnectionAttempts: ConstantsKeys.limitReconnectionAttemptsSocket,
        transports: ['websocket'],
        timeout: 10000,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        closeOnBeforeunload: true,
    });
    console.log('conectando socket');
    socket.io.opts.query = params;
    handleDisconnect = _handleDisconnect;
    getLastUpdates = _getLastUpdates;
    origin = _origin;
    id = _id;
    attachSocketListeners(socket);
    socket.connect();
};

export const disconnect = (channels: string[]) => {
    console.log('desligando socket', channels);
    for (const channel of channels) {
        socket?.off(channel);
    }
    handleDisconnect = null;
    getLastUpdates = null;
    origin = null;
    id = null;
    socket?.disconnect();
    socket = null;
};

const handleDisconnectLocal = (temporary:boolean) => {
    if (handleDisconnect) {
        handleDisconnect(temporary);
    }
}

const attachSocketListeners = (socket: Socket) => {
    socket.on('connect', () => {
        console.log('Socket Connected', new Date().toISOString());
    });

    socket.io.on('reconnect', () => {
        console.log('Socket Reconnected', displayDateToLocale(new Date().toISOString()));
        if (getLastUpdates) {
            getLastUpdates();
        }
    });

    socket.on('disconnect', (reason: string) => {
        console.log('Socket Disconnected:', reason, displayDateToLocale(new Date().toISOString()));
    });

    socket.io.on('error', (error: Error) => {
        console.log('Socket Error:', error, displayDateToLocale(new Date().toISOString()), socket.active);
    });

    socket.on('connect_failed', (error: Error) => {
        console.log('Socket Connection Failed:', error.message);
        const channelsDisconnect = ['message'];
        if (id) {
            channelsDisconnect.push(id)
        }
        disconnect(channelsDisconnect);
        handleDisconnectLocal(false);
    });

    socket.on('connect_error', async (error: any) => {
        if (socket.active) {
            console.log('falha temporária, o soquete tentará reconectar automaticamente');
            const token = await getToken();
            socket.io.opts.query = {
                authorization: `Bearer ${token}`,
                origin: origin ?? ''
            }
            handleDisconnectLocal(true);
        } else {
            console.log('a conexão foi negada pelo servidor, nesse caso, `socket.connect()` deve ser chamado manualmente para reconectar');
            handleDisconnectLocal(false);
        }
    });

    socket.io.on('reconnect_failed', function () {
        console.log("reconnect failed");
        const channelsDisconnect = ['message'];
        if (id) {
            channelsDisconnect.push(id)
        }
        handleDisconnectLocal(false);
        disconnect(channelsDisconnect);
    });
};