import {io} from 'socket.io-client';
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";

export const socket = io(process.env.SOCKET_URL, {
    autoConnect: false,
    reconnectionAttempts: 15,
    transports: ['websocket'],
    timeout: 10000,
    reconnection: true
});

export const socketConnect = (params?: { [key: string]: string }) => {
    console.log('conectando socket');
    socket.io.opts.query = params;
    socket.connect();
};

export const disconnect = (channels: string[]) => {
    console.log('desligando socket', channels);
    for (const channel of channels) {
        socket.off(channel);
    }
    socket.disconnect();
};

socket.on('connect', () => {
    console.log('Socket Connected', displayDateToLocale(new Date().toISOString()));
});

socket.on('reconnect_attempt', (attemptNumber: number) => {
    console.log(`Socket Reconnect attempt #${attemptNumber}`);
});

socket.on('reconnect', () => {
    console.log('Socket Reconnected', displayDateToLocale(new Date().toISOString()));
});

socket.on('disconnect', (reason: string) => {
    console.log('Socket Disconnected:', reason, displayDateToLocale(new Date().toISOString()));
});

socket.on('error', (error: Error) => {
    console.log('Socket Error:', error, displayDateToLocale(new Date().toISOString()));
});

socket.on('connect_error', (error: Error) => {
    console.log('Socket Connection Error:', error.message);
});

socket.on("reconnect_error", (error) => {
    console.log("Erro durante a tentativa de reconexão:", error);
});

socket.on("reconnect_failed", () => {
    console.log("Não foi possível reconectar após várias tentativas.");
});
