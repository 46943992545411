import styled from "styled-components";

export const ContentCheckBoxCommentPrivate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-left: 10px;
  @media (orientation: portrait) {
    margin-left: 35px;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;


export const ContentCommentPrivate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (orientation: portrait) {
    width: 100%;
  }
`;
