import {
    Container,
    ContainerDescription,
    ContentDescriptionProblem,
    ContentLineClamp,
    TextDescriptionProblem,
    ContentProtocol,
    TextProtocol,
    ContentMoreInformation,
    ContentButtonMoreInformation,
    Separator,
    ContentDataTicket,
    ContentClientData,
    ContentEdit,
    TextClient,
    IconEdit,
    ContentSector,
} from "./styled";
import {Tooltip} from "antd";
import {observer} from "mobx-react";
import React, {useContext} from "react";
import {useBetween} from "use-between";
import {useModalViewTickets} from "@dropDesk/presentation/components/chat_view/modals/visible_modal_view_ticket";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {MenuCollapsedContext} from "@dropDesk/presentation/routes/private_route";

const HeaderViewInformation = observer(
    ({
         ticket,
         canChange,
         canChangeClient,
         alertPulseAnimation,
         loading,
         widthContainerListChats
     }: {
        ticket: TicketEntity
        canChange: boolean,
        canChangeClient: boolean,
        loading: boolean,
        alertPulseAnimation?: boolean,
        widthContainerListChats?: number

    }) => {
        const collapsed = useContext(MenuCollapsedContext);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const widthContainerProfile = 61;
        const widthHeader = ((widthContainerListChats ?? 0) + (collapsed ? 80 : 250) + widthContainerProfile + 100);
        const {
            setVisibleModalCreateClient,
            setVisibleModalChangeProblemDescription
        } = useBetween(useModalViewTickets);

        return (
            <Container bordercolor={colors.delicateBackground} background={"transparent"}>

                <ContainerDescription>
                    <ContentDataTicket>
                        <AvatarImage
                            useCursorPointer={ticket.userClient?.urlImageProfile ? true : false}
                            title={ticket.userClient?.name ?? ''}
                            size={45}
                            url={ticket.userClient?.urlImageProfile}
                            round={true}
                            hasPreviewImage={true}
                            letterSize={20}
                            isBackgroundChat={true}
                            alertPulseAnimation={alertPulseAnimation}
                        />
                        <ContentDescriptionProblem>
                            <ContentClientData>
                                <ContentProtocol>
                                    <Tooltip title={"Protocolo do atendimento"} placement="topLeft">
                                        <TextProtocol color={colors.text}>#{ticket.number}</TextProtocol>
                                    </Tooltip>
                                </ContentProtocol>

                                <Separator>
                                    <TextProtocol color={colors.text}> - </TextProtocol>
                                </Separator>
                                <ContentEdit color={colors.text} canChange={canChangeClient} onClick={() => {
                                    if (canChangeClient) {
                                        setVisibleModalCreateClient(true);
                                    }
                                }}>
                                    <ContentLineClamp width={widthHeader + 50}>
                                        <Tooltip title={"Nome do contato"} placement="topLeft">
                                            <TextClient
                                                color={colors.text}>{ticket.userClient?.name ?? ''}</TextClient>
                                        </Tooltip>

                                        {ticket.userClient?.telephone &&

                                            <Tooltip title={"Telefone do contato"} placement="topLeft">
                                                <TextClient
                                                    color={colors.text}>&nbsp;&nbsp;{ticket.userClient?.telephone ?? ''}</TextClient>
                                            </Tooltip>
                                        }
                                    </ContentLineClamp>

                                    <IconEdit
                                        size={13}
                                        className={`icon-${ICONS_DROPDESK.edit}`}
                                        color={colors.text}
                                    />
                                </ContentEdit>
                            </ContentClientData>

                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <ContentEdit color={colors.text} canChange={canChange} onClick={() => {
                                    if (canChange) {
                                        setVisibleModalChangeProblemDescription(true);
                                    }
                                }}>
                                    <ContentLineClamp width={widthHeader} toShare={!!ticket.sector}>
                                        <Tooltip placement="topLeft" title={"Descrição do problema"}>
                                            <TextDescriptionProblem color={colors.text}>
                                                {ticket.realDescription(appController.user?.company.configurations.ticket.useProblemStatic ?? false, loading)}
                                            </TextDescriptionProblem>
                                        </Tooltip>
                                    </ContentLineClamp>
                                    <IconEdit
                                        size={13}
                                        className={`icon-${ICONS_DROPDESK.edit}`}
                                        color={colors.text}
                                    />
                                </ContentEdit>

                                {ticket.sector && <ContentSector color={colors.text}>
                                    <ContentLineClamp width={widthHeader} toShare={!!ticket.sector}>
                                        <Tooltip placement="topLeft" title={"Setor"}>
                                            <TextDescriptionProblem color={colors.text}>
                                                &nbsp;&nbsp;|&nbsp;&nbsp;{ticket.sector?.realName(appController.user!) ?? ''}
                                            </TextDescriptionProblem>
                                        </Tooltip>
                                    </ContentLineClamp>
                                </ContentSector>}
                            </div>
                        </ContentDescriptionProblem>
                    </ContentDataTicket>


                </ContainerDescription>

                <ContentMoreInformation>

                    <ContentButtonMoreInformation>


                    </ContentButtonMoreInformation>

                </ContentMoreInformation>
            </Container>
        );
    });
export default HeaderViewInformation;
