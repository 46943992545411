import React from "react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Button, Container} from "@dropDesk/presentation/pages/subscription/ui/modals/pay_info/buttons_invoice/styled";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {copyToClipboard} from "@dropDesk/utils/helpers/common";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";

const ButtonsInvoice = (
    {
        digitableLine,
        inverse,
        onClickDownloadInvoice,
    }: {
        digitableLine: string,
        inverse?: boolean,
        onClickDownloadInvoice: () => void
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const color = inverse ? colors.accent : SharedColors.white;
    const background = inverse ? 'transparent' : colors.accent;

    return (
        <Container>
            <Button
                onClick={() => {
                    copyToClipboard(digitableLine);
                    toastMessage.success('Código de barras copiado para área de transferência.');
                }}
                size={12}
                color={color}
                background={background}
                accent={colors.accent}
                style={{marginRight: 10}}
            >
                <DropDeskIcon size={18} icon={ICONS_DROPDESK.billet} color={color} style={{marginRight: 10}}/>
                Copiar cód. de barras
            </Button>
            <Button
                onClick={() => {
                    onClickDownloadInvoice();
                }}
                size={12}
                color={color}
                background={background}
                accent={colors.accent}
            >
                <DropDeskIcon size={18} icon={ICONS_DROPDESK.download} color={color} style={{marginRight: 10}}/>
                Baixar fatura
            </Button>
        </Container>
    )
}
export default ButtonsInvoice;
