import React, {useEffect} from "react";
import {useInjection} from "inversify-react";
import {useNavigate, useParams} from "react-router-dom";
import {
    ContentFormTickets,
    ContentFormCreateTicket, ContainerCreate,
} from "./styled";
import {observer} from "mobx-react";
import GenericScreenNotFound from "@dropDesk/presentation/components/generic_screen_not_found";
import {TicketController} from "@dropDesk/presentation/pages/ticket/controller/ticket.controller";
import HeaderFormCreateTicket from "@dropDesk/presentation/pages/ticket/ui/form_create/header_form";
import InputsFormCreateTickets from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs";
import {useValidatorInputTickets} from "@dropDesk/presentation/pages/ticket/ui/form_create/inputs/validator";
import FooterFormCreateTicket from "@dropDesk/presentation/pages/ticket/ui/form_create/footer_form";
import Modals from "@dropDesk/presentation/pages/ticket/ui/form_create/modals";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {ClientController} from "@dropDesk/presentation/pages/client/controller/client.controller";
import {useTranslation} from "react-i18next";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ChatController} from "@dropDesk/presentation/pages/chat/controller/chat.controller";


const FormCreateTicket = observer(
    ({
         isEdit,
         handleSubmitExternalScreen,
         backPreviousScreenExternalScreen,
         idTicketEdit,
         isNewChat,
         isNewChatBody
     }: {
        handleSubmitExternalScreen?: (ticket: TicketEntity) => void,
        backPreviousScreenExternalScreen?: () => void,
        isEdit?: boolean
        idTicketEdit?: string
        isNewChat?: boolean
        isNewChatBody?: { client: ClientEntity, contact: UserEntity | null }

    }) => {
        const {t} = useTranslation();
        const controller = useInjection(TicketController);
        const appController = useInjection(AppController);
        const clientController = useInjection(ClientController);
        const chatController = useInjection(ChatController);
        const userLogged = appController.user!;
        const colors = appController.theme.colorScheme;
        const {id} = useParams();
        const isNewRegister = 'new';
        const navigate = useNavigate();
        const useProblemStatic = userLogged.company.configurations.ticket.useProblemStatic;

        const initialize = async () => {
            if ((id === isNewRegister) || isNewChat) {
                controller.makeTicket(userLogged, appController.ticketsGlobalConfigs, isNewChatBody);
            } else {
                await controller.getTicket(idTicketEdit ?? id ?? isNewRegister);
            }
        }


        useEffect(() => {
            initialize();
        }, []);

        const {
            validate,
            isValidDataForm,
            autoFillData,
            setAutoFillDataLocalStorage
        } = useValidatorInputTickets(controller.ticket);

        const onSuccess = (key: string, ticket: TicketEntity) => {
            if (handleSubmitExternalScreen) {
                handleSubmitExternalScreen(ticket);
                backPreviousScreen();
            } else {
                chatController.setChat(ticket);
                navigate(`${RoutesEnum.ticketsView}/${ticket.id}`);
            }
            toastMessage.success(t(key));
        }

        const handleSubmit = async () => {
            if (validate()) {
                await controller.set(onSuccess);
            }
        };

        const backPreviousScreen = () => {
            if (backPreviousScreenExternalScreen) {
                backPreviousScreenExternalScreen()
            } else {
                navigate(-1);
            }
        };

        return (
            <ContainerCreate background={(isEdit || isNewChat) ? 'transparent' : colors.onBackground}>

                <ContentFormTickets>
                    <ContentFormCreateTicket
                        isUserClient={appController.isUserClient}
                        style={{margin: (isEdit || isNewChat) ? 0 : 16}}
                        background={colors.onBackground}
                        border={colors.border}
                    >

                        {!controller.ticketsNotFound && controller.ticket &&
                            <>
                                <HeaderFormCreateTicket
                                    loading={controller.loading}
                                    backPreviousScreen={backPreviousScreen}
                                />

                                <InputsFormCreateTickets
                                    onUpdate={(ticket) => controller.setTicket(ticket)}
                                    handleRemoveClient={controller.handleRemoveClient}
                                    ticket={controller.ticket}
                                    loading={controller.loading}
                                    isEdit={isEdit}
                                    isNewChat={isNewChat}
                                    useProblemStatic={useProblemStatic}
                                    permissionScreenClients={appController.user?.permissionScreenClients ?? false}
                                />

                                <Modals
                                    handleSelectClientAndDependOpenModal={async (client) => {
                                        const _client = await clientController.listOnlyUsersLinked(client.id);
                                        return controller.handleSelectClientAndDependOpenModal(_client)
                                    }}
                                    handleSelectContact={(contact) => controller.handleSelectContact(contact)}
                                    handleSelectUserAttendant={(user) => controller.handleSelectUserAttendant(user, appController.ticketsGlobalConfigs)}
                                    handleSelectSector={(sector) => controller.handleSelectSector(sector, appController.ticketsGlobalConfigs)}
                                    handleSelectDescriptionStatic={(descriptionStatic) => controller.handleSelectDescriptionStatic(descriptionStatic)}
                                    onCreateClient={(client) => controller.handleSelectClientAndDependOpenModal(client)}
                                    onEditClient={(client) => controller.handleChangeClient(client)}
                                    client={controller.ticket.client}
                                    isEdit={isEdit}
                                    idUserLogged={userLogged.permissionAdmin ? undefined : userLogged.id}
                                    idSector={controller.ticket.idSector}
                                />

                                <FooterFormCreateTicket
                                    isValidDataForm={isValidDataForm}
                                    loading={controller.loading}
                                    handleSubmit={handleSubmit}
                                    backPreviousScreen={backPreviousScreen}
                                    autoFillData={autoFillData}
                                    setAutoFillDataLocalStorage={setAutoFillDataLocalStorage}
                                />
                            </>}

                    </ContentFormCreateTicket>

                    {controller.ticketsNotFound &&
                        <GenericScreenNotFound
                            textNotFound={'Oops, Atendimento não encontrado ...'}
                            textHeaderForm={"Voltar"}
                        />
                    }
                </ContentFormTickets>
                {(controller.loading || clientController.loading) && <DropDeskLoading
                    height={250}
                    description={controller.loadingMessage ?? clientController.loadingMessage}
                />}
            </ContainerCreate>
        );
    });

export default FormCreateTicket;
