export enum StatusTypes {
    ERROR = 'ERROR',
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
    REPLACED = 'REPLACED',
    CONNECTING = 'CONNECTING',
    UNAUTHENTICATED = 'UNAUTHENTICATED',
    CRASHED = 'CRASHED',
    RECONNECTING = 'RECONNECTING',
    LOGGED_OUT = 'LOGGED_OUT',
    TIMED_OUT = 'TIMED_OUT',
    UPSERT = 'UPSERT',
    UPDATE = 'UPDATE',
    DUPLICATE = 'DUPLICATE',
}

export enum TabsChat {
    waiting = 'waiting',
    attending = 'attending',
    contacts = 'contacts'
}

export enum TypeChatSocket {
    informRooms = 'informRooms',
    ticket = 'ticket',
    message = 'message',
    update = 'update',
    unknown = 'unknown',
    closedBeforeAttending = 'closedBeforeAttending',
    notifyBeforeClose = 'notifyBeforeClose'
}

export enum SubscriptionType {
    gql = 'gql',
    socket = 'socket',
}
