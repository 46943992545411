import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";

export class SocketExportResponse {
    type!: TypeExportSocket;
    exportData?: ExportDataEntity;

    constructor({
                    exportData,
                    type
                }: {
        exportData?: ExportDataEntity;
        type: TypeExportSocket;

    }) {
        Object.assign(this, {
            exportData,
            type
        });
    }

    public static fromJson(json: Record<string, any>): SocketExportResponse {
        return new SocketExportResponse({
            type: json.type,
            exportData:
                json.data?.export
                    ?
                    new ExportDataEntity({
                        id: json.data?.export ? json.data?.export.id : '',
                        idCompany: json.data?.export ? json.data?.export.idCompany : '',
                        table: json.data?.export ? json.data?.export.table : '',
                        url: json.data?.export ? json.data?.export.url : '',
                        status: json.data?.export ? json.data?.export.status : StatusImport.done,
                        progress: json.data?.export ? parseFloat((json.data?.export.progress * 100).toFixed(2)) : 0,
                        rows: json.data?.export ? json.data?.export.rows : 0,
                        createdAt: json.data?.export ? json.data?.export.createdAt : '',
                        extras: json.data?.export && json.data?.export.extras ? json.data?.export.extras : undefined,
                        responseStatus: 200
                    })
                : undefined,
        });
    }

    public get hasUnknownError() {
        return this.type === TypeExportSocket.unknown;
    }
}

export enum TypeExportSocket {
    unknown = 'unknown',
    export = 'export'
}