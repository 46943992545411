export class ConstantsKeys {
    static defaultLimit: number = 50;
    static defaultHeightGarbage: number = 50;
    static safetyTimeSearch: number = 90 * 1000;
    static defaultLimitInputSearch: number = 1;
    static visibleButtonDeleteMessageInMinutes: number = 14;
    static visibleButtonEditMessageInMinutes: number = 14;
    static notificationTagCompany: string = 'companyId';
    static notificationTagSector: string = 'sectorId';
    static version: string = 'BETA 2.1.23';
    static limitUploadFile: number = 16;
    static lastUsedReactions: string = 'lastUsedReactions';
    static activePage: string = 'activePage';
    static localStorageFilters: string = 'localStorageFilters';
    static dateParams: string = 'dateParams';
    static permitReadChatRules: string = 'readChatRules';
    static soundNewMessageChat: string = 'soundNewMessageChat';
    static soundQueueWaitingChat: string = 'soundQueueWaitingChat';
    static defaultLimitListPlans: number = 50;
    static defaultPlanUserMultiplier: number = 1;
    static deadlineToGenerateBillingInDays: number = 7;
    static compactModeMessages: string = 'compactModeMessages';
    static draftMessageCache: string = 'draftMessageCache';
    static blockUpdateLocalSearchCache: string = 'blockUpdateLocalSearchCache';
    static emailQueueDropDesk: string = 'queue@dropdesk.com';
    static minuteInSeconds: number = 60;
    static assistantVirtualName: string = 'Assitente Virtual';
    static isReconnectSubscription: string = 'isReconnectSubscription';
    static limitToReconnectSubscriptionChatInMinutes: number = 58;
    static autoFillDataLocalStorage: string = 'autoFillDataLocalStorage';
    static defaultDataFormTicket: string = 'defaultDataFormTicket';
    static limitReconnectionAttemptsSocket:number = 60;
}

export class ConstantsStyles {
    static buttonMainFontSize = 16;
    static buttonSaveFontSize = 14;
    static radiusButton = 5;
    static inputFontSize = 15;
    static titleLargeFontSize = 20;
    static defaultLabel = 14;
}
