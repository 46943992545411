import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {RemoteStorageDatasource} from "@dropDesk/data/data_source/remote_storage/remote_storage_datasource";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import {IoRemoteDataSource} from "@dropDesk/data/data_source/io/io_remote.datasource";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {FileUpload} from "@dropDesk/domain/entities/common/file_upload";
import {ImportData} from "@dropDesk/domain/entities/import_data/import_data";
import {catchError, from, Observable, Observer} from "rxjs";
import {gql} from "@apollo/client";
import {apolloClient} from "@dropDesk/data/clients/apollo.client";
import {SubscriptionException} from "@dropDesk/domain/entities/exceptions/exceptions";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {ExportRemoteDataSource} from "@dropDesk/data/data_source/export/export_remote.datasource";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {mergeMap} from "rxjs/operators";
import {AxiosResponse} from "axios";
import {getListSubscriptionResponse} from "@dropDesk/domain/entities/common/get_list_subscription_response";
import {getCurrentDateISO} from "@dropDesk/utils/helpers/date_helper";
import {ChatRemoteDataSource} from "@dropDesk/data/data_source/chat/chat_remote_datasource";
import {downloadFile} from "@dropDesk/utils/helpers/files";
import {getToken} from "@dropDesk/data/data_source/token/token.datasource";
import {disconnect, socket, socketConnect} from "@dropDesk/data/clients/socket.client";
import {SocketExportResponse} from "@dropDesk/domain/entities/export_data/export_socket.entity";
import {SocketImportResponse} from "@dropDesk/domain/entities/import_data/import_socket.entity";

@injectable()
export abstract class ClientRemoteDataSource {
    public abstract set(ClientEntity: ClientEntity): Promise<ClientEntity>;

    public abstract findByPK(id: string): Promise<ClientEntity>;

    public abstract list(page: number, searchParam: string, limit: number, listOnlyDeleted: boolean): Promise<ListPaginationEntity<ClientEntity>>;

    public abstract restore(clients: ClientEntity[], restoreWithUsers: boolean): Promise<void>;

    public abstract delete(clients: ClientEntity[]): Promise<void>;

    public abstract listOnlyUsersLinked(id: string): Promise<ClientEntity>;

    public abstract listImportSubscription(
        page: number,
        period: { startDate: string; endDate: string; },
        limit: number): Observable<ListPaginationEntity<ImportData>>;

    public abstract import(file: File, id?: string): Promise<ImportData>;

    public abstract importSubscription(
        idCompany: string,
        startDate: string,
        endDate: string,
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ImportData | null>;

    public abstract revertImport(id: string): Promise<void>;

    public abstract exportSubscription(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null>;

    public abstract export(id?: string): Promise<ExportDataEntity>;

    public abstract downloadFileExample(): Promise<void>;

    public abstract getCurrentImportData(): Promise<ImportData | null>;

    public abstract listImports(page: number, limit: number, period: {
        startDate: string;
        endDate: string;
    }): Promise<ListPaginationEntity<ImportData>>;

}

@injectable()
export class ClientRemoteDataSourceImpl implements ClientRemoteDataSource {
    private _remoteDataSource: RemoteStorageDatasource;
    private _ioRemoteDataSource: IoRemoteDataSource;
    private _exportDataSource: ExportRemoteDataSource;
    private _chatRemoteDataSource: ChatRemoteDataSource;

    constructor(
        @inject(RemoteStorageDatasource) dataSource: RemoteStorageDatasource,
        @inject(IoRemoteDataSource) ioRemoteDataSource: IoRemoteDataSource,
        @inject(ExportRemoteDataSource) exportDataSource: ExportRemoteDataSource,
        @inject(ChatRemoteDataSource) chatRemoteDataSource: ChatRemoteDataSource
    ) {
        this._remoteDataSource = dataSource;
        this._ioRemoteDataSource = ioRemoteDataSource;
        this._exportDataSource = exportDataSource;
        this._chatRemoteDataSource = chatRemoteDataSource;
    }

    baseClientUrl: string = 'clients/';
    baseRestoreClientUrl: string = `${this.baseClientUrl}restore/`;
    baseListOnlyUsersLinkedClientUrl: string = `${this.baseClientUrl}listOnlyUsersLinked/`;
    baseUrlImport: string = `${this.baseClientUrl}import/`;
    baseUrlListImports: string = `${this.baseClientUrl}listImports`;
    baseUrlExport: string = `${this.baseClientUrl}export/start`;
    baseUrlRevertImports: string = `${this.baseClientUrl}revert-import/`;
    baseUrlGetCurrentImport: string = `${this.baseClientUrl}getCurrentImport/`;


    public set(client: ClientEntity): Promise<ClientEntity> {
        return new Promise<ClientEntity>(async (resolve, reject) => {
            try {
                const _addresses = client.addresses?.filter((element) => element.action != BackendAction.nil) ?? [];
                const _users = client.users?.filter((element) => (element.action != BackendAction.nil)) ?? [];


                let _client = client.copyWith({
                    addresses: _addresses,
                    users: _users
                });

                const _images = await this._makeListToUpload(_client);
                const _paths = await this._uploadImages(_images);
                await this._deleteImages(client);
                _client = this._updateClientImagesPath(_client, _paths);
                this.removeUsersCalculatedVariables(_client.users ?? []);
                const response = await api.post(this.baseClientUrl, _client);
                this._chatRemoteDataSource.updateUsersCache(_users).then();
                return resolve(response.data);

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public restore(clients: ClientEntity[], restoreWithUsers: boolean): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                for (let i = 0; i < clients.length; i++) {
                    await api.patch(`${this.baseRestoreClientUrl}${clients[i].id}/${restoreWithUsers}`);
                }

                return resolve();

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public delete(clients: ClientEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                for (let i = 0; i < clients.length; i++) {
                    await api.delete(this.baseClientUrl + clients[i].id);
                }

                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public findByPK(id: string): Promise<ClientEntity> {
        return new Promise<ClientEntity>(async (resolve, reject) => {
            try {
                const response = await api.get(this.baseClientUrl + id);
                const client = new ClientEntity({
                    ...response.data,
                    users: response.data.users.filter((entry: UserEntity) => entry.deleted === false)
                        .map((entry: UserEntity) => new UserEntity({
                            ...entry,
                            hasAccountCreated: !!entry.email,
                            action: entry.action ?? BackendAction.nil
                        })),
                    addresses: response.data.addresses.map((entry: AddressEntity) => new AddressEntity({
                        ...entry,
                        action: entry.action ?? BackendAction.nil,
                        createdAt: getCurrentDateISO()
                    }))
                })
                return resolve(client);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public export(id?: string): Promise<ExportDataEntity> {
        return new Promise<ExportDataEntity>(async (resolve, reject) => {
            try {

                const response = await api.get(`${this.baseUrlExport}?id=${id ?? ''}`);
                const exportData = new ExportDataEntity({
                    ...response.data,
                    progress: parseFloat((response.data.progress * 100).toFixed(2)),
                    responseStatus: response.status
                });
                return resolve(exportData);

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public exportSubscription(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null> {
        return new Observable<ExportDataEntity | null>((observer: Observer<ExportDataEntity | null>) => {
            return this._exportDataSource.exportSubscription(
                'clients',
                id,
                handleDisconnect,
                getLastUpdates,
                useSocket
            ).subscribe({
                next: (response) => {
                    observer.next(response);
                },
                error(err: any) {
                    observer.error(err);
                }
            })
        });
    }

    public listOnlyUsersLinked(id: string): Promise<ClientEntity> {
        return new Promise<ClientEntity>(async (resolve, reject) => {
            try {
                const response = await api.get(this.baseListOnlyUsersLinkedClientUrl + id);
                const client = new ClientEntity({
                    ...response.data,
                    users: response.data.users.map((entry: UserEntity) => new UserEntity({
                        ...entry,
                    })),
                })
                return resolve(client);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public async list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean
    ): Promise<ListPaginationEntity<ClientEntity>> {
        return new Promise<ListPaginationEntity<ClientEntity>>(async (resolve, reject) => {
            try {

                const response = await api.get(
                    `${this.baseClientUrl}?searchParam=${searchParam ?? ''}&page=${page ?? ''}&limit=${limit ?? ''}&type=${listOnlyDeleted ? 'deleted' : ''}`
                );

                const result = new ListPaginationEntity<ClientEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: ClientEntity) => new ClientEntity({...entry})),
                });

                return resolve(result);

            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public async listImports(
        page: number,
        limit: number,
        period: { startDate: string; endDate: string; }
    ): Promise<ListPaginationEntity<ImportData>> {
        return new Promise<ListPaginationEntity<ImportData>>(async (resolve, reject) => {
            try {

                const response = await api.get(
                    `${this.baseUrlListImports}?page=${page ?? ''}&limit=${limit ?? ''}&gte=${period.startDate}&lte=${period.endDate}`
                );

                const result = new ListPaginationEntity<ImportData>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: ImportData) => ImportData.fromJson({...entry})),
                });

                return resolve(result);

            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public listImportSubscription(
        page: number,
        period: { startDate: string; endDate: string; },
        limit: number): Observable<ListPaginationEntity<ImportData>> {

        const tableName = 'import_client_status';

        return from(api.get(`${this.baseClientUrl}getListImportSubscription?page=${page}&limit=${limit}&gte=${period.startDate}&lte=${period.endDate}`).catch(err => Promise.reject(parseServerError(err))))
            .pipe<ListPaginationEntity<ImportData>>(
                mergeMap<AxiosResponse, Observable<ListPaginationEntity<ImportData>>>((value: AxiosResponse<getListSubscriptionResponse, any>, index: number) => {
                        const {querySubscription, queryAggregate} = value.data;

                        const query = gql`${querySubscription}`;
                        const queryAgg = gql`${queryAggregate}`;

                        return new Observable<ListPaginationEntity<ImportData>>((observer: Observer<ListPaginationEntity<ImportData>>) => {
                            return apolloClient.subscribe({
                                query
                            }).subscribe({
                                next: async (response) => {

                                    const imports = response.data![tableName] as ImportData[];
                                    const responseAggs = await apolloClient.query({query: queryAgg});
                                    const totalRows = responseAggs.data![`${tableName}_aggregate`]['aggregate']['count'];

                                    observer.next(new ListPaginationEntity<ImportData>({
                                        page,
                                        pages: Math.ceil(totalRows / limit),
                                        totalRows,
                                        limit,
                                        data: imports?.map((entry: ImportData) =>
                                            ImportData.fromJson({
                                                ...entry,
                                            })),
                                    }));
                                },
                                error(err: any) {
                                    observer.error(parseServerError(err));
                                },
                            });
                        });
                    },
                ));
    }

    public import(file: File, id?: string): Promise<ImportData> {
        return new Promise<ImportData>(async (resolve, reject) => {
            try {
                const formData = new FormData();
                formData.append("file", file);
                const response = await api.post(`${this.baseUrlImport}?id=${id ?? ''}`, formData, {
                    headers: {
                        "Content-Type": `multipart/form-data`,
                    }
                });
                return resolve(ImportData.fromJson(response.data));

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public async getCurrentImportData(): Promise<ImportData | null> {
        return new Promise<ImportData | null>(async (resolve, reject) => {
            try {

                const response = await api.get(this.baseUrlGetCurrentImport);
                const result = response?.data as ImportData | null;
                return resolve(result ? ImportData.fromJson(result) : null);

            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public importSubscription(
        idCompany: string,
        startDate: string,
        endDate: string,
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ImportData | null> {
        if (useSocket) {
            return this.importSubscriptionSocket(id, handleDisconnect, getLastUpdates);
        }
        return this.importSubscriptionGql(idCompany, startDate, endDate);
    }

    private importSubscriptionSocket(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void
    ): Observable<ImportData | null> {
        return new Observable<ImportData | null>((observer: Observer<ImportData | null>) => {
            return from(getToken()).pipe(mergeMap((token: string | null) => {
                    return new Observable(() => {
                        socketConnect(
                            {authorization: `Bearer ${token}`, id},
                            handleDisconnect,
                            getLastUpdates,
                            null,
                            id,
                            'import'
                        );

                        socket!.on('message', async (response: any) => {
                            try {
                                const result = SocketImportResponse.fromJson(response);
                                if (result.hasUnknownError) {
                                    disconnect(['message', id]);
                                    handleDisconnect(false);
                                    observer.next(null);
                                } else {
                                    observer.next(result.importData ?? null);
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        });
                        return () => disconnect(['message', id]);
                    });
                })
            ).subscribe();
        });
    }

    private importSubscriptionGql(idCompany: string, startDate: string, endDate: string): Observable<ImportData | null> {
        const queryString = `subscription ImportSubscription {
     import_client_status(limit: 1, where: {idCompany: {_eq: "${idCompany}"}, createdAt: {_gte: "${startDate}", _lte: "${endDate}"}}, order_by: {createdAt: desc}) {
      status
      progress
      createdAt
      importId
      idCompany
      }
    }`;
        const query = gql`${queryString}`;
        return new Observable<ImportData | null>((observer: Observer<ImportData | null>) => {
            return apolloClient.subscribe({query}).subscribe({
                next: (response) => {
                    if (response.errors) {
                        observer.error(response.errors[0].message);
                    }
                    const mapImport = (response.data!['import_client_status'] as ImportData[])[0] as ImportData | undefined;
                    observer.next(ImportData.fromJson({...mapImport}));
                },
                error(err: any) {
                    observer.error(err);
                }
            })
        });
    }

    public revertImport(id: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

                await api.patch(`${this.baseUrlRevertImports}${id}`);
                return resolve();
            } catch (error) {

                return reject(parseServerError(error));
            }
        })
    }

    public downloadFileExample(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const url = await this._remoteDataSource.getDownloadUrl(process.env.URL_FILE_IMPORT_EXAMPLE as string);
                if (url) {
                    downloadFile(url, 'Planilha de exemplo importar empresas e contatos DropDesk');
                }
                return resolve();
            } catch (error) {

                return reject(parseServerError(error));
            }
        })
    }

    private async _makeListToUpload(client: ClientEntity): Promise<FileUpload[]> {

        let files: FileUpload[] = [];
        if (client.urlImageProfile && !client.urlImageProfile!.startsWith('http')) {
            const blob = await this._ioRemoteDataSource.urlToBlob(client.urlImageProfile);
            files.push({
                path: client.imagePathReference(),
                file: new File([blob], `profile_image.png`, {type: 'image/png'})
            })

        }

        if (!!client.users && client.users.length > 0) {

            for (let index = 0; index < client.users?.length!; index++) {
                const _user = client.users[index];

                if (!!_user.urlImageProfile && !_user.urlImageProfile!.startsWith('http')) {
                    const blob = await this._ioRemoteDataSource.urlToBlob(_user.urlImageProfile);
                    files.push({
                        path: _user.imagePathReference(),
                        file: new File([blob], `profile_image.png`, {type: 'image/png'})
                    })

                }
            }


        }

        return files;
    }

    private async _uploadImages(files: FileUpload[]): Promise<string[] | []> {
        return new Promise<string[] | []>(async (resolve, reject) => {
            try {
                if (files.length > 0) {
                    return resolve(await this._remoteDataSource.uploadListFile(files));
                }
                return resolve([]);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    private async _deleteImages(client: ClientEntity): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                let files: string[] = [];

                if (!client.urlImageProfile) {

                    const _url = await this._remoteDataSource.getDownloadUrl(client.imagePathReference())
                    if (!!_url) {
                        files.push(client.imagePathReference());
                    }

                }

                if (!!client.users && client.users!.length > 0) {

                    for (let index = 0; index < client.users!.length; index++) {

                        const _user = client.users![index];
                        if (!_user.urlImageProfile && _user.action !== BackendAction.nil) {

                            const _url = await this._remoteDataSource.getDownloadUrl(_user.imagePathReference());
                            if (!!_url) {
                                files.push(_user.imagePathReference());
                            }

                        }

                    }

                }

                for (let index = 0; index < files.length; index++) {
                    await this._remoteDataSource.removeFile(files[index]);
                }
                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    private _updateClientImagesPath(client: ClientEntity, paths: string[]): ClientEntity {
        if (paths.length > 0) {
            let _newClient: ClientEntity = client;
            paths.forEach((_path) => {
                if (!_path.includes('users')) {
                    _newClient = _newClient.copyWith({
                        urlImageProfile: _path,
                        replaceUrlImageProfileIfNull: true
                    })
                } else {
                    const _users = _newClient.users!;

                    for (let iUser = 0; iUser < _newClient.users!.length; iUser++) {
                        const _user = _newClient.users![iUser];

                        if (_path.includes(_user.id)) {
                            const _newUser = _user.copyWith({
                                urlImageProfile: _path,
                                replaceUrlImageProfileIfNull: true
                            });
                            _users[iUser] = _newUser;
                            _newClient = _newClient.copyWith({
                                users: _users
                            });
                        }
                    }
                }

            })

            return _newClient;
        }

        return client;
    }

    private removeUsersCalculatedVariables(users: UserEntity[]): void {
        if (users.length > 0) {
            users.forEach((_user) => {
                _user.toJson();
            })
        }
    }

}
