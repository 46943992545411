import {observer} from "mobx-react";
import React, {Dispatch, SetStateAction} from "react";
import FooterForm from "@dropDesk/presentation/components/footer/footer_form";

const FooterFormCreateTicket = observer(
    ({
         isValidDataForm,
         loading,
         handleSubmit,
         backPreviousScreen,
         autoFillData,
         setAutoFillDataLocalStorage
     }: {
         handleSubmit: () => void,
         backPreviousScreen: () => void,
         loading: boolean
         isValidDataForm: boolean
         autoFillData: boolean
         setAutoFillDataLocalStorage: (value: boolean) => void
     }
    ) => {

        return (
            <FooterForm
                disabled={loading || !isValidDataForm}
                loading={loading}
                type={"submit"}
                letter={"Salvar"}
                onSubmit={handleSubmit}
                onRequestClose={backPreviousScreen}
                useCheckBox={true}
                labelCheckBox={'Preencher automaticamente'}
                checkedCheckBox={autoFillData}
                disabledCheckBox={loading}
                checkBoxName={'autoFillData'}
                onChangeCheckBox={(event) => {
                    setAutoFillDataLocalStorage(event.target.checked);
                }}
                checkBoxToolTip={'Ative o preenchimento automático para começar novos atendimentos com suas informações préviamente preenchidas'}
            />
        )
    })
export default FooterFormCreateTicket;
