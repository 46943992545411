import React from "react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export default function ModalReconnectSocket(
    {
        visible,
        onRequestClose,
        onRequestSave,
        loading,
    }: {
        visible: boolean
        loading: boolean
        onRequestClose: () => void,
        onRequestSave: () => void,

    }) {


    return (
        <ModalInformation
            open={visible}
            onRequestClose={onRequestClose}
            textHeaderModal={'Conexão interrompida'}
            textButton={'Restaurar conexão'}
            onRequestSave={onRequestSave}
            iconColor={SharedColors.errorOnPrimary}
            nameIcon={ICONS_DROPDESK.connectionLost}
            sizeIcon={140}
            textInformation={'A conexão com o servidor foi interrompida, confira se o computador está conectado à internet, mas não se preocupe. Para restaurar, clique no botão restaurar conexão.'}
            loading={loading}
            ignoreCloseOnClickOutside={true}
        />
    );
}
