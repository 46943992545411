import React from "react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {MailProvider} from "@dropDesk/domain/entities/common/mail.entity";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export default function ModalSendEmailForgot(
    {
        visible,
        onRequestClose,
        emailProvider,
        onRequestSave,
        email,
        loading
    }: {
        visible: boolean
        loading: boolean
        onRequestClose: () => void,
        emailProvider: MailProvider,
        onRequestSave: () => void,
        email: string
    }) {


    return (
        <ModalInformation
            open={visible}
            onRequestClose={onRequestClose}
            textHeaderModal={'Recuperar senha'}
            textButton={emailProvider === MailProvider.sendgrid ? 'Enviar' : 'Enviar novamente'}
            onRequestSave={onRequestSave}
            iconColor={emailProvider === MailProvider.sendgrid ? undefined : SharedColors.success}
            nameIcon={emailProvider === MailProvider.sendgrid ? ICONS_DROPDESK.mail : ICONS_DROPDESK.check}
            sizeIcon={130}
            textInformation={
                emailProvider === MailProvider.sendgrid ?
                    `Deseja realmente enviar um email para ${email} com a instrução de recuperação de senha?`
                    :
                    `E-mail de recuperação de senha enviado com sucesso, verifique a caixa de correio do seu e-mail,
                             caso não encontre verifique a caixa de spam, caso não recebeu clique em 'Enviar novamente'.`
            }
            loading={loading}
        />
    );
}
