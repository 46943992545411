import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {ListTaskUseCase} from "@dropDesk/domain/use_case/task/list_task_pagination.usecase";
import {SetTaskUseCase} from "@dropDesk/domain/use_case/task/set_task.usecase";
import {DeleteTaskUseCase} from "@dropDesk/domain/use_case/task/delete_task.usecase";
import {TaskEntity} from "@dropDesk/domain/entities/task/tasks.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {Actions, Orders, Status, Type} from "@dropDesk/domain/entities/task/task_enum";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {MarkDoneUndoneTaskUseCase} from "@dropDesk/domain/use_case/task/mark_done_undone_task.usecase";
import {getCurrentDateISO} from "@dropDesk/utils/helpers/date_helper";
import {newTask} from "@dropDesk/presentation/pages/task/controller/new_task";

configure({
    enforceActions: "always",
});

@injectable()
export class TaskController {

    private readonly _listTaskUseCase: ListTaskUseCase;
    private readonly _setTaskUseCase: SetTaskUseCase;
    private readonly _deleteTaskUseCase: DeleteTaskUseCase;
    private readonly _markAsDoneAndUndone: MarkDoneUndoneTaskUseCase;

    constructor(
        @inject(ListTaskUseCase) listTaskUseCase: ListTaskUseCase,
        @inject(SetTaskUseCase) setTaskUseCase: SetTaskUseCase,
        @inject(MarkDoneUndoneTaskUseCase) markAsDoneAndUndone: MarkDoneUndoneTaskUseCase,
        @inject(DeleteTaskUseCase) deleteTaskUseCase: DeleteTaskUseCase,
    ) {
        makeObservable(this);
        this._listTaskUseCase = listTaskUseCase;
        this._setTaskUseCase = setTaskUseCase;
        this._deleteTaskUseCase = deleteTaskUseCase;
        this._markAsDoneAndUndone = markAsDoneAndUndone;
    }

    newTask: TaskEntity = newTask();

    table = new ListPaginationEntity<TaskEntity>({
        pages: 0,
        page: 0,
        limit: 4,
        totalRows: 0,
        data: observable.array([])
    });

    @observable
    task: TaskEntity = this.newTask;

    @observable
    tableTask: ListPaginationEntity<TaskEntity> = this.table;

    @observable
    pagesData: Record<number, TaskEntity[]> = {};

    @observable
    type: Type = Type.orderbydate;

    @observable
    orders: Orders = Orders.desc;

    @observable
    status: Status = Status.all;

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @action
    incrementPage() {
        if (this.tableTask.page < this.tableTask.pages - 1) {
            this.tableTask.page = this.tableTask.page + 1;
        }
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    setTableTask(value: ListPaginationEntity<TaskEntity>) {
        this.tableTask = value;
        this.pagesData[this.tableTask.page] = this.tableTask.data;
    }

    @action
    setTask(task: TaskEntity) {
        this.task = task;
    }

    @action
    setFilterStatus(value: Status) {
        this.status = value;
    }

    @action
    setOrder(value: Orders) {
        this.orders = value;
    }

    @action
    setType(value: Type) {
        this.type = value;
    }

    @action
    mark = (id: string, action: Actions): void => {
        const index = this.tableTask.data.findIndex((_task) => _task.id == id);

        if (index > -1) {
            const tasks = [...this.tableTask.data];
            tasks[index] = tasks[index].copyWith({
                done: action === Actions.done,
                doneAt: action === Actions.done ? getCurrentDateISO() : undefined,
            });
            const _table = this.tableTask.copyWith({
                data: tasks
            });
            this.setTableTask(_table);
        }
    }

    @action
    handleDoneAndUndoneTask = async (id: string, action: Actions): Promise<void> => {
        try {

            this.mark(id, action);
            await this._markAsDoneAndUndone.call(id, action);

        } catch (err: any) {
            this.mark(id, action === Actions.done ? Actions.undone : Actions.done);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
        }

    }

    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableTask(this.tableTask.copyWith({
                ...this.tableTask,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableTask(this.tableTask.copyWith({
                ...this.tableTask,
                page: page,
            }))
            return this.list();
        }
    }


    @action
    handleOnCreateTask = (task: TaskEntity) => {
        const totalRows = task.isUnsaved() ? (this.tableTask.totalRows + 1) : this.tableTask.totalRows;
        task.createdAt = task.createdAt ?? new Date().toISOString();
        const newList = this.getUniqueList(this.tableTask.data, [task]);
        this.setTableTask(this.tableTask.copyWith({
            data: newList,
            totalRows: totalRows
        }));
    }

    private getUniqueList(originalList: TaskEntity[], newList: TaskEntity[]): TaskEntity[] {
        const result = originalList;

        if (newList.length) {
            for (const task of newList) {
                const index = originalList.findIndex((item) => item.id === task.id);
                if (index > -1) {
                    result[index] = task;
                } else {
                    result.push(task);
                }
            }
        }

        return result;
    }


    @action
    set = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("task.loadingSave");
            if (this.task.isUnsaved()) {
                this.setTask(this.task.copyWith({
                    id: generateUUIDV4(),
                }))
            }

            await this._setTaskUseCase.call(this.task);
            this.handleOnCreateTask(this.task);
            onSuccess("task.success.save");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();

        }
    }

    @action
    delete = async (id: string, onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("task.loadingDelete");
            await this._deleteTaskUseCase.call(id);
            onSuccess("task.success.delete");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    makeTask = (user: UserEntity): void => {
        this.setTask(this.newTask.copyWith({
            idUser: user.id,
        }));
    }

    @action
    list = async (): Promise<void> => {
        try {

            this.startLoading();
            const response = await this._listTaskUseCase.call(
                this.type,
                this.orders,
                this.status,
                this.tableTask.page,
                this.tableTask.limit
            );
            this.setTableTask(response);
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

}
