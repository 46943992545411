import {
    TicketMessageEntity,
} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {differentInMinutesBetweenDates} from "@dropDesk/utils/helpers/date_helper";
import {MessageChatType} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {orderByCreatedAt} from "@dropDesk/utils/helpers/common";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export const useDeleteMessageButton = (message: TicketMessageEntity, canChange: boolean, userLogged: UserEntity, idCurrentTicket: string) => {
    return !!message.createdAt &&
        !message.deleted &&
        differentInMinutesBetweenDates(message.createdAt ?? '') <= ConstantsKeys.visibleButtonDeleteMessageInMinutes &&
        canChange &&
        message.senderId === userLogged.id &&
        message.idTicket === idCurrentTicket &&
        !message.automatic;
};

export const useEditMessageButton = (message: TicketMessageEntity, canChange: boolean, userLogged: UserEntity, idCurrentTicket: string) => {
    return !!message.createdAt && !message.deleted &&
        differentInMinutesBetweenDates(message.createdAt ?? '') <= ConstantsKeys.visibleButtonEditMessageInMinutes &&
        canChange &&
        (
            message.type === MessageChatType.textMessage ||
            message.type === MessageChatType.documentMessage && !!message.documentMessage?.caption ||
            message.type === MessageChatType.imageMessage && !!message.imageMessage?.caption ||
            message.type === MessageChatType.stickerMessage && !!message.stickerMessage?.caption ||
            message.type === MessageChatType.videoMessage && !!message.videoMessage?.caption
        ) &&
        message.senderId === userLogged.id &&
        !message.isViewOnceError &&
        !message.isLargeMediaError &&
        !message.isReceiveError &&
        message.idTicket === idCurrentTicket &&
        !message.automatic;
};

export const useReplyButton = (message: TicketMessageEntity, canChange: boolean, idCurrentTicket: string, isMeAttendingTicket: boolean, ticketIsOpen: boolean, userLogged: UserEntity) => {
    return !!message.createdAt &&
        !message.deleted &&
        !message.hasSendError &&
        canChange &&
        !message.isViewOnceError &&
        !message.isLargeMediaError &&
        !message.isReceiveError &&
        message.idTicket === idCurrentTicket &&
        (isMeAttendingTicket || ticketIsOpen || userLogged.isUserClient) &&
        !message.automatic;
};

export const useReactionButton = (message: TicketMessageEntity, canChange: boolean, idCurrentTicket: string, isMeAttendingTicket: boolean, userLogged: UserEntity) => {
    return !!message.createdAt &&
        !message.deleted &&
        !message.hasSendError &&
        canChange &&
        !message.isViewOnceError &&
        !message.isLargeMediaError &&
        !message.isReceiveError &&
        message.idTicket === idCurrentTicket &&
        (isMeAttendingTicket || userLogged.isUserClient) &&
        !message.automatic;
};

export const useCanChangeReaction = (message: TicketMessageEntity, canChange: boolean, idCurrentTicket: string) => {
    return !!message.createdAt &&
        !message.deleted &&
        !message.hasSendError &&
        canChange &&
        !message.isViewOnceError &&
        !message.isLargeMediaError &&
        !message.isReceiveError &&
        message.idTicket === idCurrentTicket &&
        !message.automatic;
};

export const useGetStatus = (message: TicketMessageEntity, userClient: UserEntity, userLogged: UserEntity, idCurrentTicket: string, attendant?: UserEntity) => {
    if ((userLogged.isUserClient && message.idTicket === idCurrentTicket) || (userLogged.isAttendant)) {
        return message.getStatus(userClient, userLogged.role, attendant);
    }
    return undefined;
};

export const useImagesMessage = (messages: TicketMessageEntity[]) => {
    const newImagesMessage = messages.filter((message) => message.isValidPreviewMessage);
    orderByCreatedAt(newImagesMessage);
    return newImagesMessage;
};

export const areReactionsEqual = (prevReactions?: TicketMessageEntity[], nextReactions?: TicketMessageEntity[]) => {
    if (prevReactions?.length !== nextReactions?.length) {
        return false;
    }

    if (prevReactions && nextReactions) {
        orderByCreatedAt(prevReactions);
        orderByCreatedAt(nextReactions);
        for (let i = 0; i < prevReactions.length; i++) {
            const prevReaction = prevReactions[i].reactionMessage;
            const nextReaction = nextReactions[i].reactionMessage;

            if (prevReaction?.text !== nextReaction?.text) {
                return false;
            }
        }
    }
    return true;
};
