import {observer} from "mobx-react";
import React from "react";
import {
    ContentSelectSearchLimitMinutes,
    ContentPermission, ContentDataInput, ContentInput
} from "./styled";
import SelectSearchMain from "@dropDesk/presentation/components/inputs/select_search_main";
import {realTimeConnectionMap, timezoneMap} from "@dropDesk/domain/entities/common/common_maps";
import {PanelAdmEntity} from "@dropDesk/domain/entities/company_configurations/panel_adm.entity";
import {RealTimeConnectionEnum} from "@dropDesk/domain/entities/company_configurations/configuration_enum";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {
    useValidatorInputPanelAdm
} from "@dropDesk/presentation/pages/company_configurations/ui/painel_adm/inputs/validator";


const InputsPanelAdm = observer(
    ({
         onUpdate,
         panelAdm,
         loading
     }: {
        onUpdate: (panelAdm: PanelAdmEntity) => void,
        panelAdm: PanelAdmEntity
        loading: boolean
    }) => {

        const {errorMessageUniqueCode} = useValidatorInputPanelAdm(panelAdm);

        const handleAllChangesPanelAdm = (event: {
            target: { name: string; value: any; checked?: any; type: string }
        }) => {

            const _panelAdm = panelAdm.copyWith({
                [event.target.name]:
                    (event.target.type === "checkbox" || event.target.type === "switch") ?
                        event.target.checked : event.target.name === "uniqueCode" ?
                            event.target.value.replace(/\D/g, "") : event.target.value
            });
            onUpdate(_panelAdm);
        };

        return (
            <ContentPermission>
                <ContentDataInput>
                    <ContentInput>
                        <InputWithBorder
                            autoFocus={true}
                            required={true}
                            name='uniqueCode'
                            size={35}
                            disabled={loading}
                            messageError={errorMessageUniqueCode}
                            label={"Código de referência"}
                            value={panelAdm.uniqueCode ? panelAdm.uniqueCode.toString() : ''}
                            onChange={handleAllChangesPanelAdm}
                        />
                    </ContentInput>
                    <ContentSelectSearchLimitMinutes>
                        <SelectSearchMain<RealTimeConnectionEnum>
                            size={35}
                            options={realTimeConnectionMap}
                            disabled={loading}
                            label={"Tipo de conexão em tempo real"}
                            onChange={(value) => {

                                handleAllChangesPanelAdm(
                                    {
                                        target:
                                            {
                                                name: 'realTimeConnection',
                                                value: value,
                                                checked: false,
                                                type: 'input'
                                            }
                                    })
                            }}
                            value={panelAdm.realTimeConnection}
                        />
                    </ContentSelectSearchLimitMinutes>
                </ContentDataInput>
            </ContentPermission>
        )
    })
export default InputsPanelAdm;
