import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {injectable} from "inversify";
import {TicketConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/ticket_configuration.entity";
import {
    BusinessOperationHoursConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/business_operation_hours_configuration.entity";
import {
    PortalClientConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/portal_client_configuration.entity";
import {ChatConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/chat_configuration.entity";
import {
    NotificationsConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/notifications_configuration.entity";
import {PanelAdmEntity} from "@dropDesk/domain/entities/company_configurations/panel_adm.entity";

@injectable()
export abstract class CompanyConfigurationRepository {
    public abstract findByPK(id: string): Promise<CompanyConfigurationEntity>;

    public abstract setTheme(name?: string, fileLogoBrand?: File, removeLogo?: boolean): Promise<Record<string, boolean>>;

    public abstract set(companyConfigurations: CompanyConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationTicket(configurationTicket: TicketConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationChat(configurationChat: ChatConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationBusinessOperator(configurationBusinessOperator: BusinessOperationHoursConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationPortalClient(configurationPortalClient: PortalClientConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract resetChatMessages(): Promise<CompanyConfigurationEntity>;

    public abstract setConfigurationNotification(notifications: NotificationsConfigurationEntity): Promise<CompanyConfigurationEntity>;

    public abstract setPanelAdmConfiguration(panelAdm: PanelAdmEntity): Promise<void>;

}
