import styled from "styled-components";
import {ConstantsStyles} from "@dropDesk/domain/entities/constants/constants_keys";

type IconOpsType = {
    color: string;
}

export const ContentSelectSearchLimitMinutes = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 350px;
    max-width: 350px;
    margin-bottom: 22px;
`;

export const ContentDataInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
`;

export const ContentInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    max-width: 350px;
`;

export const ContentPermission = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const TextPermission = styled.span<IconOpsType>`
  font-size: ${`${ConstantsStyles.inputFontSize}px`};
  color: ${props => props.color};
`;
