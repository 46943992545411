import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

type TextType = {
    color: string;
    accent: string;
}

type IconType = {
    color: string;
    size: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 90px;
  max-height: 90px;
  height: 90px;
  background: ${SharedColors.warningChat};
`;

export const ContainerIcon = styled.div`
  display: flex;
  flex: 1;
  max-width: 80px;
  width: 80px;
  min-width: 80px;
  align-items: center;
  justify-content: center;
`;

export const ContentIcon = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${SharedColors.white};
`;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentTitle = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-end;
  flex-direction: column;
  font-size: 15px;
  color: ${SharedColors.black};
`;

export const ContentSubTitle = styled.span`
  font-size: 13px;
  color: ${SharedColors.black};
`;

export const TextLink = styled.span`
  font-weight: bold;
  color: ${SharedColors.black};
  text-decoration: underline;
  border: 0;
  background: transparent;
  cursor: pointer;
  letter-spacing: .7px;

  &:hover {
    filter: brightness(80%);
  }
`;
