import {observer} from "mobx-react";
import React from "react";
import ModalLogoutByAdmin from "@dropDesk/presentation/components/modals/modal_information";
import {useBetween} from "use-between";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {
    useModalConnectionExceeded
} from "@dropDesk/presentation/pages/connections_exceeded/ui/modals/handle_change_visible";
import ModalSearchConnection from "@dropDesk/presentation/components/modals/modal_search_connection";
import {CompanyConnectionsEntity} from "@dropDesk/domain/entities/company/company_connections.entity";

const ModalsConnectionExceeded = observer(
    ({
         logoutByAdmin,
         loading,
         handleSetUserActiveConnection
     }: {
        logoutByAdmin: () => Promise<void>,
        loading: boolean
        handleSetUserActiveConnection: (connection: CompanyConnectionsEntity) => void;
    }) => {

        const {
            visibleModalLogoutByAdmin,
            setVisibleModalLogoutByAdmin,
            visibleModalConnectionExceeded, setVisibleModalConnectionExceeded
        } = useBetween(useModalConnectionExceeded);

        return (
            <>

                <ModalLogoutByAdmin
                    open={visibleModalLogoutByAdmin}
                    onRequestClose={() => {
                        setVisibleModalLogoutByAdmin(false);
                    }}
                    textHeaderModal={'Desconectar atendente'}
                    textButton={'Desconectar'}
                    onRequestSave={() => {
                        logoutByAdmin();
                        setVisibleModalLogoutByAdmin(false);
                    }}
                    nameIcon={ICONS_DROPDESK.userCancel}
                    sizeIcon={130}
                    textInformation={
                        `Ao remover a conexão deste usuário, o mesmo será desconectado forçadamente do sistema.`
                    }
                    loading={loading}
                    isActionDelete={true}
                />

                {visibleModalConnectionExceeded && <ModalSearchConnection
                    visible={visibleModalConnectionExceeded}
                    onClose={() => setVisibleModalConnectionExceeded(false)}
                    setSelectedUserConnected={(connection) => {
                        handleSetUserActiveConnection(connection);
                    }}
                    textHeaderModal={"Conexões ativas"}
                    labelNoResults={"Nenhuma atendente encontrado na pesquisa"}
                    inputSearchPlaceholder={"Pesquise por um atendente"}
                />}
            </>
        )
    })
export default ModalsConnectionExceeded;
