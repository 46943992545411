import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ClientRepository} from "@dropDesk/domain/repositories/client/client.repository";
import {ImportData} from "@dropDesk/domain/entities/import_data/import_data";

@injectable()
export class GetCurrentImportClientUseCase {
  private _repository: ClientRepository;

  constructor(@inject(ClientRepository) repository: ClientRepository) {
    this._repository = repository;
  }

  public call(): Promise<ImportData | null> {
    return this._repository.getCurrentImportData();
  }
}

