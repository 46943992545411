import AvatarImage from "@dropDesk/presentation/components/avatar";
import HighLightWords from "@dropDesk/presentation/components/high_light_words";
import React, {useEffect} from "react";
import ModalSearch from "@dropDesk/presentation/components/modals/modal_search";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import {setBlockUpdateLocalSearchCache} from "@dropDesk/utils/helpers/common";
import {
    ModalSearchConnectionType
} from "@dropDesk/presentation/components/modals/modal_search_connection/modal_search_connection";
import {CompanyController} from "@dropDesk/presentation/pages/company/controller/company.controller";
import {CompanyConnectionsEntity} from "@dropDesk/domain/entities/company/company_connections.entity";
import {displayDateToLocale} from "@dropDesk/utils/helpers/date_helper";
import {
    ButtonDesconnected,
    ContainerAction
} from "@dropDesk/presentation/pages/company_active_connections/search/table/styled";
import {Tooltip} from "antd";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const ModalSearchConnection = observer((props: ModalSearchConnectionType) => {

    const companyController = useInjection(CompanyController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    useEffect(() => {
        setBlockUpdateLocalSearchCache(true);
        onSearch(companyController.searchParam);
        return () => {
            setBlockUpdateLocalSearchCache(false);
        }
    }, []);

    const onSearch = (searchParam: string): void => {
        companyController.listActiveConnections(searchParam ?? companyController.searchParam);
    }

    const tableColumn = [
        {
            title: 'Nome',
            dataIndex: 'url',
            width: 58,
            render: (text: string, record: CompanyConnectionsEntity) => (
                <AvatarImage
                    useCursorPointer={record.user?.urlImageProfile ? true : false}
                    title={record.user.name}
                    size={35}
                    url={record.user.urlImageProfile}
                    hasPreviewImage={true}
                    round={true}
                    withBorder={true}
                    onClick={(event) => event.stopPropagation()}
                />
            ),
        },
        {
            title: '',
            ellipsis: true,
            dataIndex: 'name',
            render: (text: string, record: CompanyConnectionsEntity) => (
                <HighLightWords
                    searchWords={companyController.searchParam}
                    textToHighlight={record.user.name}
                />
            ),
        },
        {
            title: 'Email',
            ellipsis: true,
            dataIndex: 'email',
            render: (text: string, record: CompanyConnectionsEntity) => (
                <HighLightWords
                    searchWords={companyController.searchParam}
                    textToHighlight={record.user.email ?? ''}
                />
            ),
        },
        {
            title: 'Conectado em',
            ellipsis: true,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => (
                <span>{displayDateToLocale(text)}</span>
            ),
        },
        {
            title: '', key: 'userId', width: 150, render: (text: string, record: CompanyConnectionsEntity) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <ContainerAction>

                        <Tooltip placement="left" title={'Desconectar atendente'}
                        >
                            <ButtonDesconnected
                                background={colors.textError}
                                color={SharedColors.white}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    props.setSelectedUserConnected(record);
                                }}
                            >
                                Desconectar
                            </ButtonDesconnected>
                        </Tooltip>
                    </ContainerAction>
                </div>
            ),
        },
    ];


    return (
        <ModalSearch<CompanyConnectionsEntity>
            inputSearchPlaceholder={props.inputSearchPlaceholder}
            tableColumns={tableColumn}
            open={props.visible}
            onSearch={(value) => {
                onSearch(value)
            }}
            onSelectedRow={(connection: CompanyConnectionsEntity) => {

            }}
            inputValue={companyController.searchParam}
            textHeaderModal={props.textHeaderModal}
            loading={companyController.loading}
            onRequestClose={props.onClose}
            tableDataSource={companyController.tableConnections.data}
            defaultCurrentPage={1}
            totalRows={companyController.tableConnections.totalRows}
            page={companyController.tableConnections.page}
            pageSize={companyController.tableConnections.limit}
            onChange={(page) => companyController.getDataFromPage(page - 1)}
            labelNoResults={props.labelNoResults}
        />
    )
});
export default ModalSearchConnection;
