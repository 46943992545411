import React, {useState, FormEvent, useEffect} from "react";
import {Container, Content, ContentButtons} from "./styled";
import {isValidEmail} from "@dropDesk/utils/helpers/validators";
import ButtonMain from "@dropDesk/presentation/components/buttons/button_main";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import InputWithBorder from "@dropDesk/presentation/components/inputs/input_with_border";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";

export const ModalChangeEmail = observer(
    ({
         visibleModalChangeEmail,
         setVisibleModalChangeEmail,
         isCurrentUserChanged,
         oldEmail,
         onSuccessChangeEmail,
     }: {
        setVisibleModalChangeEmail: () => void,
        visibleModalChangeEmail: boolean,
        isCurrentUserChanged: boolean
        onSuccessChangeEmail?: (newEmail: string) => void
        oldEmail: string,
    }) => {

        const [email, setEmail] = useState("");
        const [errorMessageEmail, setErrorMessageEmail] = useState("");
        const userController = useInjection(UserController);
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const {t} = useTranslation();
        const navigate = useNavigate();
        const textContentModal = isCurrentUserChanged ?
            `Deseja realmente alterar seu email de acesso? Após a alteração você precisará verificar sua conta novamente com um novo código e efetuar o login com o novo email.`
            :
            `Deseja realmente alterar o email ${oldEmail}? Após a alteração o mesmo irá precisar verificar sua conta novamente com um novo código e efetuar 
      o login novamente com o novo email`;

        useEffect(() => {
            verifyIsValidEmail();
        }, [email]);

        function verifyIsValidEmail() {
            let verifyEmail = isValidEmail(email);
            if (!verifyEmail) {
                setErrorMessageEmail("E-mail inválido");
            } else {
                setErrorMessageEmail("");
            }
        }

        const onSuccess = (key: string, newEmail: string) => {
            toastMessage.success(t(key));
            setVisibleModalChangeEmail();
            if (isCurrentUserChanged) {
                navigate(RoutesEnum.login, {replace: true});
            } else if (onSuccessChangeEmail) {
                onSuccessChangeEmail(newEmail.toLowerCase());
            }
        }

        async function handleSubmit(event: FormEvent) {
            event.preventDefault();
            if (isValidEmail(email)) {
                setVisibleModalChangeEmail();
                await userController.changeEmail(oldEmail ?? '', email.trim(), isCurrentUserChanged, onSuccess);
            }
        }

        return (
            <>
                <ModalMain
                    open={visibleModalChangeEmail}
                    onRequestClose={setVisibleModalChangeEmail}
                    width={400}
                    renderComponent={
                        <Container background={colors.onBackground}>
                            <HeaderModal
                                disabled={false}
                                letter={"Trocar e-mail"}
                                nameIcon={ICONS_DROPDESK.close}
                                onClick={setVisibleModalChangeEmail}
                            />
                            <Content>
            <span
                style={{
                    color: colors.text, fontSize: 16
                }}>
              {textContentModal}
            </span>
                                <InputWithBorder
                                    required={true}
                                    autoFocus={true}
                                    size={45}
                                    marginTop={15}
                                    nameIconLeft={ICONS_DROPDESK.atSign}
                                    disabled={userController.loading}
                                    placeHolder={"Informe o novo e-mail"}
                                    label={"Novo e-mail"}
                                    messageError={errorMessageEmail}
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    onFocus={() => verifyIsValidEmail()}
                                    onBlur={() => verifyIsValidEmail()}
                                />
                                <ContentButtons>
                                    <div style={{display: 'flex', flex: 1, marginRight: 10}}>
                                        <ButtonMain

                                            widthPercentage={100}
                                            letterFontSize={16}
                                            disabled={!!errorMessageEmail || userController.loading}
                                            loading={userController.loading}
                                            color={colors.accent}
                                            type={"submit"}
                                            letter={"Salvar"}
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                    <div style={{display: 'flex', flex: 1}}>
                                        <ButtonMain
                                            borderColor={colors.text}
                                            widthPercentage={100}
                                            letterFontSize={16}
                                            disabled={userController.loading}
                                            loading={userController.loading}
                                            color={'transparent'}
                                            type={"submit"}
                                            letter={"Voltar"}
                                            letterColor={colors.text}
                                            onClick={setVisibleModalChangeEmail}
                                        />

                                    </div>
                                </ContentButtons>
                            </Content>

                        </Container>}
                />
                {
                    (userController.loading) && <DropDeskLoading
                        height={250}
                        description={userController.loadingMessage}
                    />
                }
            </>
        );
    });
