import {observer} from "mobx-react";
import React from "react";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {
    ContentButtonPay
} from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/invoices_history/styled";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";


const InvoiceView = observer(
    ({
         payment,
         handleClickPayPending,
         onClickDownloadInvoice,
         currentSubscription
     }: {
        handleClickPayPending: (payment: PaymentEntity) => void
        payment: PaymentEntity,
        onClickDownloadInvoice: (payment: PaymentEntity) => void
        currentSubscription: PaymentSubscriptionEntity
    }) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        const getContent = (payment: PaymentEntity): React.ReactNode => {
            if (!payment.canPay) {
                return (
                    <ContentButtonPay
                        onClick={() => onClickDownloadInvoice(payment)}
                        size={15}
                        color={colors.text}
                        background={colors.onBackground}
                        accent={colors.accent}
                    >
                        Abrir
                    </ContentButtonPay>
                )
            } else if (payment.canPay) {
                return (
                    <ContentButtonPay
                        onClick={() => handleClickPayPending(payment)}
                        size={15}
                        color={SharedColors.white}
                        background={colors.accent}
                        accent={colors.accent}
                    >
                        Pagar
                    </ContentButtonPay>
                )
            } else if (payment.isProcessingPaymentCreditCard && !currentSubscription.isCancelled) {
                return <span>Processando</span>
            } else {
                return null;
            }
        }

        return (
            <>
                {getContent(payment)}
            </>
        )
    })
export default InvoiceView;
