import {observer} from "mobx-react";
import React from "react";
import ModalPay from "@dropDesk/presentation/components/modals/modal_information";
import {useModalsSubscription} from "@dropDesk/presentation/pages/subscription/ui/modals/visible_modal_subscription";
import {useBetween} from "use-between";
import ModalMain from "@dropDesk/presentation/components/modals/modal_main";
import PayCreditCard from "@dropDesk/presentation/pages/subscription/ui/modals/pay_credit_card";
import PaySuccess from "@dropDesk/presentation/pages/subscription/ui/modals/pay_success";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import InputsPaymentInfo from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_info";
import {RoutesSubscription} from "@dropDesk/presentation/pages/subscription/controller/subscription.controller";
import PaymentMethod from "@dropDesk/presentation/pages/subscription/ui/checkout/payment_type/payment_method";
import {CardEntity, TokenizeCardEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {TabsPayment} from "@dropDesk/domain/entities/payment_method/payment_method.enum";
import {ContentPaymentMethod} from "@dropDesk/presentation/pages/subscription/ui/modals/styled";
import CancelSubscription from "@dropDesk/presentation/pages/subscription/ui/modals/cancel_subscription";
import ReactiveSubscription from "@dropDesk/presentation/pages/subscription/ui/modals/reactive_subscription";
import ModalInformation from "@dropDesk/presentation/components/modals/modal_information";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import PayInfo from "src/presentation/pages/subscription/ui/modals/pay_info";
import {CompanyLogsEntity} from "@dropDesk/domain/entities/company/company_logs.entity";
import ViewLog from "@dropDesk/presentation/pages/subscription/ui/modals/view_log";
import ModalCancelLegacyPlan from "@dropDesk/presentation/components/modals/modal_information";
import RejectedPayCreditCard from "@dropDesk/presentation/pages/subscription/ui/modals/rejected_pay_credit_card";


const ModalsSubscription = observer(
    ({
         changePaymentMethod,
         payCreditCardSaved,
         loading,
         onClickDownloadInvoice,
         newSubscription,
         newPaymentInfo,
         onUpdatePaymentInfo,
         onSearchCNPJ,
         onSearchZipCode,
         onClickSavePaymentInfo,
         setActiveRoute,
         newTokenizeCard,
         onUpdateTokenizeCard,
         setActiveTabPayment,
         currentPayment,
         cardSaved,
         activeTabPayment,
         makePaymentMethod,
         cancellationReason,
         setCancellationReason,
         cancelSubscription,
         reactiveSubscription,
         logout,
         resetPaymentMethod,
         labelNewPeriod,
         disposePaymentSubscription,
         oldSubscription,
         cancelFutureSubscription,
         downgradePlan,
         textDowngrade,
         lastPayment,
         log,
         hasErrorConnectSocket,
         hasErrorConnectSocketTemporary,
         handleReconnectSocket,
         loadingReconnect
     }: {
        changePaymentMethod: () => Promise<void>,
        payCreditCardSaved: () => Promise<void>,
        loading: boolean
        onClickDownloadInvoice: (payment: PaymentEntity) => void
        newSubscription: PaymentSubscriptionEntity
        newPaymentInfo: CompanyPaymentInfo
        onUpdatePaymentInfo: (paymentInfo: CompanyPaymentInfo) => void
        onSearchCNPJ: (document: string) => void,
        onSearchZipCode: (zipCode: string) => void,
        onClickSavePaymentInfo: () => void
        setActiveRoute: (route: RoutesSubscription) => void
        newTokenizeCard: TokenizeCardEntity | null
        onUpdateTokenizeCard: (newTokenizeCard: TokenizeCardEntity) => void
        setActiveTabPayment: (value: TabsPayment) => void
        currentPayment?: TabsPayment
        cardSaved?: CardEntity
        activeTabPayment: TabsPayment | null,
        makePaymentMethod: () => void
        cancellationReason?: string;
        setCancellationReason: (cancellationReason?: string) => void
        cancelSubscription: () => void
        reactiveSubscription: () => void
        logout: () => Promise<void>
        resetPaymentMethod: () => void;
        labelNewPeriod: string
        disposePaymentSubscription: () => void
        oldSubscription?: PaymentSubscriptionEntity
        cancelFutureSubscription: () => void
        downgradePlan: () => void
        textDowngrade: string
        lastPayment?: PaymentEntity
        log?: CompanyLogsEntity
        hasErrorConnectSocket: boolean
        hasErrorConnectSocketTemporary: boolean
        loadingReconnect: boolean
        handleReconnectSocket: (payment: PaymentEntity) => void

    }) => {
        const {
            visibleModalPay, setVisibleModalPay,
            visibleModalDisplayDataPix, setVisibleModalDisplayDataPix,
            visibleModalDisplayBoleto, setVisibleModalDisplayBoleto,
            visibleModalProcessingCreditCardPayment, setVisibleModalProcessingCreditCardPayment,
            visibleModalPaymentSuccess, setVisibleModalPaymentSuccess,
            visibleModalChangePaymentInfo, setVisibleModalChangePaymentInfo,
            visibleModalChangePaymentMethod, setVisibleModalChangePaymentMethod,
            visibleModalReactiveSubscription, setVisibleModalReactiveSubscription,
            visibleModalCancelSubscription, setVisibleModalCancelSubscription,
            visibleModalLogout, setVisibleModalLogout,
            visibleModalCancelFutureSubscription, setVisibleModalCancelFutureSubscription,
            visibleModalDowngrade, setVisibleModalDowngrade,
            visibleModalViewLog, setVisibleModalViewLog,
            visibleModalCancelLegacySubscription, setVisibleModalCancelLegacySubscription,
            visibleModalRejectedCreditCardPayment, setVisibleModalRejectedCreditCardPayment


        } = useBetween(useModalsSubscription);

        return (
            <>
                <ModalPay
                    open={visibleModalPay}
                    onRequestClose={() => {
                        setVisibleModalPay(false);
                    }}
                    textHeaderModal={'Realizar pagamento'}
                    textButton={'Pagar'}
                    onRequestSave={async () => {
                        payCreditCardSaved();
                        setVisibleModalPay(false);
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={
                        `Deseja realmente realizar o pagamento com este cartão selecionado?`
                    }
                    loading={loading}
                />

                {log && <ModalMain
                    open={visibleModalViewLog}
                    onRequestClose={() => setVisibleModalViewLog(false)}
                    width={600}
                    renderComponent={
                        <ViewLog
                            log={log}
                        />
                    }
                />}

                {lastPayment && lastPayment?.pix && <ModalMain
                    open={visibleModalDisplayDataPix}
                    onRequestClose={() => setVisibleModalDisplayDataPix(false)}
                    width={700}
                    renderComponent={
                        <PayInfo
                            lastPayment={lastPayment}
                            newSubscription={newSubscription}
                            labelNewPeriod={labelNewPeriod}
                            disposePaymentSubscription={disposePaymentSubscription}
                            onClickDownloadInvoice={onClickDownloadInvoice}
                            isPix={true}
                            hasErrorConnectSocket={hasErrorConnectSocket}
                            hasErrorConnectSocketTemporary={hasErrorConnectSocketTemporary}
                            handleReconnectSocket={handleReconnectSocket}
                            loadingReconnect={loadingReconnect}
                        />
                    }
                />}

                {lastPayment && lastPayment?.boleto && <ModalMain
                    open={visibleModalDisplayBoleto}
                    onRequestClose={() => setVisibleModalDisplayBoleto(false)}
                    width={700}
                    renderComponent={
                        <PayInfo
                            lastPayment={lastPayment}
                            newSubscription={newSubscription}
                            onClickDownloadInvoice={onClickDownloadInvoice}
                            labelNewPeriod={labelNewPeriod}
                            disposePaymentSubscription={disposePaymentSubscription}
                            isPix={false}
                            hasErrorConnectSocket={hasErrorConnectSocket}
                            hasErrorConnectSocketTemporary={hasErrorConnectSocketTemporary}
                            handleReconnectSocket={handleReconnectSocket}
                            loadingReconnect={loadingReconnect}
                        />
                    }
                />}

                <ModalMain
                    open={visibleModalProcessingCreditCardPayment}
                    onRequestClose={() => setVisibleModalProcessingCreditCardPayment(false)}
                    width={600}
                    renderComponent={
                        <PayCreditCard
                            newSubscription={newSubscription}
                            labelNewPeriod={labelNewPeriod}
                        />
                    }
                />

                <ModalMain
                    open={visibleModalRejectedCreditCardPayment}
                    onRequestClose={() => setVisibleModalRejectedCreditCardPayment(false)}
                    width={600}
                    renderComponent={
                        <RejectedPayCreditCard
                            newSubscription={newSubscription}
                            labelNewPeriod={labelNewPeriod}
                        />
                    }
                />

                <ModalMain
                    open={visibleModalPaymentSuccess}
                    onRequestClose={() => setVisibleModalPaymentSuccess(false)}
                    width={600}
                    renderComponent={
                        <PaySuccess/>
                    }
                />

                <ModalMain
                    open={visibleModalChangePaymentInfo}
                    onRequestClose={() => setVisibleModalChangePaymentInfo(false)}
                    width={600}
                    renderComponent={
                        <InputsPaymentInfo
                            isEditExternal={true}
                            backPreviousScreenExternalScreen={() => setVisibleModalChangePaymentInfo(false)}
                            newPaymentInfo={newPaymentInfo}
                            onUpdate={onUpdatePaymentInfo}
                            onSearchCNPJ={onSearchCNPJ}
                            onSearchZipCode={onSearchZipCode}
                            onClickSavePaymentInfo={onClickSavePaymentInfo}
                            setActiveRoute={setActiveRoute}
                            loading={loading}
                            canReturnPaymentType={false}
                            handleEditPaymentInfo={() => {
                            }}
                        />
                    }
                />


                <ModalMain
                    open={visibleModalChangePaymentMethod}
                    onRequestClose={() => setVisibleModalChangePaymentMethod(false)}
                    width={700}
                    renderComponent={
                        <ContentPaymentMethod>
                            <PaymentMethod
                                isEditExternal={true}
                                backPreviousScreenExternalScreen={() => setVisibleModalChangePaymentMethod(false)}
                                newTokenizeCard={newTokenizeCard}
                                onUpdateTokenizeCard={onUpdateTokenizeCard}
                                pay={changePaymentMethod}
                                setActiveTabPayment={setActiveTabPayment}
                                onClickPayCreditCardSaved={() => setVisibleModalPay(true)}
                                loading={loading}
                                currentPayment={currentPayment}
                                cardSaved={cardSaved}
                                activeTabPayment={activeTabPayment}
                                makePaymentMethod={makePaymentMethod}
                                resetPaymentMethod={resetPaymentMethod}
                                isReprovedPayment={newSubscription.lastPayment?.isReproved}
                            />
                        </ContentPaymentMethod>
                    }
                />

                <ModalMain
                    open={visibleModalCancelSubscription}
                    onRequestClose={() => setVisibleModalCancelSubscription(false)}
                    width={600}
                    renderComponent={
                        <CancelSubscription
                            loading={loading}
                            onRequestClose={() => setVisibleModalCancelSubscription(false)}
                            cancellationReason={cancellationReason}
                            setCancellationReason={setCancellationReason}
                            onRequestSave={() => {
                                cancelSubscription();
                                setVisibleModalCancelSubscription(false);
                            }}
                        />
                    }
                />

                <ModalCancelLegacyPlan
                    open={visibleModalCancelLegacySubscription}
                    onRequestClose={() => {
                        setVisibleModalCancelLegacySubscription(false);
                    }}
                    textHeaderModal={'Cancelar assintura'}
                    textButton={'Cancelar'}
                    onRequestSave={async () => {
                        setVisibleModalCancelLegacySubscription(false);
                        setVisibleModalCancelSubscription(true);
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={110}
                    textInformation={
                        `Você está em um PLANO LEGADO, ao cancelar essa assinatura, você nunca mais poderá voltar nesse mesmo plano e valores, deseja realmente cancelar essa assinatura?.`
                    }
                    loading={loading}
                    isActionDelete={true}
                />

                <ModalMain
                    open={visibleModalReactiveSubscription}
                    onRequestClose={() => setVisibleModalReactiveSubscription(false)}
                    width={600}
                    renderComponent={
                        <ReactiveSubscription
                            loading={loading}
                            onRequestClose={() => setVisibleModalReactiveSubscription(false)}
                            onRequestSave={() => {
                                reactiveSubscription();
                                setVisibleModalReactiveSubscription(false);
                            }}
                        />
                    }
                />

                <ModalInformation
                    open={visibleModalLogout}
                    onRequestClose={() => {
                        setVisibleModalLogout(false);
                    }}
                    textHeaderModal={'Sair do sistema'}
                    textButton={'Sair'}
                    onRequestSave={async () => {
                        await logout();
                        setVisibleModalLogout(false);
                    }}
                    nameIcon={'logout'}
                    sizeIcon={130}
                    textInformation={`Deseja realmente sair do sistema?`}
                    loading={false}
                />

                {oldSubscription &&

                    <ModalInformation
                        open={visibleModalCancelFutureSubscription}
                        onRequestClose={() => {
                            setVisibleModalCancelFutureSubscription(false);
                        }}
                        textHeaderModal={'Desfazer troca de plano'}
                        textButton={'Confirmar'}
                        onRequestSave={() => {
                            cancelFutureSubscription();
                            setVisibleModalCancelFutureSubscription(false);
                        }}
                        nameIcon={ICONS_DROPDESK.info}
                        sizeIcon={130}
                        textInformation={`Deseja realmente desfazer a mudança de plano? Ao desfazer será restaurada a assinatura ${oldSubscription.planName}.`}
                        loading={false}
                    />}

                <ModalInformation
                    open={visibleModalDowngrade}
                    onRequestClose={() => {
                        setVisibleModalDowngrade(false);
                    }}
                    textHeaderModal={'Downgrade de plano'}
                    textButton={'Confirmar'}
                    onRequestSave={() => {
                        downgradePlan();
                        setVisibleModalDowngrade(false);
                    }}
                    nameIcon={ICONS_DROPDESK.info}
                    sizeIcon={130}
                    textInformation={textDowngrade}
                    loading={false}
                />

            </>
        )
    })
export default ModalsSubscription;
