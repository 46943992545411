import {TypeChatSocket} from "@dropDesk/domain/entities/chat/chat_enum";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export class SocketResponseContent {
    idTicket?: string;
    ticket?: Record<string, any>;
    messages?: Array<Record<string, any>>;
    notification?: Record<string, any>;

    constructor({
                    idTicket,
                    ticket,
                    messages,
                    notification,
                }: {
        idTicket?: string;
        ticket?: Record<string, any>;
        messages?: Array<Record<string, any>>;
        notification?: Record<string, any>;
    }) {
        Object.assign(this, {
            idTicket,
            ticket,
            messages,
            notification,
        });
    }
}

export class SocketResponse {
    type!: TypeChatSocket;
    rooms!: string[];
    idTicket?: string;
    tickets?: TicketEntity[] | null;
    messages?: TicketMessageEntity[] | null;
    notification?: Record<string, any>;

    constructor({
                    type,
                    rooms,
                    tickets,
                    messages,
                    idTicket,
                    notification,
                }: {
        type: TypeChatSocket,
        rooms: string[];
        tickets?: TicketEntity[] | null;
        messages?: TicketMessageEntity[] | null;
        idTicket?: string;
        notification?: Record<string, any>;
    }) {
        Object.assign(this, {
            type,
            rooms,
            tickets,
            messages,
            idTicket,
            notification,
        });
    }

    public get hasUnknownError() {
        return this.type === TypeChatSocket.unknown;
    }

    public get isInformRooms() {
        return this.type === TypeChatSocket.informRooms;
    }

    public isEmptyData(user: UserEntity) {
        return this.eligibleMessages(user).length === 0 && !this.tickets && !this.notification;
    }

    public eligibleMessages(user: UserEntity): Array<TicketMessageEntity> {
        const allMessages = [
            ...(this.messages ?? []),
        ];
        return user.isAttendant
            ? allMessages
            : allMessages.filter((message) => !message.isPrivate);
    }

    public getTicketList(): Array<string> {
        return [
            ...new Set([
            ...(this.tickets?.map(entry => entry.id) ?? []),
            ...(this.messages?.map(entry => entry.idTicket) ?? []),
            ...(this.idTicket ? [this.idTicket] : [])
        ])
        ]
    }

    public static fromJson(json: SocketResponseEntity): SocketResponse {
        return new SocketResponse({
            type: json.type,
            rooms: json.type === TypeChatSocket.informRooms && json.rooms ? json.rooms : [],
            tickets: json.type === TypeChatSocket.ticket && json.data?.ticket ? [TicketEntity.fromJson(json.data!.ticket)] : null,
            messages: json.data?.messages && json.data?.messages.length > 0 ?
                json.data!.messages.map((entry: any) => TicketMessageEntity.fromJson(entry)) : null,
            idTicket: json.data?.idTicket ?? json.data?.notification?.idTicket,
            notification: json.data?.notification,
        });
    }
}


export class SocketResponseEntity {
    type!: TypeChatSocket;
    data?: SocketResponseContent;
    rooms?: Array<string>;
    error?: string;

    constructor({
                    type,
                    data,
                    rooms,
                    error,
                }: {
        type: TypeChatSocket;
        data?: SocketResponseContent;
        rooms?: Array<string>;
        error?: string;
    }) {
        Object.assign(this, {
            type,
            data,
            rooms,
            error,
        });
    }
}
