import {inject, injectable} from "inversify";
import {action, computed, configure, makeObservable, observable} from "mobx";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {
    SetCompanyConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_company_configurations.usecase";
import {
    GetCompanyConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/find_by_pk_company_configurations.usecase";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {
    CompanyConfigurationEntity
} from "@dropDesk/domain/entities/company_configurations/company_configuration.entity";
import {TicketConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/ticket_configuration.entity";
import {CreateObjectUrlBlobUseCase} from "@dropDesk/domain/use_case/io/create_object_url_blob.usecase";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {SetThemeCompanyUseCase} from "@dropDesk/domain/use_case/company_configurations/set_theme_company.usecase";
import {
    SetTicketConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_ticket_configurations.usecase";
import {
    SetChatConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_ticket_chat.usecase";
import {
    SetBusinessOperatorConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_ticket_business_operator.usecase";
import {
    SetPortalClientConfigurationUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_portal_client_configuration.usecase";
import {
    newCompanyConfiguration, newPanelAdm
} from "@dropDesk/presentation/pages/company_configurations/controller/new_configuration";
import {
    ResetChatMessagesCompanyUseCase
} from "@dropDesk/domain/use_case/company_configurations/reset_chat_messages_company.usecase";
import {ThemeEntity} from "@dropDesk/domain/entities/theme/theme.entity";
import {ThemeConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/theme_configuration.entity";
import {
    SetConfigurationNotificationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_notification_configuration.usecase";
import {PanelAdmEntity} from "@dropDesk/domain/entities/company_configurations/panel_adm.entity";
import {
    SetPanelAdmConfigurationsUseCase
} from "@dropDesk/domain/use_case/company_configurations/set_panel_adm_configurations.usecase";

configure({
    enforceActions: "always",
});

@injectable()
export class CompanyConfigurationController {

    private readonly _setCompanyConfigurationsUseCase: SetCompanyConfigurationsUseCase;
    private readonly _findByPKCompanyConfigurationsUseCase: GetCompanyConfigurationsUseCase;
    private readonly _createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase;
    private readonly _setThemeCompanyUseCase: SetThemeCompanyUseCase;
    private readonly _setTicketConfigurationsUseCase: SetTicketConfigurationsUseCase;
    private readonly _setChatConfigurationsUseCase: SetChatConfigurationsUseCase;
    private readonly _setBusinessOperatorConfigurationsUseCase: SetBusinessOperatorConfigurationsUseCase;
    private readonly _setPortalClientConfigurationUseCase: SetPortalClientConfigurationUseCase;
    private readonly _resetChatMessagesCompanyUseCase: ResetChatMessagesCompanyUseCase;
    private readonly _setConfigurationNotificationsUseCase: SetConfigurationNotificationsUseCase;
    private readonly _setPanelAdmConfigurationsUseCase: SetPanelAdmConfigurationsUseCase;


    constructor(
        @inject(GetCompanyConfigurationsUseCase) listCompanyConfigurationsUseCase: GetCompanyConfigurationsUseCase,
        @inject(CreateObjectUrlBlobUseCase) createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase,
        @inject(SetThemeCompanyUseCase) setThemeCompanyUseCase: SetThemeCompanyUseCase,
        @inject(SetCompanyConfigurationsUseCase) setCompanyConfigurationsUseCase: SetCompanyConfigurationsUseCase,
        @inject(SetTicketConfigurationsUseCase) setTicketConfigurationsUseCase: SetTicketConfigurationsUseCase,
        @inject(SetBusinessOperatorConfigurationsUseCase) setBusinessOperatorConfigurationsUseCase: SetBusinessOperatorConfigurationsUseCase,
        @inject(SetChatConfigurationsUseCase) setChatConfigurationsUseCase: SetChatConfigurationsUseCase,
        @inject(SetPortalClientConfigurationUseCase) setPortalClientConfigurationUseCase: SetPortalClientConfigurationUseCase,
        @inject(ResetChatMessagesCompanyUseCase) resetChatMessagesCompanyUseCase: ResetChatMessagesCompanyUseCase,
        @inject(SetConfigurationNotificationsUseCase) setConfigurationNotificationsUseCase: SetConfigurationNotificationsUseCase,
        @inject(SetPanelAdmConfigurationsUseCase) setPanelAdmConfigurationsUseCase: SetPanelAdmConfigurationsUseCase,
    ) {
        makeObservable(this);
        this._setCompanyConfigurationsUseCase = setCompanyConfigurationsUseCase;
        this._findByPKCompanyConfigurationsUseCase = listCompanyConfigurationsUseCase;
        this._setThemeCompanyUseCase = setThemeCompanyUseCase;
        this._setTicketConfigurationsUseCase = setTicketConfigurationsUseCase;
        this._createObjectURLBlobUseCase = createObjectURLBlobUseCase;
        this._setBusinessOperatorConfigurationsUseCase = setBusinessOperatorConfigurationsUseCase;
        this._setChatConfigurationsUseCase = setChatConfigurationsUseCase;
        this._setPortalClientConfigurationUseCase = setPortalClientConfigurationUseCase;
        this._resetChatMessagesCompanyUseCase = resetChatMessagesCompanyUseCase;
        this._setConfigurationNotificationsUseCase = setConfigurationNotificationsUseCase;
        this._setPanelAdmConfigurationsUseCase = setPanelAdmConfigurationsUseCase;
    }

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    companyConfiguration: CompanyConfigurationEntity = newCompanyConfiguration();

    @observable
    panelAdm: PanelAdmEntity = newPanelAdm();

    @observable
    fileLogoBrand?: File | null;


    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }

    @action
    createObjectURLBlobUseCase(file: File): string {
        return this._createObjectURLBlobUseCase.call(file);
    }


    @action
    setFileLogoBrand(value?: File | null) {
        this.fileLogoBrand = value;
    }

    @action
    setCompanyConfiguration(companyConfiguration: CompanyConfigurationEntity) {
        this.companyConfiguration = companyConfiguration;
    }

    @action
    setPanelAdm(panelAdm: PanelAdmEntity) {
        this.panelAdm = panelAdm;
    }

    @action
    setCompanyConfigurationTicket(companyConfigurationTicket: TicketConfigurationEntity) {
        this.companyConfiguration.ticket = companyConfigurationTicket;
    }

    @computed
    get resolveImageLogoBrand(): string | null {
        if (this.fileLogoBrand) {
            return this._createObjectURLBlobUseCase.call(this.fileLogoBrand);
        }
        if (this.fileLogoBrand === null) {
            return null;
        }
        return this.companyConfiguration.theme.logoBrandUrl;
    }

    @action
    setTheme = async (
        currentThemeConfiguration: ThemeConfigurationEntity,
        onUpdateFileAndCurrentUser: (url?: string, name?: string, removeLogo?: boolean) => void,
        name?: string,
    ): Promise<void> => {
        try {

            this.startLoading("configuration.loadingSaveTheme");
            const removeLogo = this.fileLogoBrand === null && !!currentThemeConfiguration.logoBrandUrl;
            await this._setThemeCompanyUseCase.call(name, this.fileLogoBrand ?? undefined, removeLogo);
            onUpdateFileAndCurrentUser(this.fileLogoBrand ? this.createObjectURLBlobUseCase(this.fileLogoBrand) : undefined, name, removeLogo);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }


    @action
    setTicketConfiguration = async (
        onChangeConfigurationLocal: (configuration: CompanyConfigurationEntity) => void,
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("configuration.loadingSave");
            const newCompany = await this._setTicketConfigurationsUseCase.call(this.companyConfiguration.ticket);
            onChangeConfigurationLocal(newCompany);
            onSuccess("configuration.success.save");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    setWhatsAppConfiguration = async (
        onChangeConfigurationLocal: (configuration: CompanyConfigurationEntity) => void,
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("configuration.loadingSave");
            const newCompany = await this._setChatConfigurationsUseCase.call(this.companyConfiguration.chat);
            onChangeConfigurationLocal(newCompany);
            onSuccess("configuration.success.save");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    setConfigurationNotification = async (
        onChangeConfigurationLocal: (configuration: CompanyConfigurationEntity) => void,
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("configuration.loadingSave");
            const newCompany = await this._setConfigurationNotificationsUseCase.call(this.companyConfiguration.notifications);
            onChangeConfigurationLocal(newCompany);
            onSuccess("configuration.success.save");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    resetWhatsAppMessages = async (
        onChangeConfigurationLocal: (configuration: CompanyConfigurationEntity) => void,
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("configuration.loadingSave");
            const company = await this._resetChatMessagesCompanyUseCase.call();
            this.setCompanyConfiguration(company);
            onChangeConfigurationLocal(company);
            onSuccess("configuration.success.save");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    setBusinessOperatorConfiguration = async (
        onChangeConfigurationLocal: (configuration: CompanyConfigurationEntity) => void,
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("configuration.loadingSave");
            const newCompany = await this._setBusinessOperatorConfigurationsUseCase.call(this.companyConfiguration.businessOperationHours);
            onChangeConfigurationLocal(newCompany);
            onSuccess("configuration.success.save");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    setPanelAdmConfiguration = async (
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("configuration.loadingSave");
            await this._setPanelAdmConfigurationsUseCase.call(this.panelAdm);
            onSuccess("configuration.success.save");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    setConfigurationPortalClient = async (
        onChangeConfigurationLocal: (configuration: CompanyConfigurationEntity) => void,
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("configuration.loadingSave");
            const newCompany = await this._setPortalClientConfigurationUseCase.call(this.companyConfiguration.portalClient);
            onChangeConfigurationLocal(newCompany);
            onSuccess("configuration.success.save");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    set = async (
        onChangeConfigurationLocal: (configuration: CompanyConfigurationEntity) => void,
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {

            this.startLoading("configuration.loadingSave");
            if (this.companyConfiguration.isUnsaved()) {
                this.setCompanyConfiguration(this.companyConfiguration.copyWith({
                    id: generateUUIDV4()
                }))
            }

            await this._setCompanyConfigurationsUseCase.call(this.companyConfiguration);
            onChangeConfigurationLocal(this.companyConfiguration);
            onSuccess("configuration.success.save");
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    findByPK = async (id: string): Promise<void> => {
        try {
            this.startLoading("configuration.loadingInit");
            const response = await this._findByPKCompanyConfigurationsUseCase.call(id);
            this.setCompanyConfiguration(response);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

}
