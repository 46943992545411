import {useState} from "react";

export const useModalViewTickets = () => {

    const [visibleModalMoreInformation, setVisibleModalMoreInformation] = useState<boolean>(false);
    const [visibleModalTransferTicket, setVisibleModalTransferTicket] = useState<boolean>(false);
    const [visibleModalTimeSpent, setVisibleModalTimeSpent] = useState<boolean>(false);
    const [visibleModalPriority, setVisibleModalPriority] = useState<boolean>(false);
    const [visibleModalCancellationTicket, setVisibleModalCancellationTicket] = useState<boolean>(false);
    const [visibleModalTicketClosed, setVisibleModalTicketClosed] = useState<boolean>(false);
    const [visibleModalActivities, setVisibleModalActivities] = useState<boolean>(false);
    const [visibleModalResponseStandard, setVisibleModalResponseStandard] = useState<boolean>(false);

    const [visibleModalCreateClient, setVisibleModalCreateClient] = useState<boolean>(false);
    const [visibleModalChangeProblemDescription, setVisibleModalChangeProblemDescription] = useState<boolean>(false);
    const [visibleModalChangeDateDue, setVisibleModalChangeDateDue] = useState<boolean>(false);
    const [visibleModalAttendChat, setVisibleModalAttendChat] = useState<boolean>(false);
    const [visibleModalExportPdf, setVisibleModalExportPdf] = useState<boolean>(false);
    const [visibleModalTicketRating, setVisibleModalTicketRating] = useState<boolean>(false);
    const [visibleModalChangeResolution, setVisibleModalChangeResolution] = useState<boolean>(false);
    const [visibleModalConnect, setVisibleModalConnect] = useState<boolean>(false);


    return {
        visibleModalMoreInformation, setVisibleModalMoreInformation,
        visibleModalTransferTicket, setVisibleModalTransferTicket,
        visibleModalTimeSpent, setVisibleModalTimeSpent,
        visibleModalPriority, setVisibleModalPriority,
        visibleModalCancellationTicket, setVisibleModalCancellationTicket,
        visibleModalTicketClosed, setVisibleModalTicketClosed,
        visibleModalActivities, setVisibleModalActivities,
        visibleModalResponseStandard, setVisibleModalResponseStandard,
        visibleModalCreateClient, setVisibleModalCreateClient,
        visibleModalChangeProblemDescription, setVisibleModalChangeProblemDescription,
        visibleModalChangeDateDue, setVisibleModalChangeDateDue,
        visibleModalAttendChat, setVisibleModalAttendChat,
        visibleModalExportPdf, setVisibleModalExportPdf,
        visibleModalTicketRating, setVisibleModalTicketRating,
        visibleModalChangeResolution, setVisibleModalChangeResolution,
        visibleModalConnect, setVisibleModalConnect
    }
}
