import "reflect-metadata";
import {inject, injectable} from "inversify";
import {UserRepository} from "@dropDesk/domain/repositories/user/user.repository";
import {Observable} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";

@injectable()
export class ExportUsersSubscriptionUseCase {
  private _repository: UserRepository;

  constructor(@inject(UserRepository) repository: UserRepository) {
    this._repository = repository;
  }

  public call(
      id: string,
      handleDisconnect: (temporary: boolean) => void,
      getLastUpdates: () => void,
      useSocket: boolean
  ): Observable<ExportDataEntity | null> {
    return this._repository.exportSubscription(id, handleDisconnect, getLastUpdates, useSocket);
  }
}

